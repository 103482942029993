import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.css'],
    animations: [fadeInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: PasswordInputComponent, multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, NgTemplateOutlet]
})

export class PasswordInputComponent implements ControlValueAccessor
{
	// #region Private Members

	private _innerValue: string | null = null;

	private _changed = new Array<(value: string | null) => void>();
	private _touched = new Array<() => void>();

	private _showPassword: boolean = false;

	@ViewChild('inputElement', { read: ElementRef, static: false }) private _inputElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

	// #endregion

	// #region Properties

	public get showPassword(): boolean
	{
		return this._showPassword;
	}

	public set showPassword(value: boolean)
	{
		this._showPassword = value;
	}

	public get value(): string | null
	{
		return this._innerValue;
	}

	public set value(value: string | null)
	{
		if (this._innerValue !== value)
		{
			this._innerValue = value;
			this._changed.forEach(f => f(value));
			this._touched.forEach(f => f());
			this.change.emit(value);
		}
	}

	// #endregion

    // #region Inputs

	@Input() public label: string = '';
	@Input() public isRequired: boolean = true;
	@Input() public isAutoFill: boolean = true;

	// #endregion

	// #region Events

	@Output() change: EventEmitter<string | null> = new EventEmitter<string | null>();

	// #endregion

	// #region Event Handlers

	public onShowPasswordIconMouseDown(event: MouseEvent): void
	{
		let selectionStart: number | null = null;
		if (this._inputElementRef !== undefined)
		{
			selectionStart = this._inputElementRef.nativeElement.selectionStart
		}

		this._showPassword = !this._showPassword;
		event.preventDefault();

		setTimeout(() =>
		{
			if (this._inputElementRef !== undefined)
			{
				this._inputElementRef.nativeElement.selectionStart = selectionStart;
			}
		});
	}

	// #endregion

	// #region Public Methods

	public touch(): void
	{
		this._touched.forEach(f => f());
	}

	public writeValue(value: string | null): void
	{
		this._innerValue = value;
	}

	public registerOnChange(fn: (value: string | null) => void): void
	{
		this._changed.push(fn);
	}

	public registerOnTouched(fn: () => void): void
	{
		this._touched.push(fn);
	}

	// #endregion
}  