import { ApplicationRef, ComponentRef, EmbeddedViewRef, EnvironmentInjector, Injectable, createComponent } from "@angular/core";
import { NavigationStart, Router, Event } from "@angular/router";
import { filter } from "rxjs";
import { BarcodeScannerComponent } from "../barcode-scanner.component";

@Injectable({ providedIn: 'root' })
export class BarcodeScannerService
{
    // #region Private Members

    private _barcodeScannerComponentRef: ComponentRef<BarcodeScannerComponent> | null = null;

    // #endregion

    // #region Properties

    public get instance(): BarcodeScannerComponent | null
    {
        return this._barcodeScannerComponentRef !== null ? this._barcodeScannerComponentRef.instance : null
    }

    public get isPaused(): boolean
    {
        return this._barcodeScannerComponentRef === null ? false : this._barcodeScannerComponentRef.instance.isPaused;
    }

    public set isPaused(isPaused: boolean)
    {
        if (this._barcodeScannerComponentRef !== null)
        {
            this._barcodeScannerComponentRef.instance.isPaused = isPaused;
        }
    }

    // #endregion

    // #region Constructor

    constructor(private _environmentInjector: EnvironmentInjector, private _applicationRef: ApplicationRef, private _router: Router)
    {
        this._router.events.pipe(
            filter((event: Event) =>
            {
                return (event instanceof NavigationStart);
            })).subscribe(() =>
            {
                this.destroy();
            });
    }

    // #endregion

    // #region Public Methods

    public scan(continuous: boolean = false): Promise<string | null>
    {
        return new Promise<string | null>((resolve) =>
        {
            this._barcodeScannerComponentRef = createComponent(BarcodeScannerComponent, {
                environmentInjector: this._environmentInjector
            });

            this._applicationRef.attachView(this._barcodeScannerComponentRef.hostView);

            this._barcodeScannerComponentRef.instance.continuous = continuous;

            this._barcodeScannerComponentRef.instance.barcodeScanned.subscribe((barcode: string) =>
            {
                return resolve(barcode);
            });

            this._barcodeScannerComponentRef.instance.close.subscribe(() =>
            {
                this.destroy();
                return resolve(null);
            });

            const barcodeScannerElement: HTMLElement = (this._barcodeScannerComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            document.body.insertBefore(barcodeScannerElement, document.body.firstChild);
        });
    }

    public stopScan(): void
    {
        this._barcodeScannerComponentRef?.instance.closeBarcodeScan();
    }

    // #endregion

    // #region Private Methods

    private destroy(): void
    {
        if (this._barcodeScannerComponentRef === null)
        {
            return;
        }

        this._applicationRef.detachView(this._barcodeScannerComponentRef.hostView);
        this._barcodeScannerComponentRef.destroy();

        this._barcodeScannerComponentRef = null;
    }

    // #endregion
}