<!DOCTYPE html>

@if (!('IS_MOBILE' | globals) && dropdownState !== DropdownState.Closed) {
<div #dropdownPanel class="picker-dropdown" [class.with-time]="withTime"
     [@.disabled]="dropdownState === DropdownState.Opening">
    <ng-container [ngTemplateOutlet]="withTime ? calendarWithTimeTemplate : calendarTemplate"></ng-container>
</div>
}

<div class="form-group ripple-effect ripple-inverse-color" (mousedown)="onInputGroupMouseDown($event)">
    <input name="datetime-picker" #input readonly type="text" class="form-control clearable has-icon" placeholder=" " [value]="formattedValue" />
    <div class="control-label" [class.required]="isRequired" [innerHTML]="label"></div>
    <i class="control-icon press-icon" [class]="withTime ? 'icon-calendar-clock' : 'icon-calendar-lines'"></i>
    @if (input.value && clearable && !disabled) {
    <i class="icon-cancel control-icon press-icon ripple-effect ripple-center" (mousedown)="onClearMouseDown($event)" @fadeInOutAnimation></i>
    }
</div>

@if (('IS_MOBILE' | globals) && dropdownState !== DropdownState.Closed) {
<div class="overlay" @fadeInOutAnimation (click)="onCloseClick()"></div>
}
@if (('IS_MOBILE' | globals) && dropdownState !== DropdownState.Closed) {
<div class="picker-fixed-container" @fadeZoomInOutAnimation
     (@fadeZoomInOutAnimation.done)="dateTimeTabIndex = 0">
    <div class="picker-mobile-container">
        <div class="picker-header select-none" [class.with-time]="withTime">
            <div class="picker-title" [innerHTML]="label"></div>
            <div class="picker-values">
                <div [innerHTML]="formattedDateValue"></div>
                @if (withTime) {
                <div>{{ formattedTimeValue }}</div>
                }
            </div>
        </div>
        <div class="picker-dropdown" [class.with-time]="withTime">
            <ng-container [ngTemplateOutlet]="withTime ? calendarWithTimeTemplate : calendarTemplate"></ng-container>
        </div>
    </div>
</div>
}

<ng-template #calendarTemplate>
    <ng-container [ngTemplateOutlet]="calendarDateTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="calendarModalButtonsTemplate"></ng-container>
</ng-template>

<ng-template #calendarWithTimeTemplate>
    <tabs-control class="full-height" [(tabIndex)]="dateTimeTabIndex">
        <tab-item>
            <ng-template tabItemTitleTemplate>
                <i class="icon-calendar-lines"></i>
            </ng-template>
            <ng-template tabContentTemplate>
                <ng-container [ngTemplateOutlet]="calendarDateTemplate"></ng-container>
            </ng-template>
        </tab-item>
        <tab-item>
            <ng-template tabItemTitleTemplate>
                <i class="icon-clock"></i>
            </ng-template>
            <ng-template tabContentTemplate>
                <time-picker-view [(ngModel)]="editValue" [time24Hours]="time24Hours"></time-picker-view>
            </ng-template>
        </tab-item>
    </tabs-control>
    <ng-container [ngTemplateOutlet]="calendarModalButtonsTemplate"></ng-container>
</ng-template>

<ng-template #calendarModalButtonsTemplate>
    @if (('IS_MOBILE' | globals)) {
    <div class="picker-modal-buttons-container">
        <button type="button" class="ripple-effect ripple-center ripple-inverse-color" (click)="onCloseClick()">Cancel</button>
        <button type="button" class="primary ripple-effect ripple-center ripple-inverse-color" (click)="onApplyClick()">OK</button>
    </div>
    }
</ng-template>

<ng-template #calendarDateTemplate>
    <div class="calendar-container picker-frame">
        <div class="calendar-navigation-container select-none">
            <button class="calendar-nav-button calendar-view-button ripple-effect ripple-inverse-color" (click)="onCalendarViewButtonClick($event)"
                    [disabled]="calendarViewMode === CalendarViewMode.Years">
                {{ calendarViewModeDisplay }}
            </button>
            <div class="calendar-navigation-buttons-container">
                <button class="calendar-nav-button ripple-effect ripple-center ripple-inverse-color" (click)="onPreviousButtonClick($event)">
                    <i class="icon-arrow-up"></i>
                </button>
                <button class="calendar-nav-button ripple-effect ripple-center ripple-inverse-color" (click)="onLocateButtonClick($event)">
                    <i class="icon-center"></i>
                </button>
                <button class="calendar-nav-button ripple-effect ripple-center ripple-inverse-color" (click)="onNextButtonClick($event)">
                    <i class="icon-arrow-down"></i>
                </button>
            </div>
        </div>
        <div class="calendar-views-container select-none" pointerEvents (swipe)="onCalendarViewSwipe($event)">
            @if (calendarViewMode === CalendarViewMode.Days && !isAlternateDaysView) {
            <div class="calendar-view-container weeks-view" @fadeZoomInOutAnimation>
                <ng-container [ngTemplateOutlet]="calendarWeeksTemplate"></ng-container>
            </div>
            }
            @if (calendarViewMode === CalendarViewMode.Days && isAlternateDaysView) {
            <div class="calendar-view-container weeks-view" @fadeZoomInOutAnimation>
                <ng-container [ngTemplateOutlet]="calendarWeeksTemplate"></ng-container>
            </div>
            }
            <ng-template #calendarWeeksTemplate>
                <div class="calendar-day-names-container">
                    @for (name of daysName; track name) {
                    <div class="calendar-day-name">{{name}}</div>
                    }
                </div>
                <div #calendarWeeks class="calendar-weeks-wrapper">
                    <div class="calendar-weeks-container">
                        @for (day of viewDates; track day) {
                        <div class="calendar-day">
                            <button type="button" class="calendar-item calendar-number" [disabled]="!isDateEnabled(day)"
                                    [ngClass]="{'today': isToday(day), 'selected': isSelected(day), 'out-scope': !isSelectedMonth(day)}"
                                    (mousedown)="onDateMouseDown($event, day)">
                                <span>{{day.date()}}</span>
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </ng-template>
            @if (calendarViewMode === CalendarViewMode.Months) {
            <div class="calendar-view-container" @fadeZoomInOutAnimation>
                <div #calendarMonths class="calendar-months-wrapper">
                    <div class="calendar-months-container">
                        @for (month of viewDates; track month) {
                        <div class="calendar-month">
                            <button type="button" class="calendar-item calendar-month" [disabled]="!isDateEnabled(month)"
                                    [ngClass]="{'today': isToday(month), 'selected': isSelected(month), 'out-scope': !isSelectedYear(month)}"
                                    (mousedown)="onDateMouseDown($event, month)">
                                <span>{{month.format('MMM')}}</span>
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
            @if (calendarViewMode === CalendarViewMode.Years) {
            <div class="calendar-view-container" @fadeZoomInOutAnimation>
                <div #calendarYears class="calendar-years-wrapper">
                    <div class="calendar-years-container">
                        @for (year of viewDates; track year) {
                        <div class="calendar-year">
                            <button type="button" class="calendar-item calendar-year" [disabled]="!isDateEnabled(year)"
                                    [ngClass]="{'today': isToday(year), 'selected': isSelected(year)}"
                                    (mousedown)="onDateMouseDown($event, year)">
                                <span>{{year.format('YYYY')}}</span>
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</ng-template>