import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { TooltipDirective } from '../../directives/tooltip.directive';

@Component({
    selector: 'clearable-input',
    templateUrl: './clearable-input.component.html',
    styleUrls: ['./clearable-input.component.css'],
    animations: [fadeInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: ClearableInputComponent, multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, TooltipDirective]
})

export class ClearableInputComponent implements ControlValueAccessor
{
	// #region Private Members

	private _innerValue: string | null = null;

	private _changed = new Array<(value: string | null) => void>();
	private _touched = new Array<() => void>();

	@ViewChild('clerableInput', { read: ElementRef, static: false }) private _inputElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

	// #endregion

	// #region Properties

	public get value(): string | null
	{
		return this._innerValue;
	}

	public set value(value: string | null)
	{
		if (this._innerValue !== value)
		{
			this._innerValue = value;
			this._changed.forEach(f => f(value));
			this._touched.forEach(f => f());
			this.change.emit(value);
		}
	}

	// #endregion

    // #region Inputs

	@Input() public readonly: boolean = false;
	@Input() public disabled: boolean = false;
	@Input() public type: string = 'text';
	@Input() public label: string = '';
	@Input() public icon: string | null = null;
	@Input() public isRequired: boolean = false;
	@Input() public trimOnPaste: boolean = true;
	@Input() public maxLength: number | null = null;
	@Input() public min: string | null = null;
	@Input() public max: string | null = null;

	// #endregion

	// #region Events

	@Output() private change: EventEmitter<string | null> = new EventEmitter<string | null>();

	// #endregion

	// #region Constructors

	constructor(private _elementRef: ElementRef<HTMLElement>)
	{
	}

    // #endregion

    // #region Events Hanlders

	@HostListener('focus') onFocus()
	{
		this.focus();
	}

	public onPaste(event: ClipboardEvent): void
	{
		if (!this.trimOnPaste || event.clipboardData === null || this._inputElementRef === undefined || this._inputElementRef.nativeElement.type !== 'text')
		{
			return;
		}

		event.preventDefault();

		let prefixText: string = '', suffixText: string = '';
		const innerValue: string | null = this._innerValue !== null ? this._innerValue.toString() : null;

		if (this._inputElementRef !== null && innerValue !== null)
		{
			if (this._inputElementRef.nativeElement.selectionStart !== this._inputElementRef.nativeElement.selectionEnd)
			{
				prefixText = innerValue.substring(0, this._inputElementRef.nativeElement.selectionStart ?? 0);
				suffixText = innerValue.substring(this._inputElementRef.nativeElement.selectionEnd ?? 0, innerValue.length);
			}
			else
			{
				prefixText = innerValue.substring(0, this._inputElementRef.nativeElement.selectionStart ?? 0);
				suffixText = innerValue.substring(this._inputElementRef.nativeElement.selectionStart ?? 0, innerValue.length);
			}
		}

		const clipboardData: string = event.clipboardData.getData('Text').trim();
		this.value = `${prefixText}${clipboardData}${suffixText}`;

		if (this._inputElementRef !== null)
		{
			const position: number = `${prefixText}${clipboardData}`.length;
			requestAnimationFrame(() => this._inputElementRef!.nativeElement.setSelectionRange(position, position));
		}
	}

    // #endregion

	// #region Public Methods

	public focus(): void
	{
		this._elementRef.nativeElement.querySelector('input')?.focus();
	}

	public touch(): void
	{
		this._touched.forEach(f => f());
	}

	public writeValue(value: string | null): void
	{
		this._innerValue = value;
	}

	public registerOnChange(fn: (value: string | null) => void): void
	{
		this._changed.push(fn);
	}

	public registerOnTouched(fn: () => void): void
	{
		this._touched.push(fn);
	}

	// #endregion
}  