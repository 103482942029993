<!DOCTYPE html>

<div class="page-container">
    @if (viewIsInitialized) {
    <div class="page-content">
        <div class="map-section" [@foldVerticalStateAnimation]="!collapseEventsMonitoringMapSection">
            @if (!collapseMonitoringItemsFilters) {
            <div class="live-filters-wrapper animation-overflow" [class.full-height]="collapseEventsMonitoringTable" [class.hidden]="collapseEventsMonitoringMapSection"
                 @foldHorizontalAnimation>
                <ng-container [ngTemplateOutlet]="liveFiltersTemplate"></ng-container>
            </div>
            }
            <div class="map-wrapper" [class.full-height]="collapseEventsMonitoringTable && collapseMonitoringItemsFilters"
                 [class.full-width]="collapseMonitoringItemsFilters">
                <google-map #googleMap [showTreetView]="true" (initialize)="onGoogleMapInitialize($event)">
                    <div class="map-controls-container top">
                        @if (!('IS_MOBILE' | globals) && collapseMonitoringItemsFilters) {
                        <button type="button" [disabled]="!monitoringItemsModel.isMonitoringFiltersActive" @fadeInOutAnimation
                                class="clear-filters-button floating-button ripple-effect ripple-fill" (click)="onClearMonitoringItemsFiltersButtonClick()">
                            <i class="icon-filter-circle-xmark"></i>
                        </button>
                        }
                        <div class="popup-button-container more-options">
                            <div class="dropdown" dropdown [(isExpanded)]="showMonitoringPopups[currentMonitoringPopup]" (isExpandedChange)="onShowMonitoringItemsPopupsChange()">
                                <button type="button" class="floating-button ripple-effect ripple-fill" (click)="onMapMoreOptionsButtonClick($event)">
                                    <i class="icon-more"></i>
                                </button>
                                @if (showMonitoringPopups[MonitoringPopupType.More]) {
                                <div class="dropdown-container left" @foldHorizontalAnimation
                                     (@foldHorizontalAnimation.done)="onShowMapMoreOptionsFoldAnimationDone()">
                                    <button type="button" class="menu-button" (click)="onRefreshButtonClick()"><i class="icon-refresh"></i>Refresh</button>
                                    <button type="button" class="menu-button" (click)="onExportToExcelButtonClick()">
                                        <i class="icon-excel"></i>Export to Excel
                                    </button>
                                    <button type="button" class="menu-button" (click)="onShareSnapshotMenuItemClick()">
                                        <i class="icon-share-nodes"></i>Share Snapshot
                                    </button>
                                    <button type="button" class="menu-button" (click)="onHelpButtonClick($event)"><i class="icon-question"></i>Help</button>
                                    <div class="seperator"></div>
                                    <button type="button" class="menu-button" (click)="showMapRailways = !showMapRailways">
                                        <i class="icon-train-station"></i>Show Railways
                                        <i [class.hide]="!showMapRailways" class="icon-check"></i>
                                    </button>
                                    <div class="seperator"></div>
                                    <button type="button" class="menu-button" (click)="onMapModeButtonClick(true)">
                                        <i class="icon-earth-americas"></i>Satellite View
                                        @if (isSatelliteView) {
                                        <i class="icon-check"></i>
                                        }
                                    </button>
                                    <button type="button" class="menu-button" (click)="onMapModeButtonClick(false)">
                                        <i class="icon-map"></i>Map View
                                        @if (!isSatelliteView) {
                                        <i class="icon-check"></i>
                                        }
                                    </button>
                                </div>
                                }
                                @if (showMonitoringPopups[MonitoringPopupType.Help]) {
                                <div class="popup-button-content-container help select-none" [class.small]="!collapseEventsMonitoringTable"
                                     @foldHorizontalAnimation>
                                    <ng-container [ngTemplateOutlet]="popupHelpContentTemplate"></ng-container>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="map-controls-container bottom">
                        <button type="button" class="center-button floating-button ripple-effect ripple-fill" (click)="onReCenterMapButtonClick()">
                            @if (!isMarkerActive) {
                            <i class="icon-target" @fadeInOutAnimation></i>
                            }
                            @if (isMarkerActive) {
                            <i class="icon-center" @fadeInOutAnimation></i>
                            }
                        </button>
                    </div>
                </google-map>
                <div class="vertical-expander" [class.expanded]="collapseMonitoringItemsFilters">
                    <button type="button" class="ripple-effect ripple-center ripple-inverse-color dropdown-shadow" [class.flipped]="collapseMonitoringItemsFilters"
                            (click)="collapseMonitoringItemsFilters = !collapseMonitoringItemsFilters">
                        <i class="icon-arrow-left"></i>
                    </button>
                </div>
                <div class="horizontal-expander">
                    <button type="button" class="ripple-effect ripple-center ripple-inverse-color dropdown-shadow" [class.flipped]="collapseEventsMonitoringTable"
                            (click)="collapseEventsMonitoringTable = !collapseEventsMonitoringTable">
                        <i class="icon-arrow-down"></i>
                    </button>
                </div>
            </div>
        </div>
        @if (!collapseEventsMonitoringMapSection && !collapseEventsMonitoringTable) {
        <div class="divider" @foldHorizontalAnimation></div>
        }
        <div class="live-manager-table-wrapper animation-overflow" [@foldVerticalStateAnimation]="!collapseEventsMonitoringTable"
             [class.full-height]="collapseEventsMonitoringMapSection" [class.collapse]="collapseEventsMonitoringTable">
            <div class="manager-table-container page-section page-section-padding">
                <virtual-list #monitoringItemsList [items]="monitoringItemsModel.filteredMonitoringItems" id="live-manager-events-table" class="manager-table"
                              [isSortable]="true" tabindex="-1" [virtualListInfo]="eventsMonitoringTableListInfo" [isAutoColumnsWidths]="true"
                              [filterProperties]="tableMonitoringItemsSearchProperties"
                              [class.vertical-scrollable]="monitoringItemsList.element.scrollHeight > monitoringItemsList.element.clientHeight"
                              (itemsChange)="onMonitoringItemsTableItemsChange()">
                    <div #header class="list-header">
                        @for (column of tableMonitoringItemsColumns; track column.columnType) {
                        <div class="col-list" [class]="column.classes ?? ''" [attr.property-name]="column.propertyName"
                             [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                            @switch (column.columnType) {
                            @case (EventsMonitoringColumnType.Copy) {
                            }
                            @case (EventsMonitoringColumnType.Share) {
                            }
                            @case (EventsMonitoringColumnType.Track) {
                            }
                            @default {
                            <span [innerHTML]="column.text"></span>
                            }
                            }
                        </div>
                        }
                    </div>
                    @for (monitoringItem of monitoringItemsList.viewPortItems; track monitoringItem.id) {
                    <div #body class="list-row ripple-effect ripple-fill ripple-inverse-color"
                         [ngClass]="{ 'odd-row': monitoringItemsList.filteredSortedItems.indexOf(monitoringItem) % 2,
                         'selected': monitoringItem === monitoringItemsModel.selectedMonitoringItem }"
                         (click)="onMonitoringItemsTableItemClick(monitoringItem)">
                        @for (column of tableMonitoringItemsColumns; track column.columnType) {
                        <div class="col-list" [class]="column.classes ?? ''">
                            @switch (column.columnType) {
                            @case (EventsMonitoringColumnType.Copy) {
                            <div class="dropdown" #showCopyOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownCopyEventInfoTemplate"
                                 [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                        tooltipPopup="Copy" (click)="onShareButtonClick($event)">
                                    <i class="icon-copy"></i>
                                </button>
                            </div>
                            }
                            @case (EventsMonitoringColumnType.Share) {
                            <div class="dropdown" #showShareOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownShareEventInfoTemplate"
                                 [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                        tooltipPopup="Share" (click)="onShareButtonClick($event)">
                                    <i class="icon-share-nodes"></i>
                                </button>
                            </div>
                            }
                            @case (EventsMonitoringColumnType.Type) {
                            <i [class]="shipmentEventsStyleProperties[monitoringItem.eventType].iconClassName"></i>
                            <default-list-column [column]="column" [item]="monitoringItem"></default-list-column>
                            }
                            @case (EventsMonitoringColumnType.Location) {
                            <i [class]="monitoringItem.perimeterIconClass"></i>
                            <default-list-column [column]="column" [item]="monitoringItem"></default-list-column>
                            }
                            @case (EventsMonitoringColumnType.Track) {
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                    tooltipPopup="Track" (click)="onMarkerShipmentTrackButtonClick($event, monitoringItem)">
                                <i class="icon-route"></i>
                            </button>
                            }
                            @default {
                            <default-list-column [column]="column" [item]="monitoringItem"></default-list-column>
                            }
                            }
                        </div>
                        }
                    </div>
                    }
                </virtual-list>
            </div>
            <div class="horizontal-expander top">
                <button type="button" class="ripple-effect ripple-center ripple-inverse-color" [class.flipped]="!collapseEventsMonitoringMapSection"
                        (click)="collapseEventsMonitoringMapSection = !collapseEventsMonitoringMapSection">
                    <i class="icon-arrow-down"></i>
                </button>
            </div>
        </div>
    </div>
    }
    @if (!viewIsReady || monitoringItemsModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation [loadingMessage]="statusMessage"
            [showSpinner]="showSpinner || monitoringItemsModel.isBusy" [isBusyLoader]="viewIsReady && monitoringItemsModel.isBusy" [isBusyLoaderOverlay]="true">
    </loader>
    }
</div>

<!-- dropdownShareEventInfoTemplate -->
<ng-template #dropdownShareEventInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, false)">
            <i class="icon-list-ul"></i>Share event's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, false)">
            <i class="icon-location-dot"></i>Share event's location
        </button>
    </div>
</ng-template>

<!-- dropdownCopyEventInfoTemplate -->
<ng-template #dropdownCopyEventInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, true)">
            <i class="icon-list-ul"></i>Copy event's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, true)">
            <i class="icon-location-dot"></i>Copy event's location
        </button>
    </div>
</ng-template>

<!-- popupHelpContentTemplate -->
<ng-template #popupHelpContentTemplate>
    <div class="popup-button-content-wrapper" (click)="onPopupContentClick($event)">
        <div class="popup-button-content page-scroll-padding" overlayScrollbar>
            <div class="help-title">How to use the map?</div>
            <div class="help-value">
                <p>The map shows events and groups of events.</p>
                <p>Groups of events are shown as a circle with the number of events.</p>
            </div>
            <div class="help-label">Click</div>
            <div class="help-value">
                <p>Clicking an event on the map shows its details.</p>
                <p>Clicking a group of events shows a list of their combined events.</p>
            </div>
            <div class="help-label">Double Click</div>
            <div class="help-value">
                <p>Double-clicking an event or a group of events on the map zooms the map in on the clicked item. Zooming in may show some of the group's events separately.</p>
            </div>
            <div class="help-label">Events Icons</div>
            <div class="help-value icons-index">
                @for (item of eventsIconsDisplayProperties; track $index) {
                <i [class]="item.iconClassName"></i><span>{{ item.tooltip }}</span>
                }
            </div>
        </div>
    </div>
</ng-template>

<!-- filterIconTemplate -->
<ng-template #filterIconTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="monitoringItemsModel.isMonitoringFiltersActive">
        <path d="M 490.30386,94.188357 316.45472,303.83385 v 140.51079 c 0,18.38318 -20.6905,28.91207 -35.55149,18.51503 l -75.34091,-52.7198 c -6.07436,-4.21909 -9.65305,-11.18812 -9.65305,-18.53386 V 303.83385 L 22.036581,94.188357 C 5.7064391,74.496129 20.000493,45.037835 45.886687,45.037835 H 466.4773 c 25.89843,0 39.36562,29.458294 23.82656,49.150522 z"></path>
    </svg>
</ng-template>

<!-- filterButtonIconTemplate -->
<ng-template #filterButtonIconTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" [class.active]="monitoringItemsModel.isMonitoringFiltersActive">
        <path d="m 223.1,340.7 v -68.5 c 0,-7.369 -2.539,-14.51 -7.191,-20.22 L 50.77,48.04 h 410.4 l -85.57,105.1 c -7.77,9.545 -7.061,23.42 1.643,32.13 10.06,10.06 26.62,9.219 35.6,-1.812 l 88.61,-108.8 c 11.26,-13.46 13.7,-31.89 6.225,-47.92 C 500,10.23 483.8,0 465.3,0 H 46.65 C 28.21,0 11.97,10.23 4.283,26.71 -3.195,42.73 -0.7578,61.16 10.64,74.79 l 165.4,203.1 v 67.71 c 0,12.55 6.178,24.3 16.52,31.45 l 24.81,19.15 c 15.73,12.2 38.63,1 38.63,-19 0,-7.439 -3.449,-14.46 -9.34,-19 z" />
        <path d="M 432 224 C 352.5 224 288 288.5 288 368 C 288 447.5 352.47 512 432 512 C 511.53 512 576 447.53 576 368 C 576 288.47 511.5 224 432 224 z M 386.75781 306.7793 C 390.85131 306.78287 394.94531 308.3494 398.07031 311.4707 L 431.98047 345.38086 L 465.89062 311.4707 C 472.13263 305.2287 482.26195 305.21628 488.50195 311.45898 C 494.74195 317.70168 494.73419 327.82812 488.49219 334.07812 L 454.58203 367.98828 L 488.5 401.90039 C 494.742 408.14239 494.75177 418.26953 488.50977 424.51953 C 482.26977 430.76153 472.14062 430.75011 465.89062 424.50781 L 431.98047 390.59961 L 398.07031 424.50781 C 391.82831 430.74981 381.69922 430.76193 375.44922 424.51953 C 369.19922 418.27713 369.21894 408.14844 375.46094 401.89844 L 409.36914 367.99023 L 375.46094 334.08008 C 369.21894 327.83808 369.20917 317.70898 375.45117 311.45898 C 378.57217 308.33398 382.66431 306.77572 386.75781 306.7793 z " />
    </svg>
</ng-template>

<!-- liveFiltersTemplate -->
<ng-template #liveFiltersTemplate>
    <div #filtersContainer class="live-filters-container page-section page-section-padding">
        <div class="live-filters-header-container">
            <div class="live-filters-header">
                <div class="filters-title select-none"><ng-container [ngTemplateOutlet]="filterIconTemplate"></ng-container>Filters</div>
            </div>
            <div class="live-filters-header-buttons">
                <button type="button" [disabled]="!monitoringItemsModel.isMonitoringFiltersActive"
                        class="image-button clear-filters-button ripple-effect ripple-inverse-color" (click)="onClearMonitoringItemsFiltersButtonClick()">
                    <ng-template [ngTemplateOutlet]="filterButtonIconTemplate"></ng-template>
                </button>
            </div>
        </div>
        <div class="shipments-type-container">
            <label class="radio-container ripple-effect ripple-inverse-color">
                <input type="radio" name="shipments-type" [value]="false" [(ngModel)]="monitoringItemsModel.showCompletedMonitoringItems"
                       (change)="onShipmentTypeChange()" />
                <span class="checked"></span>
                <span class="radio-text-container">
                    <i class="icon-signal-stream"></i>
                    <span class="radio-text">
                        <span class="radio-text-label">Events</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsSourceInfo.count : monitoringItemsModel.monitoringItemsInfo.count }}</span>
                        <span class="radio-text-label">Shipments</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsSourceInfo.shipmentsCount : monitoringItemsModel.monitoringItemsInfo.shipmentsCount }}</span>
                    </span>
                </span>
                <span class="placeholder"><span class="palaceholder-background"></span><span>Live</span></span>
            </label>
            <label class="radio-container ripple-effect ripple-inverse-color busy-support" [class.busy]="monitoringItemsModel.isBackgroundFetchBusy">
                <input type="radio" name="shipments-type" [value]="true" [(ngModel)]="monitoringItemsModel.showCompletedMonitoringItems"
                       (change)="onShipmentTypeChange()" />
                <span class="checked"></span>
                <span class="radio-text-container">
                    <i class="icon-flag"></i>
                    <span class="radio-text">
                        <span class="radio-text-label">Events</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsInfo.count : monitoringItemsModel.completedMonitoringItemsSourceInfo.count }}</span>
                        <span class="radio-text-label">Shipments</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsInfo.shipmentsCount : monitoringItemsModel.completedMonitoringItemsSourceInfo.shipmentsCount }}</span>
                    </span>
                </span>
                @if (!monitoringItemsModel.isBackgroundFetchBusy) {
                <span class="placeholder" @fadeInOutAnimation>
                    <span class="palaceholder-background"></span><span>Recently Completed</span>
                </span>
                }
                @if (monitoringItemsModel.isBackgroundFetchBusy) {
                <i class="icon-spinner" @fadeInOutAnimation></i>
                }
            </label>
        </div>
        @if (monitoringItemsModel.lastSearchMonitoringFilters !== null) {
        <div class="form-group last-filters" @foldAnimation>
            <button type="button" class="link-button" (click)="onloadLastSeachFiltersClick()">Load last filters</button>
        </div>
        }
        <div class="live-filters-content-container page-section-scroll-padding" overlayScrollbar>
            <div class="form-group">
                <select-list #eventsSelectList placeholder="Events" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsEvents"
                             bindLabel="value" bindValue="id" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringEventTypes"
                             (change)="onFilterFieldChange()">
                    <ng-template select-list-label-template let-selectedItems="selectedItems">
                        <div class="dropdown-value event-type-label select-none">
                            @if (selectedItems.length === 1) {
                            <i [class]="shipmentEventsStyleProperties[selectedItems[0].id].iconClassName"></i>
                            }
                            <span>{{ eventsSelectList.selectedItemsString }}</span>
                        </div>
                    </ng-template>
                    <ng-template select-list-option-template let-item="item">
                        <div class="option-value event-type-value select-none" [tooltipPopup]="item.value" [showTooltipOnVerflow]="true">
                            <i [class]="shipmentEventsStyleProperties[item.id].iconClassName"></i><span>{{ item.value }}</span>
                        </div>
                    </ng-template>
                </select-list>
            </div>
            <div class="form-group horizontal-groups">
                <div class="horizontal-group">
                    <datetime-picker label="From" name="fromDate" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedEventMonitoringDatesRange.from"
                                     [autoSelect]="false" (change)="onFilterFieldChange()"
                                     [minDate]="monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsEventDatesRange.from"
                                     [maxDate]="monitoringItemsModel.editMonitoringFilters.selectedEventMonitoringDatesRange.to ??
                                     monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsEventDatesRange.to">
                    </datetime-picker>
                </div>
                <div class="horizontal-group">
                    <datetime-picker label="To" name="toDate" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedEventMonitoringDatesRange.to"
                                     [autoSelect]="false" (change)="onFilterFieldChange()"
                                     [maxDate]="monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsEventDatesRange.to"
                                     [minDate]="monitoringItemsModel.editMonitoringFilters.selectedEventMonitoringDatesRange.from ??
                                     monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsEventDatesRange.from">
                    </datetime-picker>
                </div>
            </div>
            <div class="form-group horizontal-groups">
                <div class="horizontal-group">
                    <numeric-input name="duration" [(ngModel)]="monitoringItemsModel.eventDurationFilter" label="Min. Duration" [minValue]="1"
                                   [maxValue]="monitoringItemsModel.eventMaxDurationFilter" (change)="onFilterFieldChange()">
                    </numeric-input>
                    <div class="remark-label">(1 to {{ monitoringItemsModel.eventMaxDurationFilter }})</div>
                </div>
                <div class="horizontal-group">
                    <select-list [items]="monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsEventDurationTypes" bindLabel="value" bindValue="id"
                                 [clearable]="false" [(ngModel)]="monitoringItemsModel.eventDurationTypeFilter" (change)="onFilterFieldDurationTypeChange()">
                    </select-list>
                </div>
            </div>
            <div class="form-group">
                <select-list placeholder="Route Segments" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsSegments" bindLabel="value" bindValue="id"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringSegmentsTypes" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="Origins" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringOriginsRoutes"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringOriginsRoutes" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="Destinations" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringDestinationsRoutes"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes"
                             (change)="onFilterFieldChange()">
                </select-list>
            </div>
            @if (loginModel.isAccountTypeAmazonUS) {
            <div class="form-group">
                <select-list placeholder="Trailers Numbers" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringTrailersNumbers"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringTrailersNumbers" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            @if (!loginModel.isAccountTypeAmazonUS) {
            <div class="form-group">
                <select-list placeholder="{{ containerIdColumnName }}s" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringCargosUnits"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringCargosUnits" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            <div class="form-group">
                <select-list placeholder="References" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringBookingReferences"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringBookingReferences"
                             (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="{{ deviceIdColumnName }}s" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringDevices"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDevices" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="CG-IDs" [multiple]="true" emptyText="All" [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringKeys"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringKeys" (change)="onFilterFieldChange()">
                </select-list>
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerClusterTemplate -->
<ng-template #infoWindowMarkerClusterTemplate let-eventsOccurrences>
    <div class="marker-info-container page-scroll-padding select-none">
        <div class="marker-info-content-container">
            <div class="marker-info-content select-none">
                <div class="marker-info-item-label">
                    <button type="button" class="image-button" (click)="onClusterInfoExpandButtonClick()"><i class="icon-expand"></i></button>
                    <span>Events</span>
                </div>
                <div class="marker-info-item-value center">
                    @for (eventOccurrences of eventsOccurrences; track $index; let eventType = $index) {
                    @if (eventOccurrences > 0) {
                    <span class="event-value">
                        <span>{{ eventOccurrences }}</span>
                        <i [class]="shipmentEventsStyleProperties[eventType].iconClassName"></i>
                    </span>
                    }
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerTemplate -->
<ng-template #infoWindowMarkerTemplate let-monitoringItems="info" let-isCollapsable="isCollapsable">
    <div class="marker-info-container page-scroll-padding" [class.multiple]="monitoringItems.length > 1">
        <virtual-list #infoItemsList [items]="monitoringItems" [isAutoSizeToContent]="monitoringItems.length > 1" [isMouseWheelScrollSnap]="false"
                      (itemsChange)="onInfoListItemsChange()">
            @for (monitoringItem of infoItemsList.viewPortItems; track monitoringItem.id) {
            <div #body class="marker-info-content-container">
                <div class="marker-info-content-wrapper">
                    <div class="marker-info-content" [class.clickable]="monitoringItems.length > 1" (click)="onMonitoringItemMapClick(monitoringItem.id!)">
                        @if (monitoringItems.length > 1) {
                        <div class="marker-info-item-index">{{ monitoringItems.indexOf(monitoringItem) + 1 }} of {{ monitoringItems.length }}</div>
                        }
                        <div class="marker-info-item-line center">{{ monitoringItem.fromDateFormatted }}{{(appSettingsService.appSettings.isUsingUTCTime ? ' UTC' : '')}}</div>
                        <div class="marker-info-item-label">Event</div>
                        <div class="marker-info-item-value center">
                            <i [class]="shipmentEventsStyleProperties[monitoringItem.eventType].iconClassName"></i>
                            <span>{{ monitoringItem.eventDesc }}</span>
                        </div>
                        @if (monitoringItem.durationFormatted) {
                        <div class="marker-info-item-label">Duration</div>
                        }
                        @if (monitoringItem.durationFormatted) {
                        <div class="marker-info-item-value">{{ monitoringItem.durationFormatted }}</div>
                        }
                        @if (monitoringItem.value !== null) {
                        <div class="marker-info-item-label">
                            <span>{{ monitoringItem.valueTitle }}</span>
                        </div>
                        <div class="marker-info-item-value">
                            <span>{{ monitoringItem.value }}{{ monitoringItem.units }}</span>
                        </div>
                        }
                        @if (monitoringItem.minThreshold !== null || monitoringItem.maxThreshold !== null) {
                        <div class="marker-info-item-label">Threshold</div>
                        <div class="marker-info-item-value">
                            <span>{{ (monitoringItem.minThreshold !== null ? (monitoringItem.minThreshold + monitoringItem.units) : '') }}</span>
                            @if (monitoringItem.minThreshold !== null && monitoringItem.maxThreshold !== null) {
                            <span> to </span>
                            }
                            <span>{{ (monitoringItem.maxThreshold !== null ? (monitoringItem.maxThreshold + monitoringItem.units) : '') }}</span>
                        </div>
                        }
                        @if (monitoringItem.timeBasedMinThreshold !== null || monitoringItem.timeBasedMaxThreshold !== null) {
                        <div class="marker-info-item-label">Threshold±</div>
                        <div class="marker-info-item-value">
                            <span>{{ (monitoringItem.timeBasedMinThreshold !== null ? (monitoringItem.timeBasedMinThreshold + monitoringItem.units) : '') }}</span>
                            @if (monitoringItem.timeBasedMinThreshold !== null && monitoringItem.timeBasedMaxThreshold !== null) {
                            <span> to </span>
                            }
                            <span>{{ (monitoringItem.timeBasedMaxThreshold !== null ? (monitoringItem.timeBasedMaxThreshold + monitoringItem.units) : '') }}</span>
                        </div>
                        }
                        @if (monitoringItems.length > 1) {
                        @for (item of monitoringItem.infoWindowExtraRows; track $index) {
                        <div class="marker-info-item-label">&nbsp;</div>
                        <div class="marker-info-item-value">&nbsp;</div>
                        }
                        }
                    </div>
                    <div class="marker-info-item-buttons" (click)="onMonitoringItemMapClick(monitoringItem.id!)">
                        <div class="dropdown" #showPopupCopyOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownCopyEventInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Copy"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-copy"></i>
                            </button>
                        </div>
                        <div class="dropdown" #showPopupShareOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownShareEventInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Share"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-share-nodes"></i>
                            </button>
                        </div>
                        <button type="button" class="image-button ripple-effect ripple-fill ripple-inverse-color"
                                tooltipPopup="Track" (click)="onMarkerShipmentTrackButtonClick(null, monitoringItem)">
                            <i class="icon-route"></i>
                        </button>
                    </div>
                </div>
            </div>
            }
        </virtual-list>
        @if (isCollapsable) {
        <button type="button" class="image-button collapse-button" (click)="onClusterInfoCollapseButtonClick()">
            <i class="icon-collapse"></i>
        </button>
        }
    </div>
</ng-template>

