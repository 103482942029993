import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DemurrageModel } from './model/demurrage.model';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { AppSettingsService } from '../../services/app-settings.service';
import { Constants } from '../../utils/globals';
import { ModalButtonType, ModalIconType, ModalResultType, ModalType } from '../../controls/modal-message/modal-message.component';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { foldAnimation, foldFadeAnimation } from '../../animations/fold.animation';
import { DisplayDemurrageContract } from './model/demurrage-model.class';
import { ManagerItemBaseComponent } from '../../base/components/manager-item-base.component';
import { NumericInputComponent } from '../../controls/numeric-input/numeric-input.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { SelectListComponent } from '../../controls/select-list/select-list.component';
import { RequiredValidatorDirective } from '../../validators/required.validator';
import { DateTimePickerComponent } from '../../controls/datetime-picker/datetime-picker.component';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { FormIgnoreEnterDirective } from '../../directives/form-ignore-enter.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'demurrage',
    templateUrl: './demurrage.component.html',
    styleUrls: ['../../base/styles/manager-item-base.css', './demurrage.component.css'],
    animations: [fadeInOutAnimation, foldAnimation, foldFadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, FormIgnoreEnterDirective, OverlayScrollbarDirective, DateTimePickerComponent, RequiredValidatorDirective, SelectListComponent, TooltipDirective, NumericInputComponent]
})
export class DemurrageComponent extends ManagerItemBaseComponent<DisplayDemurrageContract>
{
    // #region Properties

    public get appSettingsService(): AppSettingsService
    {
        return this._appSettingsService;
    }

    public override get managerItemModel(): DemurrageModel
    {
        return this._managerItemModel as DemurrageModel;
    }


    // #endregion

    // #region Constructor

    constructor(_managerItemModel: DemurrageModel, _changeDetectorRef: ChangeDetectorRef,
        _modalMessageService: ModalMessageService, _routingHistoryService: RoutingHistoryService,
        private _appSettingsService: AppSettingsService)
    {
        super(_managerItemModel, _changeDetectorRef, _routingHistoryService, _modalMessageService);
    }

    // #endregion

    // #region Event Handlers

    public onAddPortDataButtonClick(): void
    {
        this.managerItemModel.addPortData();
    }

    public async onDeletePortDataButtonClick(portDataIndex: number): Promise<void>
    {
        const modalResult: ModalResultType = this.managerItemModel.isPortDataEmpty(portDataIndex) ? ModalResultType.Yes : await this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: 'Are you sure you want do delete this line?',
                modalType: ModalType.Error,
                modalIcon: ModalIconType.Question,
                modalButton: ModalButtonType.YesNo
            });

        if (modalResult === ModalResultType.Yes)
        {
            this.managerItemModel.deletePortData(portDataIndex);
            this._changeDetectorRef.detectChanges();
        }
    }

    // #endregion

    // #region Protected Methods

    protected override submitManagerItem(isDelete: boolean = false): void
    {
        this._statusMessage = `<b>${isDelete ? 'Deleting' : (this.managerItemModel.managerItem.contractId !== null ? 'Updating' : 'Adding new')} demurrage.</b> Please wait...`;

        super.submitManagerItem(isDelete);
    }

    // #endregion
}
