import { CGIClusterBaseRenderer } from "../../../controls/google-map/utils/cgi-cluster-base-renderer";
import { CGICluster } from "../../../controls/google-map/utils/cgi-cluster";
import { ICGIMarker } from "../../../base/classes/cgi-marker";
import { DisplayShipmentMonitoringEvent } from "../model/shipment-monitoring-model.class";
import { MonitoringUtils } from "../../../utils/monitoring-utils";
import { CGIMarkerItemMonitoringIconFactory } from "./cgi-marker-item-monitoring-icon-factory";

export class CGIEventsClusterRenderer extends CGIClusterBaseRenderer
{
    // #region Private Members

    private _cgiMarkerItemMonitoringIconFactory: CGIMarkerItemMonitoringIconFactory = new CGIMarkerItemMonitoringIconFactory();

    // #endregion

    // #region Renderer Interface

    public override render(cluster: CGICluster, map: google.maps.Map): google.maps.marker.AdvancedMarkerElement
    {
        const eventsOccurrences: number[] = Array(MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT).fill(0);

        if (cluster.markers !== undefined && cluster.markers.length > 0)
        {
            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent = marker instanceof google.maps.marker.AdvancedMarkerElement ?
                    (marker as ICGIMarker<DisplayShipmentMonitoringEvent>).data : marker as DisplayShipmentMonitoringEvent;

                eventsOccurrences[displayShipmentMonitoringEvent.eventType] = eventsOccurrences[displayShipmentMonitoringEvent.eventType] + 1;
            }
        }

        return this.createClusterMarkerElement(map, cluster, eventsOccurrences);
    }

    // #endregion

    // #region Protected Methods

    protected override createClusterMarkerContentElement(cluster: CGICluster, eventsOccurrences: number[], isClusterBoundsAroundSameLocation: boolean): Element
    {
        return isClusterBoundsAroundSameLocation ?
            this._cgiMarkerItemMonitoringIconFactory.createCGIClusterPinMarkerEventsElement(eventsOccurrences, cluster.markers.length) :
            this._cgiMarkerItemMonitoringIconFactory.createCGIClusterMarkerEventsElement(eventsOccurrences, cluster.markers.length);
    }

    // #endregion
}