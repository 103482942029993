import
{
    Directive,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { Constants } from '../utils/globals';
import { DropdownPosition } from '../base/components/dropdown-base.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Utils } from '../utils/utils';

@Directive({
    selector: '[tooltipPopup]',
    standalone: true
})
export class TooltipDirective implements OnInit, OnDestroy
{
    // #region Private Constants

    private readonly SHOW_DELAY_DURATION: number = 500;
    private readonly HIDE_DELAY_DURATION: number = 200;
    private readonly PAGE_SCROLL_PADDING_CLASSNAME: string = 'page-scroll-padding';
    private readonly TOOLTIP_CLASSNAME: string = 'tooltip';
    private readonly POINTER_INACTIVE_CLASSNAME: string = 'pointer-inactive';

    // #endregion

    // #region Private Members

    private _tootipPopupContainerElement: HTMLElement | null = null;
    private _tootipPopupBubbleElement: HTMLElement | null = null;

    private _showTimeoutHandle: NodeJS.Timeout | null = null;
    private _hideTimeoutHandle: NodeJS.Timeout | null = null;

    private _disposeWindowMouseWheelHandler: (() => void) | null = null;
    private _disposeWindowMouseMoveHandler: (() => void) | null = null;
    private _disposeMouseEnterHandler: (() => void) | null = null;
    private _disposeTooltipPopupMouseEnterHandler: (() => void) | null = null;

    // #endregion

    // #region Inputs

    @Input() tooltipPopup: string | null = null;
    @Input() showTooltipOnVerflow: boolean = false;
    @Input() tooltipEventsElement: HTMLElement | null = null;
    @Input() tooltipPointerInactive: boolean = true;

    // #endregion

    // #region Constructors

    constructor(private _elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2, private _ngZone: NgZone, private _router: Router)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (Constants.IS_MOBILE)
        {
            return;
        }

        const mouseEventsElement: HTMLElement = this.tooltipEventsElement ?? this._elementRef.nativeElement;

        this._ngZone.runOutsideAngular(() =>
        {
            this._disposeMouseEnterHandler = this._renderer.listen(mouseEventsElement, 'mouseenter', () =>
            {
                this.clearHideTimeout();
                this.initializeTooltip();
            });
        });

        this._router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => this.hideTooptip());
    }

    public ngOnDestroy(): void
    {
        this.clearEventsHandlers();

        if (this._disposeMouseEnterHandler !== null)
        {
            this._disposeMouseEnterHandler();
            this._disposeMouseEnterHandler = null;
        }

        this.removeTootip(true);
    }

    public onWindowMouseMove(event: any): void
    {
        const mouseEventsElement: HTMLElement = this.tooltipEventsElement ?? this._elementRef.nativeElement;

        if (!mouseEventsElement.contains(event.target as HTMLElement)
            && !this._tootipPopupContainerElement?.contains(event.target as HTMLElement))
        {
            this.hideTooptip();
        }
    }

    // #endregion

    // #region Private Methods

    private clearEventsHandlers(): void
    {
        if (this._disposeWindowMouseWheelHandler !== null)
        {
            this._disposeWindowMouseWheelHandler();
            this._disposeWindowMouseWheelHandler = null;
        }

        if (this._disposeWindowMouseMoveHandler !== null)
        {
            this._disposeWindowMouseMoveHandler();
            this._disposeWindowMouseMoveHandler = null;
        }

        if (this._disposeTooltipPopupMouseEnterHandler !== null)
        {
            this._disposeTooltipPopupMouseEnterHandler();
            this._disposeTooltipPopupMouseEnterHandler = null;
        }
    }

    private hideTooptip(): void
    {
        this.clearEventsHandlers();
        this.clearShowTimeout();

        this._hideTimeoutHandle = setTimeout(this.removeTootip.bind(this), this.HIDE_DELAY_DURATION);
    }

    private clearShowTimeout(): void
    {
        if (this._showTimeoutHandle !== null)
        {
            clearTimeout(this._showTimeoutHandle);
            this._showTimeoutHandle = null;
        }
    }

    private clearHideTimeout(): void
    {
        if (this._hideTimeoutHandle !== null)
        {
            clearTimeout(this._hideTimeoutHandle);
            this._hideTimeoutHandle = null;
        }
    }

    private showTooltip(): void
    {
        this.initializeTooltipPosition();

        if (this._tootipPopupContainerElement !== null)
        {
            this._tootipPopupContainerElement.classList.add(Constants.ACTIVE_CLASSNAME);
            if (this.tooltipPointerInactive)
            {
                this._tootipPopupContainerElement.classList.add(this.POINTER_INACTIVE_CLASSNAME);
            }
        }
    }

    private initializeTooltip()
    {
        if (this.showTooltipOnVerflow && (this._elementRef.nativeElement.scrollWidth <= this._elementRef.nativeElement.clientWidth + 1) &&
            (this._elementRef.nativeElement.scrollHeight <= this._elementRef.nativeElement.clientHeight + 1))
        {
            return;
        }

        this.clearEventsHandlers();

        if (this._tootipPopupContainerElement !== null)
        {
            this.removeTootip(true);
        }

        this._tootipPopupContainerElement = document.createElement('div');
        this._tootipPopupContainerElement.className = `${Constants.POPUP_CONTAINER_CLASSNAME} ${this.TOOLTIP_CLASSNAME}`;

        this._tootipPopupBubbleElement = document.createElement('div');
        this._tootipPopupBubbleElement.className = Constants.POPUP_BUBBLE_CLASSNAME;

        const tootipPopupBubbleContentElement = document.createElement('div');
        tootipPopupBubbleContentElement.className = `${Constants.POPUP_BUBBLE_CONTENT_CLASSNAME} ${this.PAGE_SCROLL_PADDING_CLASSNAME}`;
        tootipPopupBubbleContentElement.innerHTML = this.tooltipPopup ?? '';

        this._tootipPopupBubbleElement.appendChild(tootipPopupBubbleContentElement);
        this._tootipPopupContainerElement.appendChild(this._tootipPopupBubbleElement);

        document.body.appendChild(this._tootipPopupContainerElement);

        this._ngZone.runOutsideAngular(() =>
        {
            this._disposeWindowMouseMoveHandler = this._renderer.listen(window, 'mousemove', this.onWindowMouseMove.bind(this));

            const elementScrollableContainer: HTMLElement = Utils.getParentElementScrollableContainer(this.tooltipEventsElement ?? this._elementRef.nativeElement);
            this._disposeWindowMouseWheelHandler = this._renderer.listen(elementScrollableContainer, 'scroll', this.onWindowMouseMove.bind(this));

            this._disposeTooltipPopupMouseEnterHandler = this._renderer.listen(this._tootipPopupContainerElement, 'mouseenter',
                this.clearHideTimeout.bind(this));
        });

        this._showTimeoutHandle = setTimeout(this.showTooltip.bind(this), this.SHOW_DELAY_DURATION);
    }

    private initializeTooltipPosition(): void
    {
        if (this._tootipPopupContainerElement === null || this._tootipPopupBubbleElement === null)
        {
            return;
        }

        let verticalPopupPosition: DropdownPosition | null = DropdownPosition.Top;
        let horizontalPopupPosition: DropdownPosition | null = null;

        const rectScrollableContainerElement: DOMRect = document.body.getBoundingClientRect();
        const rectElement: DOMRect = this._elementRef.nativeElement.getBoundingClientRect();
        const rectTooltipPopupBubbleElement: DOMRect = this._tootipPopupBubbleElement.getBoundingClientRect();

        const popupBubbleAnchorAfterElementCssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this._tootipPopupBubbleElement, ':after');
        const tooltipArrowHeight: number = parseFloat(popupBubbleAnchorAfterElementCssStyleDeclaration.height);
        const tooltipArrowWidth: number = parseFloat(popupBubbleAnchorAfterElementCssStyleDeclaration.width);

        if (rectElement.top - rectTooltipPopupBubbleElement.height - tooltipArrowHeight < rectScrollableContainerElement.top)
        {
            verticalPopupPosition = DropdownPosition.Bottom;
        }
        else if (rectElement.bottom + rectTooltipPopupBubbleElement.height + tooltipArrowHeight > rectScrollableContainerElement.bottom)
        {
            verticalPopupPosition = DropdownPosition.Top;
        }

        if (rectElement.left + rectElement.width / 2 - rectTooltipPopupBubbleElement.width / 2 < rectScrollableContainerElement.left)
        {
            horizontalPopupPosition = DropdownPosition.Right;
        }
        else if (rectElement.left + rectElement.width / 2 + rectTooltipPopupBubbleElement.width / 2 > rectScrollableContainerElement.right)
        {
            horizontalPopupPosition = DropdownPosition.Left;
        }

        if (horizontalPopupPosition !== null)
        {
            if (verticalPopupPosition === DropdownPosition.Top)
            {
                if (rectElement.bottom + rectTooltipPopupBubbleElement.height + tooltipArrowHeight < rectScrollableContainerElement.bottom)
                {
                    verticalPopupPosition = null;
                }
            }
            else if (verticalPopupPosition === DropdownPosition.Bottom)
            {
                if (rectElement.top - rectTooltipPopupBubbleElement.height - tooltipArrowHeight > rectScrollableContainerElement.top)
                {
                    verticalPopupPosition = null;
                }
            }
        }

        if (verticalPopupPosition !== null)
        {
            this._tootipPopupContainerElement.style.left = `${Math.round(rectElement.width / 2 + rectElement.left)}px`;
            this._tootipPopupContainerElement.style.right = 'auto';
            this._tootipPopupContainerElement.style.bottom = 'auto';

            if (verticalPopupPosition === DropdownPosition.Top)
            {
                this._tootipPopupContainerElement.style.top = `${rectElement.top - tooltipArrowHeight}px`;
            }
            else
            {
                this._tootipPopupContainerElement.style.top = `${rectElement.bottom + rectTooltipPopupBubbleElement.height + tooltipArrowHeight}px`;
            }

            this._tootipPopupBubbleElement.classList.add((DropdownPosition[verticalPopupPosition] as string).toLowerCase());
        }

        if (horizontalPopupPosition !== null)
        {
            this._tootipPopupContainerElement.style.right = 'auto';

            if (horizontalPopupPosition === DropdownPosition.Left)
            {
                this._tootipPopupContainerElement.style.left = `${rectElement.left - rectTooltipPopupBubbleElement.width / 2 - tooltipArrowWidth}px`;
            }
            else
            {
                this._tootipPopupContainerElement.style.left = `${rectElement.right + rectTooltipPopupBubbleElement.width / 2 + tooltipArrowWidth}px`;
            }

            if (verticalPopupPosition === null)
            {
                this._tootipPopupContainerElement.style.top = `${rectElement.top + rectElement.height / 2 + rectTooltipPopupBubbleElement.height / 2}px`;
                this._tootipPopupContainerElement.style.bottom = 'auto';
            }

            this._tootipPopupBubbleElement.classList.add((DropdownPosition[horizontalPopupPosition] as string).toLowerCase());
        }
    }

    private removeTooltipElement(): void
    {
        if (this._tootipPopupContainerElement !== null)
        {
            document.body.removeChild(this._tootipPopupContainerElement);
            this._tootipPopupContainerElement = null;
        }
    }

    private removeTootip(immidiate: boolean = false): void
    {
        this.clearHideTimeout();

        if (this._tootipPopupContainerElement !== null)
        {
            if (!immidiate && this._tootipPopupContainerElement.classList.contains(Constants.ACTIVE_CLASSNAME))
            {
                this._tootipPopupContainerElement.addEventListener('transitionend', () =>
                {
                    this.removeTooltipElement();
                }, { once: true });

                this._tootipPopupContainerElement.classList.remove(Constants.ACTIVE_CLASSNAME);
            }
            else
            {
                this.removeTooltipElement();
            }
        }
    }

    // #endregion
}