import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Constants, IApiResponse } from '../../../utils/globals';
import { HttpHelper } from '../../../utils/http-helper';
import { HttpErrorCodes } from '../../../utils/http-error-codes';
import { BaseModel } from '../../../base/classes/base-model';

@Injectable({ providedIn: 'root' })
export class ResetPasswordModel extends BaseModel
{
    // #region Constants

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'auth/user';

    // #endregion

    // #region Properties

    public userEmail: string | null = null;

    // #endregion

    // #region Constructor

    constructor(private _httpClient: HttpClient)
    {
        super();
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this.userEmail = null;
    }

    public resetPassword(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            this._httpClient.post(`${this.ENTITY_ITEM_CONTROLLER_NAME}/password/reset`, HttpHelper.GetParams({ userEmail: this.userEmail }),
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                {
                    next: () =>
                    {
                        this._isBusy = false;
                        observer.next({ isSuccess: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next(
                            {
                                isSuccess: false, message: error.status === HttpErrorCodes.NOT_FOUND ?
                                    'Your email address is not registered.\r\nPlease try again with a different address.' :
                                    Constants.DATA_SERVICE_ERROR_STRING
                            });

                        observer.complete();
                    }
                });
        });
    }

    // #endregion
}