import { ICGILocation, ICGIMarkerLocation } from "../../../base/classes/cgi-marker";
import { ManagerItemStatus, MonitoringArrivalStatusType, MonitoringSegmentType } from "../../../base/models/manager-base-model.class";
import { MonitoringDetailedEventType } from "../../../base/models/monitoring-items-base-model.class";
import { DemurrageContractDeviationType } from "../../../demurrage-monitoring/model/demurrage-monitoring-model.class";

export enum ShipmentMonitoringPerimeterType
{
    RailwayStation = 'Railway Station',
    Airport = 'Airport'
}

export enum ShipmentMonitoringEventColumnType { StartTime, Type, Location, Duration, MinTemperature, MaxTemperature, Humidity, Light, Impact }

export enum ShipmentMonitoringTelemetrySourceType { Device = 1, Vessel = 2, None = 3, Sensor = 4 }
export enum ShipmentMonitoringTelemetryLogicStateType { Locked = 1, Unlocked = 2, Maintenance = 3, BreakIn = 4, Opened = 5 }
export enum ShipmentMonitoringTelemetryMediumIdType { Sea = 1, Land = 2, River = 3, Air = 4, Rail = 5, Road = 6 }

export enum ShipmentMonitoringTelemetryReasonType
{
    NoReasonRecorded = 0,
    Response = 1,
    Tracking = 2,
    Event = 4,
    Deinstalled = 5,
    Installed = 6,
    LowBattery = 7,
    DoorOpened = 8,
    DoorClosed = 9,
    StrongImpact = 10,
    ButtonOff = 11,
    ButtonOn = 12,
    WeakImpact = 13,
    LocationUpdate = 14,
    FlightTakeOff = 15,
    FlightLanded = 16,
    Closed = 22,
    Opened = 23,
    Maintenance = 24,
    BreakIn = 25,
    Unlocked = 26,
    PowerOn = 29,
    LightOff = 30,
    LightOn = 31,
    TemperatureLow = 32,
    TemperatureHigh = 33,
    HumidityLow = 35,
    HumidityHigh = 36,
    Logging = 38,
    TemperatureNormal = 40,
    HumidityNormal = 41,
    TailDisconnected = 42,
    TailConnected = 43,
    ArrivalToWaypoint = 50,
    DepartureFromWaypoint = 51,
    ExceededPlannedTimeAtWaypoint = 52,
    MissedWaypoint = 53,
    LoadedOnVessel = 54,
    UnloadedFromVessel = 55,
    ShipmentEnded = 56,
    ShipmentExpectedDelay = 57,
    PlannedVesselLeftPortWithoutLoadingDevice = 58,
    ShipmentLocationOutdated = 59,
    //MeasurementSensorsUnavailable = 60,
    //MeasurementSensorsAvailable = 61,
    Installation = 62,
    OpenedAtDestination = 63,
    //LowBattery = 64,
    TrackingByVessel = 65,
    SuspectedPOLChange = 66,
    DeviceLeftPortWithoutLoadingOnPlannedVessel = 67,
    GateOut = 68,
    //SuspectedSecurity = 69,
    //SuspectedSecurityNormal = 70,
    //FixedLocationToPlannedUWHInsidePOD = 71,
    //FoundAlternativeUWH = 72,
    //TimeBasedHighTemperature = 73,
    //TimeBasedLowTemperature = 74,
    //TimeBasedHighHumidity = 75,
    //TimeBasedLowHumidity = 76,
    //TimeBasedTemperatureNormal = 77,
    //TimeBasedHumidityNormal = 78,
    TimeExceededAtPOL = 79,
    TimeExceededAtPOD = 80,
    TimeExceededAtTRS = 81
    //Ping = 255
}

export enum ShipmentMonitoringPlanSegmentStatusType { Normal, Current, Destination }

export class ShipmentMonitoringEvent
{
    public shipmentKey: number | null = null;
    public eventId: MonitoringDetailedEventType = MonitoringDetailedEventType.None;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public eventDesc: string | null = null;
    public fromId: number | null = null;
    public toId: number | null = null;
}

export class DisplayShipmentMonitoringEvent extends ShipmentMonitoringEvent implements ICGIMarkerLocation
{
    public fromDateFormatted: string | null = null;
    public durationFormatted: string | null = null;
    public telemetries: DisplayShipmentMonitoringTelemetry[] = [];
    public eventType: MonitoringDetailedEventType = MonitoringDetailedEventType.None;
    public polyline: google.maps.Polyline | null = null;
    public minValue: number | null = null;
    public maxValue: number | null = null;
    public minThreshold: number | null = null;
    public maxThreshold: number | null = null;
    public timeBasedMinThreshold: number | null = null;
    public timeBasedMaxThreshold: number | null = null;
    public location: ICGILocation = { lat: 0, lng: 0 };
    public infoWindowExtraRows: number[] = [];
}

export class ShipmentMonitoringTelemetry
{
    public id: number | null = null;
    public shipmentKey: number | null = null;
    public telemetryTime: Date | null = null;
    public locationTime: Date | null = null;
    public reasonId: number | null = null;
    public temperature: number | null = null;
    public humidity: number | null = null;
    public telemetrySource: ShipmentMonitoringTelemetrySourceType | null = null;
    public movingFlag: number | null = null;
    public longitude: number | null = null;
    public latitude: number | null = null;
    public telemetryPlanKey: string | null = null;
    public rowId: number | null = null;
    public gforce: number | null = null;
    public security: number | null = null;
    public logicState: number | null = null;
    public mediumId: number | null = null;
    public locationUpdateTime: Date | null = null;
    public mainVoltage: number | null = null;
    public locationMethod: string | null = null;
    public topLevelSegmentName: string | null = null;
    public light: number | null = null;
}

export class DisplayShipmentMonitoringTelemetry extends ShipmentMonitoringTelemetry
{
    public telemetryTimeFormatted: string | null = null;
    public locationUpdateTimeFormatted: string | null = null;
}

export class MonitoringEventTypeOverview
{
    public events: DisplayShipmentMonitoringEvent[] = [];
    public telemetryTimeToEventsMap: Map<number, DisplayShipmentMonitoringEvent> = new Map();
    public telemtryChartData: [number, number | null][] = [];
    public telemetryTitle: string = '';
    public telemetryValueTitle: string = '';
    public telemetryUnits: string | undefined = undefined;
    public isFiltered: boolean = true;
}

export class ShipmentMonitoringPlanSegment
{
    public shipmentPlanId: number | null = null;
    public shipmentKey: number | null = null;
    public segmentIndex: number | null = null;
    public eta: Date | null = null;
    public shipNameAndImo: string | null = null;
    public shipDataUrl: string | null = null;
    public currentSegment: number | null = null;
    public segmentId: MonitoringSegmentType = MonitoringSegmentType.Origin;
    public perimeterId: string | null = null;
    public perimeterType: string | null = null;
    public perimeterName: string | null = null;
    public perimeterLongitude: number | null = null;
    public perimeterLatitude: number | null = null;
    public telemetryKey: string | null = null;
    public countryCode: string | null = null;
    public countryName: string | null = null;
}

export class DisplayShipmentMonitoringPlanSubSegment
{
    public description: string | null = null;
    public dateFormatted: string | null = null;
    public date: Date | null = null;
    public eventsOccurrences: number[] = [];
}

export class DisplayShipmentMonitoringPlanSegment extends ShipmentMonitoringPlanSegment
{
    public segmentDescription: string | null = null;
    public telemetries: ShipmentMonitoringTelemetry[] = [];
    public etaFormatted: string | null = null;
    public durationFormatted: string | null = null;
    public iconClass: string = '';
    public eventsOccurrences: number[] = [];
    public subSegments: DisplayShipmentMonitoringPlanSubSegment[] = [];
    public isExpanded: boolean = false;
    public title: string = '';
    public marker: google.maps.marker.AdvancedMarkerElement | null = null;
}

export class ShipmentMonitoringOverview
{
    public shipment: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();
    public planSegments: ShipmentMonitoringPlanSegment[] = [];
    public telemetries: DisplayShipmentMonitoringTelemetry[] = [];
    public events: ShipmentMonitoringEvent[] = [];
}

export class ShipmentMonitoringChartProperties
{
    public colors: string[] = [];
    public titles: string[] = [];
    public units: string[] = [];
}

export class ChartXAxisZoom
{
    public min: number = 0;
    public max: number = 0;
}

export class ChartXAxisMinMaxZoom
{
    public current: ChartXAxisZoom = new ChartXAxisZoom();
    public initial: ChartXAxisZoom = new ChartXAxisZoom();
}

export class ChartSelection
{
    public index: number = 0;
    public type: MonitoringDetailedEventType = MonitoringDetailedEventType.None;
    public isSelected: boolean = false;
    public class: string = '';
    public iconClassName: string = '';
    public tooltip: string = '';
}

export class ShipmentMonitoring
{
    public shipmentKey: number | null = null;
    public originName: string | null = null;
    public destinationName: string | null = null;
    public originNameDemo: string | null = null;
    public destinationNameDemo: string | null = null;
    public installDate: Date | null = null;
    public removeDate: Date | null = null;
    public statusId: ManagerItemStatus = ManagerItemStatus.Live;
    public deviceId: string | null = null;
    public containerId: string | null = null;
    public bookingReference: string | null = null;
    public delayString: string | null = null;
    public delayTime: number | null = null;
    public securityEvents: number | null = null;
    public impactEvents: number | null = null;
    public temperatureEvents: number | null = null;
    public humidityEvents: number | null = null;
    public currentLocDes: string | null = null;
    public currentSegment: string | null = null;
    public arrivalStatusId: MonitoringArrivalStatusType = MonitoringArrivalStatusType.Unknown;
    public segmentId: MonitoringSegmentType = MonitoringSegmentType.Origin;
    public progress: number = 0;
    public eta: Date | null = null;
    public pta: Date | null = null;
    public currentLat: number = 0;
    public currentLon: number = 0;
    public lastUpdate: Date | null = null;
    public perimeterId: string | null = null;
    public perimeterType: string | null = null;
    public perimeterName: string | null = null;
    public maxHumidityThreshold: number | null = null;
    public minHumidityThreshold: number | null = null;
    public maxTemperatureThreshold: number | null = null;
    public minTemperatureThreshold: number | null = null;
    public tbMaxHumidityThreshold: number | null = null;
    public tbMinHumidityThreshold: number | null = null;
    public tbMaxTemperatureThreshold: number | null = null;
    public tbMinTemperatureThreshold: number | null = null;
    public deviceType: string | null = null;
    public trailerNumber: string | null = null;
    public carrierId: number | null = null;
    public carrierType: string | null = null;
    public carrierName: string | null = null;
    public corpId: number | null = null;
    public currentPortStartTime: Date | null = null;
    public currentPortEndTime: Date | null = null;
    public shipmentOutdatedTime: Date | null = null;
    public demoFlag: number | null = null;
}

export class DisplayShipmentMonitoring extends ShipmentMonitoring implements ICGIMarkerLocation
{
    public stopEvents: number | null = null;
    public installDateFormatted: string | null = null;
    public removeDateFormatted: string | null = null;
    public etaFormatted: string | null = null;
    public ptaFormatted: string | null = null;
    public installDateShortFormatted: string | null = null;
    public removeDateShortFormatted: string | null = null;
    public etaShortFormatted: string | null = null;
    public ptaShortFormatted: string | null = null;
    public lastUpdateFormatted: string | null = null;
    public routeDescription: string | null = null;
    public deviceDescription: string | null = null;
    public isInFovarites: boolean = false;
    public currentFormattedDuration: string | null = null;
    public segmentDescription: string | null = null;
    public currentPortStartTimeFormatted: string | null = null;
    public demurragePortDuration: number | null = null;
    public demurragePortDurationFormatted: string | null = null;
    public demurrageFreeDays: number | null = null;
    public demurrageFreeDaysFormatted: string | null = null;
    public demurrageDeviation: number | null = null;
    public demurrageSortDeviation: number | null = null;
    public demurrageDeviationId: DemurrageContractDeviationType = DemurrageContractDeviationType.Unknown;
    public demurrageDeviationFormatted: string | null = null;
    public perimeterPortCode: string | null = null;
    public perimeterCountryCode: string | null = null;
    public perimeterCountryName: string | null = null;
    public perimeterLatitute: number | null = null;
    public perimeterLongitude: number | null = null;
    public location: ICGILocation = { lat: 0, lng: 0 };
    public infoWindowExtraRows: number[] = [];
}