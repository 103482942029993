import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DisplayDemurrageContract } from '../demurrage/model/demurrage-model.class';
import { DemurrageColumnType } from './model/demurrage-manager-model.class';
import { fadeInAnimation, fadeInOutAnimation, fadeSlideDownInOutAnimation, shortFadeInOutAnimation } from '../../animations/fade.animation';
import { foldFadeHorizontalAnimation } from '../../animations/fold.animation';
import { DemurrageComponent } from '../demurrage/demurrage.component';
import { DemurrageManagerModel } from './model/demurrage-manager.model';
import { Router } from '@angular/router';
import { AnimationBuilder } from '@angular/animations';
import { AppSettingsService } from '../../services/app-settings.service';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { ModalButtonType, ModalIconType, ModalResultType, ModalType } from '../../controls/modal-message/modal-message.component';
import { Constants } from '../../utils/globals';
import { Utils } from '../../utils/utils';
import { ManagerBaseComponent } from '../../base/components/manager-base.component';
import { VirtualListInfo } from '../../controls/virtual-list/model/virtual-list-info';
import { GlobalsPipe } from '../../pipes/globals.pipe';
import { LoaderComponent } from '../../controls/loader/loader.component';
import { NgClass } from '@angular/common';
import { VirtualListComponent } from '../../controls/virtual-list/virtual-list.component';
import { DateTimePickerComponent } from '../../controls/datetime-picker/datetime-picker.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { FormsModule } from '@angular/forms';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';

@Component({
    selector: 'demurrage-manager',
    templateUrl: './demurrage-manager.component.html',
    styleUrls: ['../../controls/modal-message/modal-message.component.css', '../../base/styles/manager-base.css', '../../base/styles/manager-table.css',
        './demurrage-manager.component.css'],
    animations: [fadeInOutAnimation, fadeInAnimation, fadeSlideDownInOutAnimation, shortFadeInOutAnimation, foldFadeHorizontalAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ClearableInputComponent, FormsModule, TooltipDirective, DateTimePickerComponent, VirtualListComponent, NgClass, DemurrageComponent, LoaderComponent, GlobalsPipe]
})
export class DemurrageManagerComponent extends ManagerBaseComponent<DisplayDemurrageContract, DemurrageColumnType>
{
    // #region Constants

    protected override readonly EXCEL_FILE_PREFIX: string = 'DemurrageManager_';

    // #endregion

    // #region Protected Members

    protected static override _managerTableListInfo: VirtualListInfo = new VirtualListInfo();

    @ViewChild('managerItem', { read: DemurrageComponent, static: false }) protected override _managerItemComponent: DemurrageComponent | undefined = undefined;

    // #endregion

    // #region Properties

    public override get isDirty(): boolean
    {
        return this._managerItemComponent === undefined ? false : this._managerItemComponent.isDirty;
    }

    public get DemurrageColumnType()
    {
        return DemurrageColumnType;
    }

    public override get managerModel(): DemurrageManagerModel
    {
        return this._managerModel as DemurrageManagerModel;
    }

    // #endregion

    // #region Constructor

    constructor(_managerModel: DemurrageManagerModel, _router: Router, _changeDetectorRef: ChangeDetectorRef,
        _routingHistoryService: RoutingHistoryService, _animationBuilder: AnimationBuilder, _appSettingsService: AppSettingsService,
        _modalMessageService: ModalMessageService)
    {
        super(_managerModel, _router, _changeDetectorRef, _routingHistoryService, _animationBuilder, _appSettingsService, _modalMessageService);

        this.initializeManagerTableColumns();
    }

    // #endregion

    // #region Event Handlers

    public async onDemurrageDeleteButtonClick(event: MouseEvent): Promise<void>
    {
        event.stopImmediatePropagation();

        const modalResult: ModalResultType = await this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: 'You won\'t be able to undo demurrage contract deletions. Are you sure?',
                modalType: ModalType.Error,
                modalIcon: ModalIconType.Question,
                modalButton: ModalButtonType.YesNo
            });

        if (modalResult === ModalResultType.Yes)
        {
            if (this._managerItemComponent instanceof DemurrageComponent)
            {
                (this._managerItemComponent as DemurrageComponent).deleteManagerItem();
            }
        }
    }

    public onImportFromExcelButtonClick(): void
    {
    }

    // #endregion

    // #region Protected Methods

    protected override initializeManagerTableColumns(): void
    {
        const displayDemurrageContract: DisplayDemurrageContract = new DisplayDemurrageContract();

        this.managerTableColumns =
            [
                {
                    columnType: DemurrageColumnType.CarrierName,
                    text: 'Carrier',
                    propertyName: Utils.getPropertyNameof<DisplayDemurrageContract>(displayDemurrageContract, displayDemurrageContract =>
                        displayDemurrageContract.carrierName)
                },
                {
                    columnType: DemurrageColumnType.FromDate,
                    text: `From<span class="small-units">${(this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '')}</span>`,
                    propertyName: Utils.getPropertyNameof<DisplayDemurrageContract>(displayDemurrageContract, displayDemurrageContract =>
                        displayDemurrageContract.fromDateFormatted)
                },
                {
                    columnType: DemurrageColumnType.ToDate,
                    text: `To<span class="small-units">${(this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '')}</span>`,
                    propertyName: Utils.getPropertyNameof<DisplayDemurrageContract>(displayDemurrageContract, displayDemurrageContract =>
                        displayDemurrageContract.toDateFormatted)
                },
                {
                    columnType: DemurrageColumnType.PortsCount,
                    text: 'No. of Ports',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayDemurrageContract>(displayDemurrageContract, displayDemurrageContract =>
                        displayDemurrageContract.portsCount)
                },
                {
                    columnType: DemurrageColumnType.Delete,
                    classes: 'col-button center',
                    isFixedWidth: true
                }
            ];

        this._managerTableSortColumns = [...this.managerTableColumns];
    }

    // #endregion
}
