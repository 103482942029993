import { Capacitor } from "@capacitor/core";

export interface TypeConstructor<T> { new(): T }

export interface IRange<T>
{
    from: T;
    to: T;
}

export interface IApiResponse
{
    isSuccess: boolean;
    message?: string;
    isComplete?: boolean;
    progress?: number;
    status?: number;
}

export interface IApiResponseData<T> extends IApiResponse
{
    data?: T;
}

export interface IIdValue
{
    id: number | string | null;
    value: number | string | null;
}

export interface IListColumn
{
    columnType: number;
    order?: number;
    text?: string;
    classes?: string;
    propertyName?: string;
    sortPropertyName?: string;
    isFixedWidth?: boolean;
    extraWidth?: number;
    isOptional?: boolean;
    isOptionalSelected?: boolean;
}

export interface IOptionalListColumn
{
    columnType: number;
    text?: string;
    selected: boolean;
}

export enum RouteType
{
    None = '**',
    Login = 'login',
    ResetPassword = 'reset-password',
    ChangePassword = 'change-password',
    ShipmentsMonitoring = 'shipments-monitoring',
    ShipmentManager = 'shipment-manager',
    InventoryManager = 'inventory-manager',
    ShipmentDetails = 'shipment-details',
    InventoryDetails = 'inventory-details',
    ShipmentMonitoring = 'shipment-monitoring',
    ShipmentMonitoringArchive = 'shipment-monitoring/archive',
    EventsMonitoring = 'events-monitoring',
    DemurrageMonitoring = 'demurrage-monitoring',
    Analytics = 'analytics',
    DemurrageManager = 'demurrage-manager',
    TransitManager = 'transit_manager',
    AlertsManager = 'alerts',
    Devices = 'devices',
    Forecast = 'forecast',
    StartForm = 'start-form',
    MiniControlCenter = 'mini-control-center',
    SmartLockManager = 'smart-lock-manager',
    PageNotFound = 'page-not-found'
}

export const awaitTimeout = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

function isMobile(): boolean
{
    if (Capacitor.isNativePlatform())
    {
        return true;
    }

    if ('orientation' in window)
    {
        return true;
    }

    if ('matchMedia' in window)
    {
        //return (window as any).matchMedia('(any-pointer:coarse)').matches;
        return (window as any).matchMedia("only screen and (max-width: 600px)").matches;
    }

    const userAgent: string = (navigator as any).userAgent ?? '';
    return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) || /\b(Android|Windows phone|iPad|iPod)\b/i.test(userAgent);
}

export class Constants
{
    public static readonly APP_TITLE: string = 'Contguard';

    public static readonly EMPTY_FIELD_VALUE: string = '—'; // '─';
    public static readonly IS_MOBILE: boolean = isMobile();

    public static readonly LOADING_DATA_STRING: string = `<b>Loading data${this.IS_MOBILE ? '' : '.'}</b>${this.IS_MOBILE ? '<br>' : ' ' }Please wait...`;
    public static readonly DATA_SERVICE_ERROR_STRING: string = '<b>An error occurred while contacting the server.</b><br>Please try again later.';
    
    public static readonly PAGE_NOT_FOUND_URL: string =  '/not-found';

    public static readonly DROPDOWN_SHADOW_SIZE: number = 8;

    public static readonly DRAGGING_START_DELAY: number = 200;
    public static readonly CLICK_TIMEOUT_DURATION: number = 300;

    public static readonly MODAL_TRANSITION_DURATION: number = 500;
    public static readonly LOADING_ELEMENTS_TIMEOUT_DURATION: number =  5000;

    public static readonly SPLASH_DELAY_DURATION: number =  1000;

    public static readonly FOCUS_CLASSNAME: string = 'focus';
    public static readonly SHOW_CLASSNAME: string = 'show';
    public static readonly ANIMATE_CLASSNAME: string =  'animate';
    public static readonly ACTIVE_CLASSNAME: string =  'active';
    public static readonly HIDDEN_CLASSNAME: string = 'hidden';
    public static readonly SUCCESS_CLASSNAME: string = 'success';
    public static readonly SELECTED_CLASSNAME: string = 'selected';
    public static readonly ERROR_CLASSNAME: string =  'error';
    public static readonly ON_CLASS_NAME: string =  'on';

    public static readonly INVALID_FEEDBACK_SELECTOR: string = '.invalid-feedback';
    public static readonly FORM_GROUP_SELECTOR: string = '.form-group';

    public static readonly POPUP_CONTAINER_CLASSNAME: string = 'popup-container';
    public static readonly POPUP_BUBBLE_CLASSNAME: string = 'popup-bubble';
    public static readonly POPUP_BUBBLE_CONTENT_CLASSNAME: string = 'popup-bubble-content';
}

export enum KeyValue
{
    Enter = 'Enter',
    Escape = 'Escape',
    Tab = 'Tab',
    Delete = 'Delete',
    Backspace = 'Backspace',
    ArrowDown = 'ArrowDown',
    ArrowUp = 'ArrowUp',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    PageUp = 'PageUp',
    PageDown = 'PageDown',
    Home= 'Home',
    End = 'End'
}