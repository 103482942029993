import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { LoginModel } from "../../../user/login/model/login.model";
import { Observable, Observer } from "rxjs";
import { Constants, IApiResponseData } from "../../../utils/globals";
import { DisplayInventory, Inventory } from "./inventory-model.class";
import { Utils } from "../../../utils/utils";
import { HttpHelper } from "../../../utils/http-helper";
import { HttpErrorCodes } from "../../../utils/http-error-codes";
import { InventoryLists } from "../../../inventory-manager/inventory-manager/model/inventory-manager-model.class";
import { InventoryManagerModel } from "../../../inventory-manager/inventory-manager/model/inventory-manager.model";
import { ManagerItemBaseModel } from "../../../base/models/managaer-item-base.model";

@Injectable({ providedIn: 'root' })
export class InventoryModel extends ManagerItemBaseModel<DisplayInventory>
{
    // #region Constants

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'inventory-manager';

    // #endregion

    // #region Properties

    public get isAdministrator(): boolean
    {
        return this._loginModel.userInfo.isAdministrator;
    }

    public get inventoryLists(): InventoryLists
    {
        return this._inventoryManagerModel.inventoryLists;
    }

    // #endregion

    // #region Constructors

    constructor(private _httpClient: HttpClient, private _inventoryManagerModel: InventoryManagerModel, private _loginModel: LoginModel)
    {
        super(DisplayInventory);
    }

    // #endregion

    // #region Public Methods

    public override initialize(): void
    {
        super.initialize();

        this.initializeManagerItem(this._inventoryManagerModel.selectedManagerItem !== null ? this._inventoryManagerModel.selectedManagerItem : this._managerItem);
    }

    public override submitManagerItem(): Observable<IApiResponseData<DisplayInventory>>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponseData<DisplayInventory>>) =>
        {
            let httpUpdater: Observable<any>;

            const inventory: Inventory = Utils.clearObjectEmptyStrings(Utils.copyObjectByTargetProperties(this._managerItem, new Inventory()));

            const inventoryUrl: string = `${this.ENTITY_ITEM_CONTROLLER_NAME}/inventory`;
            const body: string = JSON.stringify(inventory);
            const httpHeaders: HttpHeaders = HttpHelper.GetHttpJsonHeaders();

            if (this._managerItem.inventoryId == null)
            {
                httpUpdater = this._httpClient.post<Inventory>(inventoryUrl, body, { headers: httpHeaders });
            }
            else
            {
                httpUpdater = this._httpClient.put<Inventory>(inventoryUrl, body, { headers: httpHeaders });
            }

            httpUpdater.subscribe(
                {
                    next: (inventory: Inventory) =>
                    {
                        this._isBusy = false;

                        if (inventory !== null && this._managerItem.inventoryId === null)
                        {
                            this._managerItem.inventoryId = inventory.inventoryId;
                            this._managerItem.date = inventory.date;
                        }

                        observer.next({ isSuccess: true, isComplete: true, data: this._managerItem });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next(
                            {
                                isSuccess: false,
                                isComplete: true,
                                message: error.status === HttpErrorCodes.NOT_ACCEPTABLE ? 'Editing exceeded the time allowd!' :
                                    Constants.DATA_SERVICE_ERROR_STRING
                            });
                        observer.complete();
                    }
                });
        });
    }

    // #endregion
}