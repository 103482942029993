import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { slideInOutAnimation } from '../../animations/slide.animation'
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { SwipeDirection, PointerEventsDirective } from '../../directives/pointer-events.directive';
import { foldAnimation } from '../../animations/fold.animation';
import { AppMenuItem, AppMenuService } from '../../services/app-menu.service';
import { Utils } from '../../utils/utils';
import { SettingsComponent } from '../../settings/settings.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { RouteType } from '../../utils/globals';

export enum ShowMenuType { MainMenu, SettingsMenu, None }

@Component({
    selector: 'top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.css'],
    animations: [slideInOutAnimation, fadeInOutAnimation, foldAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [UserMenuComponent, PointerEventsDirective, SettingsComponent]
})
export class TopBarComponent implements OnInit
{
    // #region Private Memers

    private _topbarAppMenuItems: AppMenuItem[] = [];

    // #endregion

    // #region Properties

    public showMenuType: ShowMenuType = ShowMenuType.None;

    public get ShowMenuType()
    {
        return ShowMenuType;
    }

    public get topbarAppMenuItems(): AppMenuItem[]
    {
        return this._topbarAppMenuItems;
    }

    // #endregion

    // #region Inputs

    @Input() public barTitle: string = '';

    // #endregion

    // #region Constructor

    constructor(private _appMenuService: AppMenuService)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (Utils.isNullOrEmpty(this.barTitle))
        {
            this.barTitle = this._appMenuService.activeAppMenuItem?.text ?? '';
        }

        const topbarMenuItemsRoutesTypes: RouteType[] =
            [
                RouteType.ShipmentsMonitoring,
                RouteType.ShipmentManager,
                RouteType.InventoryManager,
                RouteType.SmartLockManager
            ];

        this._topbarAppMenuItems = this._appMenuService.allAppMenuItems.filter((appMenuItem: AppMenuItem) =>
            topbarMenuItemsRoutesTypes.includes(appMenuItem.routeType));
    }

    @HostListener('document:keyup.escape') onKeyUpHandler(): void
    {
        if (this.showMenuType !== ShowMenuType.None)
        {
            this.showMenuType = ShowMenuType.None;
        }
    }

    public onMenuSwipe(swipeDirection: SwipeDirection): void
    {
        if (swipeDirection === SwipeDirection.Left)
        {
            this.showMenuType = ShowMenuType.None;
        }
    }

    public onMenuClick(event: MouseEvent): void
    {
        if (event.target !== event.currentTarget)
        {
            this.showMenuType = ShowMenuType.None;
        }
    }

    public onMenuItemClick(routeType: RouteType): void
    {
        this.showMenuType = ShowMenuType.None;

        this._appMenuService.handleAppMenuItemClick(routeType);
    }

    // #endregion
}