import { GoogleMapUtils } from "./google-map-utils";
import Supercluster, { ClusterFeature } from "supercluster";
import { CGICluster } from "./cgi-cluster";
import { MAP_MAX_BOUNDS, SUPERCLUSTER_OPTIONS } from "./cgi-marker-clusterer";
import { ICGIMarkerLocation } from "../../../base/classes/cgi-marker";

export interface ICGIRenderer
{
    render(cluster: CGICluster, map: google.maps.Map): google.maps.marker.AdvancedMarkerElement;
}

export abstract class CGIClusterBaseRenderer implements ICGIRenderer
{
    // #region Constants

    private readonly MARKER_MAX_ZINDEX: number = 1000000;

    // #endregion

    // #region Private Members

    private _maxZoomService: google.maps.MaxZoomService | null = null;
    private _supercluster: Supercluster = new Supercluster(SUPERCLUSTER_OPTIONS);

    // #endregion

    // #region Renderer Interface

    public abstract render(cluster: CGICluster, map: google.maps.Map): google.maps.marker.AdvancedMarkerElement

    // #endregion

    // #region Protected Methods

    protected createClusterMarkerElement(map: google.maps.Map, cluster: CGICluster, info: number[]): google.maps.marker.AdvancedMarkerElement
    {
        const isClusterBoundsAroundSameLocation: boolean = GoogleMapUtils.isBoundsAroundSameLocation(cluster.bounds!);

        const markerElement: google.maps.marker.AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement(
            {
                position: cluster.position,
                content: this.createClusterMarkerContentElement(cluster, info, isClusterBoundsAroundSameLocation),
                zIndex: this.MARKER_MAX_ZINDEX + cluster.markers.length
            });

        if (this._maxZoomService === null)
        {
            this._maxZoomService = new google.maps.MaxZoomService();
        }

        this._maxZoomService.getMaxZoomAtLatLng(cluster.position, (result: google.maps.MaxZoomResult) =>
        {
            if (result.status === google.maps.MaxZoomStatus.OK)
            {
                const isClusterMarkersAroundSameLocationAtZoomLevel: boolean = result.zoom === map.getZoom() ?
                    cluster.markers !== undefined && cluster.markers.length > 1 : this.getClusters(cluster.markers, result.zoom).length === 1;

                if (isClusterMarkersAroundSameLocationAtZoomLevel === isClusterBoundsAroundSameLocation)
                {
                    return;
                }

                const markerContentElement: Element | null = this.createClusterMarkerContentElement(cluster, info, isClusterMarkersAroundSameLocationAtZoomLevel);

                if (markerContentElement !== null)
                {
                    (markerElement.content as HTMLElement).className = (markerContentElement as HTMLElement).className;
                    (markerElement.content as HTMLElement).style.background = (markerContentElement as HTMLElement).style.background;
                    (markerElement.content as HTMLElement).innerHTML = (markerContentElement as HTMLElement).innerHTML;
                }
            }
        });

        return markerElement;
    }

    protected abstract createClusterMarkerContentElement(_cluster: CGICluster, _info: number[], _isClusterBoundsAroundSameLocation: boolean): Element

    // #endregion

    // #region Protected Methods

    private getClusters(markers: (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[], mapZoom: number): Array<ClusterFeature<any>>
    {
        this._supercluster.load(GoogleMapUtils.getMarkerSuperclusterPoints(markers));
        return this._supercluster.getClusters(MAP_MAX_BOUNDS, Math.round(mapZoom));
    }

    // #endregion
}