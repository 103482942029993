import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHelper } from '../../../utils/http-helper';
import
    {
        IUserAuthData, UserInsightPermission, UserInfo, UserCredetials, UserExternalLogin, UserExternalLoginResponse,
        UserExternalLoginAccessCodeRequest,
        CustomerIdType
    } from './login-model.class';
import { Constants, IApiResponse, IApiResponseData, RouteType } from '../../../utils/globals';
import { catchError, Observable, Observer, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { jwtDecode } from 'jwt-decode';
import { AppStorage } from '../../../utils/app-storage';
import { HttpErrorCodes } from '../../../utils/http-error-codes';
import { Utils } from '../../../utils/utils';
import { RoutingHistoryService } from '../../../services/routing-history.service';
import { BaseModel } from '../../../base/classes/base-model';

@Injectable({ providedIn: 'root' })
export class LoginModel extends BaseModel
{
    // #region Constants

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'auth/user';
    private readonly AMAZON_IDENTITY_PROVIDER: string = 'amazon';
    private readonly AMAZON_LOGIN_EMAILS: string[] =
        [
            'jnesani@amazon.com', 'hcovanvo@amazon.com', 'tranfran@amazon.com', 'hetheriw@amazon.com', 'sbbidion@amazon.com', 'robnatal@amazon.com',
            'campjane@amazon.com', 'pamialle@amazon.com', 'abrahaki@amazon.com', 'sagrene@amazon.com', 'aldrinb@amazon.com', 'brightos@amazon.com',
            'galwalte@amazon.com', 'clatamik@amazon.com', 'crden@amazon.com', 'hiteshkd@amazon.com', 'framptmi@amazon.com', 'wzaar@amazon.com',
            'morrharv@amazon.com', 'joaprove@amazon.com', 'srebeccd@amazon.com', 'bronsm@amazon.com', 'dvariul@amazon.com', 'ryasimas@amazon.com',
            'ncourtni@amazon.com', 'tyrange@amazon.com',
            'jahoaf@amazon.com', 'centmdav@amazon.com', 'gordoded@amazon.com', 'daviedga@amazon.com', 'eruz@amazon.com', 'thmasxa@amazon.com',
            'kyletoll@amazon.com', 'taebrow@amazon.com', 'paynzavi@amazon.com', 'bryatye@amazon.com', 'fjasrodr@amazon.com', 'joseblev@amazon.com',
            'mousksec@amazon.com', 'suquinto@amazon.com', 'applewsh@amazon.com', 'vicintrg@amazon.com', 'graxbobb@amazon.com', 'benjsten@amazon.com',
            'pantebra@amazon.com', 'velajess@amazon.com', 'enriwmel@amazon.com', 'clquayve@amazon.com', 'tiricha@amazon.com', 'adizas@amazon.com',
            'archbolb@amazon.com', 'dankalal@amazon.com', 'odarperr@amazon.com', 'caponigr@amazon.com', 'rdeontye@amazon.com', 'ernverdu@amazon.com',
            'geoevans@amazon.com', 'moorjrm@amazon.com', 'hjeron@amazon.com', 'jorweb@amazon.com', 'rmustaka@amazon.com', 'lvvi@amazon.com',
            'kerdjoud@amazon.com', 'sandmi@amazon.com', 'michahme@amazon.com', 'shunkm@amazon.com', 'cragrc@amazon.com', 'yrysilde@amazon.com',
            'samanre@amazon.com', 'adeosuns@amazon.com', 'robivane@amazon.com', 'vwilwhit@amazon.com', 'balzacha@amazon.com', 'bindriag@amazon.com',
            'folied@amazon.com', 'hcolemad@amazon.com', 'ericisla@amazon.com', 'hickscho@amazon.com', 'lopjacqe@amazon.com', 'kjasford@amazon.com',
            'lytierra@amazon.com', 'vancepv@amazon.com', 'rimeader@amazon.com', 'josamanq@amazon.com', 'ricezs@amazon.com', 'hcalhout@amazon.com',
            'nssenec@amazon.com', 'zhudsotr@amazon.com', 'oabubsad@amazon.com', 'neandero@amazon.com', 'amaduzia@amazon.com', 'findlach@amazon.com',
            'debellis@amazon.com', 'blawrenj@amazon.com', 'mccomark@amazon.com', 'matrodge@amazon.com', 'mattief@amazon.com', 'osmithgr@amazon.com',
            'abarcamy@amazon.com', 'sharkemy@amazon.com', 'scarrolt@amazon.com', 'sillopez@amazon.com', 'vrrowlan@amazon.com', 'jayvcoff@amazon.com',
            'lstenson@amazon.com', 'yedrickp@amazon.com', 'lamwatso@amazon.com', 'fitcamer@amazon.com', 'frausc@amazon.com', 'qhernadi@amazon.com',
            'lewiskru@amazon.com', 'jesfuenr@amazon.com', 'joelchv@amazon.com', 'kngya@amazon.com', 'lilithw@amazon.com', 'villtmar@amazon.com',
            'benmatth@amazon.com', 'schaubmi@amazon.com', 'villmoni@amazon.com', 'powertho@amazon.com', 'trammt@amazon.com', 'uarellan@amazon.com',
            'devihols@amazon.com', 'lemullin@amazon.com', 'kmartez@amazon.com', 'angesmiw@amazon.com', 'brewsart@amazon.com', 'newsonb@amazon.com',
            'cwlsong@amazon.com', 'tdertayl@amazon.com', 'mcdominb@amazon.com', 'edwalewd@amazon.com', 'monsivj@amazon.com', 'kbackyte@amazon.com',
            'saddleka@amazon.com', 'mxlorenz@amazon.com', 'moibauti@amazon.com', 'uwaeo@amazon.com', 'queenqun@amazon.com', 'wquscott@amazon.com',
            'ramvrafa@amazon.com', 'rayharr@amazon.com', 'rodalant@amazon.com', 'patinoul@amazon.com', 'bfouraym@amazon.com', 'ricardug@amazon.com',
            'zortizwi@amazon.com', 'iamtaz@amazon.com', 'nabrand@amazon.com', 'cgspiess@amazon.com', 'woycharl@amazon.com', 'ednalizs@amazon.com',
            'schiope@amazon.com', 'jesblai@amazon.com', 'gaertk@amazon.com', 'snylesli@amazon.com', 'aiyedun@amazon.com', 'tmiawh@amazon.com',
            'gravtany@amazon.com', 'uhhutchi@amazon.com', 'dimock@amazon.com', 'wallenjo@amazon.com', 'brimarel@amazon.com', 'demskinn@amazon.com',
            'rittenem@amazon.com', 'jaednorr@amazon.com', 'jeaarist@amazon.com', 'pkaradye@amazon.com', 'targprin@amazon.com', 'shavoc@amazon.com',
            'williaaf@amazon.com', 'ambersaj@amazon.com', 'uevns@amazon.com', 'curharda@amazon.com', 'deliliv@amazon.com', 'erzambra@amazon.com',
            'estiveno@amazon.com', 'djaydenw@amazon.com', 'jonquake@amazon.com', 'calejuan@amazon.com', 'julideon@amazon.com', 'karekehr@amazon.com',
            'xkrramir@amazon.com', 'hornadal@amazon.com', 'gemare@amazon.com', 'fishmarl@amazon.com', 'mccarrim@amazon.com', 'matsimpk@amazon.com',
            'qwhitemi@amazon.com', 'wnomung@amazon.com', 'smthron@amazon.com', 'tsellis@amazon.com', 'willowy@amazon.com', 'kemberlf@amazon.com',
            'stekeesl@amazon.com', 'ucarrena@amazon.com', 'ylbenhud@amazon.com', 'kwdarlen@amazon.com', 'enrinara@amazon.com', 'fkulargu@amazon.com',
            'harmonzw@amazon.com', 'pardihun@amazon.com', 'jaomoone@amazon.com', 'chaneyl@amazon.com', 'nlusgo@amazon.com', 'lpatmich@amazon.com',
            'pavjusti@amazon.com', 'sergmorb@amazon.com', 'tarndee@amazon.com', 'ansjoser@amazon.com', 'warabrie@amazon.com', 'acserenv@amazon.com',
            'viduandr@amazon.com', 'jozbryan@amazon.com', 'dzflores@amazon.com', 'kdestyne@amazon.com', 'ericfeas@amazon.com', 'hjjanda@amazon.com',
            'jelundys@amazon.com', 'jocdomin@amazon.com', 'stovajoh@amazon.com', 'jrdatyl@amazon.com', 'garcmajo@amazon.com', 'jkensika@amazon.com',
            'ybacokyl@amazon.com', 'olakint@amazon.com', 'romarbou@amazon.com', 'merismre@amazon.com', 'weatrons@amazon.com', 'ikumarsa@amazon.com',
            'jeasinsk@amazon.com', 'tsstanbe@amazon.com', 'wendsena@amazon.com', 'naathoma@amazon.com', 'bulmandr@amazon.com', 'kthodavi@amazon.com',
            'dennisut@amazon.com', 'brehakik@amazon.com', 'jajuanwe@amazon.com', 'ojamehi@amazon.com', 'gerichs@amazon.com', 'wkobcoe@amazon.com',
            'mflrest@amazon.com', 'ruizrenx@amazon.com', 'mbuyjuli@amazon.com', 'cshammon@amazon.com', 'ansherry@amazon.com', 'sptaylod@amazon.com',
            'jwilguen@amazon.com', 'guird@amazon.com', 'cuayaald@amazon.com', 'angeelki@amazon.com', 'drantond@amazon.com', 'thompsbi@amazon.com',
            'choudavt@amazon.com', 'zwilliel@amazon.com', 'hopsoeri@amazon.com', 'jggoldey@amazon.com', 'pdjonath@amazon.com', 'katietag@amazon.com',
            'salesma@amazon.com', 'farmepat@amazon.com', 'robcly@amazon.com', 'fseanjon@amazon.com', 'sidkarna@amazon.com'
        ];
    private readonly AMAZON_AUTO_LOGOUT_TIMEOUT: number = 20 * 60 * 60 * 1000;

    //#endregion

    // #region Private Members

    private _userInfo: UserInfo = new UserInfo();
    private _userAuthData: IUserAuthData | null = null;
    private _userInsightPermissions: UserInsightPermission[] = [];
    private _loginExternalIdentityProvider: string | null = null;
    private _username: string | null = null;
    private _isAmazonEmailLogin: boolean = false;

    private _autoLogoutTimeoutHandleId: NodeJS.Timeout | null = null;

    private _defaultRouteRedirectTo: string | null = null;

    // #endregion

    // #region Properties

    public password: string | null = null;

    public get username(): string | null
    {
        return this._username;
    }

    public set username(value: string | null)
    {
        this._username = value;
        Utils.clearValueEmptyStrings(this._username);

        this.updateLoginExternalIdentityProvider(this._username);
    }

    public get loginExternalIdentityProvider(): string | null
    {
        return this._loginExternalIdentityProvider;
    }

    public get isAmazonEmailLogin(): boolean
    {
        return this._isAmazonEmailLogin;
    }

    public get isAccountTypeAmazon(): boolean
    {
        return this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonEU) ||
            this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonUS);
    }

    public get isAccountTypeAmazonEU(): boolean
    {
        return this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonEU);
    }

    public get isAccountTypeAmazonUS(): boolean
    {
        return this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonUS);
    }

    public get shipmentManagerPermission(): UserInsightPermission
    {
        if (this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonEU))
        {
            return UserInsightPermission.ShipmentManagerAmazonEU;
        }
        else if (this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonUS))
        {
            return UserInsightPermission.ShipmentManagerAmazonUS;
        }

        return UserInsightPermission.ShipmentManager;
    }

    public get userInfo(): UserInfo
    {
        return this._userInfo;
    }

    public get UserInsightPermission()
    {
        return UserInsightPermission;
    }

    public get userInsightPermissions(): UserInsightPermission[]
    {
        return this._userInsightPermissions;
    }

    private get userAuthData(): IUserAuthData | null
    {
        return this._userAuthData;
    }

    private set userAuthData(userAuthData: IUserAuthData | null)
    {
        this._userAuthData = userAuthData;
        if (this._userAuthData !== null)
        {
            AppStorage.setStorageItem(AppStorage.AUTH_KEY_NAME, JSON.stringify(this.userAuthData));

            const decodedToken: any = jwtDecode(this._userAuthData.access_token);

            if (Utils.isNullOrEmpty(decodedToken.given_name))
            {
                decodedToken.given_name.charAt(0).toUpperCase() + decodedToken.given_name.slice(1);
            }

            this._userInfo.firstName = this.capitalizeName(decodedToken.given_name);
            this._userInfo.lastName = this.capitalizeName(decodedToken.family_name);
            this._userInfo.fullName = `${decodedToken.given_name ?? '?'} ${decodedToken.family_name ?? '?'}`;
            this._userInfo.initials = `${!Utils.isNullOrEmpty(decodedToken.given_name) ? decodedToken.given_name.charAt(0) : '?'}${''
                }${ !Utils.isNullOrEmpty(decodedToken.given_name) ? decodedToken.family_name.charAt(0) : '?' }`;
            this._userInfo.customerId = decodedToken.customer_id;
            this._userInfo.email = decodedToken.email;
            this._userInfo.isTest = decodedToken.isTest === 'true';
            this._userInfo.isAdministrator = decodedToken.groups != null && decodedToken.groups.indexOf('admin') > -1;
            this._userInfo.isDemo = decodedToken.groupIds != null && decodedToken.groupIds.includes('DEMO');

            this._isAmazonEmailLogin = this._userInfo.email !== null && this.AMAZON_LOGIN_EMAILS.includes(this._userInfo.email);

            if (!Utils.isNullOrEmpty(decodedToken.corp_id))
            {
                this._userInfo.corpId = parseInt(decodedToken.corp_id);
            }

            if (Array.isArray(decodedToken.pbiReports))
            {
                this._userInsightPermissions = [...decodedToken.pbiReports];
            }

            this._defaultRouteRedirectTo = this.getDefaultRouteRedirectTo();
            if (this._defaultRouteRedirectTo !== null)
            {
                this._router.config[this._router.config.length - 2].redirectTo = this._defaultRouteRedirectTo;
                this._router.resetConfig(this._router.config);
            }
        }
        else
        {
            AppStorage.setStorageItem(AppStorage.AUTH_KEY_NAME, null);
        }
    }

    public get accessToken(): string | null
    {
        return this.userAuthData !== null ? this.userAuthData.access_token : null;
    }

    public get defaultRouteRedirectTo()
    {
        return this._defaultRouteRedirectTo
    }

    // #endregion

    // #region Constructors

    constructor(private _httpClient: HttpClient, private _router: Router, private _location: Location, private _routingHistoryService: RoutingHistoryService)
    {
        super();
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._username = null;
        this.password = null;
        this.userAuthData = null;
    }

    public logout(isReplaceUrl: boolean = false): void
    {
        if (this.userAuthData !== null)
        {
            this.userLogout();
        }

        this.clearAutoLogoutTimeout();

        const loginPath: string = `/${RouteType.Login}`;

        if (this._routingHistoryService.isEmpty && this._location.path().length > 0 && !this._location.path().startsWith(loginPath) &&
            this._location.path() !== '/')
        {
            this._routingHistoryService.addNavigationUrl(this._location.path());
        }

        this._router.navigate([loginPath], { replaceUrl: isReplaceUrl });
    }

    public loginUser(): Observable<IApiResponse>
    {
        if (this._loginExternalIdentityProvider !== null)
        {
            return this.externalLoginUser();
        }

        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const userCredetials: UserCredetials = new UserCredetials();
            userCredetials.username = this._username;
            userCredetials.password = this.password;

            this._httpClient.post<IUserAuthData>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/login`, HttpHelper.GetParams(userCredetials),
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (userAuthData: IUserAuthData) =>
                        {
                            this._isBusy = false;

                            this.userAuthData = userAuthData;

                            this.initializeAmazonAutoLogoutTimeout();

                            observer.next({ isSuccess: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            const errorMessage: string = error.status === HttpErrorCodes.NOT_FOUND ? 'The login failed.\r\nThe username or password are incorrect.' :
                                Constants.DATA_SERVICE_ERROR_STRING;

                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: errorMessage });
                            observer.complete();
                        }
                    });
        });
    }

    public getAccessToken(code: string): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const userExternalLoginAccessCodeRequest: UserExternalLoginAccessCodeRequest = new UserExternalLoginAccessCodeRequest();
            userExternalLoginAccessCodeRequest.code = code;
            userExternalLoginAccessCodeRequest.redirectBaseUrl = this.getRedirectUrl();

            this._httpClient.post<IUserAuthData>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/token`, HttpHelper.GetParams(userExternalLoginAccessCodeRequest),
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (userAuthData: IUserAuthData) =>
                        {
                            this._isBusy = false;

                            this.userAuthData = userAuthData;

                            observer.next({ isSuccess: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
        });
    }

    public refreshUserToken(): Observable<IUserAuthData>
    {
        return this._httpClient.post<IUserAuthData>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/refresh`, HttpHelper.GetParams({ refreshToken: this.userAuthData?.refresh_token }),
            { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).pipe(
                tap((userAuthData: IUserAuthData) =>
                {
                    this.userAuthData = userAuthData;
                }),
                catchError((error: HttpErrorResponse) =>
                {
                    this.clear();
                    this.logout();

                    return throwError(() => error);
                }));
    }

    public loadSavedData(): void
    {
        const userAuthDataJson: string | null = AppStorage.getStorageItem(AppStorage.AUTH_KEY_NAME);
        this.userAuthData = userAuthDataJson !== null ? JSON.parse(userAuthDataJson) as IUserAuthData : null;

        this.initializeAmazonAutoLogoutTimeout();
    }

    // #endregion

    // #region Private Methods

    private getDefaultRouteRedirectTo(): string | null
    {
        if (this.userInsightPermissions.includes(UserInsightPermission.ShipmetsMonitoring))
        {
            return RouteType.ShipmentsMonitoring;
        }
        else if (!Constants.IS_MOBILE && this.userInsightPermissions.includes(UserInsightPermission.DemurrageMonitoring))
        {
            return RouteType.DemurrageMonitoring;
        }
        else if (this.userInsightPermissions.includes(this.shipmentManagerPermission))
        {
            return RouteType.ShipmentManager;
        }
        else if (!Constants.IS_MOBILE && this.userInsightPermissions.includes(UserInsightPermission.StartForm))
        {
            return RouteType.StartForm
        }
        else if (!Constants.IS_MOBILE && this.userInsightPermissions.includes(UserInsightPermission.DemurrageManager))
        {
            return RouteType.DemurrageManager;
        }
        else if (this.userInsightPermissions.includes(UserInsightPermission.InventoryManager))
        {
            return RouteType.InventoryManager;
        }
        else if (!Constants.IS_MOBILE && this.userInsightPermissions.includes(UserInsightPermission.Devices))
        {
            return RouteType.Devices;
        }
        else if (!Constants.IS_MOBILE && this.userInsightPermissions.includes(UserInsightPermission.Analytics))
        {
            return RouteType.Analytics
        }
        else if (!Constants.IS_MOBILE && this.userInsightPermissions.includes(UserInsightPermission.MiniControlCenter))
        {
            return RouteType.MiniControlCenter
        }
        else if (Constants.IS_MOBILE && this.userInsightPermissions.includes(UserInsightPermission.SmartLockManager))
        {
            return RouteType.SmartLockManager
        }

        return null;
    }

    private initializeAmazonAutoLogoutTimeout(): void
    {
        const customerId: number = Number(this._userInfo.customerId);
        if (customerId === CustomerIdType.AmazonEurope || customerId === CustomerIdType.AmazonUS)
        {
            let loginTime: number;
            const loginTimeString: string | null = AppStorage.getStorageItem(AppStorage.LOGIN_KEY_NAME);
            if (loginTimeString === null)
            {
                loginTime = new Date().getTime();
                AppStorage.setStorageItem(AppStorage.LOGIN_KEY_NAME, loginTime.toString());
            }
            else
            {
                loginTime = Number(loginTimeString);
            }

            this._autoLogoutTimeoutHandleId = setTimeout(() => this.logout(), Math.max(0, loginTime - new Date().getTime() + this.AMAZON_AUTO_LOGOUT_TIMEOUT));
        }
        else
        {
            AppStorage.setStorageItem(AppStorage.LOGIN_KEY_NAME, null);
        }
    }

    private clearAutoLogoutTimeout(): void
    {
        AppStorage.setStorageItem(AppStorage.LOGIN_KEY_NAME, null);

        if (this._autoLogoutTimeoutHandleId !== null)
        {
            clearTimeout(this._autoLogoutTimeoutHandleId);
            this._autoLogoutTimeoutHandleId = null;
        }
    }

    private updateLoginExternalIdentityProvider(email: string | null): void
    {
        if (email !== null && this.AMAZON_LOGIN_EMAILS.includes(email))
        {
            this._loginExternalIdentityProvider = this.AMAZON_IDENTITY_PROVIDER;
        }
        else
        {
            this._loginExternalIdentityProvider = null;
        }
    }

    private getRedirectUrl(): string
    {
        return `${window.location.origin}/${RouteType.Login}`;
    }

    private externalLoginUser(): Observable<IApiResponseData<string | null>>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponseData<string | null>>) =>
        {
            const userExternalLogin: UserExternalLogin = new UserExternalLogin();
            userExternalLogin.identityProvider = this._loginExternalIdentityProvider;
            userExternalLogin.redirectBaseUrl = this.getRedirectUrl();

            this._httpClient.post<UserExternalLoginResponse>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/external-login`, HttpHelper.GetParams(userExternalLogin),
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (userExternalLoginResponse: UserExternalLoginResponse) =>
                        {
                            this._isBusy = false;

                            observer.next({ isSuccess: true, data: userExternalLoginResponse.redirectUrl });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            const errorMessage: string = error.status === HttpErrorCodes.NOT_FOUND ? 'The login failed.\r\nThe username or password are incorrect.' :
                                Constants.DATA_SERVICE_ERROR_STRING;

                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: errorMessage });
                            observer.complete();
                        }
                    });
        });
    }

    private userLogout(): void
    {
        this._httpClient.post(`${this.ENTITY_ITEM_CONTROLLER_NAME}/logout`, HttpHelper.GetParams({ refreshToken: this.userAuthData?.refresh_token }),
            { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                {
                    error: (error: HttpErrorResponse) => console.error(error)
                });
    }

    private capitalizeName(name: string): string
    {
        if (Utils.isNullOrEmpty(name))
        {
            return '';
        }

        return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    }

    // #endregion
}
