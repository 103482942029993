import { Injectable } from "@angular/core";
import { ManagerBaseModel } from "../../../base/models/manager-base.model";
import { DisplaySmartLock, SmartLockColumnType, SmartLockKeyHash, SmartLockLogicStateType, SmartLockManagerServiceInfo, SmartLockUserUnlockLog } from "./smart-lock-manager-model.class";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { Observable, Observer, forkJoin } from "rxjs";
import { Constants, IApiResponse } from "../../../utils/globals";
import { HttpHelper } from "../../../utils/http-helper";
import { LoginModel } from "../../../user/login/model/login.model";
import { default as moment } from "moment";

@Injectable({ providedIn: 'root' })
export class SmartLockManagerModel extends ManagerBaseModel<DisplaySmartLock, SmartLockColumnType>
{
    // #region Constants

    private readonly BATTERY_MIN_VOLTAGE: number = 3.6;
    private readonly BATTERY_MAX_VOLTAGE: number = 4.1;
    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'smart-lock-manager';

    // #endregion

    // #region Private Members

    private _smartLockManagerServiceInfo: SmartLockManagerServiceInfo = new SmartLockManagerServiceInfo();
    private _bluetoothConncetedUnitNumberToDeviceIdMap: Map<number, string> = new Map();
    private _unitNumberToSmartLockMap: Map<number, DisplaySmartLock> = new Map();
    private _filterSmartLockLogicState: SmartLockLogicStateType | null = null;

    // #endregion

    // #region Properties

    public get smartLockManagerServiceInfo(): SmartLockManagerServiceInfo
    {
        return this._smartLockManagerServiceInfo;
    }

    public get filterSmartLockLogicState(): SmartLockLogicStateType | null
    {
        return this._filterSmartLockLogicState;
    }

    public set filterSmartLockLogicState(value: SmartLockLogicStateType | null)
    {
        this._filterSmartLockLogicState = value;

        this.applyFilters();
        this.sortManagerItems();
    }

    // #endregion

    // #region Constructors

    constructor(_httpClient: HttpClient, private _loginModel: LoginModel)
    {
        super(_httpClient);

        this._sortColumn = SmartLockColumnType.LastTrasmitDate;

        const displaySmartLock: DisplaySmartLock = new DisplaySmartLock();

        this._managerItemKeyPropertyName = Utils.getPropertyNameof<DisplaySmartLock>(displaySmartLock, displaySmartLock => displaySmartLock.unitNumber);
        this._managerItemDatePropertyName = Utils.getPropertyNameof<DisplaySmartLock>(displaySmartLock, displaySmartLock => displaySmartLock.lastTransmitDate);
    }

    // #endregion

    // #region Public Methods

    public override clear()
    {
        super.clear();
        this._unitNumberToSmartLockMap.clear();
    }

    public findConnectedSmartLock(unitNumber: number): boolean
    {
        return this._bluetoothConncetedUnitNumberToDeviceIdMap.get(unitNumber) !== undefined;
    }

    public isSmartLockAvailable(unitNumber: number): boolean
    {
        return this._unitNumberToSmartLockMap.get(unitNumber) !== undefined;
    }

    public getBluetoothConncetedDeviceIds(): string[]
    {
        return Array.from(this._bluetoothConncetedUnitNumberToDeviceIdMap.values());
    }

    public addSmartLockBluetoothConnectionInfo(unitNumber: number, deviceId: string): void
    {
        this._bluetoothConncetedUnitNumberToDeviceIdMap.set(unitNumber, deviceId);
        const smartLock: DisplaySmartLock | undefined = this._unitNumberToSmartLockMap.get(unitNumber);
        if (smartLock !== undefined)
        {
            smartLock.deviceId = deviceId;
            this.sortManagerItems();
        }
    }

    public removeSmartLockBluetoothConnectionInfo(unitNumber: number): void
    {
        this._bluetoothConncetedUnitNumberToDeviceIdMap.delete(unitNumber);
        const smartLock: DisplaySmartLock | undefined = this._unitNumberToSmartLockMap.get(unitNumber);
        if (smartLock !== undefined)
        {
            smartLock.deviceId = undefined;
            smartLock.liveSmartLockStatus = undefined;
            this.sortManagerItems();
        }
    }

    public clearSmartLocksBluetoothConnectionInfo(): void
    {
        this._unitNumberToSmartLockMap.clear();

        for (const managerItem of this._managerItems)
        {
            managerItem.deviceId = undefined;
            managerItem.liveSmartLockStatus = undefined;
        }

        this._bluetoothConncetedUnitNumberToDeviceIdMap.clear();
    }

    public getSelectedSmartLockKeyHash(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            this._httpClient.get<SmartLockKeyHash>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/key-hash?customerId=${''
                }${this._loginModel.userInfo.customerId}&unitNumber=${this.selectedManagerItem?.unitNumber}`,
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (smartLockKeyHash: SmartLockKeyHash) =>
                        {
                            this._isBusy = false;

                            if (this.selectedManagerItem !== null)
                            {
                                this.selectedManagerItem.smartLockKeyHash = smartLockKeyHash;
                            }

                            observer.next({ isSuccess: true, isComplete: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, isComplete: true, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
        });
    }

    public sendSmartLockUserUnlockLog(unitNumber: number, logicState: SmartLockLogicStateType): Observable<IApiResponse>
    {
        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const smartLockUserUnlockLog: SmartLockUserUnlockLog = new SmartLockUserUnlockLog();
            smartLockUserUnlockLog.unitNumber = unitNumber;
            smartLockUserUnlockLog.userName = this._loginModel.userInfo.email;
            smartLockUserUnlockLog.logicState = logicState;

            this._httpClient.post<boolean>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/unlock-log`, JSON.stringify(smartLockUserUnlockLog),
                { headers: HttpHelper.GetHttpJsonHeaders() }).subscribe(
                    {
                        next: () =>
                        {
                            observer.next({ isSuccess: true, isComplete: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            console.error(error);

                            observer.next({ isSuccess: false, isComplete: true, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
        });
    }

    public calculateBaterryPowerPercent(mainVoltage: number | null)
    {
        if (mainVoltage === null)
        {
            return 0;
        }

        return Math.min(100, Math.max(0,
            Math.round((mainVoltage - this.BATTERY_MIN_VOLTAGE) / (this.BATTERY_MAX_VOLTAGE - this.BATTERY_MIN_VOLTAGE) * 100)));
    }

    public override getManagerItems(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const joinObservables =
            {
                smartLocks: this._httpClient.get<DisplaySmartLock[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/smart-locks?customerId=${this._loginModel.userInfo.customerId}`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                serviceInfo: this._httpClient.get<SmartLockManagerServiceInfo>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/service-info`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
            };

            forkJoin(joinObservables).subscribe(
                {
                    next: (join) =>
                    {
                        this._smartLockManagerServiceInfo = join.serviceInfo;

                        this._managerItems = [];
                        for (const smartLock of join.smartLocks)
                        {
                            if (smartLock.unitNumber === null)
                            {
                                continue;
                            }

                            if (smartLock.name !== null)
                            {
                                smartLock.name = `<b>${smartLock.unitNumber}</b>${smartLock.name.substring(smartLock.unitNumber.toString().length)}`;
                            }

                            if (smartLock.lastTransmit !== null)
                            {
                                smartLock.lastTransmitDate = moment(smartLock.lastTransmit, 'YYYYMMDDHHmmss').toDate();
                                smartLock.lastTransmitDuration = `${Utils.formatDuration(smartLock.lastTransmitDate, new Date(), true)} ago`;
                            }

                            smartLock.baterryPowerPercent = this.calculateBaterryPowerPercent(smartLock.mainVoltage);
                            smartLock.deviceId = this._bluetoothConncetedUnitNumberToDeviceIdMap.get(smartLock.unitNumber);

                            this._managerItems.push(smartLock);

                            this._unitNumberToSmartLockMap.set(smartLock.unitNumber, smartLock);
                        }

                        this.applyFilters();
                        this.sortManagerItems();

                        this._isBusy = false;
                        observer.next({ isSuccess: true, isComplete: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, isComplete: true, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });
        });
    }

    // #endregion

    // #region Protected Methods

    protected override getSortColumnProperty(managerItem: DisplaySmartLock): any
    {
        switch (this._sortColumn)
        {
            case SmartLockColumnType.LastTrasmitDate:
                {
                    return managerItem.lastTransmitDate;
                }
        }
    }

    protected override sortManagerItems(): void
    {
        super.sortManagerItems();

        if (this._filteredManagerItems.length === 0)
        {
            return;
        }

        const connectedItems: DisplaySmartLock[] = [];

        for (let i: number = this._filteredSortedManagerItems.length - 1; i >= 0; i--)
        {
            if (!Utils.isNullOrEmpty(this._filteredSortedManagerItems[i].deviceId))
            {
                connectedItems.push(this._filteredSortedManagerItems.splice(i, 1)[0]);
            }
        }

        this._filteredSortedManagerItems = [...connectedItems, ...this._filteredSortedManagerItems];
    }

    // #endregion
}