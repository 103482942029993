import { Constants } from "./globals";

export class HtmlToImage
{
    // #region Constants

    private static readonly URL_REGEX: RegExp = /url\((['"]?)([^'"]+?)\1\)/g;
    private static readonly DATA_REGEX: RegExp = /^(data:)/;
    private static readonly FONT_URL_REGEX: RegExp = /url\(["']?([^"')]+)["']?\)/g;
    private static readonly WATERMARK_IMAGE_RATIO: number = Constants.IS_MOBILE ? 1 : 0.4;
    private static readonly WATERMARK_IMAGE_OFFSET: number = 10 * this.WATERMARK_IMAGE_RATIO;
    private static readonly WATERMARK_IMAGE_PATH: string = '/assets/vectors/logo-watermark.svg';

    // #endregion

    // #region Private Members

    private static _urlCacheMap: Map<string, string> = new Map();

    // #endregion

    // #region Public Methods

    public static async htmlElementToBase64ImageData(element: HTMLElement): Promise<string>
    {
        this._urlCacheMap.clear();

        const clonedImageElements: (HTMLImageElement | SVGImageElement)[] = [];
        const clonedBackgroundElements: HTMLElement[] = [];
        const clonedElement: HTMLElement = await this.cloneNode(element, clonedImageElements, clonedBackgroundElements);

        await Promise.all(clonedImageElements.map((imageElement: HTMLImageElement | SVGImageElement) => this.updateEmbedImageElement(imageElement)));
        await Promise.all(clonedBackgroundElements.map((element: HTMLElement) => this.updateEmbedBackgroundElement(element)));

        const fontEmbedCSS: string = await this.getFontEmbedCSS();

        if (fontEmbedCSS.length > 0)
        {
            const styleElement: HTMLElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode(fontEmbedCSS));

            if (clonedElement.childElementCount > 0)
            {
                clonedElement.insertBefore(styleElement, clonedElement.firstChild);
            }
            else
            {
                clonedElement.appendChild(styleElement);
            }
        }

        const width: number = element.clientWidth;
        const height: number = element.clientHeight;

        const xmlSerializer: XMLSerializer = new XMLSerializer();
        const svgElementData: string = encodeURIComponent(xmlSerializer.serializeToString(this.wrapElementWithSvgElement(clonedElement, width, height)));

        const imageElement: HTMLImageElement = await this.createImage(`data:image/svg+xml;charset=utf-8,${svgElementData}`);

        const canvasElement: HTMLCanvasElement = document.createElement('canvas');
        const canvasRenderingContext: CanvasRenderingContext2D = canvasElement.getContext('2d')!;
        const devicePixelRatio: number = window.devicePixelRatio || 1;

        canvasElement.width = width * devicePixelRatio;
        canvasElement.height = height * devicePixelRatio;

        canvasElement.style.width = `${width}px`;
        canvasElement.style.height = `${height}px`;

        canvasRenderingContext.drawImage(imageElement, 0, 0, canvasElement.width, canvasElement.height);

        const watermarkImage: HTMLImageElement = await this.createImage(this.WATERMARK_IMAGE_PATH);
        canvasRenderingContext.translate(this.WATERMARK_IMAGE_OFFSET, this.WATERMARK_IMAGE_OFFSET);
        canvasRenderingContext.drawImage(watermarkImage, 0, 0, watermarkImage.width * this.WATERMARK_IMAGE_RATIO, watermarkImage.height * this.WATERMARK_IMAGE_RATIO);

        return canvasElement.toDataURL();
    }

    // #endregion

    // #region Private Methods

    private static wrapElementWithSvgElement(element: HTMLElement, svgWidth: number, svgHeight: number): Element
    {
        const xmlns: string = 'http://www.w3.org/2000/svg';
        const svgElement: Element = document.createElementNS(xmlns, 'svg');
        const foreignObjectElement: Element = document.createElementNS(xmlns, 'foreignObject');

        svgElement.setAttribute('width', `${svgWidth}`);
        svgElement.setAttribute('height', `${svgHeight}`);
        svgElement.setAttribute('viewBox', `0 0 ${svgWidth} ${svgHeight}`);

        foreignObjectElement.setAttribute('width', '100%');
        foreignObjectElement.setAttribute('height', '100%');
        foreignObjectElement.setAttribute('x', '0');
        foreignObjectElement.setAttribute('y', '0');
        foreignObjectElement.setAttribute('externalResourcesRequired', 'true');

        svgElement.appendChild(foreignObjectElement);
        foreignObjectElement.appendChild(element);

        return svgElement;
    }

    private static isDataUrl(url: string): boolean
    {
        return url.search(this.DATA_REGEX) !== -1
    }

    private static async getBlobDataUrl(blob: Blob): Promise<string>
    {
        return new Promise<string>((resolve) =>
        {
            const fileReader: FileReader = new FileReader();
            fileReader.onloadend = () =>
            {
                resolve(`${fileReader.result}`);
            }

            fileReader.readAsDataURL(blob);
        });
    }

    private static async getFontEmbedCSS(): Promise<string>
    {
        const fontsCssRules: CSSRule[] = [];

        for (const styleSheet of Array.from(document.styleSheets))
        {
            try
            {
                for (const cssRule of Array.from(styleSheet.cssRules))
                {
                    if (cssRule.constructor.name === 'CSSFontFaceRule')
                    {
                        fontsCssRules.push(cssRule);
                    }
                }
            }
            catch
            {
            }
        }

        let fontEmbedCSS: string = '';

        for (const fontCssRule of fontsCssRules)
        {
            const fontUrls = fontCssRule.cssText.match(this.FONT_URL_REGEX) || [];
            for (const fontUrl of fontUrls)
            {
                const url: string = fontUrl.replace(this.FONT_URL_REGEX, '$1');
                const dataUrl: string = await this.urlToDataUrl(url);

                fontEmbedCSS += fontCssRule.cssText.replace(fontUrl, `url(${dataUrl})`);
            }
        }

        return fontEmbedCSS;
    }

    private static async urlToDataUrl(url: string): Promise<string>
    {
        let dataUrl: string | undefined = this._urlCacheMap.get(url);
        if (dataUrl !== undefined)
        {
            return dataUrl;
        }

        const response: Response = await fetch(url);
        if (!response.ok)
        {
            return '';
        }

        const blob: Blob = await response.blob();
        dataUrl = await this.getBlobDataUrl(blob);
        this._urlCacheMap.set(url, dataUrl);

        return dataUrl;
    }

    private static async updateEmbedImageElement(imageElement: HTMLImageElement | SVGImageElement): Promise<void>
    {
        const dataUrl: string = await this.urlToDataUrl(imageElement instanceof HTMLImageElement ? imageElement.src : imageElement.href.baseVal);

        return new Promise<void>((resolve, reject) =>
        {
            imageElement.onload = () => resolve();
            imageElement.onerror = () => reject();

            if (imageElement instanceof HTMLImageElement)
            {
                imageElement.decode = async () => resolve();
                imageElement.srcset = '';
                imageElement.src = dataUrl;
                imageElement.crossOrigin = 'anonymous';
                imageElement.decoding = 'async';
            }
            else
            {
                imageElement.href.baseVal = dataUrl;
            }
        });
    }

    private static async updateEmbedBackgroundElement(element: HTMLElement): Promise<void>
    {
        let background: string = element.style.getPropertyValue('background');

        const urls: string[] = [];
        background.replace(this.URL_REGEX, (raw, _quotation, url) =>
        {
            urls.push(url);
            return raw;
        });

        for (const url of urls.filter((url: string) => !this.isDataUrl(url)))
        {
            const dataUrl: string = await this.urlToDataUrl(url);
            background = background.replace(url, dataUrl);
        }

        element.style.setProperty('background', background);
    }

    private static async cloneNode(node: HTMLElement, clonedImageElements: (HTMLImageElement | SVGImageElement)[], clonedBackgroundElements: HTMLElement[]): Promise<HTMLElement>
    {
        if (node instanceof HTMLCanvasElement)
        {
            const dataURL: string = node.toDataURL();
            if (dataURL === 'data:,')
            {
                return node.cloneNode(false) as HTMLCanvasElement;
            }

            return await this.createImage(dataURL);
        }

        const clonedNode: HTMLElement = node.cloneNode() as HTMLElement;
        if (node instanceof Element)
        {
            const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(node);
            clonedNode.style.cssText = cssStyleDeclaration.cssText;
            if (cssStyleDeclaration.cssText.length === 0)
            {
                for (const propertyName of Array.from(cssStyleDeclaration))
                {
                    clonedNode.style.setProperty(propertyName, cssStyleDeclaration.getPropertyValue(propertyName), cssStyleDeclaration.getPropertyPriority(propertyName));
                }
            }

            this.updatePseudoElements(node, clonedNode);

            if ((clonedNode instanceof HTMLImageElement && !this.isDataUrl(clonedNode.src)) ||
                (clonedNode instanceof SVGImageElement && !this.isDataUrl(clonedNode.href.baseVal)))
            {
                clonedImageElements.push(clonedNode);
            }

            if (clonedNode.style.getPropertyValue('background').search(this.URL_REGEX) !== -1)
            {
                clonedBackgroundElements.push(clonedNode);
            }
        }

        for (const childNode of Array.from(node.childNodes))
        {
            const clonedChildNode: HTMLElement = await this.cloneNode(childNode as HTMLElement, clonedImageElements, clonedBackgroundElements);
            clonedNode.appendChild(clonedChildNode);
        }

        return clonedNode;
    }

    private static createImage(url: string): Promise<HTMLImageElement>
    {
        return new Promise<HTMLImageElement>((resolve, reject) =>
        {
            const imageElement: HTMLImageElement = new Image();
            imageElement.decode = async () => resolve(imageElement);
            imageElement.onload = () => resolve(imageElement);
            imageElement.onerror = () => reject();
            imageElement.crossOrigin = 'anonymous';
            imageElement.decoding = 'async';
            imageElement.src = url;
        });
    }

    private static formatCSSProperties(cssStyleDeclaration: CSSStyleDeclaration)
    {
        return Array.from(cssStyleDeclaration).map((propertyName: string) =>
        {
            const value: string = cssStyleDeclaration.getPropertyValue(propertyName);
            const priority: string = cssStyleDeclaration.getPropertyPriority(propertyName);

            return `${propertyName}: ${value}${priority ? ' !important' : ''};`;
        }).join(' ');
    }

    private static updatePseudoElements(element: HTMLElement, clonedElement: HTMLElement): void
    {
        for (const pseudoType of [':before', ':after'])
        {
            const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(element, pseudoType);
            const content: string = cssStyleDeclaration.getPropertyValue('content');
            if (content !== '' && content !== 'none')
            {
                const className: string = `u${Date.now().toString(36)}${Math.random().toString(36).substring(2)}`;
                clonedElement.className = `${clonedElement.className} ${className}`;

                const selector: string = `.${className}:${pseudoType}`;
                const cssText: string = cssStyleDeclaration.cssText ? `${cssStyleDeclaration.cssText} content: '${content.replace(/'|"/g, '')}';` :
                    HtmlToImage.formatCSSProperties(cssStyleDeclaration);

                const styleElement: HTMLStyleElement = document.createElement('style');
                styleElement.appendChild(document.createTextNode(`${selector}{${cssText}}`));
                clonedElement.appendChild(styleElement);
            }
        }
    }

    // #endregion
}