import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { MonitoringBaseComponent } from "./monitoring-base.component";
import { Subscription } from "rxjs";
import { IdleCheck } from "../../utils/idle-check";
import { VirtualListComponent } from "../../controls/virtual-list/virtual-list.component";
import { LoginModel } from "../../user/login/model/login.model";
import { MonitoringItemsBaseModel } from "../models/monitoring-items-base.model";
import { AppSettingsService } from "../../services/app-settings.service";
import { RoutingHistoryService } from "../../services/routing-history.service";
import { ModalMessageService } from "../../controls/modal-message/services/modal-message.service";
import { Router } from "@angular/router";
import { Constants, IApiResponse, IListColumn, RouteType } from "../../utils/globals";
import { CGIMarkerClusterer, onClusterClickHandler } from "../../controls/google-map/utils/cgi-marker-clusterer";
import { ICGIMarker, ICGIMarkerLocation } from "../classes/cgi-marker";
import { ManagerItemStatus, MonitoringPopupType } from "../models/manager-base-model.class";
import { CGICluster } from "../../controls/google-map/utils/cgi-cluster";
import { Utils } from "../../utils/utils";
import { MonitoringUtils, DisplayProperties } from "../../utils/monitoring-utils";

@Component({ template: '' })
export class MonitoringItemsBaseComponent<BaseMonitoringItemType extends ICGIMarkerLocation, BaseFiltersType, BaseDataListsType, BaseMonitoringInfoType>
    extends MonitoringBaseComponent<BaseMonitoringItemType> implements OnInit, OnDestroy
{
    // #region Protected Constants

    protected readonly EXCEL_FILE_PREFIX: string = 'Monitoring_';
    protected override readonly SHARING_INFO_TITLE: string = 'Contguard Insights Monitoring Info:';
    protected override readonly SHARING_LOCATION_TITLE: string = 'Contguard Insights Monitoring Location:';
    protected readonly IDLE_REFRESH_TIMEOUT_DURATION: number = 300000;

    // #endregion

    // #region Protected Members

    protected _getMonitoringItemsSubscription: Subscription | null = null;

    protected _idleCheck: IdleCheck;
    protected _idleCheckSubscription: Subscription | null = null;

    protected _tableMonitoringItemsColumns: IListColumn[] = [];
    protected _tableMonitoringItemsSearchProperties: string[] = [];

    @ViewChild('monitoringShareTemplate', { read: TemplateRef }) protected _monitoringShareModalContentTemplateRef!: TemplateRef<any>;

    @ViewChild('monitoringItemsList', { read: VirtualListComponent, static: false }) protected _monitoringItemsVirtualList: VirtualListComponent | undefined = undefined;

    // #endregion

    // #region Properties

    public get arrivalStatusesStyleProperties(): DisplayProperties[]
    {
        return MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES;
    }

    public get loginModel(): LoginModel
    {
        return this._loginModel;
    }

    public get tableMonitoringItemsColumns(): IListColumn[]
    {
        return this._tableMonitoringItemsColumns;
    }

    public set tableMonitoringItemsColumns(value: IListColumn[])
    {
        this._tableMonitoringItemsColumns = value;

        this._tableMonitoringItemsSearchProperties = [];
        for (const column of this._tableMonitoringItemsColumns)
        {
            if (column.propertyName !== undefined)
            {
                this._tableMonitoringItemsSearchProperties.push(column.propertyName);
            }
        }
    }

    public get tableMonitoringItemsSearchProperties(): string[]
    {
        return this._tableMonitoringItemsSearchProperties;
    }

    public get monitoringItemsModel(): MonitoringItemsBaseModel<BaseMonitoringItemType, BaseFiltersType, BaseDataListsType, BaseMonitoringInfoType>
    {
        return this._monitoringItemsModel;
    }

    public get collapseMonitoringItemsFilters(): boolean
    {
        return false;
    }

    public set collapseMonitoringItemsFilters(_value: boolean)
    {
    }

    // #endregion

    // #region Constructors

    constructor(protected _monitoringItemsModel: MonitoringItemsBaseModel<BaseMonitoringItemType, BaseFiltersType, BaseDataListsType, BaseMonitoringInfoType>,
        protected _renderer: Renderer2, _appSettingsService: AppSettingsService, _changeDetectorRef: ChangeDetectorRef,
        _routingHistoryService: RoutingHistoryService, _ngZone: NgZone, _modalMessageService: ModalMessageService, _viewContainerRef: ViewContainerRef,
        _router: Router, _loginModel: LoginModel)
    {
        super(_appSettingsService, _changeDetectorRef, _routingHistoryService, _ngZone, _modalMessageService, _viewContainerRef, _router, _loginModel);

        this._idleCheck = new IdleCheck(this._ngZone, this._renderer, this.IDLE_REFRESH_TIMEOUT_DURATION);

        this._idleCheckSubscription = this._idleCheck.idleOccured.subscribe(() =>
        {
            this.loadMonitoringItems(false);
            this._changeDetectorRef.detectChanges();
        });
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this._monitoringItemsModel.isInitialized)
        {
            this.initializeMonitoringItemsTableColumns();

            this.updateFilterElementsInfo();

            this._viewIsInitialized = true;
            this._changeDetectorRef.detectChanges();

            this._idleCheck.start();
        }
        else
        {
            this._monitoringItemsModel.clear();
            this.loadMonitoringItems(true);
        }
    }

    public ngOnDestroy(): void
    {
        this.clearGetMonitoringItemsSubscription();

        if (this._idleCheckSubscription !== null)
        {
            this._idleCheckSubscription.unsubscribe();
            this._idleCheckSubscription = null;
        }
    }

    public onShipmentTypeChange(): void
    {
        this.setSelectedMonitoringItem(null);
        this.closeMarkerPopup();

        this.monitoringItemsModel.clearMonitoringItemsFilters();
        this.monitoringItemsModel.applyMonitoringFilters(true);
        this.updateFilterElementsInfo();

        this.updateGoogleMapMarkers(true);
    }

    public onloadLastSeachFiltersClick(): void
    {
        this.monitoringItemsModel.loadLastSeachFilters();
        if (!Constants.IS_MOBILE)
        {
            this.applyMonitoringFilters();
        }
    }

    public onMonitoringItemMapClick(itemKey: number): void
    {
        this.setSelectedMonitoringItem(this.monitoringItemsModel.findMonitoringItem(itemKey));

        if (this._cgiMarkerClusterer === null)
        {
            return;
        }

        const marker: google.maps.marker.AdvancedMarkerElement | null = this._cgiMarkerClusterer.findMarkerByData(this.monitoringItemsModel.selectedMonitoringItem);
        if (marker !== null)
        {
            this._monitoringItemsVirtualList?.scrollIntoView((marker as ICGIMarker<BaseMonitoringItemType>).data, true);
            this.selectAndExtractMonitoringMarkerFromCluster((marker as ICGIMarker<BaseMonitoringItemType>).data, marker);
        }
    }

    public onMonitoringItemsFilterTableItemClick(monitoringItem: BaseMonitoringItemType): void
    {
        this.showSearchShipments = false;
        this.monitoringSearchFilter = null;

        if (this.monitoringItemsModel.filterMonitoringItem(monitoringItem))
        {
            this.initializeMonitoringItemsTableColumns();
        }

        this.applyMonitoringFilters(true);

        this.selectMonitoringItemsTableItem(monitoringItem);
    }

    public onMonitoringItemsTableItemsChange(): void
    {
        if (this.monitoringItemsModel.selectedMonitoringItem !== null)
        {
            this._monitoringItemsVirtualList?.scrollIntoView(this.monitoringItemsModel.selectedMonitoringItem, true);
        }
    }

    public onMonitoringItemsTableItemClick(monitoringItem: BaseMonitoringItemType): void
    {
        if (this.monitoringItemsModel.selectedMonitoringItem !== monitoringItem)
        {
            this.selectMonitoringItemsTableItem(monitoringItem);
        }
        else
        {
            this.closeMarkerPopup();
        }
    }

    public onApplyMonitoringItemsFiltersButtonClick(): void
    {
        this.applyMonitoringFilters();
    }

    public onClearMonitoringItemsFiltersButtonClick(): void
    {
        if (Constants.IS_MOBILE)
        {
            this.collapseMonitoringItemsFilters = true;
        }

        if (this.monitoringItemsModel.clearMonitoringItemsFilters())
        {
            this.updateFilterElementsInfo();

            this.updateGoogleMapMarkers(true);
        }
    }

    public onShowFiltersButtonClick(): void
    {
        if (!this.collapseMonitoringItemsFilters)
        {
            this.collapseMonitoringItemsFilters = true;
        }
        else
        {
            this.collapseMonitoringItemsFilters = false;

            if (Constants.IS_MOBILE)
            {
                this.monitoringItemsModel.initializeMonitoringItemsFilters();
            }
        }
    }

    public onInfoButtonClick(event: MouseEvent): void
    {
        event.stopImmediatePropagation();

        setTimeout(() =>
        {
            this._currentMonitoringPopup = MonitoringPopupType.Info;
            this._showMonitoringPopups[this._currentMonitoringPopup] = true;
            this._changeDetectorRef.detectChanges();
        });
    }

    public onReCenterMapButtonClick(): void
    {
        this.reCenterMapToMarkers();
    }

    public onShipmentMonitoringShareButtonClick(event: MouseEvent | null, monitoringItem: BaseMonitoringItemType): void
    {
        if (event !== null && this.monitoringItemsModel.selectedMonitoringItem === monitoringItem)
        {
            event.stopImmediatePropagation();
        }

        this._modalMessageService.show({ modalContentTemplateRef: this._monitoringShareModalContentTemplateRef });
    }

    public onShareButtonClick(event: MouseEvent): void
    {
        if (this.monitoringItemsModel.selectedMonitoringItem !== null)
        {
            event.stopImmediatePropagation();
        }
    }

    public override async onShareSelectedMonitoringLocation(shareCopyMonitoringInfo: BaseMonitoringItemType, copyOnlyToClipboard: boolean): Promise<void>
    {
        super.onShareSelectedMonitoringLocation(shareCopyMonitoringInfo, copyOnlyToClipboard);

        const monitoringItemLocation: google.maps.LatLngLiteral =
            this.monitoringItemsModel.getMonitoringItemLocation(shareCopyMonitoringInfo);

        const shareUrl: string = `http://maps.google.com/?q=${monitoringItemLocation.lat},${monitoringItemLocation.lng}`;

        await this.shareInfo(this.SHARING_LOCATION_TITLE, shareUrl, copyOnlyToClipboard);
    }

    public onFilterFieldChange(): void
    {
        if (!Constants.IS_MOBILE)
        {
            this.applyMonitoringFilters();
        }

        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
    }

    public onRefreshButtonClick(): void
    {
        this.loadMonitoringItems(false);
    }

    protected override onClusterClick(event: google.maps.MapMouseEvent, cluster: CGICluster): boolean
    {
        if (super.onClusterClick(event, cluster))
        {
            this.setSelectedMonitoringItem(null);
            return true;
        }

        return false;
    }

    public onMarkerShipmentTrackButtonClick(event: MouseEvent | null, monitoringItem: BaseMonitoringItemType): void
    {
        if (event !== null)
        {
            if (this.monitoringItemsModel.selectedMonitoringItem === monitoringItem)
            {
                event.stopImmediatePropagation();
            }
            else
            {
                this.monitoringItemsModel.selectedMonitoringItem = monitoringItem;
            }
        }

        this._router.navigate([`/${RouteType.ShipmentMonitoring}/${(monitoringItem as any).shipmentKey}`]);
    }

    // #endregion

    // #region Callbacks

    protected override updateMapActiveMarkerClusterCallback(): void
    {
        this.setSelectedMonitoringItem(null);
    }

    // #endregion

    // #region Protected Methods

    protected override closeMarkerPopup(isCallingFromOpeneingMarkerPopup: boolean = false): void
    {
        super.closeMarkerPopup(isCallingFromOpeneingMarkerPopup, Utils.isNullOrUndefined(this._cgiMarkerClusterer?.activeMarker) ||
            (this._cgiMarkerClusterer?.activeMarker as ICGIMarker<BaseMonitoringItemType>).data === undefined ? null :
            Utils.getPropertyNameof<ICGIMarker<BaseMonitoringItemType>>(this._cgiMarkerClusterer?.activeMarker as ICGIMarker<BaseMonitoringItemType>,
                activeMarker => activeMarker.data));

        if (!isCallingFromOpeneingMarkerPopup)
        {
            this.setSelectedMonitoringItem(null);
        }
    }

    protected override initializeGoogleMap(): void
    {
        super.initializeGoogleMap();

        const selectedMonitoringItem: BaseMonitoringItemType | null = this.monitoringItemsModel.selectedMonitoringItem;

        this._ngZone.runOutsideAngular(() =>
        {
            this._cgiMarkerClusterer = new CGIMarkerClusterer(
                {
                    map: this._googleMap?.map!,
                    locations: this.ctreateGoogleMapMarkersLocations(),
                    renderer: this.getClusterRenderer(),
                    createMarkerCallback: this.ctreateGoogleMapMarker.bind(this)
                });

            this._cgiMarkerClusterer.waitForMapClusteringEnd().then(() =>
            {
                this._googleMap?.waitForMapTilesLoaded().then(() =>
                {
                    this.setViewState(true);
                    this._cgiMarkerClusterer!.viewIsReady = true;

                    this._ngZone.run(() => this._changeDetectorRef.markForCheck());
                });

                if (selectedMonitoringItem !== null)
                {
                    this.monitoringItemsModel.selectedMonitoringItem = null;
                    this.selectMonitoringItemsTableItem(selectedMonitoringItem);
                }
                else
                {
                    requestAnimationFrame(() => this.reCenterMapToMarkers());
                }
            });

            const onClusterClick: onClusterClickHandler = this._cgiMarkerClusterer.onClusterClick;

            this.handleMarkerClustererClickEvent();
            this.handleMarkerClustererDblClickEvent(onClusterClick);
        });
    }

    protected applyMonitoringFilters(forceUpdate: boolean = false): void
    {
        if (Constants.IS_MOBILE)
        {
            this.collapseMonitoringItemsFilters = true;
        }

        if (this.monitoringItemsModel.applyMonitoringFilters(forceUpdate))
        {
            this.updateFilterElementsInfo();

            const selectedShipmentMonitoringKey: number | null = this.monitoringItemsModel.selectedMonitoringItem !== null ?
                this.monitoringItemsModel.getMonitoringItemKey(this.monitoringItemsModel.selectedMonitoringItem) : null;

            this.updateGoogleMapMarkers(true, selectedShipmentMonitoringKey);
        }
    }

    protected selectShipmentMonitoringMarker(marker: ICGIMarker<BaseMonitoringItemType>): void
    {
        if (this._cgiMarkerClusterer === null)
        {
            return;
        }

        if (this.monitoringItemsModel.selectedMonitoringItem === marker.data)
        {
            this.closeMarkerPopup();
            return;
        }

        this.setSelectedMonitoringItem(marker.data);
        this.selectAndExtractMonitoringMarkerFromCluster(marker.data, marker);
    }

    protected updateFilterElementsInfo(): void
    {
    }

    protected clearGetMonitoringItemsSubscription(): void
    {
        if (this._getMonitoringItemsSubscription !== null)
        {
            this._getMonitoringItemsSubscription.unsubscribe();
            this._getMonitoringItemsSubscription = null;
        }
    }

    protected loadMonitoringItems(recenterMap: boolean, loadMonitoringItemsCallback: (() => void) | null = null): void
    {
        const selectedMonitoringItemKey: number | null = this.monitoringItemsModel.selectedMonitoringItem !== null ?
            this.monitoringItemsModel.getMonitoringItemKey(this.monitoringItemsModel.selectedMonitoringItem) : null;

        this._idleCheck.stop();

        this.clearGetMonitoringItemsSubscription();

        this._modalMessageService.instance?.closeModal();

        let iterationsCounter: number = 0;

        this._getMonitoringItemsSubscription = this.monitoringItemsModel.getMonitoringItems().subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                this.updateFilterElementsInfo();

                if (iterationsCounter++ === 0)
                {
                    if (this._viewIsInitialized)
                    {
                        this.updateGoogleMapMarkers(recenterMap, selectedMonitoringItemKey);
                    }
                    else
                    {
                        if (loadMonitoringItemsCallback !== null)
                        {
                            loadMonitoringItemsCallback();
                        }

                        this._viewIsInitialized = true;
                        this._changeDetectorRef.detectChanges();

                        if (this._googleMap !== undefined && this._googleMap.map !== null)
                        {
                            this.initializeGoogleMap();
                        }
                    }
                }

                if (response.isComplete)
                {
                    this._idleCheck.start();
                    this.clearGetMonitoringItemsSubscription();
                }
            }
            else if (!this._viewIsReady)
            {
                this.setViewState(response.isSuccess, response.message);
            }

            this._changeDetectorRef.detectChanges();
        });
    }

    protected setSelectedMonitoringItem(monitoringItem: BaseMonitoringItemType | null): void
    {
        this.monitoringItemsModel.selectedMonitoringItem = monitoringItem;
        this._changeDetectorRef.detectChanges();
    }

    protected ctreateGoogleMapMarkersLocations(): ICGIMarkerLocation[]
    {
        const activeMarkerData: BaseMonitoringItemType | undefined = this._cgiMarkerClusterer !== null && this._cgiMarkerClusterer.activeMarker !== null ?
            (this._cgiMarkerClusterer.activeMarker as ICGIMarker<BaseMonitoringItemType>).data : undefined;

        const locations: ICGIMarkerLocation[] = this.monitoringItemsModel.filteredMonitoringItems.filter((monitoringItem: BaseMonitoringItemType) =>
        {
            if (activeMarkerData !== undefined && this.monitoringItemsModel.getMonitoringItemKey(activeMarkerData as BaseMonitoringItemType) ===
                this.monitoringItemsModel.getMonitoringItemKey(monitoringItem))
            {
                (this._cgiMarkerClusterer!.activeMarker as ICGIMarker<BaseMonitoringItemType>).data = monitoringItem;
                return false;
            }

            const monitoringItemLocation: google.maps.LatLngLiteral = this.monitoringItemsModel.getMonitoringItemLocation(monitoringItem);
            return monitoringItemLocation.lat !== null && monitoringItemLocation.lng !== null;
        }).map((monitoringItem: BaseMonitoringItemType) => monitoringItem as ICGIMarkerLocation);

        return locations;
    }

    protected override updateMapFromActiveMarker(): boolean
    {
        if (super.updateMapFromActiveMarker())
        {
            return true;
        }

        if (this._cgiMarkerClusterer !== null && this._cgiMarkerClusterer.activeMarker !== null)
        {
            const activeMarkerData: BaseMonitoringItemType | undefined = (this._cgiMarkerClusterer.activeMarker as ICGIMarker<BaseMonitoringItemType>).data;
            const selectedMonitoringItemKey: number | null = activeMarkerData !== undefined ? this.monitoringItemsModel.getMonitoringItemKey(activeMarkerData) : null;
            if (selectedMonitoringItemKey !== null)
            {
                const selectedMonitoringItem: BaseMonitoringItemType | null = this.monitoringItemsModel.findMonitoringItem(selectedMonitoringItemKey);
                if (selectedMonitoringItem !== null)
                {
                    const updatedActiveMarker: google.maps.marker.AdvancedMarkerElement | null = this._cgiMarkerClusterer.findMarkerByData(selectedMonitoringItem);
                    if (updatedActiveMarker !== null)
                    {
                        this._cgiMarkerClusterer?.removeMarker(updatedActiveMarker);
                    }

                    this.setSelectedMonitoringItem(selectedMonitoringItem);
                    this._monitoringItemsVirtualList?.scrollIntoView(selectedMonitoringItem, true);

                    return true;
                }
                else
                {
                    this._cgiMarkerClusterer.activeMarker.map = null;
                    this._cgiMarkerClusterer.activeMarker = null;
                }
            }
        }

        this.closeMarkerPopup();

        return false;
    }

    protected addMarkerClickEvent(marker: ICGIMarker<BaseMonitoringItemType>): void
    {
        this._googleMap?.addMarkerClickEvent(marker, () =>
        {
            if (this.isMarkerPopupOpen(marker))
            {
                this.closeMarkerPopup();
            }
            else
            {
                this.setSelectedMonitoringItem(marker.data);

                this._monitoringItemsVirtualList?.scrollIntoView(marker.data, true);

                this.selectAndExtractMonitoringMarkerFromCluster(marker.data, marker);
            }
        });
    }

    protected updateGoogleMapMarkers(recenter: boolean, selectedShipmentMonitoringKey: number | null = null): void
    {
        if (this._cgiMarkerClusterer === null)
        {
            return;
        }

        this._cgiMarkerClusterer.waitForMapClusteringEnd().then(() =>
        {
            if (selectedShipmentMonitoringKey !== null)
            {
                this.setSelectedMonitoringItem(this.monitoringItemsModel.findMonitoringItem(selectedShipmentMonitoringKey));
            }

            if (this._cgiMarkerClusterer?.activeMarker !== null && this._cgiMarkerClusterer?.activeMarker.map === null)
            {
                this.updateActiveMarker(null);
            }

            if (recenter && this._cgiMarkerClusterer?.activeMarker === null)
            {
                this._googleMap?.waitForMapIdle().then(() => this._changeDetectorRef.markForCheck());

                this.reCenterMapToMarkers();
            }
            else
            {
                this._changeDetectorRef.markForCheck();
            }
        });

        this._ngZone.runOutsideAngular(() =>
        {
            this._cgiMarkerClusterer?.replaceMarkers(this.ctreateGoogleMapMarkersLocations());
        });

        this.updateMapFromActiveMarker();
    }

    protected initializeMonitoringItemsTableColumns(): void
    {
    }

    // #endregion

    // #region Private Methods

    private reCenterMapToMarkers(): void
    {
        if (this._googleMap === undefined)
        {
            return;
        }

        let position: google.maps.LatLngLiteral;
        if (this._cgiMarkerPopup !== null && this._cgiMarkerPopup.marker !== null)
        {
            position = this._cgiMarkerPopup.marker.position as google.maps.LatLngLiteral;
        }
        else
        {
            const coordinates: google.maps.LatLngLiteral[] = this._cgiMarkerClusterer!.clusters.map((cluster: CGICluster) =>
            {
                return { lat: cluster.position.lat(), lng: cluster.position.lng() };
            });

            if (coordinates.length === 0)
            {
                this._googleMap.mapPanToPosition(this.getMapStartingCenterPosition());
                return;
            }

            position = this._googleMap.updatePositionWithMapRestrictions(this._googleMap.getAverageGeolocation(coordinates));
            if (!this._googleMap.isCoordinatesInPoisitionBounds(position, coordinates))
            {
                position = this._googleMap.getClosestCoordinateToPosition(position, coordinates);
                position = this._googleMap.updatePositionWithMapRestrictions(position);
            }
        }

        this._googleMap.setMapZoom(this._googleMap.mapStartingZoom);
        this._googleMap.mapPanToPosition(position);
    }

    private getMapStartingCenterPosition(): google.maps.LatLngLiteral
    {
        return { lat: 45, lng: 90 };
    }

    private async selectMonitoringItemsTableItem(monitoringItem: BaseMonitoringItemType): Promise<void>
    {
        if (this._googleMap === undefined || this._cgiMarkerClusterer === null)
        {
            return;
        }

        const marker: google.maps.marker.AdvancedMarkerElement | null = this._cgiMarkerClusterer.findMarkerByData(monitoringItem);
        if (marker !== null)
        {
            const cgiMarker: ICGIMarker<BaseMonitoringItemType> = marker as ICGIMarker<BaseMonitoringItemType>;

            const position: google.maps.LatLngLiteral = marker.position as google.maps.LatLngLiteral;
            const maxZoom: number | null = await this._googleMap.getMaxZoomAtLatLng(position);
            if (maxZoom === null)
            {
                return;
            }

            if (maxZoom !== this._googleMap.map?.getZoom())
            {
                this._googleMap.map!.setCenter(position);
                this._googleMap.setMapZoom(maxZoom);

                await this._cgiMarkerClusterer.waitForMapClusteringEnd();
            }
            else
            {
                this._googleMap.mapPanToPosition(position);
            }

            this.selectShipmentMonitoringMarker(cgiMarker);
            return;
        }

        this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: this.monitoringItemsModel.getMonitoringItemStatusId(monitoringItem) === ManagerItemStatus.Live ?
                    'Shipment\'s data will soon be avilable' : 'This shipment does not contain data'
            });
    }

    // #endregion
}