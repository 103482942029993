<!DOCTYPE html>

<div class="page-container page-image">
    <div class="loader-content">
        @if (!isBusyLoader && showLoadingIcon) {
        <div class="loader-icon" [class.active]="loadingIcon !== null">
            <i [class]="loadingIcon ?? 'icon-cancel'"></i>
        </div>
        }
        @if (!isBusyLoader) {
        <div class="loader-text" [ngClass]="{'animated': showSpinner && loadingProgress === null, 'progress': loadingProgress !== null}"
             [ngStyle]="{'background-position': loadingProgress !== null ? (100 - loadingProgress) + '% 100%' : '' }" [innerHtml]="loadingMessage">
        </div>
        }
        @if (loadingProgress !== null) {
        <div class="loader-progress">{{ loadingProgress }}%</div>
        }
        @if (showSpinner) {
        <div class="loader-spinner">
            <ng-container [ngTemplateOutlet]="spinDotTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="spinDotTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="spinDotTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="spinDotTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="spinDotTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="spinDotTemplate"></ng-container>
        </div>
        }
        <ng-content></ng-content>
    </div>
</div>

<ng-template #spinDotTemplate>
    <div class="spin">
        <div class="dot"></div>
    </div>
</ng-template>