import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHelper } from '../../../utils/http-helper';
import { Constants, IApiResponse } from '../../../utils/globals';
import { ShipmentLists, ShipmentColumnType } from './shipment-manager-model.class';
import { Observable, Observer, forkJoin } from 'rxjs';
import { CustomAttributeType, Shipment, DisplayShipment } from '../../shipment/model/shipment-model.class';
import { DateTimeFormatType, Utils } from '../../../utils/utils';
import { LoginModel } from '../../../user/login/model/login.model';
import { ManagerBaseModel } from '../../../base/models/manager-base.model';
import { ManagerItemStatus } from '../../../base/models/manager-base-model.class';
import { ManagerConstants } from '../../../utils/monitoring-utils';
import { SmartLockManagerModel } from '../../../smart-lock-manager/smart-lock-manager/model/smart-lock-manager.model';
import { DisplaySmartLock } from '../../../smart-lock-manager/smart-lock-manager/model/smart-lock-manager-model.class';

@Injectable({ providedIn: 'root' })
export class ShipmentManagerModel extends ManagerBaseModel<DisplayShipment, ShipmentColumnType>
{
    // #region Constants

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'shipment-manager';

    // #endregion

    // #region Private Members

    private _shipmentsStatus: ManagerItemStatus = ManagerItemStatus.Live;
    private _shipmentLists: ShipmentLists = new ShipmentLists();
    private _isFilterAnomalies: boolean = false;
    private _isFilterArrived: boolean = false;
    private _isFilterAutoClosed: boolean = false;
    private _isFilterNoDevice: boolean | null = null;

    // #endregion

    // #region Properties

    public get shipmentLists(): ShipmentLists
    {
        return this._shipmentLists;
    }

    public get isFilterAnomalies(): boolean
    {
        return this._isFilterAnomalies;
    }

    public set isFilterAnomalies(value: boolean)
    {
        this._isFilterAnomalies = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    public get isFilterAutoClosed(): boolean
    {
        return this._isFilterAutoClosed;
    }

    public set isFilterAutoClosed(value: boolean)
    {
        this._isFilterAutoClosed = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    public get isFilterArrived(): boolean
    {
        return this._isFilterArrived;
    }

    public set isFilterArrived(value: boolean)
    {
        this._isFilterArrived = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    public get isFilterNoDevice(): boolean | null
    {
        return this._isFilterNoDevice;
    }

    public set isFilterNoDevice(value: boolean | null)
    {
        if (this._isFilterNoDevice)
        {
            this._isFilterNoDevice = null;
        }
        else if (this._isFilterNoDevice === null)
        {
            this._isFilterNoDevice = false;
        }
        else
        {
            this._isFilterNoDevice = value;
        }

        this.applyFilters();
        this.sortManagerItems();
    }

    public get shipmentsStatus(): ManagerItemStatus
    {
        return this._shipmentsStatus;
    }

    public set shipmentsStatus(value: ManagerItemStatus)
    {
        this._shipmentsStatus = value;

        if (this._shipmentsStatus === ManagerItemStatus.Live)
        {
            this._isFilterAnomalies = false;
            this._isFilterAutoClosed = false;
        }
        else if (this._shipmentsStatus === ManagerItemStatus.Completed)
        {
            this._isFilterArrived = false;
        }

        this.applyFilters();
        this.sortManagerItems();
    }

    public get ManagerItemStatus()
    {
        return ManagerItemStatus;
    }

    // #endregion

    // #region Constructors

    constructor(_httpClient: HttpClient, private _loginModel: LoginModel, private _smartLockManagerModel: SmartLockManagerModel)
    {
        super(_httpClient);

        this._sortColumn = ShipmentColumnType.InstallDate;

        const displayShipment: DisplayShipment = new DisplayShipment();

        this._managerItemKeyPropertyName = Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.shipmentKey);
        this._managerItemDatePropertyName = Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.installDate);
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._shipmentsStatus = ManagerItemStatus.Live;
        this._shipmentLists = new ShipmentLists();
        this._isFilterAnomalies = false;
        this._isFilterArrived = false;
        this._isFilterAutoClosed = false;
        this._isFilterNoDevice = null;
    }

    public getSmartLockItems(): Observable<IApiResponse>
    {
        return new Observable((observer: Observer<IApiResponse>) =>
        {
            this._smartLockManagerModel.getManagerItems().subscribe((response: IApiResponse) =>
            {
                if (response.isSuccess)
                {
                    const smartLocksUnitNumbers: number[] = this._smartLockManagerModel.managerItems.map((item: DisplaySmartLock) => item.unitNumber!);
                    for (const item of this.managerItems)
                    {
                        if (item.deviceId !== null && /^\+?(0|[1-9]\d*)$/.test(item.deviceId))
                        {
                            const unitNumber: number = parseInt(item.deviceId);
                            if (smartLocksUnitNumbers.includes(unitNumber))
                            {
                                item.unitNumber = unitNumber;
                            }
                        }
                    }

                    observer.next({ isSuccess: true, isComplete: true });
                }

                observer.complete();
            });
        });
    }

    public override getManagerItems(): Observable<IApiResponse>
    {
        this._isBusy = true;

        if (this.selectedManagerItem !== null && this.selectedManagerItem.statusId !== null && this.selectedManagerItem.statusId !== ManagerItemStatus.Canceled &&
            this.selectedManagerItem.statusId !== this._shipmentsStatus)
        {
            this.shipmentsStatus = this.selectedManagerItem.statusId;
        }

        this._isBackgroundFetchBusy = this._shipmentsStatus === ManagerItemStatus.Live;

        const routeIdToRouteDescriptionMap: Map<string | number | null, string> = new Map();
        routeIdToRouteDescriptionMap.set(null, ManagerConstants.NO_ROUTE_DESCRIPTION);

        const deviceIdToDeviceDescriptionMap: Map<string | number | null, string> = new Map();

        const completedShipments: Shipment[] = [];

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const joinObservables =
            {
                shipments: this._httpClient.get<Shipment[]>(
                    `${this.ENTITY_ITEM_CONTROLLER_NAME}/${this._isBackgroundFetchBusy ? 'live-shipments' : 'shipments'}?isMobile=${Constants.IS_MOBILE}`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                shipmentList: this._httpClient.get<ShipmentLists>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/shipment/lists?isMobile=${Constants.IS_MOBILE}`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
            };

            forkJoin(joinObservables).subscribe(
                {
                    next: (join) =>
                    {
                        this._shipmentLists = join.shipmentList;

                        if (this._shipmentLists.inactiveRoutes !== undefined)
                        {
                            for (const route of this._shipmentLists.inactiveRoutes)
                            {
                                routeIdToRouteDescriptionMap.set(route.id,
                                    this._loginModel.userInfo.isDemo ? ManagerConstants.DEMO_ROUTE_DESCRIPTION : route.value as string);
                            }
                        }

                        for (const route of this._shipmentLists.routes)
                        {
                            routeIdToRouteDescriptionMap.set(route.id,
                                this._loginModel.userInfo.isDemo ? ManagerConstants.DEMO_ROUTE_DESCRIPTION : route.value as string);
                        }

                        for (const device of this._shipmentLists.devices)
                        {
                            deviceIdToDeviceDescriptionMap.set(device.id, device.value as string);
                        }

                        this._managerItems = [];
                        const shipments: Shipment[] = [...join.shipments, ...completedShipments];

                        this._isBusy = false;
                        this.updateShipmentsResponse(observer, shipments, routeIdToRouteDescriptionMap, deviceIdToDeviceDescriptionMap);
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, isComplete: true, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });

            if (this._isBackgroundFetchBusy)
            {
                this._httpClient.get<Shipment[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/completed-shipments?isMobile=${Constants.IS_MOBILE}`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (shipments: Shipment[]) =>
                        {
                            this._isBackgroundFetchBusy = false;
                            if (this._isBusy)
                            {
                                completedShipments.push(...shipments);
                            }
                            else
                            {
                                this.updateShipmentsResponse(observer, shipments, routeIdToRouteDescriptionMap, deviceIdToDeviceDescriptionMap);
                            }
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBackgroundFetchBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
            }
        });
    }

    // #endregion

    // #region Protected Methods

    protected override getSortColumnProperty(managerItem: DisplayShipment): any
    {
        switch (this._sortColumn)
        {
            case ShipmentColumnType.ShipmentKey:
                {
                    return managerItem.shipmentKey;
                }

            case ShipmentColumnType.ContainerId:
                {
                    return managerItem.containerId;
                }

            case ShipmentColumnType.DeviceId:
                {
                    return managerItem.deviceId;
                }

            case ShipmentColumnType.InstallDate:
                {
                    return managerItem.installDate;
                }

            case ShipmentColumnType.RouteDescription:
                {
                    return managerItem.routeDescription;
                }

            case ShipmentColumnType.IsArrived:
                {
                    return managerItem.isArrived;
                }

            case ShipmentColumnType.IsAutoClose:
                {
                    return managerItem.autoClose;
                }

            case ShipmentColumnType.IsReportAnomaly:
                {
                    return managerItem.isReportAnomaly;
                }

            default:
                {
                    return managerItem.installDate;
                }
        }
    }

    protected override applyFilters(): void
    {
        super.applyFilters();

        this._filteredManagerItems = this._filteredManagerItems.filter((displayShipment: DisplayShipment) =>
        {
            if (displayShipment.statusId !== this._shipmentsStatus)
            {
                return false;
            }

            if (!this.isDateRangeFiltered(displayShipment))
            {
                return false;
            }

            if (this._isFilterAnomalies)
            {
                if (Utils.isNullOrUndefined(displayShipment.customAttributes))
                {
                    return false;
                }

                let foundReportAnomaliesAttribute: boolean = false;

                for (const customAttribute of displayShipment.customAttributes)
                {
                    if (customAttribute.attributeId === CustomAttributeType.report_anomalies)
                    {
                        foundReportAnomaliesAttribute = true;

                        if (customAttribute.value !== null && customAttribute.value !== 'true')
                        {
                            return false;
                        }

                        break;
                    }
                }

                if (!foundReportAnomaliesAttribute)
                {
                    return false;
                }
            }

            if (this._isFilterAutoClosed && !displayShipment.isReportAutoClose)
            {
                return false;
            }

            if (this._isFilterArrived && !displayShipment.isReportArrived)
            {
                return false;
            }

            if (this._isFilterNoDevice && displayShipment.deviceId !== null)
            {
                return false;
            }
            else if (this._isFilterNoDevice === false && displayShipment.deviceId === null)
            {
                return false;
            }

            return true;
        });

        this.updateSelectedManagerItem();
    }

    // #endregion

    // #region Private Methods

    private updateShipmentsResponse(observer: Observer<IApiResponse>, shipments: Shipment[], routeIdToRouteDescriptionMap: Map<string | number | null, string>,
        deviceIdToDeviceDescriptionMap: Map<string | number | null, string>): void
    {
        for (const shipment of shipments)
        {
            this._managerItems.push(this.convertShipmentToDisplayShipment(shipment, routeIdToRouteDescriptionMap,
                deviceIdToDeviceDescriptionMap));
        }

        this.applyFilters();
        this.sortManagerItems();

        observer.next({ isSuccess: true, isComplete: true });

        if (!this._isBackgroundFetchBusy && !this._isBusy)
        {
            observer.complete();
        }
    }

    private convertShipmentToDisplayShipment(shipment: Shipment, routeIdToRouteDescriptionMap: Map<string | number | null, string>,
        deviceIdToDeviceDescriptionMap: Map<string | number | null, string>): DisplayShipment
    {
        const displayShipment: DisplayShipment = new DisplayShipment();
        Object.assign(displayShipment, shipment);

        displayShipment.isReportArrived = displayShipment.isArrived === true;
        displayShipment.isReportAutoClose = displayShipment.autoClose === 1;

        if (displayShipment.created !== null)
        {
            displayShipment.createdDateFormatted = Utils.getFormattedDateTime(new Date(displayShipment.created), DateTimeFormatType.DateTime);
        }

        if (displayShipment.installDate !== null)
        {
            displayShipment.installDateFormatted = Utils.getFormattedDateTime(new Date(displayShipment.installDate), DateTimeFormatType.DateTime);
        }

        if (displayShipment.customAttributes !== null)
        {
            for (const customAttribute of displayShipment.customAttributes)
            {
                if (customAttribute.attributeId === CustomAttributeType.report_anomalies)
                {
                    displayShipment.isReportAnomaly = customAttribute.value === 'true';
                }
            }
        }

        displayShipment.routeDescription = routeIdToRouteDescriptionMap.get(shipment.routeId) ?? ManagerConstants.NO_ROUTE_DESCRIPTION;
        displayShipment.deviceDescription = deviceIdToDeviceDescriptionMap.get(displayShipment.deviceId) ?? displayShipment.deviceId;

        return displayShipment;
    }

    // #endregion
}
