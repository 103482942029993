import { Injectable } from "@angular/core";
import { DisplayInventory, Inventory } from "../../inventory/model/inventory-model.class";
import { InventoryColumnType, InventoryConstants, InventoryLists } from "./inventory-manager-model.class";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoginModel } from "../../../user/login/model/login.model";
import { DateTimeFormatType, Utils } from "../../../utils/utils";
import { Observable, Observer, forkJoin } from "rxjs";
import { Constants, IApiResponse } from "../../../utils/globals";
import { HttpHelper } from "../../../utils/http-helper";
import { ManagerBaseModel } from "../../../base/models/manager-base.model";
import { ManagerItemStatus } from "../../../base/models/manager-base-model.class";

@Injectable({ providedIn: 'root' })
export class InventoryManagerModel extends ManagerBaseModel<DisplayInventory, InventoryColumnType>
{
    // #region Constants

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'inventory-manager';

    // #endregion

   // #region Private Members

    private _inventoryLists: InventoryLists = new InventoryLists();
    private _isFilterLastInventory: boolean = false;

    // #endregion

    // #region Properties

    public get isAdministrator(): boolean
    {
        return this._loginModel.userInfo.isAdministrator;
    }

    public get inventoryLists(): InventoryLists
    {
        return this._inventoryLists;
    }

    public get isFilterLastInventory(): boolean
    {
        return this._isFilterLastInventory;
    }

    public set isFilterLastInventory(value: boolean)
    {
        this._isFilterLastInventory = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    // #endregion

    // #region Constructors

    constructor(_httpClient: HttpClient, private _loginModel: LoginModel)
    {
        super(_httpClient);

        this._sortColumn = InventoryColumnType.Date;

        const displayInventory: DisplayInventory = new DisplayInventory();

        this._managerItemKeyPropertyName = Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.inventoryId);
        this._managerItemDatePropertyName = Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.date);
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._inventoryLists = new InventoryLists();
        this._isFilterLastInventory = false;
    }

    public override deleteManagerItem(managerItem: DisplayInventory): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const inventory: Inventory = Utils.clearObjectEmptyStrings(Utils.copyObjectByTargetProperties(managerItem, new Inventory()));
            inventory.statusId = ManagerItemStatus.Canceled;

            this._httpClient.put<Inventory>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/inventory`, JSON.stringify(inventory),
                { headers: HttpHelper.GetHttpJsonHeaders() }).subscribe(
                {
                    next: () =>
                    {
                        this._isBusy = false;

                        observer.next({ isSuccess: true, isComplete: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, isComplete: true, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });
        });
    }

    public override getManagerItems(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const joinObservables =
            {
                inventories: this._httpClient.get<Inventory[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/inventories`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                inventoryLists: this._httpClient.get<InventoryLists>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/inventory/lists`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
            };

            forkJoin(joinObservables).subscribe(
                {
                    next: (join) =>
                    {
                        this._inventoryLists = join.inventoryLists;

                        const warehouseIdToWarehouseNameMap: Map<number | null, string> = new Map();
                        warehouseIdToWarehouseNameMap.set(null, InventoryConstants.NO_VALUE);

                        for (const warehouse of this._inventoryLists.warehouses)
                        {
                            warehouseIdToWarehouseNameMap.set(warehouse.warehouseId!, warehouse.warehouseName!);
                        }

                        this._managerItems = [];
                        for (const inventory of join.inventories)
                        {
                            this._managerItems.push(this.convertInventoryToDisplayInventory(inventory, warehouseIdToWarehouseNameMap));
                        }

                        this.applyFilters();
                        this.sortManagerItems();

                        this._isBusy = false;
                        observer.next({ isSuccess: true, isComplete: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, isComplete: true, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });
        });
    }

    // #endregion

    // #region Protected Methods

    protected override getSortColumnProperty(managerItem: DisplayInventory): any
    {
        switch (this._sortColumn)
        {
            case InventoryColumnType.Date:
                {
                    return managerItem.date;
                }

            case InventoryColumnType.Warehouse:
                {
                    return managerItem.warehouseId;
                }

            case InventoryColumnType.Cables:
                {
                    return managerItem.cables;
                }

            case InventoryColumnType.Chargers:
                {
                    return managerItem.chargers;
                }

            case InventoryColumnType.Locks:
                {
                    return managerItem.locks;
                }

            case InventoryColumnType.Shackles:
                {
                    return managerItem.shackles;
                }

            case InventoryColumnType.Notes:
                {
                    return managerItem.notes;
                }
        }
    }

    protected override applyFilters(): void
    {
        super.applyFilters();

        if (this._isFilterLastInventory)
        {
            const lastInventoriesMap: Map<number, DisplayInventory> = new Map();

            for (const displayInventory of this._filteredManagerItems)
            {
                if (displayInventory.warehouseId === null)
                {
                    continue;
                }

                const foundInventory: DisplayInventory | undefined = lastInventoriesMap.get(displayInventory.warehouseId);
                if (foundInventory !== undefined && (displayInventory.date === null || displayInventory.date?.getTime() <= foundInventory.date!.getTime()))
                {
                    continue;
                }

                lastInventoriesMap.set(displayInventory.warehouseId, displayInventory);
            }

            this._filteredManagerItems = [...lastInventoriesMap.values()];
        }

        this.updateSelectedManagerItem();
    }

    // #endregion

    // #region Private Methods

    private convertInventoryToDisplayInventory(inventory: Inventory, warehouseIdToWarehouseNameMap: Map<number | null, string>): DisplayInventory
    {
        const displayInventory: DisplayInventory = new DisplayInventory();
        Object.assign(displayInventory, inventory);

        if (displayInventory.date !== null)
        {
            displayInventory.dateFormatted = Utils.getFormattedDateTime(new Date(displayInventory.date), DateTimeFormatType.DateTime);
        }

        displayInventory.warehouseName = warehouseIdToWarehouseNameMap.get(displayInventory.warehouseId) ?? InventoryConstants.NO_VALUE;

        return displayInventory;
    }

    // #endregion
}