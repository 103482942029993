import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Constants, IApiResponse } from '../../utils/globals';
import { AppSettingsService } from '../../services/app-settings.service';
import { HtmlToImage } from '../../utils/html-to-image';
import { Utils } from '../../utils/utils';
import ContguardPlugin from '../../../plugins/contguard.plugin';
import { GoogleMapUtils } from './utils/google-map-utils';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { Loader } from "@googlemaps/js-api-loader"

@Component({
    selector: 'google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.css'],
    animations: [fadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class GoogleMapComponent implements OnDestroy
{
    // #region Private Constants

    private readonly GOOGLE_MAP_API_KEY: string = 'AIzaSyAKey0Hwny7rKShX1sEsqdNF8sJSXcsz74';

    private readonly MAP_CONTAINER_SELECTOR: string = '.map-container';
    private readonly STREET_VIEW_CONTAINER_SELECTOR: string = '.street-view-container';
    private readonly STREET_VIEW_CLOSE_BUTTON_SELECTOR: string = '.street-view-close-button';
    private readonly MAP_STARTING_ZOOM: number = 2;
    private readonly MAP_MIN_ZOOM: number = 2;
    private readonly MAP_FIT_BOUNDS_PADDING: number = 70;
    private readonly GOOGLE_MAPS_INIT_ERROR_MESSAGE: string = 'Google Map service could not be loaded.';
    private readonly SNAPSHOT_PREFIX: string = 'snapshot_';

    // #endregion

    // #region Private Members

    private _isMapIdle: boolean = false;

    private _googleMap: google.maps.Map | null = null;
    private _mapRestrictions: google.maps.MapRestriction | null = null;

    private _streetViewPanorama: google.maps.StreetViewPanorama | null = null;

    private _maxZoomService: google.maps.MaxZoomService | null = null;

    private _imageOpenRailwayMapType: google.maps.ImageMapType | null = null;
    private _showMapRailways: boolean = false;

    private _isDuringSetMapZoom: boolean = false;

    // #region Properties

    public get map(): google.maps.Map | null
    {
        return this._googleMap;
    }

    public get mapRestrictions(): google.maps.MapRestriction | null
    {
        return this._mapRestrictions;
    }

    public get mapStartingCenterPosition(): google.maps.LatLngLiteral
    {
        return { lat: 45, lng: 90 };
    }

    public get isMapIdle(): boolean
    {
        return this._isMapIdle;
    }

    public get mapStartingZoom(): number
    {
        return this.MAP_STARTING_ZOOM;
    }

    public get isSatelliteView(): boolean
    {
        return this._appSettingsService.appSettings.isSatelliteView;
    }

    public get showMapRailways(): boolean
    {
        return this._showMapRailways;
    }

    public set showMapRailways(value: boolean)
    {
        if (this._showMapRailways === value)
        {
            return;
        }

        this._showMapRailways = value;

        if (this._showMapRailways)
        {
            this._googleMap?.overlayMapTypes.push(this._imageOpenRailwayMapType);
        }
        else
        {
            this._googleMap?.overlayMapTypes.pop();
        }
    }

    // #endregion

    // #region Inputs

    @Input() public showTreetView: boolean = false;

    // #endregion

    // #region Events

    @Output() public initialize: EventEmitter<IApiResponse> = new EventEmitter();

    // #endregion

    // #region Constructors

    constructor(private _elementRef: ElementRef<HTMLElement>, private _appSettingsService: AppSettingsService, private _changeDetectorRef: ChangeDetectorRef)
    {
        const loader: Loader = new Loader({
            apiKey: this.GOOGLE_MAP_API_KEY,
            version: 'weekly'
        });

        loader.importLibrary('maps').then(() => this.initializeGoogleMap());

        this._appSettingsService.satelliteViewUpdatedObservable.subscribe((isSatelliteView: boolean) =>
        {
            this.updateMapType(isSatelliteView);
            this._changeDetectorRef.detectChanges();
        });
    }

    // #endregion

    // #region Events Hanlders

    public ngOnDestroy(): void
    {
        if (this._googleMap !== null)
        {
            google.maps.event.clearInstanceListeners(this._googleMap);
        }
    }

    public onStreetViewCloseButtonClick(): void
    {
        this._streetViewPanorama?.setVisible(false);
    }

    // #endregion

    // #region Public Methods

    public waitForMapIdle(): Promise<void>
    {
        return new Promise<void>((resolve) =>
        {
            if (this._isMapIdle)
            {
                resolve();
                return;
            }

            google.maps.event.addListenerOnce(this._googleMap!, 'idle', () => resolve());
        });
    }

    public waitForMapTilesLoaded(): Promise<void>
    {
        return new Promise<void>((resolve) =>
        {
            google.maps.event.addListenerOnce(this._googleMap!, 'tilesloaded', () => resolve());
        });
    }

    public updatePositionWithMapRestrictions(position: google.maps.LatLngLiteral): google.maps.LatLngLiteral
    {
        if (this._googleMap === null || this._mapRestrictions === null)
        {
            return position;
        }

        const projection: google.maps.Projection | undefined = this._googleMap.getProjection();
        if (projection === undefined)
        {
            return position;
        }

        const mapRestrictionsBounds: google.maps.LatLngBoundsLiteral = this._mapRestrictions.latLngBounds as google.maps.LatLngBoundsLiteral;

        const worldCoordinateMapRestrictionNW: google.maps.Point | null =
            projection.fromLatLngToPoint({ lat: mapRestrictionsBounds.north, lng: mapRestrictionsBounds.west });

        const worldCoordinateMapRestrictionSE: google.maps.Point | null =
            projection.fromLatLngToPoint({ lat: mapRestrictionsBounds.south, lng: mapRestrictionsBounds.east });

        const worldCoordinatePosition: google.maps.Point | null = projection.fromLatLngToPoint(position);
        const mapZoom: number | undefined = this._googleMap.getZoom();

        if (mapZoom === undefined || worldCoordinateMapRestrictionNW == null || worldCoordinateMapRestrictionSE === null || worldCoordinatePosition === null)
        {
            return position;
        }

        const zoomScale = Math.pow(2, mapZoom);

        const positionPixelOffsetFromMapRestrictionNW = new google.maps.Point(
            Math.floor((worldCoordinatePosition.x - worldCoordinateMapRestrictionNW.x) * zoomScale),
            Math.floor((worldCoordinatePosition.y - worldCoordinateMapRestrictionNW.y) * zoomScale)
        );

        const positionPixelOffsetFromMapRestrictionSE = new google.maps.Point(
            Math.floor((worldCoordinateMapRestrictionSE.x - worldCoordinatePosition.x) * zoomScale),
            Math.floor((worldCoordinateMapRestrictionSE.y - worldCoordinatePosition.y) * zoomScale)
        );

        const screenHalfWidth: number = this._googleMap.getDiv().clientWidth / 2;
        const screenHalfHeight: number = this._googleMap.getDiv().clientHeight / 2;

        if (positionPixelOffsetFromMapRestrictionSE.x < screenHalfWidth)
        {
            worldCoordinatePosition.x -= Math.ceil((screenHalfWidth - positionPixelOffsetFromMapRestrictionSE.x) / zoomScale);
        }

        if (positionPixelOffsetFromMapRestrictionNW.x < screenHalfWidth)
        {
            worldCoordinatePosition.x += Math.ceil((screenHalfWidth - positionPixelOffsetFromMapRestrictionNW.x) / zoomScale);
        }

        if (positionPixelOffsetFromMapRestrictionSE.y < screenHalfHeight)
        {
            worldCoordinatePosition.y -= Math.ceil((screenHalfHeight - positionPixelOffsetFromMapRestrictionSE.y) / zoomScale);
        }

        if (positionPixelOffsetFromMapRestrictionNW.y < screenHalfHeight)
        {
            worldCoordinatePosition.y += Math.ceil((screenHalfHeight - positionPixelOffsetFromMapRestrictionNW.y) / zoomScale);
        }

        const updatedPosition: google.maps.LatLng | null = projection.fromPointToLatLng(worldCoordinatePosition);
        return updatedPosition === null ? position : { lat: updatedPosition.lat(), lng: updatedPosition.lng() };
    }

    public updateMapType(isSatelliteView: boolean | null = null): void
    {
        if (this._googleMap === null)
        {
            return;
        }

        if (isSatelliteView === null)
        {
            isSatelliteView = this._appSettingsService.appSettings.isSatelliteView;
        }
        else if (this._appSettingsService.appSettings.isSatelliteView !== isSatelliteView)
        {
            this._appSettingsService.appSettings.isSatelliteView = isSatelliteView;
        }

        this._googleMap.setMapTypeId(isSatelliteView ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP);
    }

    public setMapZoom(zoom: number): void
    {
        if (this._googleMap === null)
        {
            return;
        }

        const currentZoom: number | undefined = this._googleMap.getZoom();
        if (currentZoom !== zoom)
        {
            this._isDuringSetMapZoom = true;
            this._googleMap.setZoom(zoom);
            this._isDuringSetMapZoom = false;
        }
    }

    public async shareMapSnapshot(sharingTitle: string, sharingText: string, filenamePrefix: string = ''): Promise<void>
    {
        if (this._googleMap === null)
        {
            return;
        }

        const sharingFileName: string = Utils.getFileDateName(`${this.SNAPSHOT_PREFIX}${filenamePrefix}`, 'png', new Date());

        const base64ImageData: string = await HtmlToImage.htmlElementToBase64ImageData(this._googleMap.getDiv());

        ContguardPlugin.share({ title: sharingTitle, text: sharingText, filename: sharingFileName, base64fileData: base64ImageData });
    }

    public mapPanToPosition(position: google.maps.LatLngLiteral): void
    {
        if (this._googleMap === null)
        {
            return;
        }

        const mapCenter: google.maps.LatLng = this._googleMap.getCenter() as google.maps.LatLng;
        if (Math.sign(mapCenter.lat()) !== Math.sign(position.lat) || Math.sign(mapCenter.lng()) !== Math.sign(position.lng))
        {
            this._googleMap.setCenter({ lat: (mapCenter.lat() + Math.sign(position.lat) * 90) % 90, lng: (mapCenter.lng() + Math.sign(position.lng) * 180) % 180 });
        }

        this._googleMap.panTo(position);
    }

    public mapFitBounds(bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral, restrictBounds: boolean = false): void
    {
        if (this._googleMap === null)
        {
            return;
        }

        this._isMapIdle = false;

        if (restrictBounds)
        {
            this._googleMap.setOptions({ restriction: null });
            this.waitForMapIdle().then(() =>
            {
                bounds = this._googleMap?.getBounds()!;

                this._mapRestrictions = { latLngBounds: bounds, strictBounds: false };

                this._googleMap!.setOptions({ restriction: this._mapRestrictions });
            });
        }

        const boundsPaddings = restrictBounds ? this.MAP_FIT_BOUNDS_PADDING : 0;

        this._googleMap.fitBounds(bounds, boundsPaddings);
        this._googleMap.panToBounds(bounds, boundsPaddings);
    }

    public sendMapMouseDownEvent(): void
    {
        document.dispatchEvent(new MouseEvent('mousedown'));
    }

    public addMarkerClickEvent(marker: google.maps.marker.AdvancedMarkerElement, clickCallback: (() => void) | null): void
    {
        let clickTimeoutHandleId: NodeJS.Timeout | null = null;

        (marker.content as HTMLElement).addEventListener('click', () =>
        {
            this.sendMapMouseDownEvent();

            if (clickTimeoutHandleId !== null)
            {
                clearTimeout(clickTimeoutHandleId);
                clickTimeoutHandleId = null;

                const markerPosition: google.maps.LatLngLiteral = marker.position as google.maps.LatLngLiteral;
                if (markerPosition !== null)
                {
                    this.getMaxZoomAtLatLng(markerPosition).then((maxZoom: number | null) =>
                    {
                        if (maxZoom !== null)
                        {
                            this.setMapZoom(maxZoom);
                            this.mapPanToPosition(markerPosition);
                        }
                    });
                }
            }
            else
            {
                clickTimeoutHandleId = setTimeout(() =>
                {
                    clickTimeoutHandleId = null;

                    if (clickCallback !== null)
                    {
                        clickCallback();
                    }
                }, Constants.CLICK_TIMEOUT_DURATION);
            }
        });

        marker.addListener('click', () => { });
    }

    public getClosestCoordinateToPosition(position: google.maps.LatLngLiteral, coordinates: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral
    {
        if (this._googleMap === null)
        {
            return position;
        }

        const mapZoom: number | undefined = this._googleMap.getZoom();

        const projection: google.maps.Projection | undefined = this._googleMap.getProjection();
        if (projection === undefined || mapZoom === undefined)
        {
            return position;
        }

        const positionPoint: google.maps.Point | null = projection.fromLatLngToPoint(position);
        if (positionPoint === null)
        {
            return position;
        }

        let minDistance: number | null = null;
        let closestCoordinate: google.maps.LatLngLiteral = position;

        for (const coordinate of coordinates)
        {
            const coordinatePoint: google.maps.Point | null = projection.fromLatLngToPoint(coordinate);
            if (coordinatePoint === null)
            {
                continue;
            }

            const distance: number = GoogleMapUtils.getPointsDistanceAtZoomLevel(coordinatePoint, positionPoint, mapZoom);
            if (minDistance == null || distance < minDistance)
            {
                minDistance = distance;
                closestCoordinate = coordinate;
            }
        }

        return closestCoordinate;
    }

    public async getMaxZoomAtLatLng(latlng: google.maps.LatLng | google.maps.LatLngLiteral): Promise<number | null>
    {
        const result: google.maps.MaxZoomResult | undefined = await this._maxZoomService?.getMaxZoomAtLatLng(latlng);
        return result === undefined ? null : result.zoom;
    }

    public getAverageGeolocation(coordinates: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral
    {
        if (coordinates.length === 1)
        {
            return coordinates[0];
        }

        let latitudeSum: number = 0;
        let longitudeSum: number = 0;

        for (const coordinate of coordinates)
        {
            latitudeSum += coordinate.lat;
            longitudeSum += coordinate.lng;
        }

        return {
            lat: latitudeSum / coordinates.length,
            lng: longitudeSum / coordinates.length
        };
    }

    public isCoordinatesInPoisitionBounds(position: google.maps.LatLngLiteral, coordinates: google.maps.LatLngLiteral[]): boolean
    {
        if (this._googleMap === null)
        {
            return false;
        }

        const projection: google.maps.Projection | undefined = this._googleMap.getProjection();
        if (projection === undefined)
        {
            return false;
        }

        const worldCoordinatePosition: google.maps.Point | null = projection.fromLatLngToPoint(position);
        const mapZoom: number | undefined = this._googleMap.getZoom();

        if (mapZoom === undefined || worldCoordinatePosition === null)
        {
            return false;
        }

        const screenHalfWidth: number = this._googleMap.getDiv().clientWidth / 2;
        const screenHalfHeight: number = this._googleMap.getDiv().clientHeight / 2;

        const zoomScale = Math.pow(2, mapZoom);

        const positionNW: google.maps.LatLng | null = projection.fromPointToLatLng(new google.maps.Point(
            worldCoordinatePosition.x - screenHalfWidth / zoomScale,
            worldCoordinatePosition.y - screenHalfHeight / zoomScale
        ));

        const positionSE: google.maps.LatLng | null = projection.fromPointToLatLng(new google.maps.Point(
            worldCoordinatePosition.x + screenHalfWidth / zoomScale,
            worldCoordinatePosition.y + screenHalfHeight / zoomScale
        ));

        if (positionNW === null || positionSE === null)
        {
            return false;
        }

        const positionBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
            {
                north: positionNW.lat(),
                west: positionNW.lng(),
                south: positionSE.lat(),
                east: positionSE.lng()
            });

        for (const coordinate of coordinates)
        {
            if (positionBounds.contains(coordinate))
            {
                return true;
            }
        }

        return false;
    }

    // #endregion

    // #region Private Methods

    private limitMapZoom(): void
    {
        if (this._googleMap === null)
        {
            return;
        }

        const mapCenterPosition: google.maps.LatLng | undefined = this._googleMap.getCenter();
        const mapZoom: number | undefined = this._googleMap.getZoom();

        if (mapCenterPosition === undefined || mapZoom === undefined)
        {
            return;
        }

        this.getMaxZoomAtLatLng(mapCenterPosition).then((maxZoom: number | null) =>
        {
            if (maxZoom !== null && maxZoom < mapZoom)
            {
                this._googleMap?.setZoom(maxZoom);
            }
        });
    }

    private initializeMapEvents(): void
    {
        if (this._googleMap === null)
        {
            return;
        }

        google.maps.event.addListener(this._googleMap, 'zoom_changed', () =>
        {
            this._isMapIdle = false;

            if (!this._isDuringSetMapZoom)
            {
                this.limitMapZoom();
            }
        });

        google.maps.event.addListener(this._googleMap, 'idle', () =>
        {
            this._isMapIdle = true;
        });

        google.maps.event.addListener(this._googleMap, 'bounds_changed', () =>
        {
            this._isMapIdle = false;
        });
    }

    private async initializeGoogleMap(): Promise<void>
    {
        await google.maps.importLibrary("marker");
        await google.maps.importLibrary("geometry");

        try
        {
            if (this.showTreetView)
            {
                const streetViewPanoramaOptions: google.maps.StreetViewPanoramaOptions =
                {
                    fullscreenControl: false,
                    enableCloseButton: false,
                    addressControl: false,
                    zoomControl: false,
                    linksControl: false,
                    visible: false
                };

                this._streetViewPanorama = new google.maps.StreetViewPanorama(this._elementRef.nativeElement.querySelector(this.STREET_VIEW_CONTAINER_SELECTOR) as HTMLElement,
                    streetViewPanoramaOptions);

                this._streetViewPanorama.controls[google.maps.ControlPosition.LEFT_TOP].push(
                    this._elementRef.nativeElement.querySelector(this.STREET_VIEW_CLOSE_BUTTON_SELECTOR) as HTMLElement);
            }

            this._mapRestrictions =
            {
                latLngBounds: { north: 85, south: -85, west: -179.9999, east: 179.9999 },
                strictBounds: true
            };

            const mapOptions: google.maps.MapOptions =
            {
                center: this.mapStartingCenterPosition,
                zoom: this.MAP_STARTING_ZOOM,
                scaleControl: true,
                restriction: this._mapRestrictions,
                minZoom: this.MAP_MIN_ZOOM,
                streetView: this._streetViewPanorama,
                disableDefaultUI: true,
                streetViewControl: this.showTreetView,
                streetViewControlOptions: { position: google.maps.ControlPosition.BOTTOM_LEFT },
                scrollwheel: true,
                clickableIcons: false,
                keyboardShortcuts: false,
                mapId: '49b1b83fc3bfae91'//'ccb377da353fe6e5'
            };

            this._googleMap = new google.maps.Map(this._elementRef.nativeElement.querySelector(this.MAP_CONTAINER_SELECTOR) as HTMLElement, mapOptions);

            this._imageOpenRailwayMapType = new google.maps.ImageMapType(
                {
                    getTileUrl: (coord, zoom) =>
                    {
                        return `https://tiles.openrailwaymap.org/standard/${zoom}/${coord.x}/${coord.y}.png`;
                    },
                    tileSize: new google.maps.Size(256, 256),
                    name: 'OpenRailwayMap'
                });

            this.updateMapType();

            this._maxZoomService = new google.maps.MaxZoomService();

            this.initializeMapEvents();

            this.initialize.emit({ isSuccess: true });
        }
        catch (error: any)
        {
            console.log(this.GOOGLE_MAPS_INIT_ERROR_MESSAGE, error.message);

            this.initialize.emit({ isSuccess: false, message: this.GOOGLE_MAPS_INIT_ERROR_MESSAGE });
        }
    }

    // #endregion
}
