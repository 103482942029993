import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeInAnimation, fadeInOutAnimation, fadeSlideDownInOutAnimation } from '../../animations/fade.animation';
import { ManagerBaseComponent } from '../../base/components/manager-base.component';
import { DisplaySmartLock, SmartLockColumnType, SmartLockLogicStateType, SmartLockStatus, SmartLockStatusResponse } from './model/smart-lock-manager-model.class';
import { VirtualListInfo } from '../../controls/virtual-list/model/virtual-list-info';
import { SmartLockManagerModel } from './model/smart-lock-manager.model';
import { Router } from '@angular/router';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { AnimationBuilder } from '@angular/animations';
import { AppSettingsService } from '../../services/app-settings.service';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { BleClient, ScanResult, dataViewToNumbers, numbersToDataView } from '@capacitor-community/bluetooth-le';
import { BarcodeScannerService } from '../../controls/barcode-scanner/services/barcode-scanner.service';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { slideInOutAnimation, slideInOutReverseAnimation } from '../../animations/slide.animation';
import { Constants, IApiResponse } from '../../utils/globals';
import { ModalType } from '../../controls/modal-message/modal-message.component';
import { Capacitor } from '@capacitor/core';
import { foldFadeHorizontalAnimation } from '../../animations/fold.animation';
import { LoaderComponent } from '../../controls/loader/loader.component';
import { NgTemplateOutlet } from '@angular/common';
import { PointerEventsDirective } from '../../directives/pointer-events.directive';
import { VirtualListComponent } from '../../controls/virtual-list/virtual-list.component';
import { OverlayScrollbarComponent } from '../../controls/overlay-scrollbar/overlay-scrollbar.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { FormsModule } from '@angular/forms';
import { TopBarComponent } from '../../controls/top-bar/top-bar.component';
import { Utils } from '../../utils/utils';

@Component({
    selector: 'smart-lock-manager',
    templateUrl: './smart-lock-manager.component.html',
    styleUrls: ['../../base/styles/manager-base.css', '../../base/styles/manager-table.css', './smart-lock-manager.component.css'],
    animations: [fadeInOutAnimation, fadeInAnimation, slideInOutAnimation, slideInOutReverseAnimation, fadeSlideDownInOutAnimation, foldFadeHorizontalAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TopBarComponent, ClearableInputComponent, FormsModule, TooltipDirective, OverlayScrollbarComponent, VirtualListComponent, PointerEventsDirective,
        NgTemplateOutlet, LoaderComponent]
})
export class SmartLockManagerComponent extends ManagerBaseComponent<DisplaySmartLock, SmartLockColumnType> implements OnInit, OnDestroy
{
    // #region Constants

    private readonly SMART_LOCK_NAME_PREFIX: string = 'STAR';
    private readonly READ_STAUS_GET_INTERVAL: number = 1000 * 20;
    private readonly BLUTTHOOTH_SCAN_INTERVAL: number = 1000 * 10;

    // #endregion

    // #region Private Members

    @ViewChild(ClearableInputComponent, { read: ClearableInputComponent, static: false })
    private _searchClearableInput: ClearableInputComponent | undefined = undefined;

    private _smartLockReadStatusTimeoutHandleId: NodeJS.Timeout | null = null;
    private _bluetoothScanTimeoutHandleId: NodeJS.Timeout | null = null;

    private _isDuringDestroy: boolean = false;

    // #endregion

    // #region Protected Members

    protected static override _managerItemsListInfo: VirtualListInfo = new VirtualListInfo();

    // #endregion

    // #region Properties

    public get SmartLockLogicStateType()
    {
        return SmartLockLogicStateType;
    }

    public get SmartLockColumnType()
    {
        return SmartLockColumnType;
    }

    public override get managerModel(): SmartLockManagerModel
    {
        return this._managerModel as SmartLockManagerModel;
    }

    // #endregion

    // #region Inputs

    @Input() public unitNumber: string = '';

    // #endregion

    // #region Constructor

    constructor(_managerModel: SmartLockManagerModel, _router: Router, _changeDetectorRef: ChangeDetectorRef,
        _routingHistoryService: RoutingHistoryService, _animationBuilder: AnimationBuilder, _appSettingsService: AppSettingsService,
        _modalMessageService: ModalMessageService, private _barcodeScannerService: BarcodeScannerService)
    {
        super(_managerModel, _router, _changeDetectorRef, _routingHistoryService, _animationBuilder, _appSettingsService, _modalMessageService);
    }

    // #endregion

    // #region ICanComponentDeactivate

    public override async canDeactivate(): Promise<boolean>
    {
        if (this._routingHistoryService.isPopState && this._routingHistoryService.isPopStateBack && this.managerModel.selectedManagerItem !== null)
        {
            this.clearSelectedManagerItem();
            return false;
        }

        return true;
    }

    // #region Event Handlers

    public override ngOnInit(): void
    {
        ManagerBaseComponent._managerItemsListInfo.clear();

        this.managerItemsListInfo.clear();
        this.managerModel.clear();

        if (!Utils.isNullOrEmpty(this.unitNumber))
        {
            this.managerModel.searchFilter = this.unitNumber;
        }

        this.loadManagerElements();
    }

    public override async ngOnDestroy(): Promise<void>
    {
        this._isDuringDestroy = true;

        super.ngOnDestroy();

        await this.stopBluetooth();
    }

    public override async onManagerItemClick(managerItem: DisplaySmartLock): Promise<void>
    {
        if (managerItem.deviceId === undefined)
        {
            this._modalMessageService.show(
                {
                    title: Constants.APP_TITLE,
                    message: 'This device is not connected to Bluetooth.<br>Either the device is not in range or the device\'s power is off.',
                    modalType: ModalType.Regular
                });

            return;
        }

        this.managerModel.selectedManagerItem = managerItem;
        this._changeDetectorRef.detectChanges();

        if (this.managerModel.selectedManagerItem.smartLockKeyHash === undefined)
        {
            this.initializePairingBluetoothSmartLock();
        }
        else
        {
            this.updateSmartLockStatus();
        }
    }

    public async onTurnOffSmartLockButtonClick(): Promise<void>
    {
        try
        {
            await this.writeBluetoothSmartLockCommand('set_power:0');
        }
        catch (exception)
        {
            console.error(`BleClient.writeCommand(set_power:0): ${JSON.stringify(exception)}`);
        }
    }

    public onSmartLockBackButtonClick(): void
    {
        this.clearSelectedManagerItem();
    }

    public async onUnlockSmartLockButtonClick(): Promise<void>
    {
        this.clearSmartLockReadStatusTimeout();

        if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined)
        {
            this.clearSelectedManagerItem();
            return;
        }

        const smartLockLogicState: SmartLockLogicStateType | null = this.managerModel.selectedManagerItem.liveSmartLockStatus !== undefined ?
            this.managerModel.selectedManagerItem.liveSmartLockStatus.logicState : this.managerModel.selectedManagerItem.logicState;

        await this.writeBluetoothSmartLockCommand(
            `set_state:${smartLockLogicState === SmartLockLogicStateType.Unlocked ? SmartLockLogicStateType.Locked : SmartLockLogicStateType.Unlocked}`);

        await this.updateSmartLockStatus();

        this._changeDetectorRef.markForCheck();
    }

    public onBarcodeButtonMouseDown(event: MouseEvent): void
    {
        event.stopImmediatePropagation();
        event.preventDefault();

        this._barcodeScannerService.scan().then((barcode: string | null) =>
        {
            if (barcode !== null && this._searchClearableInput !== undefined)
            {
                this._searchClearableInput.value = barcode;
                this._searchClearableInput.focus();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    // #endregion

    // #region Protected Methods

    protected override loadManagerElements(): void
    {
        this.stopBluetooth().then(() =>
        {
            this.managerModel.clearSmartLocksBluetoothConnectionInfo();

            super.loadManagerElements();

            this.initializeSmartLockBluetooth();
        });
    }

    // #endregion

    // #region Private Methods

    private async stopBluetooth(): Promise<void>
    {
        this.clearBluetoothScanTimeout();
        this.clearSmartLockReadStatusTimeout();

        await this.stopBuethoothScan();
        await this.stopSmartLockNotifications();

        try
        {
            for (const deviceId of this.managerModel.getBluetoothConncetedDeviceIds())
            {
                await BleClient.disconnect(deviceId);
            }
        }
        catch (exception)
        {
            console.error(`BleClient.disconnect: ${JSON.stringify(exception)}`);
        }
    }

    private clearSelectedManagerItem(): void
    {
        this.managerModel.selectedManagerItem = null;
        this.clearSmartLockReadStatusTimeout();
        this._changeDetectorRef.markForCheck();
    }

    private clearSmartLockReadStatusTimeout(): void
    {
        if (this._smartLockReadStatusTimeoutHandleId !== null)
        {
            clearTimeout(this._smartLockReadStatusTimeoutHandleId);
            this._smartLockReadStatusTimeoutHandleId = null;
        }
    }

    private clearBluetoothScanTimeout(): void
    {
        if (this._bluetoothScanTimeoutHandleId !== null)
        {
            clearTimeout(this._bluetoothScanTimeoutHandleId);
            this._bluetoothScanTimeoutHandleId = null;
        }
    }

    private async connectSmartLockToBluetooth(unitNumber: number, deviceId: string): Promise<void>
    {
        try
        {
            await BleClient.disconnect(deviceId);
            await BleClient.connect(deviceId, () =>
            {
                console.warn(`device disconnected: ${unitNumber}`);

                if (this.managerModel.selectedManagerItem?.unitNumber === unitNumber)
                {
                    this.clearSelectedManagerItem();
                }

                this.managerModel.removeSmartLockBluetoothConnectionInfo(unitNumber);
                if (!this._isDuringDestroy)
                {
                    this._changeDetectorRef.markForCheck();
                }
            });

            this.managerModel.addSmartLockBluetoothConnectionInfo(unitNumber, deviceId);
            this._changeDetectorRef.markForCheck();
        }
        catch (exception)
        {
            console.error(`connectSmartLockToBluetooth: ${JSON.stringify(exception)}`);
            return;
        }
    }

    private initializePairingBluetoothSmartLock(): void
    {
        this.managerModel.getSelectedSmartLockKeyHash().subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                if (this.managerModel.selectedManagerItem !== null)
                {
                    this._changeDetectorRef.detectChanges();
                    this.pairingBluetoothSmartLock();
                }
            }
            else
            {
                this._changeDetectorRef.detectChanges();
                this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error });
            }
        });

        this._changeDetectorRef.detectChanges();
    }

    private async pairingBluetoothSmartLock(): Promise<void>
    {
        if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined ||
            this.managerModel.selectedManagerItem.smartLockKeyHash === undefined)
        {
            return;
        }

        try
        {
            await BleClient.discoverServices(this.managerModel.selectedManagerItem.deviceId);
        }
        catch (exception)
        {
            console.error(`discoverServices: ${JSON.stringify(exception)}`);
            return;
        }

        if (!await this.writeBluetoothSmartLockCommand(`key:${this.managerModel.selectedManagerItem.smartLockKeyHash.key}`))
        {
            return;
        }

        let authHash: string = '';

        try
        {
            const response: string | null = await this.readBluetoothSmartLockResponse();
            if (response === null)
            {
                return;
            }

            const concatHashArray = Uint8Array.from(`${this.managerModel.selectedManagerItem.smartLockKeyHash.hash}${''
                }${JSON.parse(response).hash}`.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)));

            const hashBuffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', concatHashArray);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            authHash = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
        }
        catch (exception)
        {
            console.error(`discoverServices: ${JSON.stringify(exception)}`);
            return;
        }

        if (!await this.writeBluetoothSmartLockCommand(`auth:${authHash}`))
        {
            return;
        }

        try
        {
            const response: string | null = await this.readBluetoothSmartLockResponse();
            if (response === null || JSON.parse(response).authorize !== 'ok')
            {
                console.error(`Bluetooth authorize response: ${response}`);
                return;
            }
        }
        catch (exception)
        {
            return;
        }

        await this.updateSmartLockStatus();

        await this.startSmartLockNotifications();
    }

    private async startSmartLockNotifications(): Promise<void>
    {
        if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined)
        {
            return;
        }

        try
        {
            BleClient.startNotifications(this.managerModel.selectedManagerItem.deviceId, this.managerModel.smartLockManagerServiceInfo.bluetoothService,
                this.managerModel.smartLockManagerServiceInfo.bluetoothServiceCharacteristicRead, () => this.updateSmartLockStatus());
        }
        catch (exception)
        {
            console.error(`startSmartLockNotifications exception: ${JSON.stringify(exception)}`);
        }
    }

    private async stopSmartLockNotifications(): Promise<void>
    {
        if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined)
        {
            return;
        }

        try
        {
            BleClient.stopNotifications(this.managerModel.selectedManagerItem.deviceId, this.managerModel.smartLockManagerServiceInfo.bluetoothService,
                this.managerModel.smartLockManagerServiceInfo.bluetoothServiceCharacteristicRead);
        }
        catch (exception)
        {
            console.error(`stopSmartLockNotifications exception: ${JSON.stringify(exception)}`);
        }
    }

    private async updateSmartLockStatus(): Promise<void>
    {
        const smartLockStatusResponse: SmartLockStatusResponse | null = await this.getBluetoothSmartLockStatus();
        if (smartLockStatusResponse !== null)
        {
            if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined ||
                smartLockStatusResponse.logic === null || smartLockStatusResponse.logic === undefined)
            {
                this.clearSmartLockReadStatusTimeout();
                return;
            }

            if (this.managerModel.selectedManagerItem.liveSmartLockStatus !== undefined && this.managerModel.selectedManagerItem.unitNumber !== null &&
                this.managerModel.selectedManagerItem.liveSmartLockStatus.logicState !== smartLockStatusResponse.logic)
            {
                this.managerModel.sendSmartLockUserUnlockLog(this.managerModel.selectedManagerItem.unitNumber, smartLockStatusResponse.logic).subscribe();
            }

            this.managerModel.selectedManagerItem.liveSmartLockStatus = new SmartLockStatus();
            this.managerModel.selectedManagerItem.liveSmartLockStatus.features = smartLockStatusResponse.features;
            this.managerModel.selectedManagerItem.liveSmartLockStatus.temperature = smartLockStatusResponse.temp;
            this.managerModel.selectedManagerItem.liveSmartLockStatus.motor = smartLockStatusResponse.motor;
            this.managerModel.selectedManagerItem.liveSmartLockStatus.mainVoltage = smartLockStatusResponse.power;
            this.managerModel.selectedManagerItem.liveSmartLockStatus.logicState = smartLockStatusResponse.logic;
            this.managerModel.selectedManagerItem.liveSmartLockStatus.baterryPowerPercent =
                this.managerModel.calculateBaterryPowerPercent(this.managerModel.selectedManagerItem.liveSmartLockStatus.mainVoltage);

            this._changeDetectorRef.markForCheck();
        }

        this.updateBluetoothSmartLockStatusByTimeout();
    }

    private updateBluetoothSmartLockStatusByTimeout(): void
    {
        this.clearSmartLockReadStatusTimeout();

        this._smartLockReadStatusTimeoutHandleId = setTimeout(() => this.updateSmartLockStatus(), this.READ_STAUS_GET_INTERVAL);
    }

    private async getBluetoothSmartLockStatus(): Promise<SmartLockStatusResponse | null>
    {
        if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined)
        {
            return null;
        }

        await this.stopSmartLockNotifications();

        let smartLockStatusResponse: SmartLockStatusResponse | null = null;
        if (await this.writeBluetoothSmartLockCommand('get_status'))
        {
            try
            {
                const response: string | null = await this.readBluetoothSmartLockResponse();
                if (response === null)
                {
                    return null;
                }

                smartLockStatusResponse = JSON.parse(response) as SmartLockStatusResponse;
            }
            catch (exception)
            {
            }
        }

        this.startSmartLockNotifications();

        return smartLockStatusResponse;
    }

    private async writeBluetoothSmartLockCommand(command: string): Promise<boolean>
    {
        if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined)
        {
            return false;
        }

        try
        {
            const textEncoder: TextEncoder = new TextEncoder();
            const encodedCommand: Uint8Array = textEncoder.encode(command);
            await BleClient.write(this.managerModel.selectedManagerItem.deviceId, this.managerModel.smartLockManagerServiceInfo.bluetoothService,
                this.managerModel.smartLockManagerServiceInfo.bluetoothServiceCharacteristicWrite, numbersToDataView(Array.from(encodedCommand)));

            return true;
        }
        catch (exception)
        {
            console.error(`writeBluetoothSmartLockCommand(${command}): ${JSON.stringify(exception)}`);
            return false;
        }
    }

    private async readBluetoothSmartLockResponse(): Promise<string | null>
    {
        if (this.managerModel.selectedManagerItem === null || this.managerModel.selectedManagerItem.deviceId === undefined)
        {
            return null;
        }

        try
        {
            const dataResponse: DataView = await BleClient.read(this.managerModel.selectedManagerItem.deviceId,
                this.managerModel.smartLockManagerServiceInfo.bluetoothService, this.managerModel.smartLockManagerServiceInfo.bluetoothServiceCharacteristicRead);

            const textDecoder: TextDecoder = new TextDecoder();
            return textDecoder.decode(new Uint8Array(dataViewToNumbers(dataResponse)));
        }
        catch (exception)
        {
            console.error(`readBluetoothSmartLockResponse: ${JSON.stringify(exception)}`);
            return null;
        }
    }

    private async initializeSmartLockBluetooth(): Promise<void>
    {
        try
        {
            await BleClient.initialize({ androidNeverForLocation: true });
        }
        catch (exception)
        {
            console.error(`BleClient.initialize: ${JSON.stringify(exception)}`);
            return;
        }

        if (Capacitor.getPlatform() === 'android')
        {
            try
            {
                await BleClient.requestEnable();
            }
            catch (exception)
            {
                console.error(`BleClient.requestEnable: ${JSON.stringify(exception)}`);
                return;
            }
        }

        this.updateBluetoothScanByTimeout();
    }

    private updateBluetoothScanByTimeout(): void
    {
        this.clearBluetoothScanTimeout();

        this._bluetoothScanTimeoutHandleId = setTimeout(() => this.updateBluetoothScanByTimeout(), this.BLUTTHOOTH_SCAN_INTERVAL);

        this.startBuethoothScan();
    }

    private async startBuethoothScan(): Promise<void>
    {
        await this.stopBuethoothScan();

        try
        {
            await BleClient.requestLEScan(
                {
                    namePrefix: this.SMART_LOCK_NAME_PREFIX
                },
                (result: ScanResult) =>
                {
                    if (result.device.name !== undefined)
                    {
                        const unitNumber: number = parseInt(result.device.name.substring(this.SMART_LOCK_NAME_PREFIX.length));
                        if (this.managerModel.isSmartLockAvailable(unitNumber) && !this.managerModel.findConnectedSmartLock(unitNumber))
                        {
                            this.connectSmartLockToBluetooth(unitNumber, result.device.deviceId);
                        }
                    }
                }
            );
        }
        catch (exception)
        {
            console.error(`BleClient.requestLEScan: ${JSON.stringify(exception)}`);
            return;
        }
    }

    private async stopBuethoothScan(): Promise<void>
    {
        try
        {
            await BleClient.stopLEScan();
        }
        catch (exception)
        {
            console.error(`BleClient.stopLEScan: ${JSON.stringify(exception)}`);
        }
    }

    // #endregion
}
