import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInAnimation, fadeInOutAnimation, fadeSlideDownInOutAnimation, shortFadeInOutAnimation } from '../../animations/fade.animation';
import { Constants, RouteType } from '../../utils/globals';
import { ShipmentManagerModel } from './model/shipment-manager.model';
import { AnimationBuilder } from '@angular/animations';
import { Utils } from '../../utils/utils';
import { ShipmentComponent } from '../shipment/shipment.component';
import { ShipmentColumnType } from './model/shipment-manager-model.class';
import { StringTableUtils } from '../../utils/string-table-utils';
import { DisplayShipment } from '../shipment/model/shipment-model.class';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { AppSettingsService } from '../../services/app-settings.service';
import { foldFadeHorizontalAnimation } from '../../animations/fold.animation';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { LoginModel } from '../../user/login/model/login.model';
import { ManagerBaseComponent } from '../../base/components/manager-base.component';
import { ManagerItemStatus } from '../../base/models/manager-base-model.class';
import { VirtualListInfo } from '../../controls/virtual-list/model/virtual-list-info';
import { GlobalsPipe } from '../../pipes/globals.pipe';
import { DefaultColumnComponent } from '../../controls/defualt-list-column/default-list-column.component';
import { LoaderComponent } from '../../controls/loader/loader.component';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { ElementResizedDirective } from '../../directives/element-resized.directive';
import { PointerEventsDirective } from '../../directives/pointer-events.directive';
import { VirtualListComponent } from '../../controls/virtual-list/virtual-list.component';
import { OverlayScrollbarComponent } from '../../controls/overlay-scrollbar/overlay-scrollbar.component';
import { SelectListComponent } from '../../controls/select-list/select-list.component';
import { DateTimePickerComponent } from '../../controls/datetime-picker/datetime-picker.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { FormsModule } from '@angular/forms';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { TopBarComponent } from '../../controls/top-bar/top-bar.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'shipment-manager',
    templateUrl: './shipment-manager.component.html',
    styleUrls: ['../../controls/modal-message/modal-message.component.css', './shipment-manager.component.css', '../../base/styles/manager-base.css',
        '../../base/styles/manager-table.css'],
    animations: [fadeInOutAnimation, fadeInAnimation, fadeSlideDownInOutAnimation, shortFadeInOutAnimation, foldFadeHorizontalAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TopBarComponent, ClearableInputComponent, FormsModule, TooltipDirective, DateTimePickerComponent, SelectListComponent, OverlayScrollbarComponent,
        VirtualListComponent, PointerEventsDirective, ElementResizedDirective, NgTemplateOutlet, ShipmentComponent, LoaderComponent, NgClass,
        DefaultColumnComponent, GlobalsPipe]
})
export class ShipmentManagerComponent extends ManagerBaseComponent<DisplayShipment, ShipmentColumnType> implements OnInit
{
    // #region Constants

    protected override readonly EXCEL_FILE_PREFIX: string = 'ShipmentManager_';

    // #endregion

    // #region Private Members

    private _smartLockItemsSubscription: Subscription | null = null;

    // #endregion

    // #region Protected Members

    protected static override _managerItemsListInfo: VirtualListInfo = new VirtualListInfo();
    protected static override _managerTableListInfo: VirtualListInfo = new VirtualListInfo();
    protected static override _isGridView: boolean = Constants.IS_MOBILE;
    protected static override _gridViewItemsPerRow: number = 1;

    @ViewChild('managerItem', { read: ShipmentComponent, static: false }) protected override _managerItemComponent: ShipmentComponent | undefined = undefined;

    // #endregion

    // #region Properties

    public override get isDirty(): boolean
    {
        return this._managerItemComponent === undefined ? false : this._managerItemComponent.isDirty;
    }

    public get ShipmentColumnType()
    {
        return ShipmentColumnType;
    }

    public get loginModel(): LoginModel
    {
        return this._loginModel;
    }

    public override get managerModel(): ShipmentManagerModel
    {
        return this._managerModel as ShipmentManagerModel;
    }

    // #endregion

    // #region Inputs

    @Input() public shipmentKey: string = '';

    // #endregion

    // #region Constructor

    constructor(_managerModel: ShipmentManagerModel, _router: Router, _changeDetectorRef: ChangeDetectorRef,
        _routingHistoryService: RoutingHistoryService, _animationBuilder: AnimationBuilder, _appSettingsService: AppSettingsService,
        _modalMessageService: ModalMessageService, private _loginModel: LoginModel)
    {
        super(_managerModel, _router, _changeDetectorRef, _routingHistoryService, _animationBuilder, _appSettingsService, _modalMessageService);

        this.initializeManagerTableColumns();
    }

    // #endregion

    // #region Event Handlers

    public override ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this.managerModel.isInitialized && !Constants.IS_MOBILE)
        {
            this.selectShipmentByShipmentKey();
        }

        super.ngOnInit();
    }

    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        this.clearSmartLockItemsSubscription();
    }

    public async onDisplayStatusClick(event: Event, value: number): Promise<void>
    {
        event.preventDefault();

        if (this.managerModel.shipmentsStatus === value || !await this.canDeactivate())
        {
            return;
        }

        requestAnimationFrame(() =>
        {
            this.managerModel.shipmentsStatus = value;
            this.initializeManagerTableColumns();
            setTimeout(() => this._changeDetectorRef.detectChanges());
        });
    }

    public onShipmentMonitoringButtonClick(displayShipment: DisplayShipment): void
    {
        this._router.navigate([`${Constants.IS_MOBILE ? RouteType.ShipmentsMonitoring :
            (displayShipment.statusId === ManagerItemStatus.Completed ? RouteType.ShipmentMonitoringArchive : RouteType.ShipmentMonitoring)}${''
            }/${displayShipment.shipmentKey}`]);
    }

    public onSmartLockButtonClick(event: MouseEvent, displayShipment: DisplayShipment): void
    {
        event.stopImmediatePropagation();

        this._router.navigate([`${RouteType.SmartLockManager}/${displayShipment.unitNumber}`]);
    }

    // #endregion

    // #region Protected Methods

    protected override loadManagerElements(): void
    {
        super.loadManagerElements(() =>
        {
            //if (Constants.IS_MOBILE && this._loginModel.userInsightPermissions.includes(UserInsightPermission.SmartLockManager))
            //{
            //    this.clearSmartLockItemsSubscription();

            //    this._smartLockItemsSubscription = this.managerModel.getSmartLockItems().subscribe((response: IApiResponse) =>
            //    {
            //        if (response.isSuccess)
            //        {
            //            this._changeDetectorRef.detectChanges();
            //        }
            //    });
            //}

            this.selectShipmentByShipmentKey();
        });
    }

    protected override updateLoadedSelectedManagerItem(selectedManagerItem: DisplayShipment): void
    {
        this.managerModel.selectedManagerItem = selectedManagerItem;
        this.managerModel.updateSelectedManagerItem();

        this.initializeManagerTableColumns();
        this._changeDetectorRef.detectChanges();
    }

    protected override selectManagerItem(managerItem: DisplayShipment | null, forceSelect: boolean = false): void
    {
        super.selectManagerItem(managerItem, forceSelect);

        if (Constants.IS_MOBILE)
        {
            this._router.navigate([`/${RouteType.ShipmentDetails}`]);
        }
    }

    protected override loadManagerItem(): void
    {
        this._managerItemComponent?.loadShipment();
    }

    protected override initializeManagerTableColumns(): void
    {
        const displayShipment: DisplayShipment = new DisplayShipment();

        this.managerTableColumns =
            [
                {
                    columnType: ShipmentColumnType.ShipmentKey,
                    text: 'CG-ID',
                    classes: 'col-shipment-key',
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.shipmentKey)
                },
                {
                    columnType: ShipmentColumnType.ContainerId,
                    text: this._loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : StringTableUtils.getContainerIdColumnName(this._loginModel.isAccountTypeAmazon),
                    classes: 'col-container-id',
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment =>
                        this._loginModel.isAccountTypeAmazonUS ? displayShipment.trailerNumber : displayShipment.containerId)
                },
                {
                    columnType: ShipmentColumnType.DeviceId,
                    text: StringTableUtils.getDeviceIdColumnName(this._loginModel.isAccountTypeAmazonUS),
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.deviceDescription)
                },
                {
                    columnType: ShipmentColumnType.CreateDate,
                    text: 'Created',
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.createdDateFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.created)
                },
                {
                    columnType: ShipmentColumnType.InstallDate,
                    text: `${StringTableUtils.getInstallDateColumnName(this._loginModel.isAccountTypeAmazon)}<span class="small-units">${(this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '')}</span>`,
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.installDateFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.installDate)
                },
                {
                    columnType: ShipmentColumnType.RouteDescription,
                    text: 'Route',
                    classes: 'col-route col-overflow',
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.routeDescription)
                }
            ];

        this._managerListColumns = [...this._managerTableColumns];

        if (this.managerModel.shipmentsStatus === ManagerItemStatus.Live)
        {
            this._managerTableColumns.push(
                {
                    columnType: ShipmentColumnType.IsArrived,
                    text: 'Arrived',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.isReportArrived)
                });
        }

        if (this.managerModel.shipmentsStatus === ManagerItemStatus.Completed)
        {
            if (this._loginModel.isAccountTypeAmazon)
            {
                this._managerTableColumns.push(
                    {
                        columnType: ShipmentColumnType.IsReportAnomaly,
                        text: 'Anomaly',
                        classes: 'center',
                        propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.isReportAnomaly)
                    });
            }

            this._managerTableColumns.push(
                {
                    columnType: ShipmentColumnType.IsAutoClose,
                    text: 'Auto Closed',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayShipment>(displayShipment, displayShipment => displayShipment.isReportAutoClose)
                });
        }

        this._managerTableSortColumns = [...this._managerTableColumns];

        this._managerTableColumns.push(
            {
                columnType: ShipmentColumnType.Track,
                classes: 'col-button center',
                isFixedWidth: true
            });
    }

    // #endregion

    // #region Private Methods

    private clearSmartLockItemsSubscription(): void
    {
        if (this._smartLockItemsSubscription !== null)
        {
            this._smartLockItemsSubscription.unsubscribe();
            this._smartLockItemsSubscription = null;
        }
    }

    private selectShipmentByShipmentKey(): void
    {
        if (Utils.isNullOrEmpty(this.shipmentKey))
        {
            return;
        }

        const shipmentKey: number = Number(this.shipmentKey);
        for (const managerItem of this.managerModel.managerItems)
        {
            if (managerItem.shipmentKey === shipmentKey)
            {
                this.selectManagerItem(managerItem, true);

                if (!Constants.IS_MOBILE)
                {
                    if (managerItem.statusId !== null && this.managerModel.shipmentsStatus !== managerItem.statusId)
                    {
                        this.managerModel.shipmentsStatus = managerItem.statusId;
                        this.initializeManagerTableColumns();
                    }
                }

                return;
            }
        }
    }

    // #endregion
}
