import { Injectable } from "@angular/core";
import { MonitoringItemsBaseModel } from "../../base/models/monitoring-items-base.model";
import { DisplayEventMonitoring, EventDurationType, EventMonitoring, EventsMonitoringDataLists, EventsMonitoringFilters, EventsMonitoringInfo } from "./events-monitoring-model.class";
import { AppSettingsService, EventsMonitoringSettingsData } from "../../services/app-settings.service";
import { DateTimeFormatType, Utils } from "../../utils/utils";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoginModel } from "../../user/login/model/login.model";
import { ManagerItemStatus, MonitoringSegmentType } from "../../base/models/manager-base-model.class";
import { Observable, Observer, forkJoin } from "rxjs";
import { Constants, IApiResponse, IIdValue, IRange } from "../../utils/globals";
import { HttpHelper } from "../../utils/http-helper";
import { DisplayProperties, ManagerConstants, MonitoringUtils } from "../../utils/monitoring-utils";
import { cloneDeep, isEqual } from "lodash";
import { DisplayShipmentMonitoring, ShipmentMonitoring } from "../../shipments-monitoring/shipment-monitoring/model/shipment-monitoring-model.class";
import { MonitoringDetailedEventType } from "../../base/models/monitoring-items-base-model.class";

@Injectable({ providedIn: 'root' })
export class EventsMonitoringModel extends MonitoringItemsBaseModel<DisplayEventMonitoring, EventsMonitoringFilters, EventsMonitoringDataLists,
    EventsMonitoringInfo>
{
    // #region Constants

    private readonly MINUTES_DURATION: number = 60 * 1000;
    private readonly HOURS_DURATION: number = 60 * this.MINUTES_DURATION;
    private readonly DAYS_DURATION: number = 24 * this.HOURS_DURATION;
    private readonly WEEKS_DURATION: number = 7 * this.DAYS_DURATION;
    private readonly MONTHS_DURATION: number = 30 * this.DAYS_DURATION;

    private readonly MAX_PREFIX: string = 'Max. ';
    private readonly MIN_PREFIX: string = 'Min. ';

    private readonly DEFAULT_DURATION_TYPE: EventDurationType = EventDurationType.Days;

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'events-monitoring';
    private readonly SHIPMENTS_MONITORING_CONTROLLER_NAME: string = 'shipments-monitoring';

    // #endregion

    // #region Private Members

    private readonly _durationTypesToDurationMap: Map<number, number> = new Map(
        [
            [EventDurationType.Minutes, this.MINUTES_DURATION],
            [EventDurationType.Hours, this.HOURS_DURATION],
            [EventDurationType.Days, this.DAYS_DURATION],
            [EventDurationType.Weeks, this.WEEKS_DURATION],
            [EventDurationType.Months, this.MONTHS_DURATION]
        ]);

    private readonly _monitoringItemsDetailedEventIdToEventIdMap: Map<number, number> = new Map(
        [
            [MonitoringDetailedEventType.HighTemperature, MonitoringDetailedEventType.HighTemperature],
            [MonitoringDetailedEventType.LowTemperature, MonitoringDetailedEventType.HighTemperature],
            [MonitoringDetailedEventType.HighHumidity, MonitoringDetailedEventType.HighHumidity],
            [MonitoringDetailedEventType.LowHumidity, MonitoringDetailedEventType.HighHumidity],
            [MonitoringDetailedEventType.StrongImpact, MonitoringDetailedEventType.StrongImpact],
            [MonitoringDetailedEventType.WeakImpact, MonitoringDetailedEventType.StrongImpact],
            [MonitoringDetailedEventType.TimeBasedHighHumidity, MonitoringDetailedEventType.TimeBasedHighHumidity],
            [MonitoringDetailedEventType.TimeBasedLowHumidity, MonitoringDetailedEventType.TimeBasedHighHumidity],
            [MonitoringDetailedEventType.TimeBasedHighTemperature, MonitoringDetailedEventType.TimeBasedHighTemperature],
            [MonitoringDetailedEventType.TimeBasedLowTemperature, MonitoringDetailedEventType.TimeBasedHighTemperature],
            [MonitoringDetailedEventType.Security, MonitoringDetailedEventType.Security],
            [MonitoringDetailedEventType.DoorOpen, MonitoringDetailedEventType.DoorOpen],
            [MonitoringDetailedEventType.LightOn, MonitoringDetailedEventType.LightOn],
            [MonitoringDetailedEventType.Stop, MonitoringDetailedEventType.Stop],
            [MonitoringDetailedEventType.TimeExceededAtPort, MonitoringDetailedEventType.TimeExceededAtPort]
        ]
    );

    private _eventDurationTypeFilter: EventDurationType = this.DEFAULT_DURATION_TYPE;
    private _eventMaxDurationFilter: number | null = null;
    private _eventDurationFilter: number | null = null;

    // #endregion

    // #region Properties

    public override get lastSearchMonitoringFilters(): EventsMonitoringFilters | null
    {
        return this._showCompletedMonitoringItems ?
            this._appSettingsService.appSettings.eventsMonitoringSettingsData.lastSearchCompletedEventsMonitoringFilters :
            this._appSettingsService.appSettings.eventsMonitoringSettingsData.lastSearchLiveEventsMonitoringFilters;
    }

    public override set lastSearchMonitoringFilters(value: EventsMonitoringFilters | null)
    {
        if (this._showCompletedMonitoringItems)
        {
            this._appSettingsService.appSettings.eventsMonitoringSettingsData.lastSearchCompletedEventsMonitoringFilters = value;
        }
        else
        {
            this._appSettingsService.appSettings.eventsMonitoringSettingsData.lastSearchLiveEventsMonitoringFilters = value;
        }
    }

    public get eventMaxDurationFilter(): number | null
    {
        return this._eventMaxDurationFilter;
    }

    public get eventDurationTypeFilter(): EventDurationType
    {
        return this._eventDurationTypeFilter;
    }

    public set eventDurationTypeFilter(value: EventDurationType)
    {
        if (this._eventDurationTypeFilter == value)
        {
            return;
        }

        this._eventDurationTypeFilter = value;

        if (this._eventDurationFilter !== null)
        {
            this._editMonitoringFilters.selectedEventMonitoringDurationData.duration = this._eventDurationFilter;
            this._editMonitoringFilters.selectedEventMonitoringDurationData.durationType = this._eventDurationTypeFilter;
        }

        this.updateMonitoringDataListsEventMaxDuration(this._filteredMonitoringItems);
    }

    public get eventDurationFilter(): number | null
    {
        return this._eventDurationFilter;
    }

    public set eventDurationFilter(value: number | null)
    {
        this._eventDurationFilter = value;

        this._editMonitoringFilters.selectedEventMonitoringDurationData.duration = this._eventDurationFilter;
        this._editMonitoringFilters.selectedEventMonitoringDurationData.durationType =
            this._editMonitoringFilters.selectedEventMonitoringDurationData.duration === null ? this.DEFAULT_DURATION_TYPE : this._eventDurationTypeFilter;
    }

    // #endregion

    // #region Contructors

    constructor(private _httpClient: HttpClient, private _appSettingsService: AppSettingsService, _loginModel: LoginModel)
    {
        super(_loginModel, EventsMonitoringFilters, EventsMonitoringDataLists, EventsMonitoringInfo);
    }

    // #endregion

    // #region Public Methods

    public override getMonitoringItemKey(monitoringItem: DisplayEventMonitoring): string | null
    {
        return monitoringItem.id;
    }

    public override getMonitoringItemLocation(monitoringItem: DisplayEventMonitoring): any
    {
        return { lat: monitoringItem.latitude, lng: monitoringItem.longitude };
    }

    public override findMonitoringItem(itemKey: string | null, monitoringItems: DisplayEventMonitoring[] | null = null): DisplayEventMonitoring | null
    {
        if (monitoringItems === null)
        {
            monitoringItems = this._filteredMonitoringItems;
        }

        if (itemKey === null)
        {
            return null;
        }

        for (const monitoringItem of monitoringItems)
        {
            if (monitoringItem.id === itemKey)
            {
                return monitoringItem;
            }
        }

        return null;
    }

    public updateEventFilter(shipmentMonitoringDetailedEventType: MonitoringDetailedEventType): void
    {
        this._editMonitoringFilters.selectedShipmentMonitoringEventTypes = [shipmentMonitoringDetailedEventType];
    }

    public override getMonitoringItems(): Observable<IApiResponse>
    {
        this._isBusy = true;
        this._isBackgroundFetchBusy = !this._showCompletedMonitoringItems;

        const completedEvents: EventMonitoring[] = [];
        const completedShipments: ShipmentMonitoring[] = [];

        const shipmentKeyToShipmentMap: Map<number, DisplayShipmentMonitoring> = new Map();

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const joinObservables =
            {
                events: this._httpClient.get<EventMonitoring[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/${this._isBackgroundFetchBusy ? 'live-events' : 'events'}`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                shipments: this._httpClient.get<ShipmentMonitoring[]>(`${this.SHIPMENTS_MONITORING_CONTROLLER_NAME}/${''
                }${ this._isBackgroundFetchBusy ? 'live-shipments' : 'shipments' }`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
            };

            forkJoin(joinObservables).subscribe(
                {
                    next: (join) =>
                    {
                        this.addShipmentsToMap([...join.shipments, ...completedShipments], shipmentKeyToShipmentMap);

                        this._monitoringItems = [];
                        this._completedMonitoringItems = [];
                        const events: EventMonitoring[] = [...join.events, ...completedEvents];

                        this._isBusy = false;
                        this.updateEventsResponse(observer, events, shipmentKeyToShipmentMap);

                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });

            if (this._isBackgroundFetchBusy)
            {
                const joinCompletedObservables =
                {
                    events: this._httpClient.get<EventMonitoring[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/completed-events`,
                        { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                    shipments: this._httpClient.get<ShipmentMonitoring[]>(`${this.SHIPMENTS_MONITORING_CONTROLLER_NAME}/completed-shipments`,
                        { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
                };

                forkJoin(joinCompletedObservables).subscribe(
                    {
                        next: (join) =>
                        {
                            this._isBackgroundFetchBusy = false;

                            if (this._isBusy)
                            {
                                completedEvents.push(...join.events);
                                completedShipments.push(...join.shipments);
                            }
                            else
                            {
                                this.addShipmentsToMap(join.shipments, shipmentKeyToShipmentMap);
                                this.updateEventsResponse(observer, join.events, shipmentKeyToShipmentMap);
                            }
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBackgroundFetchBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
            }
        });
    }

    public override clearMonitoringItemsFilters(): boolean
    {
        const isUpdated: boolean = super.clearMonitoringItemsFilters();

        this._eventDurationFilter = null;
        this.eventDurationTypeFilter = this.DEFAULT_DURATION_TYPE;

        this.updateMonitoringDataListsEventMaxDuration(this._currentMonitoringItems);

        return isUpdated;
    }

    public override loadLastSeachFilters(): void
    {
        super.loadLastSeachFilters();

        if (this._editMonitoringFilters.selectedEventMonitoringDurationData.duration !== null)
        {
            this._eventDurationFilter = this._editMonitoringFilters.selectedEventMonitoringDurationData.duration;
            this.eventDurationTypeFilter = this._editMonitoringFilters.selectedEventMonitoringDurationData.durationType;
        }
    }

    // #endregion

    // #region Protected Methods

    protected override updateMonitoringItemsByFilters(monitoringFilters: EventsMonitoringFilters | null = null): DisplayEventMonitoring[]
    {
        if (monitoringFilters === null)
        {
            monitoringFilters = this._monitoringFilters;
        }

        if (isEqual(monitoringFilters, new EventsMonitoringFilters()))
        {
            Utils.copyObjectByTargetProperties(this._showCompletedMonitoringItems ? this._completedMonitoringItemsSourceInfo :
                this._monitoringItemsSourceInfo, this._monitoringItemsInfo);

            this.updateMonitoringDataListsEventMaxDuration(this._currentMonitoringItems);

            return [...this._currentMonitoringItems];
        }
        else
        {
            this._monitoringItemsInfo = new EventsMonitoringInfo();

            const selectedShipmentMonitoringKeys: number[] = [...monitoringFilters!.selectedShipmentMonitoringKeys];

            let filteredMonitoringItems: DisplayEventMonitoring[] = this._currentMonitoringItems.filter((eventMonitoring: DisplayEventMonitoring) =>
            {
                return this.isMonitoringItemFilteredByPropertyValue(eventMonitoring.shipmentKey, selectedShipmentMonitoringKeys) &&
                    this.isMonitoringItemFilteredByPropertyValue(eventMonitoring.firstLevelSegmentId, monitoringFilters!.selectedShipmentMonitoringSegmentsTypes) &&
                    this.isMonitoringItemFilteredByPropertyValue(eventMonitoring.shipment.deviceId, monitoringFilters!.selectedShipmentMonitoringDevices) &&
                    this.isMonitoringItemFilteredByPropertyValue(eventMonitoring.shipment.containerId, monitoringFilters!.selectedShipmentMonitoringCargosUnits) &&
                    this.isMonitoringItemFilteredByPropertyValue(eventMonitoring.shipment.bookingReference, monitoringFilters!.selectedShipmentMonitoringBookingReferences) &&
                    this.isMonitoringItemFilteredByPropertyValue(eventMonitoring.shipment.originName, monitoringFilters!.selectedShipmentMonitoringOriginsRoutes) &&
                    this.isMonitoringItemFilteredByPropertyValue(eventMonitoring.shipment.destinationName, monitoringFilters!.selectedShipmentMonitoringDestinationsRoutes) &&
                    this.isEventMonitoringFilteredByEventType(eventMonitoring) &&
                    this.isEventMonitoringFilteredByDates(eventMonitoring);
            });

            this.updateMonitoringDataListsEventMaxDuration(filteredMonitoringItems);

            if (monitoringFilters.selectedEventMonitoringDurationData.duration !== null)
            {
                filteredMonitoringItems = filteredMonitoringItems.filter((eventMonitoring: DisplayEventMonitoring) =>
                {
                    return this._monitoringFilters.selectedEventMonitoringDurationData.duration === null ? true :
                        eventMonitoring.toDate!.getTime() - eventMonitoring.fromDate!.getTime() >=
                            this.getDurationByDurationType(this._monitoringFilters.selectedEventMonitoringDurationData.duration, true);
                });
            }

            this.updateMonitoringInfoGeneralData(this._monitoringItemsInfo, filteredMonitoringItems);

            return filteredMonitoringItems;
        }
    }

    protected override updateMonitoringDataLists(monitoringItems: DisplayEventMonitoring[]): EventsMonitoringDataLists
    {
        const monitoringDataLists: EventsMonitoringDataLists = new EventsMonitoringDataLists();
        const shipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();
        const eventMonitoring: DisplayEventMonitoring = new DisplayEventMonitoring();

        const shipmentPropertyName: string = Utils.getPropertyNameof<DisplayEventMonitoring>(eventMonitoring, eventMonitoring => eventMonitoring.shipment);

        monitoringDataLists.shipmentMonitoringKeys = this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringKeys)),
            `${shipmentPropertyName}.${Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.shipmentKey)}`);

        monitoringDataLists.shipmentMonitoringCargosUnits = this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringCargosUnits)),
            `${shipmentPropertyName}.${Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.containerId)}`);

        monitoringDataLists.shipmentMonitoringTrailersNumbers = this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringTrailersNumbers)),
            `${shipmentPropertyName}.${Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.trailerNumber)}`);

        monitoringDataLists.shipmentMonitoringDevices = this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringDevices)),
            `${shipmentPropertyName}.${Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.deviceId)}`);

        monitoringDataLists.shipmentMonitoringBookingReferences = this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringBookingReferences)),
            `${shipmentPropertyName}.${Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.bookingReference)}`);

        monitoringDataLists.shipmentMonitoringOriginsRoutes = this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringOriginsRoutes)),
            `${shipmentPropertyName}.${Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.originName)}`);

        monitoringDataLists.shipmentMonitoringDestinationsRoutes = this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringDestinationsRoutes)),
            `${shipmentPropertyName}.${Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.destinationName)}`);

        monitoringDataLists.monitoringItemsEvents = this.getMonitoringItemsEventDurationTypes(this.getMonitoringItemsPropertyList(
            this.getSelectiveMonitoringItemsByFilters(monitoringItems, Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters,
                monitoringFilters => monitoringFilters.selectedShipmentMonitoringEventTypes)),
            Utils.getPropertyNameof<DisplayEventMonitoring>(eventMonitoring, eventMonitoring => eventMonitoring.eventType)));

        monitoringDataLists.monitoringItemsSegments = this.getMonitoringItemsSegementTypes(this.getMonitoringItemsPropertyList(this.getSelectiveMonitoringItemsByFilters(monitoringItems,
            Utils.getPropertyNameof<EventsMonitoringFilters>(this._editMonitoringFilters, monitoringFilters => monitoringFilters.selectedShipmentMonitoringSegmentsTypes)),
            Utils.getPropertyNameof<DisplayEventMonitoring>(eventMonitoring, eventMonitoring => eventMonitoring.firstLevelSegmentId)));

        monitoringDataLists.monitoringItemsEventDatesRange = this.getMonitoringItemsEventDatesRange(monitoringItems);

        monitoringDataLists.monitoringItemsEventDurationTypes = this.getMonitoringItemsEventDurationTypesList(monitoringDataLists.monitoringItemsEventDatesRange);

        //this.updateMonitoringDataListsEventDurationRange(monitoringItems);

        this.sortMonitoringDataLists(monitoringDataLists,
            [Utils.getPropertyNameof<EventsMonitoringDataLists>(monitoringDataLists, monitoringDataLists => monitoringDataLists.monitoringItemsEventDurationTypes)]);

        return monitoringDataLists;
    }

    protected override updateMonitoringInfoGeneralData(monitoringItemsInfo: EventsMonitoringInfo, monitoringItems: DisplayEventMonitoring[]): void
    {
        monitoringItemsInfo.count = monitoringItems.length;

        const uniqueSet = new Set();
        for (const monitoringItem of monitoringItems)
        {
            if (!uniqueSet.has(monitoringItem.shipmentKey))
            {
                uniqueSet.add(monitoringItem.shipmentKey);
            }
        }

        monitoringItemsInfo.shipmentsCount = uniqueSet.size;
    }

    protected override updateMonitoringItemsInfo(monitoringItems: DisplayEventMonitoring[]): EventsMonitoringInfo
    {
        const monitoringItemsInfo: EventsMonitoringInfo = new EventsMonitoringInfo();

        this.updateMonitoringInfoGeneralData(monitoringItemsInfo, monitoringItems);

        return monitoringItemsInfo;
    }

    // #endregion

    // #region Private Methods

    private addShipmentsToMap(shipments: ShipmentMonitoring[], shipmentKeyToShipmentMap: Map<number, DisplayShipmentMonitoring>): void
    {
        for (const shipmentMonitoring of shipments)
        {
            if (shipmentMonitoring.shipmentKey === null)
            {
                continue;
            }

            const displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();
            Utils.copyObjectByTargetProperties(shipmentMonitoring, displayShipmentMonitoring);
            MonitoringUtils.updateDisplayShipmentMonitoringProperties(displayShipmentMonitoring, this._loginModel.userInfo.isDemo);
            shipmentKeyToShipmentMap.set(shipmentMonitoring.shipmentKey, displayShipmentMonitoring);
        }
    }

    private updateEventsResponse(observer: Observer<IApiResponse>, events: EventMonitoring[], shipmentKeyToShipmentMap: Map<number, DisplayShipmentMonitoring>): void
    {
        for (const eventMonitoring of events)
        {
            if (eventMonitoring.shipmentKey === null)
            {
                continue;
            }

            const displayEventMonitoring: DisplayEventMonitoring = new DisplayEventMonitoring();
            Utils.copyObjectByTargetProperties(eventMonitoring, displayEventMonitoring);
            displayEventMonitoring.eventType = this._monitoringItemsDetailedEventIdToEventIdMap.get(displayEventMonitoring.eventId)!;

            const shipemnt: DisplayShipmentMonitoring | undefined = shipmentKeyToShipmentMap.get(eventMonitoring.shipmentKey);
            if (shipemnt === undefined)
            {
                continue;
            }

            displayEventMonitoring.shipment = shipemnt;

            this.updateEventMonitoringProperties(displayEventMonitoring);

            if (shipemnt.statusId === ManagerItemStatus.Live)
            {
                this._monitoringItems.push(displayEventMonitoring);
            }
            else
            {
                this._completedMonitoringItems.push(displayEventMonitoring);
            }
        }

        this._allMonitoringItems = [...this._monitoringItems, ...this._completedMonitoringItems];
        this._currentMonitoringItems = [...(this._showCompletedMonitoringItems ? this._completedMonitoringItems : this._monitoringItems)];

        this._monitoringItemsSourceInfo = this.updateMonitoringItemsInfo(this._monitoringItems);
        this._completedMonitoringItemsSourceInfo = this.updateMonitoringItemsInfo(this._completedMonitoringItems);

        this._monitoringDataLists = this.updateMonitoringDataLists(this._currentMonitoringItems);
        this._filteredMonitoringItems = this.updateMonitoringItemsByFilters();

        this.updateLastSearchMonitoringFilters(Utils.getPropertyNameof<EventsMonitoringSettingsData>(
            this._appSettingsService.appSettings.eventsMonitoringSettingsData,
            eventsMonitoringSettingsData => eventsMonitoringSettingsData.lastSearchLiveEventsMonitoringFilters));

        this.updateLastSearchMonitoringFilters(Utils.getPropertyNameof<EventsMonitoringSettingsData>(
            this._appSettingsService.appSettings.eventsMonitoringSettingsData,
            eventsMonitoringSettingsData => eventsMonitoringSettingsData.lastSearchCompletedEventsMonitoringFilters));

        this.updateMonitoringDataListsByFilters();

        observer.next({ isSuccess: true, isComplete: true });

        if (!this._isBackgroundFetchBusy && !this._isBusy)
        {
            observer.complete();
        }
    }

    private updateMonitoringDataListsEventMaxDuration(monitoringItems: DisplayEventMonitoring[]): void
    {
        let monitoringItemsEventMaxDuration: number | null = null;

        for (const monitoringItem of monitoringItems)
        {
            if (monitoringItem.duration !== null)
            {
                if (monitoringItemsEventMaxDuration === null || monitoringItemsEventMaxDuration < monitoringItem.duration)
                {
                    monitoringItemsEventMaxDuration = monitoringItem.duration;
                }
            }
        }

        if (monitoringItemsEventMaxDuration !== null)
        {
            this._eventMaxDurationFilter = Math.floor(this.getDurationByDurationType(monitoringItemsEventMaxDuration, false));
        }
    }

    private getMonitoringItemsSegementTypes(shipmentMonitoringSegementTypes: MonitoringSegmentType[]): IIdValue[]
    {
        const monitoringItemsSegements: IIdValue[] = [];
        for (const shipmentMonitoringSegementType of shipmentMonitoringSegementTypes)
        {
            const segement: IIdValue =
            {
                id: shipmentMonitoringSegementType,
                value: Utils.getEnumParsedDescription(MonitoringSegmentType, shipmentMonitoringSegementType)
            };

            if (!monitoringItemsSegements.includes(segement))
            {
                monitoringItemsSegements.push(segement);
            }
        }

        return monitoringItemsSegements;
    }

    private getMonitoringItemsEventDurationTypes(shipmentMonitoringDetailedEventTypes: MonitoringDetailedEventType[]): IIdValue[]
    {
        const monitoringItemsEvents: IIdValue[] = [];
        for (const shipmentMonitoringDetailedEventType of shipmentMonitoringDetailedEventTypes)
        {
            const detailedEvent: IIdValue =
            {
                id: shipmentMonitoringDetailedEventType,
                value: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[shipmentMonitoringDetailedEventType].tooltip
            };

            if (!monitoringItemsEvents.includes(detailedEvent))
            {
                monitoringItemsEvents.push(detailedEvent);
            }
        }

        return monitoringItemsEvents;
    }

    private updateEventMonitoringProperties(displayEventMonitoring: DisplayEventMonitoring): void
    {
        displayEventMonitoring.containerId = this._loginModel.isAccountTypeAmazonUS ? displayEventMonitoring.shipment.trailerNumber :
            displayEventMonitoring.shipment.containerId;

        displayEventMonitoring.routeDescription = displayEventMonitoring.shipment.routeDescription;

        displayEventMonitoring.location = { lat: displayEventMonitoring.latitude, lng: displayEventMonitoring.longitude };

        displayEventMonitoring.perimeterIconClass = MonitoringUtils.getShipmentIconClassName(displayEventMonitoring.firstLevelSegmentId,
            displayEventMonitoring.perimeterType);

        if (displayEventMonitoring.eventLocation === null)
        {
            displayEventMonitoring.eventLocation = MonitoringUtils.getShipmentPerimeterName(displayEventMonitoring.firstLevelSegmentId, null);
        }

        displayEventMonitoring.fromDateFormatted = displayEventMonitoring.fromDate !== null ?
            Utils.getFormattedDateTime(new Date(displayEventMonitoring.fromDate), DateTimeFormatType.DateTime) : ManagerConstants.MISSING_VALUE;

        if (displayEventMonitoring.fromDate !== null)
        {
            displayEventMonitoring.duration = (displayEventMonitoring.toDate ?? new Date()).getTime() - displayEventMonitoring.fromDate.getTime();
            displayEventMonitoring.durationFormatted = Utils.formatDuration(displayEventMonitoring.fromDate, displayEventMonitoring.toDate ?? new Date());
        }

        const displayProperties: DisplayProperties = MonitoringUtils.getUpdatedEventDisplayPropertiesByDeviceType(displayEventMonitoring.eventType,
            displayEventMonitoring.shipment.deviceType);

        displayEventMonitoring.units = displayProperties.units!;
        displayEventMonitoring.valueTitle = displayProperties.tooltip;

        switch (displayEventMonitoring.eventType)
        {
            case MonitoringDetailedEventType.HighTemperature:
                {
                    displayEventMonitoring.minThreshold = displayEventMonitoring.shipment.minTemperatureThreshold;
                    displayEventMonitoring.maxThreshold = displayEventMonitoring.shipment.maxTemperatureThreshold;
                    displayEventMonitoring.value = displayEventMonitoring.eventId === MonitoringDetailedEventType.HighTemperature ?
                        displayEventMonitoring.maxTemperature : displayEventMonitoring.minTemperature;
                    displayEventMonitoring.valueTitle =
                        `${displayEventMonitoring.eventId === MonitoringDetailedEventType.HighTemperature ? this.MAX_PREFIX : this.MIN_PREFIX}${''
                        }${MonitoringUtils.TEMPERATURE_EVENT_TITLE}`;
                }
                break;

            case MonitoringDetailedEventType.TimeBasedHighTemperature:
                {
                    displayEventMonitoring.timeBasedMinThreshold = displayEventMonitoring.shipment.tbMinTemperatureThreshold;
                    displayEventMonitoring.timeBasedMaxThreshold = displayEventMonitoring.shipment.tbMaxTemperatureThreshold;
                    displayEventMonitoring.value = displayEventMonitoring.eventId === MonitoringDetailedEventType.TimeBasedHighTemperature ?
                        displayEventMonitoring.maxTemperature : displayEventMonitoring.minTemperature;
                    displayEventMonitoring.valueTitle =
                        `${displayEventMonitoring.eventId === MonitoringDetailedEventType.TimeBasedHighTemperature ? this.MAX_PREFIX : this.MIN_PREFIX}${''
                        }${MonitoringUtils.TEMPERATURE_EVENT_TITLE}`;
                }
                break;

            case MonitoringDetailedEventType.HighHumidity:
                {
                    displayEventMonitoring.minThreshold = displayEventMonitoring.shipment.minHumidityThreshold;
                    displayEventMonitoring.maxThreshold = displayEventMonitoring.shipment.maxHumidityThreshold;
                    displayEventMonitoring.value = displayEventMonitoring.eventId === MonitoringDetailedEventType.HighHumidity ?
                        displayEventMonitoring.maxHumidity : displayEventMonitoring.minHumidity;
                    displayEventMonitoring.valueTitle =
                        `${displayEventMonitoring.eventId === MonitoringDetailedEventType.HighHumidity ? this.MAX_PREFIX : this.MIN_PREFIX}${''
                        }${MonitoringUtils.HUMIDITY_EVENT_TITLE}`;
                }
                break;

            case MonitoringDetailedEventType.TimeBasedHighHumidity:
                {
                    displayEventMonitoring.timeBasedMinThreshold = displayEventMonitoring.shipment.tbMinHumidityThreshold;
                    displayEventMonitoring.timeBasedMaxThreshold = displayEventMonitoring.shipment.tbMaxHumidityThreshold;
                    displayEventMonitoring.value = displayEventMonitoring.eventId === MonitoringDetailedEventType.TimeBasedHighHumidity ?
                        displayEventMonitoring.maxHumidity : displayEventMonitoring.minHumidity;
                    displayEventMonitoring.valueTitle =
                        `${displayEventMonitoring.eventId === MonitoringDetailedEventType.TimeBasedHighHumidity ? this.MAX_PREFIX : this.MIN_PREFIX}${''
                        } ${MonitoringUtils.HUMIDITY_EVENT_TITLE}`;
                }
                break;

            case MonitoringDetailedEventType.Security:
                {
                    if (displayEventMonitoring.units !== MonitoringUtils.LIGHT_UNITS)
                    {
                        displayEventMonitoring.value = displayEventMonitoring.maxLight;
                        displayEventMonitoring.valueTitle = `${this.MAX_PREFIX}${displayEventMonitoring.valueTitle}`;
                    }
                }
                break;
        }
    }

    private getMonitoringItemsEventDurationTypesList(eventsDatesRange: IRange<(Date | null)>): IIdValue[]
    {
        if (eventsDatesRange.to === null || eventsDatesRange.from === null)
        {
            return [];
        }

        const monitoringItemsEventDurationTypes: IIdValue[] = [];

        const eventsMaxDuration: number = eventsDatesRange.to!.getTime() - eventsDatesRange.from!.getTime();

        for (const durationType of (Object.values(EventDurationType).filter((value: any) => typeof (value) === 'number') as number[]))
        {
            if (eventsMaxDuration < this._durationTypesToDurationMap.get(durationType)!)
            {
                break;
            }

            monitoringItemsEventDurationTypes.push({ id: durationType, value: EventDurationType[durationType] });
        }

        return monitoringItemsEventDurationTypes;
    }

    private getMonitoringItemsEventDatesRange(monitoringItems: DisplayEventMonitoring[]): IRange<(Date | null)>
    {
        const datesRange: IRange<(Date | null)> = { from: null, to: null };

        for (const eventMonitoring of monitoringItems)
        {
            if (eventMonitoring.fromDate !== null && (datesRange.from === null ||
                eventMonitoring.fromDate.getTime() < datesRange.from.getTime()))
            {
                datesRange.from = eventMonitoring.fromDate;
            }

            if (eventMonitoring.toDate !== null && (datesRange.to === null ||
                eventMonitoring.toDate.getTime() > datesRange.to.getTime()))
            {
                datesRange.to = eventMonitoring.toDate;
            }
        }

        return datesRange;
    }

    private getDurationByDurationType(duration: number, isReverse: boolean): number
    {
        const typeDurationValue: number = this._durationTypesToDurationMap.get(this._eventDurationTypeFilter)!;
        return isReverse ? duration * typeDurationValue : duration / typeDurationValue;
    }

    private isEventMonitoringFilteredByDates(eventMonitoring: DisplayEventMonitoring): boolean
    {
        let isFiltered: boolean = true;

        if (this._monitoringFilters.selectedEventMonitoringDatesRange.from !== null)
        {
            isFiltered = eventMonitoring.fromDate!.getTime() >= this._monitoringFilters.selectedEventMonitoringDatesRange.from.getTime() ||
                eventMonitoring.toDate!.getTime() >= this._monitoringFilters.selectedEventMonitoringDatesRange.from.getTime();
        }

        if (isFiltered && this._monitoringFilters.selectedEventMonitoringDatesRange.to !== null)
        {
            isFiltered = eventMonitoring.fromDate!.getTime() <= this._monitoringFilters.selectedEventMonitoringDatesRange.to.getTime() ||
                eventMonitoring.toDate!.getTime() <= this._monitoringFilters.selectedEventMonitoringDatesRange.to.getTime();
        }

        return isFiltered;
    }

    private isEventMonitoringFilteredByEventType(eventMonitoring: DisplayEventMonitoring): boolean
    {
        let isFiltered: boolean = false;

        if (this._monitoringFilters.selectedShipmentMonitoringEventTypes === null || this._monitoringFilters.selectedShipmentMonitoringEventTypes.length === 0)
        {
            isFiltered = true;
        }
        else
        {
            for (const selectedShipmentMonitoringEventType of this._monitoringFilters.selectedShipmentMonitoringEventTypes)
            {
                if (eventMonitoring.eventType === selectedShipmentMonitoringEventType)
                {
                    isFiltered = true;
                    break;
                }
            }
        }

        return isFiltered;
    }

    private updateLastSearchMonitoringFilters(lastSearchFiltersPropertyName: string): void
    {
        if ((this._appSettingsService.appSettings.eventsMonitoringSettingsData as any)[lastSearchFiltersPropertyName] === null)
        {
            return;
        }

        const lastSearchEventsMonitoringFilters: EventsMonitoringFilters =
            cloneDeep((this._appSettingsService.appSettings.eventsMonitoringSettingsData as any)[lastSearchFiltersPropertyName]);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringKeys =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringKeys,
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringKeys);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringCargosUnits =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringCargosUnits,
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringCargosUnits);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringTrailersNumbers =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringTrailersNumbers,
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringTrailersNumbers);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringDevices =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringDevices,
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringDevices);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringBookingReferences =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringBookingReferences,
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringBookingReferences);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringOriginsRoutes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringOriginsRoutes,
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringOriginsRoutes);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringDestinationsRoutes,
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringSegmentsTypes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.monitoringItemsSegments.map((segmentType: IIdValue) => segmentType.id),
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringSegmentsTypes);

        lastSearchEventsMonitoringFilters.selectedShipmentMonitoringEventTypes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.monitoringItemsEvents.map((eventType: IIdValue) => eventType.id),
                lastSearchEventsMonitoringFilters.selectedShipmentMonitoringEventTypes);

        Utils.convertDates(lastSearchEventsMonitoringFilters.selectedEventMonitoringDatesRange);

        if ((lastSearchEventsMonitoringFilters.selectedEventMonitoringDatesRange.from !== null &&
            (this._monitoringDataLists.monitoringItemsEventDatesRange.from === null ||
                this._monitoringDataLists.monitoringItemsEventDatesRange.from.getTime() >
                lastSearchEventsMonitoringFilters.selectedEventMonitoringDatesRange.from.getTime())) || 
            (lastSearchEventsMonitoringFilters.selectedEventMonitoringDatesRange.to !== null &&
                (this._monitoringDataLists.monitoringItemsEventDatesRange.to === null ||
                    this._monitoringDataLists.monitoringItemsEventDatesRange.to.getTime() <
                    lastSearchEventsMonitoringFilters.selectedEventMonitoringDatesRange.to.getTime())))
        {
            lastSearchEventsMonitoringFilters.selectedEventMonitoringDatesRange = { from: null, to: null };
        }

        if (!this._monitoringDataLists.monitoringItemsEventDurationTypes.map((durationType: IIdValue) => durationType.id).includes(
            lastSearchEventsMonitoringFilters.selectedEventMonitoringDurationData.durationType) ||
            lastSearchEventsMonitoringFilters.selectedEventMonitoringDurationData.duration !== null && (this._eventMaxDurationFilter === null ||
                Math.floor(this.getDurationByDurationType(lastSearchEventsMonitoringFilters.selectedEventMonitoringDurationData.duration, false)) >
                this._eventMaxDurationFilter))
        {
            lastSearchEventsMonitoringFilters.selectedEventMonitoringDurationData.duration = null;
            lastSearchEventsMonitoringFilters.selectedEventMonitoringDurationData.durationType = this._eventDurationTypeFilter;
        }

        if (!isEqual(lastSearchEventsMonitoringFilters, (this._appSettingsService.appSettings.eventsMonitoringSettingsData as any)[lastSearchFiltersPropertyName]))
        {
            (this._appSettingsService.appSettings.eventsMonitoringSettingsData as any)[lastSearchFiltersPropertyName] = lastSearchEventsMonitoringFilters;
        }

        this.updateEmptyLastSearchFilters();
    }
}