import { Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";
import { Constants } from "../utils/globals";

@Directive({
    selector: '[zoomGesture]',
    standalone: true
})
export class ZoomGestureDirective
{
    // #region Constants

    private readonly PINCH_ZOOM_MULTIPLIER: number = 1 / 200;
    private readonly DARG_MOVE_MULTIPLIER: number = Constants.IS_MOBILE ? 0.05 : 0.03;
    private readonly ZOOM_TRANSITION: string = 'transform 0.5s ease-in-out';

    private readonly _zoomSteps: number[] = Constants.IS_MOBILE ? [1, 3, 6] : [1, 2, 4];

    // #endregion

    // #region Private Memebers

    private _zoomLevel: number = 0;
    private _translateX: number = 0;
    private _translateY: number = 0;
    private _isZoomGestureActive: boolean = false;
    private _isStartMouseDragging: boolean = false;
    private _draggingStartX: number = 0;
    private _draggingStartY: number = 0;

    private _pointerEventsCache: PointerEvent[] = [];
    private _previousPointersDiff: number = -1;

    private _pinchZoomScale: number = 1;

    // #endregion

    // #region Properties

    @Input()
    get isZoomGestureActive()
    {
        return this._isZoomGestureActive;
    }
    set isZoomGestureActive(value: boolean)
    {
        this._isZoomGestureActive = value;

        if (!this._isZoomGestureActive)
        {
            this._renderer.setStyle(this._elementRef.nativeElement, 'transition', this.ZOOM_TRANSITION);

            this.setZoomLevel(0);
            this.resetPosition();

            this._pointerEventsCache = [];
        }
    }

    // #region Constructors

    constructor(private _elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2)
    {
        this.updateZoomCursor();
    }

    // #endregion

    // #region Event Handlers

    @HostListener('pointerdown', ['$event']) onPointerDown(event: PointerEvent): void
    {
        this._pointerEventsCache.push(event);

        this._draggingStartX = event.clientX;
        this._draggingStartY = event.clientY;

        this._renderer.setStyle(this._elementRef.nativeElement, 'transition', Constants.IS_MOBILE ? 'none' : this.ZOOM_TRANSITION);

        if (event.button === 0 && event.pointerType === 'mouse')
        {
            this._isStartMouseDragging = false;

            this.updateZoomCursor();
        }
    }

    @HostListener('pointercancel', ['$event'])
    @HostListener('pointerout', ['$event'])
    @HostListener('pointerleave', ['$event'])
    @HostListener('pointerup', ['$event']) onPointerUp(event: PointerEvent): void
    {
        if (event.button === 0 && event.pointerType === 'mouse')
        {
            if (this._pointerEventsCache.length > 0)
            {
                if (!this._isStartMouseDragging)
                {
                    this._renderer.setStyle(this._elementRef.nativeElement.parentElement, 'transition', this.ZOOM_TRANSITION);

                    this.setZoomLevel(++this._zoomLevel % this._zoomSteps.length);

                    this._translateX = 0;
                    this._translateY = 0;
                    this.updatePositionStyle();
                }
                else
                {
                    this._isStartMouseDragging = false;
                    this.updateZoomCursor();
                }

                this._pointerEventsCache = [];
                return;
            }
        }

        this._pointerEventsCache = [];
        this._previousPointersDiff = -1;
    }

    @HostListener('pointermove', ['$event']) onPointerMove(event: PointerEvent): void
    {
        for (let i: number = 0; i < this._pointerEventsCache.length; i++)
        {
            if (event.pointerId == this._pointerEventsCache[i].pointerId)
            {
                this._pointerEventsCache[i] = event;
                break;
            }
        }

        if (this._pointerEventsCache.length == 2)
        {
            const currentPointersDiff = Math.sqrt(Math.pow(this._pointerEventsCache[1].clientX - this._pointerEventsCache[0].clientX, 2) +
                Math.pow(this._pointerEventsCache[1].clientY - this._pointerEventsCache[0].clientY, 2));

            if (this._previousPointersDiff > 0)
            {
                this._pinchZoomScale += (currentPointersDiff - this._previousPointersDiff) * this.PINCH_ZOOM_MULTIPLIER;
                this._pinchZoomScale = Math.min(this._zoomSteps[this._zoomSteps.length - 1], Math.max(1, this._pinchZoomScale));

                this.updatePinchZoomStyle();
            }

            this._previousPointersDiff = currentPointersDiff;
            return;
        }
        else if (this._pointerEventsCache.length !== 1)
        {
            return;
        }

        if (event.pointerType === 'mouse')
        {
            if (this._zoomLevel > 0)
            {
                if (!this._isStartMouseDragging)
                {
                    this._isStartMouseDragging = true;
                    this.updateZoomCursor();
                }

                this.updateMovePosition(event.clientX, event.clientY);
            }
        }
        else if (this._pinchZoomScale > 1)
        {
            this.updateMovePosition(event.clientX, event.clientY);
        }
    }

    // #endregion

    // #region Private Methods

    private resetPosition(): void
    {
        this._translateX = 0;
        this._translateY = 0;
        this.updatePositionStyle();

        this._pinchZoomScale = 1;
    }

    private updateMovePosition(clientX: number, clientY: number): void
    {
        if (this._elementRef.nativeElement.parentElement === null)
        {
            return;
        }

        if (this._zoomLevel === 0 && this._pinchZoomScale === 0)
        {
            this.resetPosition();
            return;
        }

        const parentClientRect: DOMRect = this._elementRef.nativeElement.parentElement.getBoundingClientRect();
        const clientRect: DOMRect = this._elementRef.nativeElement.getBoundingClientRect();

        this._translateX += this._elementRef.nativeElement.parentElement.scrollWidth === this._elementRef.nativeElement.parentElement.offsetWidth ? 0 :
            (this._draggingStartX - clientX) * this.DARG_MOVE_MULTIPLIER * (clientRect.width - parentClientRect.width) / clientRect.width;

        const offsetX: number = (this._elementRef.nativeElement.parentElement.scrollWidth - this._elementRef.nativeElement.parentElement.offsetWidth);
        this._translateX = Math.min(offsetX, Math.max(-offsetX, this._translateX));

        this._translateY += this._elementRef.nativeElement.parentElement.scrollHeight === this._elementRef.nativeElement.parentElement.offsetHeight ? 0 :
            (this._draggingStartY - clientY) * this.DARG_MOVE_MULTIPLIER * (clientRect.height - parentClientRect.height) / clientRect.height;

        const offsetY: number = (this._elementRef.nativeElement.parentElement.scrollHeight - this._elementRef.nativeElement.parentElement.offsetHeight);
        this._translateY = Math.min(offsetY, Math.max(-offsetY, this._translateY));

        this.updatePositionStyle();
    }

    private updateZoomCursor(): void
    {
        this._renderer.setStyle(this._elementRef.nativeElement, 'cursor',
            this._isStartMouseDragging && this._zoomLevel > 0 ? 'grab' : (this._zoomLevel < this._zoomSteps.length - 1 ? 'zoom-in' : 'zoom-out'));
    }

    private updatePositionStyle(): void
    {
        if (this._elementRef.nativeElement.parentElement !== null)
        {
            this._renderer.setStyle(this._elementRef.nativeElement.parentElement, 'transform', `translate(${-this._translateX}px, ${-this._translateY}px)`);
        }
    }

    private updatePinchZoomStyle(): void
    {
        this._renderer.setStyle(this._elementRef.nativeElement, 'transform', `scale(${this._pinchZoomScale})`);
    }

    private updateZoomStepStyle(): void
    {
        this._renderer.setStyle(this._elementRef.nativeElement, 'transform', `scale(${this._zoomSteps[this._zoomLevel]})`);
    }

    private setZoomLevel(zoomLevel: number): void
    {
        this._zoomLevel = zoomLevel;

        this.updateZoomStepStyle();
        this.updateZoomCursor();
    }

    // #endregion
}