import { NgZone, Renderer2 } from "@angular/core";
import { AnimationsConstants } from "../../animations/constant";
import { Constants } from "../../utils/globals";
import { Subscription } from "rxjs";
import { EasingType, Utils } from "../../utils/utils";

export enum ScrollbarType { Vertical, Horizontal }

export class OverlayScrollbar
{
    // #region Constants

    private readonly OVERLAY_SCROLLBAR_CLASSNAME: string = 'overlay-scrollbar';
    private readonly OVERLAY_SCROLLBAR_THUMB_CLASSNAME: string = 'overlay-scrollbar-thumb';
    private readonly VERTICAL_CLASSNAME: string = 'vertical';
    private readonly HORIZONTAL_CLASSNAME: string = 'horizontal';
    private readonly SCROLLBAR_THROTTLE_INTERVAL: number = AnimationsConstants.ANIMATION_DURATION;
    private readonly MIN_SCROLLBAR_THUMB_SIZE: number = 20;

    // #endregion

    // #region Private Members

    private _renderer: Renderer2;
    private _ngZone: NgZone;
    private _scrollbarType: ScrollbarType;

    private _scrollContainerElement: HTMLElement;
    private _scrollbarsRootElement: HTMLElement;
    private _scrollbarElement: HTMLElement;
    private _scrollbarThumbElement: HTMLElement;

    private _scrollbarThrottleIntervalHandle: NodeJS.Timeout | null = null;
    private _scrollThrottleDirection: number = 0;

    private _startScrollbarThumbDragPosition: number | null = null;
    private _startDragSrollValue: number | null = null;

    private _disposeScrollbarMouseLeaveHandler: (() => void) | null = null;
    private _disposeWindowScrollbarMouseUpHandler: (() => void) | null = null;
    private _disposeScrollbarThumbMouseEnterHandler: (() => void) | null = null;
    private _disposeScrollbarThumbMouseDownHandler: (() => void) | null = null;
    private _disposeScrollbarMouseDownHandler: (() => void) | null = null;
    private _disposeWindowScrollbarThumbMouseMoveHandler: (() => void) | null = null;
    private _disposeWindowScrollbarThumbMouseUpHandler: (() => void) | null = null;

    private _smoothScrollToValueSubscription: Subscription | null = null;

    private _scrollContentSize: number = -1;
    private _scrollElementSize: number = -1;

    private _isRtl: boolean = document.dir === 'rtl';

    // #endregion

    // #region Properties

    private get scrollbarSizePropertyName(): string
    {
        return this._scrollbarType === ScrollbarType.Vertical ? 'clientHeight' : 'clientWidth';
    }

    private get scrollbarContentSizePropertyName(): string
    {
        return this._scrollbarType === ScrollbarType.Vertical ? 'scrollHeight' : 'scrollWidth';
    }

    private get scrollbarTranslateStylePropertyName(): string
    {
        return this._scrollbarType === ScrollbarType.Vertical ? 'translateY' : 'translateX';
    }

    private get scrollbarStylePropertyName(): string
    {
        return this._scrollbarType === ScrollbarType.Vertical ? 'height' : 'width';
    }

    private get scrollbarValue(): number
    {
        return (this._scrollContainerElement as any)[this.getScrollbarValuePropertyName(this._scrollbarType)];
    }

    private set scrollbarValue(value: number)
    {
        if (Math.abs(this.scrollbarValue - value) >= 1)
        {
            (this._scrollContainerElement as any)[this.getScrollbarValuePropertyName(this._scrollbarType)] = value;

            this.updateScrollbarPosition();
        }
    }

    private get scrollbarSize(): number
    {
        return (this._scrollbarElement as any)[this.scrollbarSizePropertyName];
    }

    private get scrollbarThumbSize(): number
    {
        return (this._scrollbarThumbElement as any)[this.scrollbarSizePropertyName];
    }

    // #endregion

    // #region Constructors

    constructor(scrollContainerElement: HTMLElement, scrollbarsRootElement: HTMLElement, scrollbarType:
        ScrollbarType, renderer: Renderer2, ngZone: NgZone)
    {
        this._scrollContainerElement = scrollContainerElement;
        this._scrollbarsRootElement = scrollbarsRootElement;
        this._scrollbarType = scrollbarType;
        this._renderer = renderer;
        this._ngZone = ngZone;

        this._scrollbarElement = this._renderer.createElement('div');

        this._scrollbarElement.className = `${this.OVERLAY_SCROLLBAR_CLASSNAME} 
            ${scrollbarType === ScrollbarType.Vertical ? this.VERTICAL_CLASSNAME : this.HORIZONTAL_CLASSNAME}`;

        this._scrollbarElement.tabIndex = -1;

        this._scrollbarThumbElement = this._renderer.createElement('div');

        this._scrollbarThumbElement.className = this.OVERLAY_SCROLLBAR_THUMB_CLASSNAME;
        this._scrollbarThumbElement.tabIndex = -1;

        this._scrollbarElement.appendChild(this._scrollbarThumbElement);
        this._scrollbarsRootElement.appendChild(this._scrollbarElement);

        this._ngZone.runOutsideAngular(() =>
        {
            this._disposeScrollbarThumbMouseDownHandler = this._renderer.listen(this._scrollbarThumbElement, 'mousedown', this.onScrollbarThumbMouseDown.bind(this));
            this._disposeScrollbarMouseDownHandler = this._renderer.listen(this._scrollbarElement, 'mousedown', this.onScrollbarMouseDown.bind(this));
        });
    }

    // #endregion

    // #region Event Handlers

    private onScrollbarMouseDown(event: MouseEvent): void
    {
        if (event.button === 0)
        {
            this._disposeScrollbarMouseLeaveHandler = this._renderer.listen(this._scrollbarElement, 'mouseleave', this.onScrollbarMouseLeave.bind(this));
            this._disposeWindowScrollbarMouseUpHandler = this._renderer.listen('window', 'mouseup', this.onScrollbarMouseLeave.bind(this));
            this._disposeScrollbarThumbMouseEnterHandler = this._renderer.listen(this._scrollbarThumbElement, 'mouseenter', this.onScrollbarMouseLeave.bind(this));

            const rect: DOMRect = this._scrollbarThumbElement.getBoundingClientRect();

            this._scrollThrottleDirection = (this._scrollbarType === ScrollbarType.Vertical ? event.clientY > rect.y : event.clientX > rect.x) ? 1 : -1;

            if (this.setScrollbarThrottle(this._scrollThrottleDirection, EasingType.InOut))
            {
                this._scrollbarThrottleIntervalHandle = setInterval(() =>
                    this.setScrollbarThrottle(this._scrollThrottleDirection, EasingType.Linear), this.SCROLLBAR_THROTTLE_INTERVAL);
            }
        }
    }

    private onScrollbarMouseLeave(): void
    {
        this.clearScrollbarThrottleInterval();
        this.removeScrollbarMouseListeners();
    }

    private onScrollbarThumbMouseDown(event: MouseEvent): void
    {
        if (event.button === 0)
        {
            event.stopImmediatePropagation();

            this._disposeWindowScrollbarThumbMouseMoveHandler = this._renderer.listen('window', 'mousemove', this.onWindowScrollbarMouseMove.bind(this));
            this._disposeWindowScrollbarThumbMouseUpHandler = this._renderer.listen('window', 'mouseup', this.onWindowScrollbarMouseUp.bind(this));

            this._startScrollbarThumbDragPosition = this._scrollbarType === ScrollbarType.Vertical ? event.pageY : event.pageX;
            this._startDragSrollValue = this.scrollbarValue;
        }
    }

    private onWindowScrollbarMouseMove(event: MouseEvent): void
    {
        if (this._startScrollbarThumbDragPosition !== null && this._startDragSrollValue !== null)
        {
            const deltaValue: number = ((this._scrollbarType === ScrollbarType.Vertical ? event.pageY : event.pageX) - this._startScrollbarThumbDragPosition) *
                (this._scrollContentSize - this._scrollElementSize) / (this.scrollbarSize - this.scrollbarThumbSize);

            this.scrollbarValue = this.validateScrollValue(this._startDragSrollValue + deltaValue);

            event.preventDefault();
        }
    }

    private onWindowScrollbarMouseUp(): void
    {
        this._startScrollbarThumbDragPosition = null;
        this._startDragSrollValue = null;

        this.removeWindowScrollbarEventListeners();
    }

    // #endregion

    // #region Public Methods

    public clear(): void
    {
        this.clearSmoothScrollToValueSubscription();
        this.clearScrollbarThrottleInterval();

        if (this._disposeScrollbarThumbMouseDownHandler !== null)
        {
            this._disposeScrollbarThumbMouseDownHandler();
            this._disposeScrollbarThumbMouseDownHandler = null;
        }

        if (this._disposeScrollbarMouseDownHandler !== null)
        {
            this._disposeScrollbarMouseDownHandler();
            this._disposeScrollbarMouseDownHandler = null;
        }

        this.removeScrollbarMouseListeners();
        this.removeWindowScrollbarEventListeners();
    }

    public updateScrollbarSize(): void
    {
        this._ngZone.runOutsideAngular(() =>
        {
            this._scrollbarsRootElement.style.width = '0';
            this._scrollbarsRootElement.style.height = '0';

            this._scrollElementSize = (this._scrollContainerElement as any)[this.scrollbarSizePropertyName];
            this._scrollContentSize = (this._scrollContainerElement as any)[this.scrollbarContentSizePropertyName];

            this._scrollbarsRootElement.style.width = '';
            this._scrollbarsRootElement.style.height = '';

            if (this._scrollElementSize >= this._scrollContentSize)
            {
                this._scrollbarElement.classList.remove(Constants.ACTIVE_CLASSNAME);
                this.scrollbarValue = 0;
                return;
            }

            this._scrollbarElement.classList.add(Constants.ACTIVE_CLASSNAME);

            this._renderer.setStyle(this._scrollbarThumbElement, this.scrollbarStylePropertyName, `${Math.max(this.MIN_SCROLLBAR_THUMB_SIZE,
                this.calculateScrollbarThumbSize())}px`);
        });
    }

    public updateScrollbarPosition(): void
    {
        this._ngZone.runOutsideAngular(() =>
        {
            if (this.scrollbarValue > this._scrollContentSize)
            {
                this.scrollbarValue = this._scrollContentSize;
            }

            this._renderer.setStyle(this._scrollbarsRootElement, this.scrollbarStylePropertyName, `${this._scrollElementSize}px`);

            const calculatedScrollbarThumbSize: number = this.calculateScrollbarThumbSize();

            const scrollbarThumbTransformStyle: string = `${this.scrollbarTranslateStylePropertyName}(${this.scrollbarValue *
                (calculatedScrollbarThumbSize / (this._scrollElementSize + (this.scrollbarThumbSize - calculatedScrollbarThumbSize)))}px)`;

            this._renderer.setStyle(this._scrollbarThumbElement, 'transform', scrollbarThumbTransformStyle);
            this._renderer.setStyle(this._scrollbarThumbElement, 'webkitTransform', scrollbarThumbTransformStyle);

            const oppositeScrollbarValue: number = (this._scrollContainerElement as any)[this.getScrollbarValuePropertyName(this._scrollbarType === ScrollbarType.Vertical ?
                ScrollbarType.Horizontal : ScrollbarType.Vertical)];

            const scrollbarsRootTransformStyle: string = `translate3d(${this._scrollbarType === ScrollbarType.Vertical ? oppositeScrollbarValue : this.scrollbarValue}px, 
            ${this._scrollbarType === ScrollbarType.Vertical ? this.scrollbarValue : oppositeScrollbarValue}px, 0px)`;

            this._renderer.setStyle(this._scrollbarsRootElement, 'transform', scrollbarsRootTransformStyle);
            this._renderer.setStyle(this._scrollbarsRootElement, 'webkitTransform', scrollbarsRootTransformStyle);
        });
    }

    // #endregion

    // #region Private Methods

    private getScrollbarValuePropertyName(scrollbarType: ScrollbarType): string
    {
        return scrollbarType === ScrollbarType.Vertical ? 'scrollTop' : 'scrollLeft';
    }

    private setScrollbarThrottle(scrollThrottleDirection: number, easingType: EasingType): boolean
    {
        const targetScrollValue: number = this.validateScrollValue(this.scrollbarValue + scrollThrottleDirection * this._scrollElementSize);

        if (targetScrollValue === this.scrollbarValue)
        {
            this.clearScrollbarThrottleInterval();
            return false;
        }

        if (targetScrollValue === 0 || targetScrollValue === this._scrollContentSize - this._scrollElementSize)
        {
            easingType = easingType === EasingType.Linear ? EasingType.Out : easingType;
        }

        this.clearSmoothScrollToValueSubscription();

        this._smoothScrollToValueSubscription = Utils.smoothTransition(this.scrollbarValue, targetScrollValue,
            AnimationsConstants.ANIMATION_DURATION * (easingType === EasingType.Linear ? 1 : 0.5), easingType).subscribe((scrollValue: number) =>
            {
                this.scrollbarValue = scrollValue;

                if (scrollValue === targetScrollValue)
                {
                    this.clearSmoothScrollToValueSubscription();
                }
            });

        return true;
    }

    private clearScrollbarThrottleInterval(): void
    {
        if (this._scrollbarThrottleIntervalHandle !== null)
        {
            clearInterval(this._scrollbarThrottleIntervalHandle);
            this._scrollbarThrottleIntervalHandle = null;
        }

        this._scrollThrottleDirection = 0;
    }

    private validateScrollValue(scrollValue: number): number
    {
        if (this._isRtl && this._scrollbarType === ScrollbarType.Horizontal)
        {
            return Math.max(-(this._scrollContentSize - this._scrollElementSize), Math.min(0, scrollValue));
        }
        else
        {
            return Math.min(this._scrollContentSize - this._scrollElementSize, Math.max(0, scrollValue));
        }
    }

    private removeScrollbarMouseListeners(): void
    {
        if (this._disposeScrollbarMouseLeaveHandler !== null)
        {
            this._disposeScrollbarMouseLeaveHandler();
            this._disposeScrollbarMouseLeaveHandler = null;
        }

        if (this._disposeWindowScrollbarMouseUpHandler !== null)
        {
            this._disposeWindowScrollbarMouseUpHandler();
            this._disposeWindowScrollbarMouseUpHandler = null;
        }

        if (this._disposeScrollbarThumbMouseEnterHandler !== null)
        {
            this._disposeScrollbarThumbMouseEnterHandler();
            this._disposeScrollbarThumbMouseEnterHandler = null;
        }
    }

    private removeWindowScrollbarEventListeners(): void
    {
        if (this._disposeWindowScrollbarThumbMouseMoveHandler !== null)
        {
            this._disposeWindowScrollbarThumbMouseMoveHandler();
            this._disposeWindowScrollbarThumbMouseMoveHandler = null;
        }

        if (this._disposeWindowScrollbarThumbMouseUpHandler !== null)
        {
            this._disposeWindowScrollbarThumbMouseUpHandler();
            this._disposeWindowScrollbarThumbMouseUpHandler = null;
        }
    }

    private calculateScrollbarThumbSize(): number
    {
        return this._scrollElementSize - (this._scrollContentSize - this._scrollElementSize) * (this._scrollElementSize / this._scrollContentSize);
    }

    private clearSmoothScrollToValueSubscription(): void
    {
        if (this._smoothScrollToValueSubscription !== null)
        {
            this._smoothScrollToValueSubscription.unsubscribe();
            this._smoothScrollToValueSubscription = null;
        }
    }

    // #endregion
}