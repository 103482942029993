import { IIdValue } from "../../../utils/globals";

export enum ShipmentColumnType { ShipmentKey, ContainerId, DeviceId, CreateDate, InstallDate, RouteDescription, IsArrived, IsAutoClose, IsReportAnomaly, Track }

export class ShipmentLists
{
    public devices: IIdValue[] = [];
    public lsps: IIdValue[] = [];
    public routes: IIdValue[] = [];
    public inactiveRoutes: IIdValue[] = [];
    public carriers: IIdValue[] = [];
    public lockMethods: IIdValue[] = [];
    public devicelessReasons: IIdValue[] = [];
    public trailerTypes: IIdValue[] = [];
}
