export enum SmartLockColumnType { LastTrasmitDate }

export enum SmartLockLogicStateType { Locked = 1, Unlocked = 2, BreakIn = 4, Opened = 5 }

export class SmartLockKeyHash
{
    public unitNumber: number | null = null;
    public unitType: number | null = null;
    public key: string | null = null;
    public hash: string | null = null;
    public supportTurnOff: boolean | null = null;
}

export class SmartLockStatusResponse
{
    public shackle: number | null = null;
    public motor: number | null = null;
    public fault: number | null = null;
    public features: number | null = null;
    public logic: number | null = null;
    public power: number | null = null;
    public light: number | null = null;
    public temp: number | null = null;
}

export class SmartLockStatus
{
    public motor: number | null = null;
    public logicState: number | null = null;
    public temperature: number | null = null;
    public mainVoltage: number | null = null;
    public features: number | null = null;
    public baterryPowerPercent: number = 0;
}

export class SmartLock extends SmartLockStatus
{
    public unitNumber: number | null = null;
    public name: string | null = null;
    public lastTransmit: string | null = null;
}

export class DisplaySmartLock extends SmartLock
{
    public lastTransmitDate: Date | null = null;
    public lastTransmitDuration: string | null = null;
    public deviceId: string | undefined = undefined;
    public liveSmartLockStatus: SmartLockStatus | undefined = undefined;
    public smartLockKeyHash: SmartLockKeyHash | undefined = undefined;
}

export class SmartLockManagerServiceInfo
{
    public bluetoothService: string = '';
    public bluetoothServiceCharacteristicWrite: string = '';
    public bluetoothServiceCharacteristicRead: string = '';
}

export class SmartLockUserUnlockLog
{
    public unitNumber: number | null = null;
    public userName: string | null = null;
    public logicState: number | null = null;
}
