import { Injectable } from "@angular/core";
import { AlertEscalationRequestType, AlertReasonType, AlertStatusType, ControlCenterAlertConstants, DisplayControlCenterAlertData, EscalationActionType, NotEscalatedReasonType } from "./control-center-alert-model.class";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { MiniControlCenterModel } from "../../mini-control-center/model/mini-control-center.model";
import { ManagerItemBaseModel } from "../../../base/models/managaer-item-base.model";
import { Constants, IApiResponseData, IIdValue } from "../../../utils/globals";
import { Observable, Observer } from "rxjs";
import { Utils } from "../../../utils/utils";
import { cloneDeep } from "lodash";
import { HttpHelper } from "../../../utils/http-helper";
import { HttpErrorCodes } from "../../../utils/http-error-codes";

@Injectable({ providedIn: 'root' })
export class ControlCenterAlertModel extends ManagerItemBaseModel<DisplayControlCenterAlertData>
{
    // #region Constants

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'mini-control-center';

    // #endregion

    // #region Private Members

    private readonly _alertStatuses: IIdValue[] =
        [
            { id: AlertStatusType.Pending, value: 'Pending' },
            { id: AlertStatusType.InProcess, value: 'In Process' },
            { id: AlertStatusType.Done, value: 'Done' }
        ];

    private readonly _escalationRequirements: IIdValue[] =
        [
            { id: AlertEscalationRequestType.RequiresEscalation, value: 'Requires Escalation' },
            { id: AlertEscalationRequestType.NotRequireEscalation, value: 'Not Require Escalation' }
        ];

    private readonly _noEscalationResons: IIdValue[] =
        [
            { id: NotEscalatedReasonType.OccurredInAuthorizedLocation, value: 'Occurred in Authorized Location' },
            { id: NotEscalatedReasonType.AlreadyReported, value: 'Already Reported' },
            { id: NotEscalatedReasonType.LeftPerimeterInaccurateCellOrWIFILocation, value: 'Left Perimeter Inaccurate Cell or Wifi Location' },
            { id: NotEscalatedReasonType.Other, value: 'Other' }
        ];

    private readonly _escalationActionTypes: IIdValue[] =
        [
            { id: EscalationActionType.PhoneCall, value: 'Phone Call' },
            { id: EscalationActionType.Email, value: 'Email' },
            { id: EscalationActionType.WhatsApp, value: 'WhatsApp/Chime' }
        ];

    private _updatedPropertiesMap: Map<string, any> = new Map();

    // #endregion

    // #region Properties

    public selectedEscalationActionTypes: number[] = [];

    public get AlertStatusType()
    {
        return AlertStatusType;
    }

    public get AlertReasonType()
    {
        return AlertReasonType;
    }

    public get AlertEscalationRequestType()
    {
        return AlertEscalationRequestType;
    }

    public get NotEscalatedReasonType()
    {
        return NotEscalatedReasonType;
    }

    public get EscalationActionType()
    {
        return EscalationActionType;
    }

    public get alertStatuses(): IIdValue[]
    {
        return this._alertStatuses;
    }

    public get escalationRequirements(): IIdValue[]
    {
        return this._escalationRequirements;
    }

    public get noEscalationResons(): IIdValue[]
    {
        return this._noEscalationResons;
    }

    public get escalationActionTypes(): IIdValue[]
    {
        return this._escalationActionTypes;
    }

    // #endregion

    // #region Constructors

    constructor(private _httpClient: HttpClient, private _miniControlCenterModel: MiniControlCenterModel)
    {
        super(DisplayControlCenterAlertData);
    }

    // #endregion

    // #region Public Methods

    public override initialize(): void
    {
        super.initialize();

        this.selectedEscalationActionTypes = [];
        if (this._miniControlCenterModel.selectedManagerItem!.useActionTypeMail)
        {
            this.selectedEscalationActionTypes.push(EscalationActionType.Email)
        }

        if (this._miniControlCenterModel.selectedManagerItem!.useActionTypePhone)
        {
            this.selectedEscalationActionTypes.push(EscalationActionType.PhoneCall)
        }

        if (this._miniControlCenterModel.selectedManagerItem!.useActionTypeWhatsapp)
        {
            this.selectedEscalationActionTypes.push(EscalationActionType.WhatsApp)
        }

        this.initializeManagerItem(this._miniControlCenterModel.selectedManagerItem!);
    }

    public override submitManagerItem(): Observable<IApiResponseData<DisplayControlCenterAlertData>>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponseData<DisplayControlCenterAlertData>>) =>
        {
            const alertsToSubmit: DisplayControlCenterAlertData[] = Utils.clearObjectEmptyStrings(cloneDeep(this._miniControlCenterModel.selectedAlerts));
            for (const alertToSubmit of alertsToSubmit)
            {
                for (const propertyValuePair of this._updatedPropertiesMap)
                {
                    (alertToSubmit as any)[propertyValuePair[0]] = propertyValuePair[1];
                }

                if (alertToSubmit.statusId === AlertStatusType.Done)
                {
                    alertToSubmit.useActionTypePhone = null;
                    alertToSubmit.useActionTypeWhatsapp = null;
                    alertToSubmit.useActionTypeMail = null;

                    for (const selectedEscalationActionType of this.selectedEscalationActionTypes)
                    {
                        switch (selectedEscalationActionType)
                        {
                            case EscalationActionType.PhoneCall:
                                {
                                    alertToSubmit.useActionTypePhone = true;
                                }
                                break;

                            case EscalationActionType.Email:
                                {
                                    alertToSubmit.useActionTypeMail = true;
                                }
                                break;

                            case EscalationActionType.WhatsApp:
                                {
                                    alertToSubmit.useActionTypeWhatsapp = true;
                                }
                                break;
                        }
                    }
                }
            }

            this._httpClient.put<DisplayControlCenterAlertData[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/alerts`, JSON.stringify(alertsToSubmit),
                { headers: HttpHelper.GetHttpJsonHeaders() }).subscribe(
                    {
                        next: (updatedAlerts: DisplayControlCenterAlertData[]) =>
                        {
                            for (const updatedAlert of updatedAlerts)
                            {
                                for (const selectedAlert of this._miniControlCenterModel.selectedAlerts)
                                {
                                    if (selectedAlert.id === updatedAlert.id)
                                    {
                                        Utils.copyObjectBySourceProperties(updatedAlert, selectedAlert);
                                        break;
                                    }
                                }
                            }

                            this.initialize();

                            this._isBusy = false;

                            observer.next({ isSuccess: true, isComplete: true, data: this._managerItem });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            observer.next(
                                {
                                    isSuccess: false,
                                    isComplete: true,
                                    message: error.status === HttpErrorCodes.CONFLICT ? ControlCenterAlertConstants.CONFLICT_MESSAGE :
                                        Constants.DATA_SERVICE_ERROR_STRING,
                                    status: error.status
                                });

                            observer.complete();
                        }
                    });
        });
    }

    // #endregion

    // #region protected Methods

    protected override initializeManagerItem(managerItem: DisplayControlCenterAlertData): void
    {
        this._updatedPropertiesMap.clear();

        this._managerItem = Utils.createProxyOnSetNotifier(cloneDeep(managerItem), (_target: any, propertyKey: string, value: any) =>
        {
            this._updatedPropertiesMap.set(propertyKey, Utils.clearValueEmptyStrings(value));
        });

        this.setValidManagerItem();
    }

    // #endregion
}