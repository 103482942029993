<!DOCTYPE html>

@if (('IS_MOBILE' | globals)) {
<label for="file-input-capture" class="camera-button">
    <i class="icon-camera"></i>
</label>
}
<input #fileInput id="file-input-capture" type="file" accept="image/*" [attr.capture]="('IS_MOBILE' | globals) ? '' : null" (change)="onImageCaptureChange($event)" />

@if (!('IS_MOBILE' | globals)) {
<button type="button" class="camera-button" (click)="onShowWebcamButtonClick()">
    <i class="icon-camera"></i>
</button>
}

<div class="camera-wrapper">
    @if (showWebcam) {
    <div class="camera-background" (click)="onCloseWebcamButtonClick()" @fadeInOutAnimation></div>
    <div class="camera-container" @fadeInOutAnimation>
        <div class="camera-header">
            <button type="button" class="image-button close-button" (click)="onCloseWebcamButtonClick()"><i class="icon-cancel"></i></button>
        </div>
        <video #videoCapture></video>
        <div class="camera-controls">
            <button type="button" class="capture-button" (click)="onImageCaptureButtonClick()"></button>
        </div>
        @if (approveImageUrl) {
        <div @fadeInOutAnimation class="image-capture-container">
            <div class="camera-header">
                <button type="button" class="image-button close-button" (click)="onCloseApproveImageButtonClick()"><i class="icon-cancel"></i></button>
            </div>
            <img [src]="approveImageUrl" />
            <div class="camera-controls">
                <button type="button" class="cancel-button" (click)="onCloseApproveImageButtonClick()"><i class="icon-cancel"></i></button>
                <button type="button" class="approve-button" (click)="onApproveButtonClick()"><i class="icon-check"></i></button>
            </div>
        </div>
        }
    </div>
    }

    @if (isLoadingWebcam) {
    <div class="camera-overlay"></div>
    }
</div>

