import { cloneDeep, isEqual, orderBy } from "lodash";
import { BaseModel } from "../classes/base-model";
import { LoginModel } from "../../user/login/model/login.model";
import { Utils } from "../../utils/utils";
import { IApiResponse, TypeConstructor } from "../../utils/globals";
import { Observable, Observer } from "rxjs";
import { MonitoringArrivalStatusType } from "./manager-base-model.class";
import { MonitoringDetailedEventType } from "./monitoring-items-base-model.class";
import { ManagerConstants } from "../../utils/monitoring-utils";

export class MonitoringItemsBaseModel<BaseMonitoringItemType, BaseFiltersType, BaseDataListsType, BaseMonitoringInfoType> extends BaseModel
{
    // #region Protected Members

    protected _monitoringFilters: BaseFiltersType;
    protected _editMonitoringFilters: BaseFiltersType;
    protected _monitoringDataLists: BaseDataListsType;
    protected _filteredMonitoringDataLists: BaseDataListsType;
    protected _monitoringItems: BaseMonitoringItemType[] = [];
    protected _filteredMonitoringItems: BaseMonitoringItemType[] = [];
    protected _monitoringItemsInfo: BaseMonitoringInfoType;
    protected _monitoringItemsSourceInfo: BaseMonitoringInfoType;
    protected _completedMonitoringItemsSourceInfo: BaseMonitoringInfoType;
    protected _completedMonitoringItems: BaseMonitoringItemType[] = [];
    protected _currentMonitoringItems: BaseMonitoringItemType[] = [];
    protected _allMonitoringItems: BaseMonitoringItemType[] = [];
    protected _showCompletedMonitoringItems: boolean = false;

    // #endregion

    // #region Properties

    public selectedMonitoringItem: BaseMonitoringItemType | null = null;

    public get isDemo(): boolean
    {
        return this._loginModel.userInfo.isDemo;
    }

    public get isMonitoringFiltersActive(): boolean
    {
        return !isEqual(this._monitoringFilters, new this._baseFiltersTypeConstructor());
    }

    public get isMonitoringFiltersDirty(): boolean
    {
        return !isEqual(this._editMonitoringFilters, this._monitoringFilters);
    }

    public get showCompletedMonitoringItems(): boolean
    {
        return this._showCompletedMonitoringItems;
    }

    public set showCompletedMonitoringItems(value: boolean)
    {
        if (this._showCompletedMonitoringItems === value)
        {
            return;
        }

        this._showCompletedMonitoringItems = value;
        this._currentMonitoringItems = [...(this._showCompletedMonitoringItems ? this._completedMonitoringItems : this._monitoringItems)];
        this._monitoringDataLists = this.updateMonitoringDataLists(this._currentMonitoringItems);
        this.updateMonitoringDataListsByFilters();
    }

    public get allMonitoringItems(): BaseMonitoringItemType[]
    {
        return this._allMonitoringItems;
    }

    public get currentMonitoringItemsSourceInfo(): BaseMonitoringInfoType
    {
        return this._showCompletedMonitoringItems ? this._completedMonitoringItemsSourceInfo : this._monitoringItemsSourceInfo;
    }

    public get completedMonitoringItemsSourceInfo(): BaseMonitoringInfoType
    {
        return this._completedMonitoringItemsSourceInfo;
    }

    public get monitoringItems(): BaseMonitoringItemType[]
    {
        return this._monitoringItems;
    }

    public get filteredMonitoringItems(): BaseMonitoringItemType[]
    {
        return this._filteredMonitoringItems;
    }

    public get lastSearchMonitoringFilters(): BaseFiltersType | null
    {
        return null;
    }

    public set lastSearchMonitoringFilters(_value: BaseFiltersType | null)
    {
    }

    public get editMonitoringFilters(): BaseFiltersType
    {
        return this._editMonitoringFilters;
    }

    public get filteredMonitoringDataLists(): BaseDataListsType
    {
        return this._filteredMonitoringDataLists;
    }

    public get monitoringItemsInfo(): BaseMonitoringInfoType
    {
        return this._monitoringItemsInfo;
    }

    public get monitoringItemsSourceInfo(): BaseMonitoringInfoType
    {
        return this._monitoringItemsSourceInfo;
    }

    public get MonitoringDetailedEventType()
    {
        return MonitoringDetailedEventType;
    }

    public get MonitoringArrivalStatusType()
    {
        return MonitoringArrivalStatusType;
    }

    // #endregion

    // #region Contructors

    constructor(protected _loginModel: LoginModel,
        private _baseFiltersTypeConstructor: TypeConstructor<BaseFiltersType>,
        private _baseDataListsTypeConstructor: TypeConstructor<BaseDataListsType>,
        private _baseMonitoringInfoTypeConstructor: TypeConstructor<BaseMonitoringInfoType>)
    {
        super();

        this._monitoringFilters = new this._baseFiltersTypeConstructor();
        this._editMonitoringFilters = new this._baseFiltersTypeConstructor();
        this._monitoringDataLists = new this._baseDataListsTypeConstructor();
        this._filteredMonitoringDataLists = new this._baseDataListsTypeConstructor();
        this._monitoringItemsInfo = new this._baseMonitoringInfoTypeConstructor();
        this._monitoringItemsSourceInfo = new this._baseMonitoringInfoTypeConstructor();
        this._completedMonitoringItemsSourceInfo = new this._baseMonitoringInfoTypeConstructor();
    }

    // #endregion

    // #region Public Methods

    public getMonitoringItemKey(_monitoringItem: BaseMonitoringItemType): any
    {
        return null;
    }

    public getMonitoringItemStatusId(_monitoringItem: BaseMonitoringItemType): any
    {
        return null;
    }

    public getMonitoringItemLocation(_monitoringItem: BaseMonitoringItemType): any
    {
        return null;
    }

    public getMonitoringItems(): Observable<IApiResponse>
    {
        return new Observable((observer: Observer<IApiResponse>) =>
        {
            observer.next({ isSuccess: true });
            observer.complete();
        });
    }

    public findMonitoringItem(itemKey: string | number | null, monitoringItems: BaseMonitoringItemType[] | null = null): BaseMonitoringItemType | null
    {
        if (monitoringItems === null)
        {
            monitoringItems = this._filteredMonitoringItems;
        }

        if (itemKey === null)
        {
            return null;
        }

        for (const monitoringItem of monitoringItems)
        {
            if (this.getMonitoringItemKey(monitoringItem) === itemKey)
            {
                return monitoringItem;
            }
        }

        return null;
    }

    public updateMonitoringDataListsByFilters(forceUpdate: boolean = false): void
    {
        if (!forceUpdate && !this.isMonitoringFiltersActive)
        {
            this._filteredMonitoringDataLists = cloneDeep(this._monitoringDataLists);
            return;
        }

        this._filteredMonitoringDataLists = this.updateMonitoringDataLists(this.updateMonitoringItemsByFilters(this._editMonitoringFilters));
    }

    public initializeMonitoringItemsFilters(): void
    {
        this._editMonitoringFilters = cloneDeep(this._monitoringFilters);
        this.updateMonitoringDataListsByFilters();
    }

    public clearMonitoringItemsFilters(): boolean
    {
        if (!isEqual(this._monitoringFilters, new this._baseFiltersTypeConstructor()))
        {
            this._monitoringFilters = new this._baseFiltersTypeConstructor();
            this.initializeMonitoringItemsFilters();
            this._filteredMonitoringItems = this.updateMonitoringItemsByFilters();
            return true;
        }

        this._editMonitoringFilters = cloneDeep(this._monitoringFilters);
        return false;
    }

    public override clear(): void
    {
        super.clear();

        this._monitoringItems = [];
        this._filteredMonitoringItems = [];
        this._monitoringDataLists = new this._baseDataListsTypeConstructor();
        this._filteredMonitoringDataLists = new this._baseDataListsTypeConstructor();

        this._completedMonitoringItems = [];
        this._currentMonitoringItems = [];
        this._allMonitoringItems = [];
        this._monitoringItemsSourceInfo = new this._baseMonitoringInfoTypeConstructor();
        this._completedMonitoringItemsSourceInfo = new this._baseMonitoringInfoTypeConstructor();
        this._monitoringItemsInfo = new this._baseMonitoringInfoTypeConstructor();
        this._showCompletedMonitoringItems = false;


        this.selectedMonitoringItem = null;
        this.clearMonitoringItemsFilters();
    }

    public loadLastSeachFilters(): void
    {
        if (this.lastSearchMonitoringFilters !== null)
        {
            this._editMonitoringFilters = cloneDeep(this.lastSearchMonitoringFilters);
            this.updateMonitoringDataListsByFilters();
        }
    }

    public applyMonitoringFilters(forceUpdate: boolean = false): boolean
    {
        if (forceUpdate || !isEqual(this._monitoringFilters, this._editMonitoringFilters))
        {
            this._monitoringFilters = cloneDeep(this._editMonitoringFilters);
            this._filteredMonitoringItems = this.updateMonitoringItemsByFilters();

            if (this.isMonitoringFiltersActive)
            {
                this.lastSearchMonitoringFilters = cloneDeep(this._monitoringFilters);
            }

            return true;
        }

        return false;
    }

    public filterMonitoringItem(_monitoringItem: BaseMonitoringItemType): boolean
    {
        return false;
    }

    // #endregion

    // #region Protected Methods

    protected updateMonitoringItemsInfo(_monitoringItems: BaseMonitoringItemType[]): BaseMonitoringInfoType
    {
        return new this._baseMonitoringInfoTypeConstructor();
    }

    protected sortMonitoringDataLists(monitoringDataLists: BaseDataListsType, ignoreProperties: string[] = []): void
    {
        for (const propertyName of Object.getOwnPropertyNames(monitoringDataLists))
        {
            if (ignoreProperties.includes(propertyName))
            {
                continue
            }

            let dataList: any[] = (monitoringDataLists as any)[propertyName];
            if (!Array.isArray(dataList))
            {
                continue;
            }

            if (dataList.length > 0)
            {
                dataList = orderBy(dataList, (typeof dataList[0] === 'object' && 'value' in dataList[0]) ? ['value'] : []);
                (monitoringDataLists as any)[propertyName] = dataList;
            }

            const missingValueIndex: number = dataList.indexOf(ManagerConstants.MISSING_VALUE);
            if (missingValueIndex >= 0)
            {
                dataList.unshift(dataList.splice(missingValueIndex, 1)[0]);
            }

            const noDeviceValueIndex: number = dataList.indexOf(ManagerConstants.NO_DEVICE_VALUE);
            if (noDeviceValueIndex >= 0)
            {
                dataList.unshift(dataList.splice(noDeviceValueIndex, 1)[0]);
            }
        }
    }

    protected updateMonitoringDataLists(_monitoringItems: BaseMonitoringItemType[]): BaseDataListsType
    {
        return new this._baseDataListsTypeConstructor();
    }

    protected updateMonitoringItemsByFilters(_monitoringFilters: BaseFiltersType | null = null): BaseMonitoringItemType[]
    {
        return [];
    }

    protected updateLastSeachMonitoringItemFilteredByPropertyValue(propertiesValues: any[], lastSearchSelectedPropertiesValues: any[]): any[]
    {
        if (lastSearchSelectedPropertiesValues === undefined)
        {
            lastSearchSelectedPropertiesValues = [];
        }

        lastSearchSelectedPropertiesValues = [...lastSearchSelectedPropertiesValues.filter((propertyValue: any) =>
        {
            return propertiesValues.includes(propertyValue);
        })];

        return lastSearchSelectedPropertiesValues;
    }

    protected isMonitoringItemFilteredByPropertyValue(propertyValue: any | null, selectedPropertiesValues: any[]): boolean
    {
        if (selectedPropertiesValues.length > 0)
        {
            if (Utils.isNullOrUndefined(propertyValue))
            {
                return false;
            }

            return selectedPropertiesValues.includes(propertyValue);
        }

        return true;
    }

    protected isMonitoringItemFilteredByMinimumNumericPropertyValue(propertyValue: number | null, minValue: number | null): boolean
    {
        if (minValue === null)
        {
            return true;
        }

        if (propertyValue === null)
        {
            return false;
        }

        return propertyValue >= minValue;
    }

    protected isMonitoringItemFilteredByMaximumNumericPropertyValue(propertyValue: number | null, maxValue: number | null): boolean
    {
        if (maxValue === null)
        {
            return true;
        }

        if (propertyValue === null)
        {
            return false;
        }

        return propertyValue <= maxValue;
    }

    protected updateEmptyLastSearchFilters(): void
    {
        if (isEqual(this.lastSearchMonitoringFilters, new this._baseFiltersTypeConstructor()))
        {
            this.lastSearchMonitoringFilters = null;
        }
    }

    protected getSelectiveMonitoringItemsByFilters(monitoringItems: BaseMonitoringItemType[], propertyName: string): BaseMonitoringItemType[]
    {
        if ((this._editMonitoringFilters as any)[propertyName].length > 0)
        {
            const editMonitoringFilters: BaseFiltersType = cloneDeep(this._editMonitoringFilters);
            (editMonitoringFilters as any)[propertyName] = [];
            return this.updateMonitoringItemsByFilters(editMonitoringFilters);
        }

        return monitoringItems
    }

    protected getMonitoringItemsPropertyList(monitoringItems: BaseMonitoringItemType[], propertyName: string): any[]
    {
        const uniqueSet = new Set();
        return monitoringItems.map((monitoringItem: BaseMonitoringItemType) => Utils.getItemNestedPropertyValue(monitoringItem, propertyName)).filter((property) =>
        {
            if (property === undefined || property === null)
            {
                return false;
            }

            if (!uniqueSet.has(property))
            {
                uniqueSet.add(property);
                return true;
            }

            return false;
        });
    }

    protected updateMonitoringInfoGeneralData(_monitoringItemsInfo: BaseMonitoringInfoType, _monitoringItems: BaseMonitoringItemType[]): void
    {
    }

    // #endregion
}