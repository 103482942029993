import { ChangeDetectorRef, Component, EmbeddedViewRef, NgZone, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { BaseView } from "../classes/base-view";
import { CGIMarkerClusterer, onClusterClickHandler } from "../../controls/google-map/utils/cgi-marker-clusterer";
import { CGIMarkerPopup, MarkerPopupIconType } from "../../controls/google-map/utils/cgi-marker-popup";
import { GoogleMapComponent } from "../../controls/google-map/google-map.component";
import { MonitoringShipmentColumnType } from "../../shipments-monitoring/shipments-monitoring/model/shipments-monitoring-model.class";
import { IApiResponse, IListColumn } from "../../utils/globals";
import { ModalMessageService } from "../../controls/modal-message/services/modal-message.service";
import { isEqual } from "lodash";
import { Router } from "@angular/router";
import { RoutingHistoryService } from "../../services/routing-history.service";
import { DateTimeFormatType, Utils } from "../../utils/utils";
import { AppSettingsService } from "../../services/app-settings.service";
import { CGICluster } from "../../controls/google-map/utils/cgi-cluster";
import ContguardPlugin from "../../../plugins/contguard.plugin";
import { VirtualListInfo } from "../../controls/virtual-list/model/virtual-list-info";
import { StringTableUtils } from "../../utils/string-table-utils";
import { LoginModel } from "../../user/login/model/login.model";
import { CGIClusterBaseRenderer } from "../../controls/google-map/utils/cgi-cluster-base-renderer";
import { MonitoringArrivalStatusType, MonitoringPopupType } from "../models/manager-base-model.class";
import { GoogleMapUtils } from "../../controls/google-map/utils/google-map-utils";
import { ICGIMarker, ICGIMarkerLocation } from "../classes/cgi-marker";
import { AnimationsConstants } from "../../animations/constant";
import { DropdownDirective } from "../../directives/dropdown.directive";
import { MonitoringDetailedEventType } from "../models/monitoring-items-base-model.class";
import { DisplayShipmentMonitoring } from "../../shipments-monitoring/shipment-monitoring/model/shipment-monitoring-model.class";
import { ManagerConstants } from "../../utils/monitoring-utils";

@Component({ template: '' })
export class MonitoringBaseComponent<BaseMonitoringType> extends BaseView
{
    // #region Protected Constants

    protected readonly MARKER_INFO_CONTAINER_CLASSNAME: string = 'marker-info-container';
    protected readonly MARKER_INFO_CONTENT_CONTAINER_CLASSNAME: string = 'marker-info-content-container';
    protected readonly SHARING_MAP_TITLE: string = 'Monitoring';
    protected readonly SHARING_INFO_TITLE: string = 'Contguard Insights Info:';
    protected readonly SHARING_LOCATION_TITLE: string = 'Contguard Insights Location:';

    // #endregion

    // #region Private Constants

    private readonly SHARING_MAP_TEXT: string = `Contguard Insights ${this.SHARING_MAP_TITLE} Screenshot`;

    // #endregion

    // #region Private Members

    private _shareMapSnapshop: boolean = false;

    // #endregion

    // #region Protected Members

    protected static _externalShipmentsFilterTableListInfo: VirtualListInfo = new VirtualListInfo();

    protected _tableExternalShipmentsMonitoringSearchColumns: IListColumn[] = [];
    protected _tableExtrenalShipmentsMonitoringSearchProperties: string[] = [];
    protected _bodyCssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(document.body);
    protected _cgiMarkerClusterer: CGIMarkerClusterer | null = null;
    protected _cgiMarkerPopup: CGIMarkerPopup | null = null;
    protected _monitoringSearchFilter: string | null = null;

    protected _currentMonitoringPopup: MonitoringPopupType = MonitoringPopupType.More;
    protected _showMonitoringPopups: boolean[] = Array((Object.values(MonitoringPopupType).filter((value: any) =>
        typeof (value) === 'number') as number[]).length).fill(false);

    @ViewChild('googleMap', { read: GoogleMapComponent, static: false }) protected _googleMap: GoogleMapComponent | undefined = undefined;
    @ViewChild('infoWindowMarkerTemplate', { read: TemplateRef }) protected _infoWindowMarkerTemplateRef!: TemplateRef<any>;
    @ViewChild('infoWindowMarkerClusterTemplate', { read: TemplateRef }) protected _infoWindowMarkerClusterTemplateRef!: TemplateRef<any>;

    @ViewChildren('showCopyOptions') protected _showCopyOptionsList!: QueryList<DropdownDirective>;
    @ViewChildren('showShareOptions') protected _showShareOptionsList!: QueryList<DropdownDirective>;

    @ViewChild('showPopupCopyOptions', { read: DropdownDirective, static: false }) protected _showPopupCopyOptions: DropdownDirective | undefined = undefined;
    @ViewChild('showPopupShareOptions', { read: DropdownDirective, static: false }) protected _showPopupShareOptions: DropdownDirective | undefined = undefined;

    // #endregion

    // #region Properties

    public showSearchShipments: boolean = false;

    public get MonitoringPopupType()
    {
        return MonitoringPopupType;
    }

    public get currentMonitoringPopup(): MonitoringPopupType
    {
        return this._currentMonitoringPopup;
    }

    public get showMonitoringPopups(): boolean[]
    {
        return this._showMonitoringPopups
    }

    public get showMapRailways(): boolean
    {
        return this._googleMap === undefined ? false : this._googleMap.showMapRailways;
    }

    public set showMapRailways(value: boolean)
    {
        if (this._googleMap === undefined)
        {
            return;
        }

        this._googleMap.showMapRailways = value;
    }

    public get isSatelliteView(): boolean
    {
        return this._googleMap === undefined ? false : this._googleMap.isSatelliteView;
    }

    public get MonitoringDetailedEventType()
    {
        return MonitoringDetailedEventType;
    }

    public get MonitoringArrivalStatusType()
    {
        return MonitoringArrivalStatusType;
    }

    public get isMarkerActive(): boolean
    {
        return !Utils.isNullOrUndefined(this._cgiMarkerClusterer?.activeMarker);
    }

    public get monitoringSearchFilter(): string | null
    {
        return this._monitoringSearchFilter;
    }

    public set monitoringSearchFilter(value: string | null)
    {
        this._monitoringSearchFilter = value;

        if (Utils.isNullOrEmpty(this._monitoringSearchFilter))
        {
            MonitoringBaseComponent._externalShipmentsFilterTableListInfo.scrollTop = 0;
        }
    }

    public get externalShipmentsFilterTableListInfo(): VirtualListInfo
    {
        return MonitoringBaseComponent._externalShipmentsFilterTableListInfo;
    }

    public get appSettingsService(): AppSettingsService
    {
        return this._appSettingsService;
    }

    public get tableExternalShipmentsMonitoringSearchColumns(): IListColumn[]
    {
        return this._tableExternalShipmentsMonitoringSearchColumns;
    }

    public set tableExternalShipmentsMonitoringSearchColumns(value: IListColumn[])
    {
        this._tableExternalShipmentsMonitoringSearchColumns = value;

        this._tableExtrenalShipmentsMonitoringSearchProperties = [];
        for (const column of this._tableExternalShipmentsMonitoringSearchColumns)
        {
            if (column.propertyName !== undefined)
            {
                this._tableExtrenalShipmentsMonitoringSearchProperties.push(column.propertyName);
            }
        }
    }

    public get tableExtrenalShipmentsMonitoringSearchProperties(): string[]
    {
        return this._tableExtrenalShipmentsMonitoringSearchProperties;
    }

    public get containerIdColumnName(): string
    {
        return StringTableUtils.getContainerIdColumnName(this._loginModel.isAccountTypeAmazon);
    }

    public get deviceIdColumnName(): string
    {
        return StringTableUtils.getDeviceIdColumnName(this._loginModel.isAccountTypeAmazonUS);
    }

    public get installDateColumnName(): string
    {
        return StringTableUtils.getInstallDateColumnName(this._loginModel.isAccountTypeAmazon)
    }

    // #endregion

    // #region Constructors

    constructor(protected _appSettingsService: AppSettingsService, protected _changeDetectorRef: ChangeDetectorRef,
        protected _routingHistoryService: RoutingHistoryService, protected _ngZone: NgZone, protected _modalMessageService: ModalMessageService,
        protected _viewContainerRef: ViewContainerRef, protected _router: Router, protected _loginModel: LoginModel)
    {
        super();

        this.initializeTableExternalShipmentsMonitoringSearchColumns();
    }

    // #endregion

    // #region Event Handlers

    public onMonitoringShareCopyDropdownClick(event: MouseEvent): void
    {
        event.stopImmediatePropagation();
    }

    public async onShareSelectedMonitoringLocation(_shareCopyMonitoringInfo: BaseMonitoringType, _copyOnlyToClipboard: boolean): Promise<void>
    {
        this.collapseShareOptions();
    }

    public async onShareSelectedMonitoringInfo(_shareCopyMonitoringInfo: BaseMonitoringType, _copyOnlyToClipboard: boolean): Promise<void>
    {
    }

    public onMapMoreOptionsButtonClick(event: MouseEvent): void
    {
        event.stopImmediatePropagation();
    }

    public onShowMonitoringItemsPopupsChange(): void
    {
        if (!this._showMonitoringPopups[this._currentMonitoringPopup])
        {
            this._currentMonitoringPopup = MonitoringPopupType.More;
        }
    }

    public onHelpButtonClick(event: MouseEvent): void
    {
        event.stopImmediatePropagation();

        setTimeout(() =>
        {
            this._currentMonitoringPopup = MonitoringPopupType.Help;
            this._showMonitoringPopups[this._currentMonitoringPopup] = true;
            this._changeDetectorRef.detectChanges();
        });
    }

    public onPopupContentClick(event: MouseEvent): void
    {
        event.stopImmediatePropagation();
    }

    public onShowMapMoreOptionsFoldAnimationDone(): void
    {
        if (this._shareMapSnapshop)
        {
            this._shareMapSnapshop = false;

            setTimeout(() => this._googleMap?.shareMapSnapshot(this.SHARING_MAP_TITLE, this.SHARING_MAP_TEXT));
        }
    }

    public onShareSnapshotMenuItemClick(): void
    {
        this._shareMapSnapshop = true;
    }

    public onMapModeButtonClick(isSatelliteView: boolean)
    {
        this._googleMap?.updateMapType(isSatelliteView);
    }

    public onModalButtonCloseClick(): void
    {
        this._modalMessageService.instance?.closeModal();
    }

    public onGoogleMapInitialize(response: IApiResponse): void
    {
        if (response.isSuccess)
        {
            if (this._viewIsInitialized)
            {
                this.initializeGoogleMap();
            }
        }
        else
        {
            this._statusMessage = response.message ?? '';
        }

        this._changeDetectorRef.detectChanges();
    }

    public onClusterInfoCollapseButtonClick(): void
    {
        const activeCluster: CGICluster | undefined = this._cgiMarkerClusterer?.findClusterByMarker(this._cgiMarkerClusterer.activeMarker);
        if (activeCluster !== undefined)
        {
            this.buildClusterInfowWindow(true, activeCluster, false);
        }
    }

    public onClusterInfoExpandButtonClick(): void
    {
        const activeCluster: CGICluster | undefined = this._cgiMarkerClusterer?.findClusterByMarker(this._cgiMarkerClusterer.activeMarker);
        if (activeCluster !== undefined)
        {
            this.buildClusterInfowWindow(false, activeCluster, true);
        }
    }

    protected onClusterClick(event: google.maps.MapMouseEvent, activeCluster: CGICluster): boolean
    {
        this._googleMap?.sendMapMouseDownEvent();

        if (this.isMarkerPopupOpen(activeCluster.marker!))
        {
            this.closeMarkerPopup();
            return false;
        }

        if (event.latLng === null)
        {
            return false;
        }

        if (this._cgiMarkerClusterer !== null && this._cgiMarkerClusterer.activeMarker !== null &&
            this._cgiMarkerClusterer.findClusterByMarker(this._cgiMarkerClusterer.activeMarker) === undefined)
        {
            this.closeMarkerPopup();

            for (const cluster of this._cgiMarkerClusterer.clusters)
            {
                if (cluster.markers.length === activeCluster.markers.length &&
                    cluster.position.lat() === activeCluster.position.lat() && cluster.position.lng() === activeCluster.position.lng())
                {
                    activeCluster = cluster;
                    break;
                }
            }
        }

        this.showClusterInfowWindow(activeCluster);

        return true;
    }

    protected onInfoListItemsChange(): void
    {
        setTimeout(() => this._cgiMarkerPopup?.setVisible(true), AnimationsConstants.ANIMATION_DURATION);
    }

    // #endregion

    // #region Callbacks

    protected updateMapActiveMarkerClusterCallback(): void
    {
    }

    // #endregion

    // #region Protected Methods

    protected buildClusterInfowWindow(_showClusterMarkersSummary: boolean, _cluster: CGICluster, _isCollapsable: boolean): void
    {
    }

    protected collapseShareOptions(): void
    {
        for (const showCopyOptions of this._showCopyOptionsList)
        {
            showCopyOptions.collapse();
        }

        for (const showShareOptions of this._showShareOptionsList)
        {
            showShareOptions.collapse();
        }

        this._showPopupCopyOptions?.collapse();
        this._showPopupShareOptions?.collapse();
    }

    protected ctreateGoogleMapMarker(_location: ICGIMarkerLocation): google.maps.marker.AdvancedMarkerElement
    {
        return new google.maps.marker.AdvancedMarkerElement();
    }

    protected getMarkerData(marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation): ICGIMarkerLocation
    {
        return (marker instanceof google.maps.marker.AdvancedMarkerElement) ? (marker as ICGIMarker<DisplayShipmentMonitoring>).data : marker;
    }

    protected initializeTableExternalShipmentsMonitoringSearchColumns(): void
    {
        const displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();

        this.tableExternalShipmentsMonitoringSearchColumns =
            [
                {
                    columnType: MonitoringShipmentColumnType.ShipmentKey,
                    text: this._loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : this.containerIdColumnName,
                    classes: 'col-container-id',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        this._loginModel.isAccountTypeAmazonUS ? displayShipmentMonitoring.trailerNumber : displayShipmentMonitoring.containerId)
                },
                {
                    columnType: MonitoringShipmentColumnType.DeviceId,
                    text: this.deviceIdColumnName,
                    classes: 'col-device-id',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.deviceDescription)
                },
                {
                    columnType: MonitoringShipmentColumnType.ShipmentKey,
                    text: 'CG-ID',
                    classes: 'col-shipment-key',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.shipmentKey)
                }
            ];
    }

    protected async shareInfo(title: string, text: string, copyOnlyToClipboard: boolean): Promise<void>
    {
        if (copyOnlyToClipboard)
        {
            navigator.clipboard.writeText(text);
            return;
        }

        await ContguardPlugin.share({ title: title, text: text });
    }

    protected selectAndExtractMonitoringMarkerFromCluster(infoData: any, marker: google.maps.marker.AdvancedMarkerElement): void
    {
        const embeddedViewRef: EmbeddedViewRef<any> = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerTemplateRef,
            { info: [infoData] });

        this.openMarkerPopup(marker, embeddedViewRef.rootNodes[0], false);

        if (this._cgiMarkerClusterer !== null)
        {
            this._cgiMarkerClusterer.removeMarker(marker);
        }

        marker.map = this._googleMap?.map;
    }

    protected openMarkerPopup(marker: google.maps.marker.AdvancedMarkerElement, contentElement: HTMLElement, isVisibleOnOpen: boolean = true): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null)
        {
            return;
        }

        const markerPopupIconType: MarkerPopupIconType = (marker.content as HTMLElement).classList.contains('cgimarker-pin') ?
            MarkerPopupIconType.Pin : MarkerPopupIconType.Circle;

        this.closeMarkerPopup(true);

        this._cgiMarkerPopup = new CGIMarkerPopup();
        this._cgiMarkerPopup.setVisible(isVisibleOnOpen);
        this._cgiMarkerPopup.open({ map: this._googleMap.map, marker: marker, contentElement: contentElement, markerPopupIconType: markerPopupIconType });

        this.updateActiveMarker(marker);
    }

    protected closeMarkerPopup(_isCallingFromOpeneingMarkerPopup: boolean = false, activeMarkerDataProperty: string | null = null): void
    {
        const activeMarker: google.maps.marker.AdvancedMarkerElement | null = this._cgiMarkerClusterer !== null ? this._cgiMarkerClusterer.activeMarker : null;

        if (this._cgiMarkerClusterer !== null && activeMarker !== null && activeMarkerDataProperty !== null &&
            (activeMarker as any)[activeMarkerDataProperty] !== undefined)
        {
            this._cgiMarkerClusterer.addMarker(activeMarker);
        }

        this.updateActiveMarker(null);

        if (this._cgiMarkerPopup !== null)
        {
            this._cgiMarkerPopup.close();
            this._cgiMarkerPopup = null;
        }
    }

    protected handleMarkerClustererClickEvent(): void
    {
        if (this._cgiMarkerClusterer === null)
        {
            return;
        }

        this._cgiMarkerClusterer.onClusterClick = this.onClusterClick.bind(this);
    }

    protected isMarkerPopupOpen(marker: google.maps.marker.AdvancedMarkerElement): boolean
    {
        return this._cgiMarkerPopup !== null && this._cgiMarkerPopup.getMap() !== null && marker === this._cgiMarkerPopup.marker;
    }

    protected updateActiveMarker(activeMarker: google.maps.marker.AdvancedMarkerElement | null): void
    {
        if (this._cgiMarkerClusterer === null)
        {
            return;
        }

        this._cgiMarkerClusterer.activeMarker = activeMarker;
        this._ngZone.run(() => this._changeDetectorRef.detectChanges());
    }

    protected showClusterInfowWindow(cluster: CGICluster): void
    {
        if (cluster.marker === undefined)
        {
            return;
        }

        this.buildClusterInfowWindow(true, cluster, false);
    }

    protected updateMapActiveMarkerCluster(activeCluster: CGICluster): void
    {
        if (this._cgiMarkerClusterer === null || activeCluster.markers.length === 0)
        {
            return;
        }

        const activeClusterFirstMarkerPosition: google.maps.LatLngLiteral = GoogleMapUtils.getMarkerLocation(activeCluster.markers[0]);
        const activeClusterLength: number = activeCluster.markers.length;

        this._cgiMarkerClusterer.waitForMapClusteringEnd().then(() =>
        {
            this.updateMapActiveMarkerClusterCallback();

            for (const cluster of this._cgiMarkerClusterer!.clusters)
            {
                for (const marker of cluster.markers)
                {
                    if (!isEqual(GoogleMapUtils.getMarkerLocation(marker), activeClusterFirstMarkerPosition))
                    {
                        continue;
                    }

                    if (cluster.markers.length === activeClusterLength)
                    {
                        if (this._cgiMarkerPopup !== null && cluster.marker !== undefined)
                        {
                            this._cgiMarkerPopup.marker = cluster.marker;
                        }

                        return;
                    }

                    if (cluster.markers.length > activeClusterLength)
                    {
                        this.showClusterInfowWindow(cluster);
                        return;
                    }

                    break;
                }
            }

            this.closeMarkerPopup();
        });
    }

    protected updateMapFromActiveMarker(): boolean
    {
        if (this._cgiMarkerClusterer === null)
        {
            return false;
        }

        const activeCluster: CGICluster | undefined = this._cgiMarkerClusterer.findClusterByMarker(this._cgiMarkerClusterer.activeMarker);
        if (activeCluster !== undefined)
        {
            this.updateMapActiveMarkerCluster(activeCluster);
            return true;
        }

        return false;
    }

    protected getClusterRenderer(): CGIClusterBaseRenderer
    {
        return {} as CGIClusterBaseRenderer;
    }

    protected initializeGoogleMap(): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null)
        {
            return;
        }

        google.maps.event.addListener(this._googleMap.map, 'click', () =>
        {
            this.closeMarkerPopup();
            this._googleMap?.sendMapMouseDownEvent();
        });

        this.initializeMapZoomEvent();
    }

    protected handleMarkerClustererDblClickEvent(onClusterClick: onClusterClickHandler): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null || this._cgiMarkerClusterer === null)
        {
            return;
        }

        this._cgiMarkerClusterer.onClusterDblClick = (event: google.maps.MapMouseEvent, cluster: CGICluster, map: google.maps.Map) =>
        {
            this._googleMap?.sendMapMouseDownEvent();

            if (event.latLng === null)
            {
                return;
            }

            this._googleMap?.getMaxZoomAtLatLng(event.latLng).then((maxZoom: number | null) =>
            {
                const mapZoom: number | undefined = map.getZoom();
                if (mapZoom === undefined || maxZoom === null)
                {
                    return;
                }

                if (this._cgiMarkerClusterer?.activeMarker !== null && this._cgiMarkerClusterer?.activeMarker !== cluster.marker)
                {
                    this.closeMarkerPopup();
                }

                if (mapZoom < maxZoom)
                {
                    map.setOptions({ restriction: null, maxZoom: maxZoom });
                    onClusterClick(event, cluster, map);
                    map.setOptions({ restriction: this._googleMap?.mapRestrictions, maxZoom: null });

                    this._googleMap?.waitForMapIdle().then(() =>
                    {
                        if (this._cgiMarkerClusterer !== null)
                        {
                            for (const newCluster of this._cgiMarkerClusterer.clusters)
                            {
                                if (newCluster.position.equals(event.latLng))
                                {
                                    if (this._cgiMarkerPopup !== null)
                                    {
                                        this._cgiMarkerPopup.marker = newCluster.marker!;
                                        this.updateActiveMarker(this._cgiMarkerPopup.marker);
                                    }

                                    return;
                                }
                            }

                            this.closeMarkerPopup();
                        }
                    });
                }
            });
        }
    }

    protected getFormattedDateTime(date: Date | null): string
    {
        if (date === null)
        {
            return ManagerConstants.MISSING_VALUE;
        }

        return Utils.getFormattedDateTime(date, this._appSettingsService.appSettings.isUsingUTCTime ? DateTimeFormatType.DateTime : DateTimeFormatType.DateTimeGMT);
    }

    // #endregion

    // #region Private Methods

    private initializeMapZoomEvent(): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null)
        {
            return;
        }

        google.maps.event.addListener(this._googleMap.map, 'zoom_changed', () =>
        {
            this.updateMapFromActiveMarker();
        });
    }

    // #endregion
}