<!DOCTYPE html>

@if (viewIsInitialized) {
  <form class="manager-item-from" #formManagerItem="ngForm"
    (ngSubmit)="onManagerItemSubmit(formManagerItem.form.valid && managerItemModel.isContractPeriodValid && managerItemModel.managerItem.demurrageContractPortsItems.length > 0)"
    @fadeInOutAnimation [class.form-validated]="formManagerItem.submitted" ignoreEnter novalidate>
    <fieldset [disabled]="managerItemModel.isBusy">
      <div class="form-content-wrapper">
        <div #pageScroller class="form-content page-scroll-padding" overlayScrollbar>
          <div class="section-title">Contract Period</div>
          <div class="horizontal-groups">
            <div class="horizontal-group">
              <datetime-picker label="From" name="fromDate" #fromDate="ngModel" (change)="managerItemModel.validateContractPeriod()"
                [(ngModel)]="managerItemModel.managerItem.fromDate" [autoSelect]="false"
                [maxDate]="managerItemModel.managerItem.toDate" [isRequired]="true" requiredValidator>
              </datetime-picker>
              @if (formManagerItem.submitted && fromDate.invalid) {
                <div class="invalid-feedback" @foldAnimation>This field is required</div>
              }
            </div>
            <div class="horizontal-group">
              <datetime-picker label="To" name="toDate" #toDate="ngModel" (change)="managerItemModel.validateContractPeriod()"
                [(ngModel)]="managerItemModel.managerItem.toDate" [autoSelect]="false"
                [minDate]="managerItemModel.managerItem.fromDate" [isRequired]="true" requiredValidator>
              </datetime-picker>
              @if (formManagerItem.submitted && toDate.invalid) {
                <div class="invalid-feedback" @foldAnimation>This field is required</div>
              }
            </div>
          </div>
          @if (formManagerItem.submitted && !toDate.invalid && !fromDate.invalid && !managerItemModel.isContractPeriodValid) {
            <div class="invalid-feedback"
              @foldAnimation>
              This contract period is overlapping with other contrcts
            </div>
          }
          <div class="section-title">Contract Carrier</div>
          <div class="form-group">
            <select-list name="carrier" #carrier="ngModel" placeholder="Carrier" [items]="managerItemModel.demurrageLists.carriers"
              [isRequired]="true" [(ngModel)]="managerItemModel.managerItem.carrierId" bindLabel="carrierName"
              bindValue="carrierId" (change)="managerItemModel.validateContractPeriod()" requiredValidator>
            </select-list>
            @if (formManagerItem.submitted && carrier.invalid) {
              <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
          </div>
          <div class="section-title">
            <span class="required">Contract Ports</span>
            <button type="button" class="add-button ripple-effect ripple-fill" tooltipPopup="Add contract ports"
              (click)="onAddPortDataButtonClick()" [disabled]="managerItemModel.isSelectedPerimeterIdsFull">
              <span>+</span>
            </button>
          </div>
          @if (formManagerItem.submitted && managerItemModel.managerItem.demurrageContractPortsItems.length === 0) {
            <div
              class="invalid-feedback section-title-invalid" @foldAnimation>
              <span>Contract ports are required</span>
            </div>
          }
          <div class="demurrage-ports-container">
            @for (demurragePortItem of managerItemModel.managerItem.demurrageContractPortsItems; track $index; let itemIndex = $index) {
              <div class="demurrage-port animation-overflow" @foldFadeAnimation [@.disabled]="managerItemModel.isBusy"
                >
                <div class="perimeters-group">
                  <select-list name="perimeters{{itemIndex}}" #perimeters="ngModel" placeholder="Ports"
                    [items]="demurragePortItem.perimeters"
                    [isRequired]="true" [(ngModel)]="demurragePortItem.portIds" bindLabel="perimeterName"
                    bindValue="perimeterId" [multiple]="true" requiredValidator
                    (change)="managerItemModel.updateDemurrageContractPerimetersLists()">
                  </select-list>
                  @if (formManagerItem.submitted && perimeters.invalid) {
                    <div class="invalid-feedback"
                      @foldAnimation>
                      This field is required
                    </div>
                  }
                </div>
                <div class="delete-group">
                  <button type="button" class="image-button ripple-effect ripple-center" (click)="onDeletePortDataButtonClick(itemIndex)">
                    <i class="icon-delete"></i>
                  </button>
                </div>
                <div class="free-days-group">
                  <numeric-input name="polFreeDays{{itemIndex}}" #polFreeDays="ngModel" [isRequired]="true" requiredValidator
                    [(ngModel)]="demurragePortItem.polFreeDays" label="POL Free Days">
                  </numeric-input>
                  @if (formManagerItem.submitted && polFreeDays.invalid) {
                    <div class="invalid-feedback"
                      @foldAnimation>
                      This field is required
                    </div>
                  }
                </div>
                <div class="free-days-group">
                  <numeric-input name="podFreeDays{{itemIndex}}" #podFreeDays="ngModel" [isRequired]="true" requiredValidator
                    [(ngModel)]="demurragePortItem.podFreeDays" label="POD Free Days">
                  </numeric-input>
                  @if (formManagerItem.submitted && podFreeDays.invalid) {
                    <div class="invalid-feedback"
                      @foldAnimation>
                      This field is required
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        <div class="form-group manager-buttons action-button-container">
          @if (managerItemModel.managerItem.contractId === null) {
            <button type="button" class="secondary-button submit-button ripple-effect ripple-fill"
              (click)="onCancelManagerItemButtonClick()">
              Close
            </button>
          }
          @if (managerItemModel.managerItem.contractId !== null) {
            <button type="button" class="secondary-button submit-button ripple-effect ripple-fill" [disabled]="!managerItemModel.isDirty"
              (click)="loadManagerItem(false)">
              Revert
            </button>
          }
          <button type="submit" class="primary-button ripple-effect ripple-fill"
            [disabled]="!managerItemModel.isDirty && managerItemModel.managerItem.contractId !== null">
            <span class="spinner-container">
              @if (managerItemModel.isBusy) {
                <i @fadeInOutAnimation class="icon-spinner"></i>
              }
              {{ managerItemModel.managerItem.contractId !== null ? 'Update' : 'Add' }}
            </span>
          </button>
        </div>
      </div>
    </fieldset>
  </form>
}
