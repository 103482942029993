<!DOCTYPE html>

<div class="page-container">
    @if (viewIsInitialized) {
    <div class="page-content" @fadeInAnimation>
        <div class="main-content-container">
            <div class="live-filters-wrapper">
                <div class="live-filters-container page-section page-section-padding">
                    <div class="live-filters-header-container">
                        <div class="live-filters-header">
                            <button class="back-button image-button ripple-effect ripple-center ripple-inverse-color" (click)="onBackButtonClick()"
                                    [disabled]="!canNavigateBack">
                                <i class="icon-collapse-left"></i>
                            </button>
                            <div class="filters-title" [@fadeVisibilityAnimation]="!showSearchShipments">
                                <span class="filters-title-name">
                                    <i [class]="shipmentMonitoringModel.displayShipmentMonitoring.statusId === ManagerItemStatus.Live ? 'icon-signal-stream' : 'icon-flag'"></i>
                                    <span>{{ loginModel.isAccountTypeAmazonUS ? shipmentMonitoringModel.displayShipmentMonitoring.trailerNumber : shipmentMonitoringModel.displayShipmentMonitoring.containerId }}</span>
                                </span>
                                <div class="filters-subtitle"
                                     [class]="MonitoringArrivalStatusType[shipmentMonitoringModel.displayShipmentMonitoring.arrivalStatusId].toLowerCase()">
                                    <i [class]="shipmentArrivalStatusesStyleProperties[shipmentMonitoringModel.displayShipmentMonitoring.arrivalStatusId].iconClassName"></i>
                                    <span>{{ shipmentMonitoringModel.displayShipmentMonitoring.delayString }}</span>
                                </div>
                            </div>
                            <button type="button" class="image-button ripple-effect ripple-inverse-color" (click)="showSearchShipments = true">
                                <i class="icon-search"></i>
                            </button>
                            <div class="search-container" dropdown [(isExpanded)]="showSearchShipments" [autoClose]="false"
                                 [class.active]="showSearchShipments">
                                @if (showSearchShipments) {
                                <div class="dropdown-container search" @foldHorizontalAnimation>
                                    <clearable-input class="search-filter" [(ngModel)]="monitoringSearchFilter" label="Search for Shipment"
                                                     icon="icon-search" autofocus tabindex="0">
                                    </clearable-input>
                                    <button type="button" class="image-button collapse-button ripple-effect ripple-inverse-color"
                                            (click)="showSearchShipments = false">
                                        <i class="icon-arrow-right"></i>
                                    </button>
                                </div>
                                }
                                @if (showSearchShipments && monitoringSearchFilter !== null && monitoringSearchFilter.trim().length > 0) {
                                <div class="shipments-monitoring-filter-table-container animation-overflow" @foldAnimation>
                                    <virtual-list #itemsList [items]="shipmentMonitoringModel.allMonitoringItems" [maxViewportItems]="5" tabindex="-1"
                                                  id="shipments-monitoring-filter-table" [filterProperties]="tableExtrenalShipmentsMonitoringSearchProperties"
                                                  [filter]="monitoringSearchFilter" emptyPlaceholder="No Results"
                                                  [isSortable]="true" [isAutoColumnsWidths]="true" [isAutoWidthByRow]="true"
                                                  [virtualListInfo]="externalShipmentsFilterTableListInfo"
                                                  overlayScrollbar>
                                        <div #header class="list-header">
                                            @for (column of tableExternalShipmentsMonitoringSearchColumns; track column.columnType) {
                                            <div class="col-list" [class]="column.classes ?? ''"
                                                 [attr.property-name]="column.propertyName"
                                                 [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                                                <span [innerHTML]="column.text"></span>
                                            </div>
                                            }
                                        </div>
                                        @for (item of itemsList.viewPortItems; track item.shipmentKey) {
                                        <div #body class="list-row"
                                             (click)="onShipmentMonitoringFilterTableItemClick(item.shipmentKey)">
                                            @for (column of tableExternalShipmentsMonitoringSearchColumns; track column.columnType) {
                                            <div class="col-list" [class]="column.classes ?? ''">
                                                <ng-container [ngTemplateOutlet]="columnEmptyValueTemplate"
                                                              [ngTemplateOutletContext]="{ column: column, item: item }">
                                                </ng-container>
                                            </div>
                                            }
                                        </div>
                                        }
                                    </virtual-list>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="live-filters-content-container page-section-scroll-padding">
                        <div class="form-group live-info-dates-container">
                            <div class="live-info-item-container">
                                <div class="live-info-item">
                                    <div class="live-info-label">Started<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '' }}</span></div>
                                    <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.installDateShortFormatted }}</div>
                                </div>
                            </div>
                            <div class="live-info-item-container">
                                <div class="live-info-item">
                                    <div class="live-info-label">PTA<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '' }}</span></div>
                                    <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.ptaShortFormatted }}</div>
                                </div>
                            </div>
                            <div class="live-info-item-container">
                                <div class="live-info-item">
                                    <div class="live-info-label">{{ shipmentMonitoringModel.displayShipmentMonitoring.statusId === ManagerItemStatus.Live ? 'ETA' : 'Ended' }}
                                        <span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '' }}</span>
                                    </div>
                                    <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.statusId === ManagerItemStatus.Live ? shipmentMonitoringModel.displayShipmentMonitoring.etaShortFormatted : shipmentMonitoringModel.displayShipmentMonitoring.removeDateShortFormatted }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="live-info-container">
                            <div class="live-info-label">Device</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.deviceId }}</div>
                            <div class="live-info-label">Reference</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.bookingReference }}</div>
                            <div class="live-info-label">Origin</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.originName }}</div>
                            <div class="live-info-label">Destination</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.destinationName }}</div>
                            @if (shipmentMonitoringModel.displayShipmentMonitoring.carrierName !== null) {
                            <div class="live-info-label">Carrier</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.carrierName }}</div>
                            }
                            <div class="live-info-label route-progress">Progress</div>
                            <div class="live-info-text route-progress">
                                <div class="route-progress-container">
                                    <div class="route-progress-value" [style.width]="shipmentMonitoringModel.displayShipmentMonitoring.progress + '%'"></div>
                                </div>
                                <div class="route-progress-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.progress }}%</div>
                            </div>
                            <div class="seperator"></div>
                            <div class="live-info-label">Last Update<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '' }}</span></div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.lastUpdateFormatted }}</div>
                            <div class="live-info-label">Duration</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.currentFormattedDuration }}</div>
                            <div class="live-info-label">Coordinates</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.currentLat }}°, {{ shipmentMonitoringModel.displayShipmentMonitoring.currentLon }}°</div>
                            <div class="live-info-label">CG-ID</div>
                            <div class="live-info-text">{{ shipmentMonitoringModel.displayShipmentMonitoring.shipmentKey }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="visual-content-container" overlayScrollbar>
                @if (showCharts) {
                <div class="charts-section-container" [@foldHorizontalStateAnimation]="!collapseShipmentMonitoringCharts">
                    <div class="charts-section page-section">
                        <div class="charts-selection-container">
                            @for (chartSelection of availableCharts; track chartSelection.index) {
                            <label class="checkbox-container events ripple-effect ripple-fill ripple-inverse-color"
                                   [class]="chartSelection.class"
                                   [tooltipPopup]="chartSelection.tooltip">
                                <input type="checkbox" name="events"
                                       [disabled]="selectedChartsCount === 1 && chartSelection.isSelected || selectedChartsCount === 3 && !chartSelection.isSelected"
                                       [(ngModel)]="chartSelection.isSelected" />
                                <span class="container-background"></span>
                                <i [class]="chartSelection.iconClassName"></i>
                            </label>
                            }
                            <div class="charts-selection-remark">* Please select 1 to 3 charts</div>
                        </div>
                        <div class="charts-container" [class.resizing]="isChartsSectionResizing">
                            @for (chartDetails of chartsDetails; track chartDetails.chartOptions.chart.id; let chartIndex = $index) {
                            @if (availableCharts[chartIndex].isSelected) {
                            <div class="chart-container">
                                <apx-chart @fadeInOutAnimation (wheel)="onChartWheel($event)"
                                           [series]="chartDetails.chartSeries"
                                           [chart]="chartDetails.chartOptions.chart"
                                           [title]="chartDetails.chartOptions.title"
                                           [dataLabels]="chartDetails.chartOptions.dataLabels"
                                           [colors]="chartDetails.chartOptions.colors"
                                           [stroke]="chartDetails.chartOptions.stroke"
                                           [tooltip]="chartDetails.chartOptions.tooltip"
                                           [states]="chartDetails.chartOptions.states"
                                           [markers]="chartDetails.chartOptions.markers"
                                           [xaxis]="chartDetails.chartOptions.xaxis"
                                           [yaxis]="chartDetails.chartOptions.yaxis"
                                           [annotations]="chartDetails.chartOptions.annotations">
                                </apx-chart>
                            </div>
                            }
                            }
                        </div>
                        @if (showCharts) {
                        <div class="vertical-expander right" [class.expanded]="collapseShipmentMonitoringMap">
                            <button type="button" class="ripple-effect ripple-center ripple-inverse-color" [class.flipped]="collapseShipmentMonitoringMap"
                                    (click)="collapseShipmentMonitoringMap = !collapseShipmentMonitoringMap">
                                <i class="icon-arrow-right"></i>
                            </button>
                        </div>
                        }
                    </div>
                </div>
                }
                @if (showCharts && !collapseShipmentMonitoringCharts && !collapseShipmentMonitoringMap) {
                <div class="divider" @foldHorizontalAnimation></div>
                }
                <div class="map-wrapper" [@foldHorizontalStateAnimation]="!collapseShipmentMonitoringMap"
                     (@foldHorizontalStateAnimation.start)="isChartsSectionResizing = true"
                     (@foldHorizontalStateAnimation.done)="isChartsSectionResizing = false">
                    <google-map #googleMap [showTreetView]="true" (initialize)="onGoogleMapInitialize($event)">
                        <div class="map-controls-container top">
                            <div class="events-buttons-container">
                                @for (filterElement of filterEventElements; track filterElement.filterType) {
                                @if (filterElement.value > 0) {
                                <label class="checkbox-container events ripple-effect ripple-fill ripple-inverse-color"
                                       [class]="filterElement.class" [tooltipPopup]="filterElement.tooltip">
                                    <input type="checkbox" name="events"
                                           [(ngModel)]="shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[filterElement.filterType].isFiltered"
                                           (change)="onShipmentMonitoringEventsFilterChange()" />
                                    <span class="container-background"></span>
                                    <i [class]="filterElement.iconClassName"></i>
                                    <span class="checkbox-text">{{ filterElement.value }}</span>
                                </label>
                                }
                                }
                            </div>
                            <div class="popup-button-container more-options">
                                <div class="dropdown" dropdown [(isExpanded)]="showMonitoringPopups[currentMonitoringPopup]"
                                     (isExpandedChange)="onShowMonitoringItemsPopupsChange()">
                                    <button type="button" class="floating-button ripple-effect ripple-fill" (click)="onMapMoreOptionsButtonClick($event)">
                                        <i class="icon-more"></i>
                                    </button>
                                    @if (showMonitoringPopups[MonitoringPopupType.More]) {
                                    <div class="dropdown-container left" @foldHorizontalAnimation
                                         (@foldHorizontalAnimation.done)="onShowMapMoreOptionsFoldAnimationDone()">
                                        <button type="button" class="menu-button" (click)="onRefreshButtonClick()"><i class="icon-refresh"></i>Refresh</button>
                                        <button type="button" class="menu-button" (click)="onExportToExcelButtonClick()">
                                            <i class="icon-excel"></i>Export to Excel
                                        </button>
                                        <button type="button" class="menu-button" (click)="onShareSnapshotMenuItemClick()">
                                            <i class="icon-share-nodes"></i>Share Snapshot
                                        </button>
                                        <button type="button" class="menu-button" (click)="onHelpButtonClick($event)"><i class="icon-question"></i>Help</button>
                                        <div class="seperator"></div>
                                        <button type="button" class="menu-button"
                                                (click)="showCurrentTelemetryLocationMarker = !showCurrentTelemetryLocationMarker">
                                            <i class="current-icon"></i>Show Current Location
                                            <i [class.hide]="!showCurrentTelemetryLocationMarker" class="icon-check"></i>
                                        </button>
                                        <button type="button" class="menu-button" (click)="showMapRailways = !showMapRailways">
                                            <i class="icon-train-station"></i>Show Railways
                                            <i [class.hide]="!showMapRailways" class="icon-check"></i>
                                        </button>
                                        <div class="seperator"></div>
                                        <button type="button" class="menu-button" (click)="onMapModeButtonClick(true)">
                                            <i class="icon-earth-americas"></i>Satellite View
                                            <i [class.hide]="!isSatelliteView" class="icon-check"></i>
                                        </button>
                                        <button type="button" class="menu-button" (click)="onMapModeButtonClick(false)">
                                            <i class="icon-map"></i>Map View
                                            <i [class.hide]="isSatelliteView" class="icon-check"></i>
                                        </button>
                                    </div>
                                    }
                                    @if (showMonitoringPopups[MonitoringPopupType.Help]) {
                                    <div class="popup-button-content-container help select-none"
                                         @foldHorizontalAnimation>
                                        <ng-container [ngTemplateOutlet]="popupHelpContentTemplate"></ng-container>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="map-controls-container bottom">
                            <button type="button" class="center-button floating-button ripple-effect ripple-fill" (click)="onReCenterMapButtonClick()">
                                @if (!isMarkerActive) {
                                <i class="icon-target" @fadeInOutAnimation></i>
                                }
                                @if (isMarkerActive) {
                                <i class="icon-center" @fadeInOutAnimation></i>
                                }
                            </button>
                        </div>
                    </google-map>
                    @if (showCharts) {
                    <div class="vertical-expander" [class.expanded]="collapseShipmentMonitoringCharts">
                        <button type="button" class="ripple-effect ripple-center ripple-inverse-color dropdown-shadow" [class.flipped]="collapseShipmentMonitoringCharts"
                                (click)="collapseShipmentMonitoringCharts = !collapseShipmentMonitoringCharts">
                            <i class="icon-arrow-left"></i>
                        </button>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div class="live-manager-table-wrapper animation-overflow">
            <div class="manager-table-container page-section page-section-padding">
                <div class="shipment-plan-wrapper">
                    <table class="shipment-plan-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="segment-expander">
                                    <div [class.expanded]="isShipmentMonitoringPlanSubSegmentsExpanded" (click)="onShipmentMonitoringPlanAllSubSegmentsCollapseClick()">
                                        <i class="icon-arrow-down-light"></i>
                                    </div>
                                </th>
                                <th></th>
                                <th class="segment-title">Timeline Progress</th>
                                <th class="segment-country">Country</th>
                                <th class="segment-eta">Arrival<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '' }}</span></th>
                                <th class="segment-duration">Duration</th>
                                @if (!shipmentMonitoringModel.eventsOccurrencesIsEmpty) {
                                <th class="segment-events">Events</th>
                                }
                            </tr>
                        </thead>
                        @for (item of shipmentMonitoringModel.displayShipmentMonitoringPlanSegments; track item.segmentIndex; let itemIndex = $index) {
                        <tbody class="plan-segment"
                               [class.current]="shipmentMonitoringModel.displayShipmentMonitoringCurrentPlanSegmentIndex === item.segmentIndex"
                               [class.selected]="shipmentMonitoringModel.selectedShipmentMonitoringPlanSegmentIndex === item.segmentIndex"
                               [class.end]="itemIndex === shipmentMonitoringModel.displayShipmentMonitoringPlanSegments.length - 1"
                               [class.future]="item.segmentIndex !== null && shipmentMonitoringModel.displayShipmentMonitoringCurrentPlanSegmentIndex >= 0 && shipmentMonitoringModel.displayShipmentMonitoringCurrentPlanSegmentIndex < item.segmentIndex">
                            <tr [class.odd-row]="isShipmentMonitoringPlanSegmentIndexOdd(item)"
                                [class.clickable]="item.perimeterLatitude !== null && item.perimeterLongitude !== null || item.telemetries.length > 0"
                                (click)="onPlanSegmentClick(item)">
                                <td class="segment-pregress-placeholder"></td>
                                <td class="segment-expander">
                                    @if (item.subSegments.length > 0) {
                                    <div [class.expanded]="item.isExpanded"
                                         (click)="onShipmentMonitoringPlanSubSegmentCollapseClick($event, item)">
                                        <i class="icon-arrow-down-light"></i>
                                    </div>
                                    }
                                </td>
                                <td class="segment-icon"><div><i [class]="item.iconClass"></i></div></td>
                                <td class="segment-title" #segmentTitle>
                                    <div class="segment-title-content" [innerHTML]="item.title" [tooltipPopup]="item.title" [showTooltipOnVerflow]="true"
                                         [tooltipPointerInactive]="false" [tooltipEventsElement]="segmentTitle">
                                    </div>
                                </td>
                                <td class="segment-country">
                                    @if (item.countryCode !== null && item.countryName !== null) {
                                    <div [tooltipPopup]="item.countryName">
                                        <img src="assets/images/flags/{{ item.countryCode }}.png">
                                        <span class="country-code">({{ item.countryCode }})</span>
                                    </div>
                                    }
                                    @if (item.countryCode === null) {
                                    <i class="icon-arrow-down-thin"></i>
                                    }
                                </td>
                                <td class="segment-eta">{{ item.etaFormatted }}</td>
                                <td class="segment-duration" [class.empty-value]="!item.durationFormatted">
                                    <span>{{ item.durationFormatted ?? ('EMPTY_FIELD_VALUE' | globals) }}</span>
                                </td>
                                @if (!shipmentMonitoringModel.eventsOccurrencesIsEmpty) {
                                <td class="segment-events">
                                    @for (filterEventElement of filterEventElements; track filterEventElement.filterType) {
                                    <ng-container [ngTemplateOutlet]="planSegmetEventsColumnTemplate"
                                                  [ngTemplateOutletContext]="{item: item, eventType: filterEventElement.filterType}">
                                    </ng-container>
                                    }
                                </td>
                                }
                            </tr>
                            @for (subItem of item.subSegments; track $index) {
                            <tr class="sub-segment" [class.odd-row]="isShipmentMonitoringPlanSegmentIndexOdd(subItem)"
                                [class.collapsed]="!item.isExpanded">
                                <td class="segment-pregress-placeholder"></td>
                                <td></td>
                                <td></td>
                                <td class="segment-title">{{ subItem.description }}</td>
                                <td class="segment-country"><i class="icon-arrow-down-thin"></i></td>
                                <td class="segment-eta">{{ subItem.dateFormatted }}</td>
                                <td></td>
                                @if (!shipmentMonitoringModel.eventsOccurrencesIsEmpty) {
                                <td class="segment-events">
                                    @for (filterEventElement of filterEventElements; track filterEventElement.filterType) {
                                    <ng-container [ngTemplateOutlet]="planSegmetEventsColumnTemplate"
                                                  [ngTemplateOutletContext]="{item: subItem, eventType: filterEventElement.filterType}">
                                    </ng-container>
                                    }
                                </td>
                                }
                            </tr>
                            }
                        </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    </div>
    }
    @if (!viewIsReady || shipmentMonitoringModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @shortFadeInOutAnimation
            [loadingMessage]="statusMessage" [showSpinner]="showSpinner || shipmentMonitoringModel.isBusy">
    </loader>
    }
</div>

<!-- columnEmptyValueTemplate -->
<ng-template #columnEmptyValueTemplate let-column="column" let-item="item">
    <span>{{ !column.propertyName || !item[column.propertyName] ? ('EMPTY_FIELD_VALUE' | globals) : item[column.propertyName] }}</span>
</ng-template>

<!-- planSegmetEventsColumnTemplate -->
<ng-template #planSegmetEventsColumnTemplate let-item="item" let-eventType="eventType">
    @if (shipmentMonitoringModel.eventsOccurrences[eventType] > 0) {
    <span class="event-value" [class.active]="item.eventsOccurrences[eventType] > 0">
        @if (item.eventsOccurrences[eventType] > 0) {
        <span>{{ item.eventsOccurrences[eventType] }} </span>
        }
        <i [class]="shipmentEventsStyleProperties[eventType].iconClassName"></i>
    </span>
    }
</ng-template>

<!-- dropdownShareEventInfoTemplate -->
<ng-template #dropdownShareEventInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, false)">
            <i class="icon-list-ul"></i>Share event's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, false)">
            <i class="icon-location-dot"></i>Share event's location
        </button>
    </div>
</ng-template>

<!-- dropdownCopyEventInfoTemplate -->
<ng-template #dropdownCopyEventInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, true)">
            <i class="icon-list-ul"></i>Copy event's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, true)">
            <i class="icon-location-dot"></i>Copy event's location
        </button>
    </div>
</ng-template>

<!-- popupHelpContentTemplate -->
<ng-template #popupHelpContentTemplate>
    <div class="popup-button-content-wrapper" (click)="onPopupContentClick($event)">
        <div class="popup-button-content page-scroll-padding" overlayScrollbar>
            <div class="help-title">How to use the map?</div>
            <div class="help-value">
                <p>The map shows events and groups of events.</p>
                <p>Groups of events are shown as a circle with the number of events.</p>
            </div>
            <div class="help-label">Click</div>
            <div class="help-value">
                <p>Clicking an event on the map shows its details.</p>
                <p>Clicking a group of events shows a list of their combined events.</p>
            </div>
            <div class="help-label">Double Click</div>
            <div class="help-value">
                <p>Double-clicking an event or a group of events on the map zooms the map in on the clicked item. Zooming in may show some of the group's events separately.</p>
            </div>
            <div class="help-label">Events Icons</div>
            <div class="help-value icons-index">
                <i class="icon-explosion-solid"></i><span>Impact</span>
                <i class="icon-temperature-full"></i><span>Temperature</span>
                <i class="icon-droplet"></i><span>Humidity</span>
                <i class="icon-lock-open"></i><span>Security</span>
                <i class="icon-hand"></i><span>Stop</span>
                <i class="icon-time-based-temperature"></i><span>Time-Based Temperature</span>
                <i class="icon-time-based-humidity"></i><span>Time-Based Humidity</span>
                <!--<ng-container *ngFor="let item of eventsIconsDisplayProperties">
                  <i [class]="item.iconClassName"></i><span>{{ item.tooltip }}</span>
                </ng-container>-->
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerClusterTemplate -->
<ng-template #infoWindowMarkerClusterTemplate let-eventsOccurrences>
    <div class="marker-info-container page-scroll-padding select-none">
        <div class="marker-info-content-container">
            <div class="marker-info-content select-none">
                <div class="marker-info-item-label">
                    <button type="button" class="image-button" (click)="onClusterInfoExpandButtonClick()"><i class="icon-expand"></i></button>
                    <span>Events</span>
                </div>
                <div class="marker-info-item-value center">
                    @for (eventOccurrences of eventsOccurrences; track $index; let eventType = $index) {
                    @if (eventOccurrences > 0) {
                    <span class="event-value">
                        <span>{{ eventOccurrences }}</span>
                        <i [class]="shipmentEventsStyleProperties[eventType].iconClassName"></i>
                    </span>
                    }
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerTelemetryTemplate -->
<ng-template #infoWindowMarkerTelemetryTemplate let-shipmentMonitoringTelemetry>
    <div class="marker-info-container page-scroll-padding">
        <div class="marker-info-content-container">
            <div class="marker-info-content">
                <div class="marker-info-item-line center">{{ shipmentMonitoringTelemetry.telemetryTimeFormatted }}{{ (appSettingsService.appSettings.isUsingUTCTime ? ' UTC' : '') }}</div>
                <div class="marker-info-item-label">Coordinates</div>
                <div class="marker-info-item-value">{{ shipmentMonitoringTelemetry.latitude }}°, {{ shipmentMonitoringTelemetry.longitude }}°</div>
                <div class="marker-info-item-label">Source</div>
                <div class="marker-info-item-value">
                    {{ ShipmentMonitoringTelemetrySourceType[shipmentMonitoringTelemetry.telemetrySource] }}
                    @if (shipmentMonitoringTelemetry.telemetrySource === ShipmentMonitoringTelemetrySourceType.Device && shipmentMonitoringTelemetry.locationMethod !== null) {
                    <span>{{ ' (' + shipmentMonitoringTelemetry.locationMethod + ')'  }}</span>
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- eventMoreInfoTemplate -->
<ng-template #eventMoreInfoTemplate let-item="data">
    <div class="modal-content marker-info-content">
        <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" (click)="onModalButtonCloseClick()">
            <i class="icon-cancel"></i>
        </button>
        <div class="modal-body page-scroll-padding" overlayScrollbar>
            <div class="marker-info-item-label">Start Time</div>
            <div class="marker-info-item-value">{{ item.fromDateFormatted }}{{(appSettingsService.appSettings.isUsingUTCTime ? ' UTC' : '')}}</div>
            <div class="marker-info-item-label">Start Location Time</div>
            <div class="marker-info-item-value">{{ item.telemetries[0].locationUpdateTimeFormatted }}{{(appSettingsService.appSettings.isUsingUTCTime ? ' UTC' : '')}}</div>
            <div class="marker-info-item-label">Start Location Source</div>
            <div class="marker-info-item-value">{{ item.telemetries[0].locationMethod }}</div>
            <div class="marker-info-item-label">Top Level Segment</div>
            <div class="marker-info-item-value">{{ item.telemetries[0].topLevelSegmentName }}</div>
            <div class="marker-info-item-label">Event</div>
            <div class="marker-info-item-value center">
                <i [class]="shipmentEventsStyleProperties[item.eventType].iconClassName"></i>
                <span>{{ item.eventDesc }}</span>
            </div>
            @if (item.durationFormatted) {
            <div class="marker-info-item-label">Duration</div>
            }
            @if (item.durationFormatted) {
            <div class="marker-info-item-value">{{ item.durationFormatted }}</div>
            }
            @if (item.minValue !== null && item.maxValue !== null) {
            <div class="marker-info-item-label">
                <span>{{ shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[item.eventType].telemetryValueTitle }}</span>
            </div>
            <div class="marker-info-item-value">
                <span>{{ item.minValue }}{{ shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[item.eventType].telemetryUnits }}</span>
                @if (item.minValue !== item.maxValue) {
                <span> to {{ item.maxValue }}{{ shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[item.eventType].telemetryUnits }}</span>
                }
            </div>
            }
            @if (item.minThreshold !== null || item.maxThreshold !== null) {
            <div class="marker-info-item-label">Threshold</div>
            <div class="marker-info-item-value">
                <span>{{ (item.minThreshold !== null ? (item.minThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[item.eventType].telemetryUnits) : '') }}</span>
                @if (item.minThreshold !== null && item.maxThreshold !== null) {
                <span> to </span>
                }
                <span>{{ (item.maxThreshold !== null ? (item.maxThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[item.eventType].telemetryUnits) : '') }}</span>
            </div>
            }
            @if (item.timeBasedMinThreshold !== null || item.timeBasedMaxThreshold !== null) {
            <div class="marker-info-item-label">Threshold±</div>
            <div class="marker-info-item-value">
                <span>{{ (item.timeBasedMinThreshold !== null ? (item.timeBasedMinThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[item.eventType].telemetryUnits) : '') }}</span>
                @if (item.timeBasedMinThreshold !== null && item.timeBasedMaxThreshold !== null) {
                <span> to </span>
                }
                <span>{{ (item.timeBasedMaxThreshold !== null ? (item.timeBasedMaxThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[item.eventType].telemetryUnits) : '') }}</span>
            </div>
            }
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerTemplate -->
<ng-template #infoWindowMarkerTemplate let-monitoringItemsEvents="info" let-isCollapsable="isCollapsable">
    <div class="marker-info-container page-scroll-padding" [class.multiple]="monitoringItemsEvents.length > 1">
        <virtual-list #infoItemsList [items]="monitoringItemsEvents" [isAutoSizeToContent]="monitoringItemsEvents.length > 1" [isMouseWheelScrollSnap]="false"
                      (itemsChange)="onInfoListItemsChange()">
            @for (shipmentMonitoringEvent of infoItemsList.viewPortItems; track shipmentMonitoringEvent.id) {
            <div #body class="marker-info-content-container">
                <div class="marker-info-content-wrapper">
                    <div class="marker-info-content"
                         (click)="onShipmentMonitoringEventMarkerClick(shipmentMonitoringEvent, monitoringItemsEvents.length > 1)"
                         [class.clickable]="monitoringItemsEvents.length > 1">
                        @if (monitoringItemsEvents.length > 1) {
                        <div class="marker-info-item-index">{{ monitoringItemsEvents.indexOf(shipmentMonitoringEvent) + 1 }} of {{ monitoringItemsEvents.length }}</div>
                        }
                        <div class="marker-info-item-line center">{{ shipmentMonitoringEvent.fromDateFormatted }}{{(appSettingsService.appSettings.isUsingUTCTime ? ' UTC' : '')}}</div>
                        <div class="marker-info-item-label">Event</div>
                        <div class="marker-info-item-value center">
                            <i [class]="shipmentEventsStyleProperties[shipmentMonitoringEvent.eventType].iconClassName"></i>
                            <span>{{ shipmentMonitoringEvent.eventDesc }}</span>
                        </div>
                        @if (shipmentMonitoringEvent.durationFormatted) {
                        <div class="marker-info-item-label">Duration</div>
                        }
                        @if (shipmentMonitoringEvent.durationFormatted) {
                        <div class="marker-info-item-value">{{ shipmentMonitoringEvent.durationFormatted }}</div>
                        }
                        @if (shipmentMonitoringEvent.minValue !== null && shipmentMonitoringEvent.maxValue !== null) {
                        <div class="marker-info-item-label">
                            <span>{{ shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEvent.eventType].telemetryValueTitle }}</span>
                        </div>
                        <div class="marker-info-item-value">
                            <span>{{ shipmentMonitoringEvent.minValue }}{{ shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEvent.eventType].telemetryUnits }}</span>
                            @if (shipmentMonitoringEvent.minValue !== shipmentMonitoringEvent.maxValue) {
                            <span> to {{ shipmentMonitoringEvent.maxValue }}{{ shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEvent.eventType].telemetryUnits }}</span>
                            }
                        </div>
                        }
                        @if (shipmentMonitoringEvent.minThreshold !== null || shipmentMonitoringEvent.maxThreshold !== null) {
                        <div class="marker-info-item-label">Threshold</div>
                        <div class="marker-info-item-value">
                            <span>{{ (shipmentMonitoringEvent.minThreshold !== null ? (shipmentMonitoringEvent.minThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEvent.eventType].telemetryUnits) : '') }}</span>
                            @if (shipmentMonitoringEvent.minThreshold !== null && shipmentMonitoringEvent.maxThreshold !== null) {
                            <span> to </span>
                            }
                            <span>{{ (shipmentMonitoringEvent.maxThreshold !== null ? (shipmentMonitoringEvent.maxThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEvent.eventType].telemetryUnits) : '') }}</span>
                        </div>
                        }
                        @if (shipmentMonitoringEvent.timeBasedMinThreshold !== null || shipmentMonitoringEvent.timeBasedMaxThreshold !== null) {
                        <div class="marker-info-item-label">Threshold±</div>
                        <div class="marker-info-item-value">
                            <span>{{ (shipmentMonitoringEvent.timeBasedMinThreshold !== null ? (shipmentMonitoringEvent.timeBasedMinThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEvent.eventType].telemetryUnits) : '') }}</span>
                            @if (shipmentMonitoringEvent.timeBasedMinThreshold !== null && shipmentMonitoringEvent.timeBasedMaxThreshold !== null) {
                            <span> to </span>
                            }
                            <span>{{ (shipmentMonitoringEvent.timeBasedMaxThreshold !== null ? (shipmentMonitoringEvent.timeBasedMaxThreshold + shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEvent.eventType].telemetryUnits) : '') }}</span>
                        </div>
                        }
                        @if (monitoringItemsEvents.length > 1) {
                        @for (item of shipmentMonitoringEvent.infoWindowExtraRows; track $index) {
                        <div class="marker-info-item-label">&nbsp;</div>
                        <div class="marker-info-item-value">&nbsp;</div>
                        }
                        }
                    </div>
                    <div class="marker-info-item-buttons" (click)="onShipmentMonitoringEventMarkerClick(shipmentMonitoringEvent, monitoringItemsEvents.length > 1)">
                        <div class="dropdown" #showPopupCopyOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownCopyEventInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: shipmentMonitoringEvent }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Copy"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-copy"></i>
                            </button>
                        </div>
                        <div class="dropdown" #showPopupShareOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownShareEventInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: shipmentMonitoringEvent }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Share"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-share-nodes"></i>
                            </button>
                        </div>
                        <button type="button" class="image-button ripple-effect ripple-fill ripple-inverse-color" tooltipPopup="More Info"
                                (click)="onMarkerShipmentMoreInfoButtonClick($event, shipmentMonitoringEvent)">
                            <i class="icon-more-info"></i>
                        </button>
                    </div>
                </div>
            </div>
            }
        </virtual-list>
        @if (isCollapsable) {
        <button type="button" class="image-button collapse-button" (click)="onClusterInfoCollapseButtonClick()">
            <i class="icon-collapse"></i>
        </button>
        }
    </div>
</ng-template>

<!-- infoWindowPlanMarkerTemplate -->
<ng-template #infoWindowPlanMarkerTemplate let-planSegment>
    <div class="marker-info-container page-scroll-padding">
        <div class="marker-info-content-container">
            <div class="marker-info-content">
                <div class="marker-info-item-line">{{ planSegment.title }}</div>
                <div class="marker-info-item-line plan-country">
                    <img src="assets/images/flags/{{ planSegment.countryCode }}.png">
                    <span class="country-code">{{ planSegment.countryName }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

