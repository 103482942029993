import { Component, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { LoginModel } from './model/login.model'
import { foldAnimation } from '../../animations/fold.animation';
import { Router } from "@angular/router";
import { Constants, IApiResponse, IApiResponseData, RouteType } from '../../utils/globals';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { ModalType } from '../../controls/modal-message/modal-message.component';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import ContguardPlugin, { IExternalLoginResponse, IGetAppVersionResponse } from '../../../plugins/contguard.plugin';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { GlobalsPipe } from '../../pipes/globals.pipe';
import { PasswordInputComponent } from '../../controls/password-input/password-input.component';
import { RequiredValidatorDirective } from '../../validators/required.validator';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { NgClass } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    animations: [foldAnimation, fadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, NgClass, ClearableInputComponent, RequiredValidatorDirective, PasswordInputComponent, GlobalsPipe]
})
export class LoginComponent implements AfterViewInit
{
    // #region Private Memers

    private _appVersion: string = '';
    @ViewChild('formLogin', { read: NgForm, static: false }) protected _formLogin: NgForm | undefined = undefined;


    // #endregion

    // #region Properties

    public get loginModel(): LoginModel
    {
        return this._loginModel;
    }

    public get appVersion(): string
    {
        return this._appVersion;
    }

    // #endregion

    // #region Constructors

    constructor(private _loginModel: LoginModel, private _router: Router, private _changeDetectorRef: ChangeDetectorRef,
        private _modalMessageService: ModalMessageService, private _routingHistoryService: RoutingHistoryService)
    {
        this._loginModel.clear();

        ContguardPlugin.getAppVersion().then((response: IGetAppVersionResponse) =>
        {
            this._appVersion = response.appVersion;
            this._changeDetectorRef.markForCheck();
        });

        const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
        const code: string | null = urlSearchParams.get('code');
        if (code !== null)
        {
            this.getLoginAccessToken(code);
        }
    }

    // #endregion

    // #region Event Handlers

    public ngAfterViewInit(): void
    {
        setTimeout(() => ContguardPlugin.hideSplashScreen(), Constants.SPLASH_DELAY_DURATION);
    }

    public onLoginSubmit(): void
    {
        this._loginModel.loginUser().subscribe((response: IApiResponseData<string | null>) =>
        {
            if (response.isSuccess)
            {
                if (response.data !== undefined && response.data !== null)
                {
                    this.submitExternalLogin(response.data);
                }
                else if (this._loginModel.defaultRouteRedirectTo !== null)
                {
                    this._routingHistoryService.navigateBack(true, true);
                }
                else
                {
                    this._modalMessageService.show(
                        {
                            title: Constants.APP_TITLE,
                            message: 'No web products available.<br>Please use the mobile app to check for mobile-only product access'
                        }).then(() =>
                        {
                            this._formLogin?.resetForm();
                            this._loginModel.clear();
                            this._changeDetectorRef.detectChanges();
                        });
                }
            }
            else
            {
                this._changeDetectorRef.detectChanges();
                this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error });
            }
        });
    }

    public onResetPasswordButtonClick(): void
    {
        this._router.navigate([`/${RouteType.ResetPassword}`]);
    }

    // #endregion

    // #region Private Methods

    private getLoginAccessToken(code: string): void
    {
        this._loginModel.getAccessToken(code).subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                this._routingHistoryService.navigateBack(true, true);
            }
            else
            {
                this._changeDetectorRef.detectChanges();
                this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error });
            }
        });

        this._changeDetectorRef.detectChanges();
    }

    private async submitExternalLogin(url: string): Promise<void>
    {
        const response: IExternalLoginResponse = await ContguardPlugin.externalLogin({ url: url });
        if (response === null || response.code === null || response.code === undefined)
        {
            this._changeDetectorRef.detectChanges();
            return;
        }

        this.getLoginAccessToken(response.code);
    }

    // #endregion
}
