<!DOCTYPE html>

@if (managerModel.selectedManagerItem === null) {
<div class="page-container page-image" @slideInOutReverseAnimation>
    <top-bar></top-bar>
    @if (viewIsInitialized) {
    <div class="page-content grid-view mobile-standalone" @fadeInAnimation>
        <div class="page-inner-content">
            <div class="page-padding">
                <div class="search-group">
                    <clearable-input [(ngModel)]="managerModel.searchFilter" label="Filter Smart Locks" icon="icon-search"
                                     class="search-control" [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0 &&
                                 (managerModel.searchFilter === null || managerModel.searchFilter.length === 0)">
                    </clearable-input>
                    <button type="button" class="image-button barcode-button ripple-effect ripple-center" tabindex="-1" 
                            (mousedown)="onBarcodeButtonMouseDown($event)">
                        <i class="icon-barcode-read"></i>
                    </button>
                </div>
                <div class="manager-controls-container">
                    <div class="manager-elements-count">{{ (!managerVirtualList ? 0 : managerVirtualList.filteredSortedItems.length) }} Smart Locks</div>
                    <button type="button" class="image-button refresh-button ripple-effect ripple-center" tooltipPopup="Refresh"
                            (click)="onRefreshButtonClick($event)">
                        <i class="icon-refresh"></i>
                    </button>
                </div>
            </div>
            <div class="virtual-list-container">
                <div class="manager-table-container page-padding">
                    <ovarlay-scrollbar>
                        <virtual-list #managerItemsList [items]="managerModel.filteredSortedManagerItems"
                                      [filter]="managerModel.searchFilter" [isScrollSnap]="false"
                                      class="grid-view" [virtualListInfo]="managerItemsListInfo" [filterProperties]="managerSearchProperties"
                                      pointerEvents [useMouseWheel]="false" (swipe)="onManagerItemsListSwipe($event)"
                                      (itemsChange)="onManagerItemsListChange($event)" [class.active]="managerItemsList.viewPortItems.length > 0">
                            @for (managerItem of managerItemsList.viewPortItems; track managerItem.unitNumber) {
                            <div #body
                                 class="manager-item select-none" [class.selected]="managerModel.selectedManagerItem === managerItem">
                                <div class="manager-item-content-container ripple-effect ripple-fill ripple-inverse-color"
                                     (click)="onManagerItemClick(managerItem)">
                                    <div class="manager-item-content-wrapper">
                                        <div class="manager-item-content">
                                            <div class="smart-lock-name" [innerHTML]="managerItem.name"></div>
                                            <div class="item-details">
                                                <div class="item-detail"><i class="icon-signal-stream"></i>{{ managerItem.lastTransmitDuration }}</div>
                                                <ng-container [ngTemplateOutlet]="smartLockIndicatorsTemplate"
                                                              [ngTemplateOutletContext]="{ managerItem: managerItem }">
                                                </ng-container>
                                            </div>
                                            <i class="icon-bluetooth" [class.active]="managerItem.deviceId"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </virtual-list>
                    </ovarlay-scrollbar>
                </div>
                @if (!managerVirtualList || managerVirtualList.viewPortItems.length === 0) {
                <div class="list-empty-placeholder" @fadeInOutAnimation>
                    <i class="icon-lock"></i>
                </div>
                }
            </div>
        </div>
    </div>
    }
</div>
}

@if (managerModel.selectedManagerItem !== null) {
<div class="page-container smart-lock" @slideInOutAnimation>
    <top-bar barTitle="Smart Lock"></top-bar>
    @if (viewIsInitialized) {
    <div class="page-content mobile-standalone">
        <div class="page-header">
            <button class="back-button ripple-effect ripple-inverse-color" (click)="onSmartLockBackButtonClick()">
                <i class="icon-collapse-left"></i>Back
            </button>
        </div>
        <div class="manager-item-content smart-lock-content page-scroll-padding">
            <div class="smart-lock-name" [innerHTML]="managerModel.selectedManagerItem.name"></div>
            <div class="item-details">
                <div class="item-detail connect-type">
                    @if (managerModel.selectedManagerItem.liveSmartLockStatus !== undefined) {
                    <div class="item-detail" @fadeSlideDownInOutAnimation><i class="icon-bluetooth active"></i>Live data</div>
                    }
                    @else {
                    <div class="item-detail" @fadeSlideDownInOutAnimation><i class="icon-signal-stream"></i>{{ managerModel.selectedManagerItem.lastTransmitDuration }}</div>
                    }
                </div>
                <ng-container [ngTemplateOutlet]="smartLockIndicatorsTemplate"
                              [ngTemplateOutletContext]="{ managerItem: (managerModel.selectedManagerItem.liveSmartLockStatus !== undefined ? managerModel.selectedManagerItem.liveSmartLockStatus : managerModel.selectedManagerItem) }">
                </ng-container>
                @if (managerModel.selectedManagerItem.liveSmartLockStatus === undefined) {
                <loader class="inline" loadingMessage="Connecting" [showLoadingIcon]="false" @fadeInOutAnimation></loader>
                }
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="smartLockImagesTemplate"
                      [ngTemplateOutletContext]="{ managerItem: (managerModel.selectedManagerItem.liveSmartLockStatus !== undefined ? managerModel.selectedManagerItem.liveSmartLockStatus : managerModel.selectedManagerItem) }">
        </ng-container>
        <div class="smart-lock-buttons-container">
            <button type="button" class="secondary-button ripple-effect ripple-fill ripple-inverse-color"
                    [disabled]="managerModel.selectedManagerItem.liveSmartLockStatus === undefined || (managerModel.selectedManagerItem.liveSmartLockStatus.logicState !== SmartLockLogicStateType.Locked && managerModel.selectedManagerItem.liveSmartLockStatus.logicState !== SmartLockLogicStateType.Unlocked)"
                    (click)="onUnlockSmartLockButtonClick()">
                @if (managerModel.selectedManagerItem.liveSmartLockStatus !== undefined && managerModel.selectedManagerItem.liveSmartLockStatus.logicState !== SmartLockLogicStateType.Locked) {
                <span @fadeSlideDownInOutAnimation><i class="icon-lock"></i><span>Lock</span></span>
                }
                @else {
                <span @fadeSlideDownInOutAnimation><i class="icon-lock-open"></i><span>Unlock</span></span>
                }
            </button>
            @if (managerModel.selectedManagerItem.smartLockKeyHash !== undefined && managerModel.selectedManagerItem.smartLockKeyHash.supportTurnOff && managerModel.selectedManagerItem.liveSmartLockStatus?.features) {
            <button type="button" class="secondary-button ripple-effect ripple-fill ripple-inverse-color" @foldFadeHorizontalAnimation
                    [disabled]="managerModel.selectedManagerItem.liveSmartLockStatus === undefined"
                    (click)="onTurnOffSmartLockButtonClick()">
                <span @fadeSlideDownInOutAnimation><i class="icon-logout"></i><span>Turn Off</span></span>
            </button>
            }
        </div>
    </div>
    }
</div>
}

@if (!viewIsReady || managerModel.isBusy) {
<loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation
        [loadingMessage]="statusMessage" [showSpinner]="showSpinner || managerModel.isBusy"
        [isBusyLoader]="viewIsReady && managerModel.isBusy" [isBusyLoaderOverlay]="true">
</loader>
}

<ng-template #smartLockIndicatorsTemplate let-managerItem="managerItem">
    <div class="item-detail">
        <i class="battery-empty">
            <i class="battery-indicator" [style.--battery-percent]="managerItem.baterryPowerPercent + '%'"></i>
        </i>
        <span>{{ managerItem.baterryPowerPercent }}%</span>
        <i class="icon-temperature-full"></i><span>{{ managerItem.temperature }}ºC</span>
    </div>
</ng-template>

<ng-template #smartLockImagesTemplate let-managerItem="managerItem">
    <div class="smart-lock-images"
         [class.smart-lock-open]="managerItem.logicState === SmartLockLogicStateType.Opened ||
             managerItem.logicState === SmartLockLogicStateType.BreakIn">
        <img class="shackle-image" src="assets/images/smart-lock-shackle.png" />
        <img src="assets/images/smart-lock-base.png" />
        @if (managerItem.logicState === SmartLockLogicStateType.Unlocked) {
        <img src="assets/images/smart-lock-button-on.png" class="animate-flicker" />
        }
        @if (managerItem.logicState === SmartLockLogicStateType.BreakIn) {
        <img src="assets/images/smart-lock-break-in-sign.png" @fadeInOutAnimation />
        }
    </div>
</ng-template>

