import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BarcodeScanner, BarcodesScannedEvent } from '@capacitor-mlkit/barcode-scanning';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';

@Component({
    selector: 'barcode-scanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class BarcodeScannerComponent implements OnInit, OnDestroy
{
    // #region Private Constants

    private readonly BARCODE_SCANNER_ACTIVE_CLASSNAME: string = 'barcode-scanner-active';

    // #endregion

    // #region Private Members

    private _audioElement: HTMLAudioElement = new Audio('assets/sounds/barcode-scanner.mp3');

    private _isTorchOn: boolean = false;

    private _isTorchAvailable: boolean = false;

    private _pluginListenerHandle: PluginListenerHandle | null = null;

    // #endregion

    // #region Properties

    public get isTorchAvailable(): boolean
    {
        return this._isTorchAvailable;
    }

    public get isTorchOn(): boolean
    {
        return this._isTorchOn;
    }

    // #endregion

    // #region Inputs

    @Input() public continuous: boolean = false;
    @Input() public isPaused: boolean = false;

    // #endregion

    // #region Constructor

    constructor(private _changeDetectorRef: ChangeDetectorRef)
    {
    }

    // #endregion

    // #region Events

    @Output() barcodeScanned: EventEmitter<string> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();

    // #endregion

    // #region Event Handlers

    public async ngOnInit(): Promise<void>
    {
        if (!Capacitor.isNativePlatform())
        {
            this.close.emit();
            return;
        }

        this._isTorchAvailable = (await BarcodeScanner.isTorchAvailable()).available;
        this._changeDetectorRef.markForCheck();

        document.body.classList.add(this.BARCODE_SCANNER_ACTIVE_CLASSNAME);

        this._pluginListenerHandle = await BarcodeScanner.addListener('barcodesScanned', async (result: BarcodesScannedEvent) =>
        {
            if (!this.isPaused)
            {
                this._audioElement.play();
                this.barcodeScanned.emit(result.barcodes[0].rawValue);
            }

            if (!this.continuous)
            {
                await this.closeBarcodeScan();
            }
        });

        await BarcodeScanner.startScan();
    }

    public async ngOnDestroy(): Promise<void>
    {
        await this.closeBarcodeScan();
    }

    public async onCloseButtonClick(): Promise<void>
    {
        await this.closeBarcodeScan();
    }

    public async onTorchButtonClick(): Promise<void>
    {
        await BarcodeScanner.toggleTorch();

        this._isTorchOn = !this._isTorchOn;
        this._changeDetectorRef.markForCheck();
    }

    // #endregion

    // #region Public Methods

    public async closeBarcodeScan(): Promise<void>
    {
        if (Capacitor.isNativePlatform())
        {
            await this._pluginListenerHandle!.remove();

            document.body.classList.remove(this.BARCODE_SCANNER_ACTIVE_CLASSNAME);

            await BarcodeScanner.stopScan();
        }

        this.close.emit();
    }

    // #endregion
}
