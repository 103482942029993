import { Injectable } from "@angular/core";
import { ManagerBaseModel } from "../../../base/models/manager-base.model";
import { ControlCenterAlertsCountRequest, MiniControlCenterAlertColumnType } from "./mini-control-center-model.class";
import { AlertEscalationRequestType, AlertStatusType, ControlCenterAlertConstants, DisplayControlCenterAlertData } from "../../control-center-alert/model/control-center-alert-model.class";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { DateTimeFormatType, Utils } from "../../../utils/utils";
import { Observable, Observer, Subject } from "rxjs";
import { Constants, IApiResponse, IApiResponseData } from "../../../utils/globals";
import { HttpHelper } from "../../../utils/http-helper";
import { cloneDeep, orderBy } from "lodash";
import { HttpErrorCodes } from "../../../utils/http-error-codes";

@Injectable({ providedIn: 'root' })
export class MiniControlCenterModel extends ManagerBaseModel<DisplayControlCenterAlertData, MiniControlCenterAlertColumnType>
{
    // #region Constants

    private readonly ENTITY_ITEM_CONTROLLER_NAME: string = 'mini-control-center';
    private readonly MIN_FORMAT_ALERTS_COUNT: number = 1000;

    // #endregion

    // #region Private Members

    private _alertsStatus: AlertStatusType = AlertStatusType.Pending;
    private _newAlerts: DisplayControlCenterAlertData[] = [];
    private _isAlertsInitialized: boolean = false;
    private _pendingAlertsCount: string = '';
    private _isFilterAnomalies: boolean = false;
    private _isFilterPending: boolean = false;
    private _alertsResponseSubject: Subject<IApiResponse> = new Subject<IApiResponse>();
    private _groupedAlerts: DisplayControlCenterAlertData[] = [];

    // #endregion

    // #region Properties

    public selectedAlerts: DisplayControlCenterAlertData[] = [];

    public get isAlertsInitialized(): boolean
    {
        return this._isAlertsInitialized;
    }

    public get alertsResponseObservable(): Observable<IApiResponse>
    {
        return this._alertsResponseSubject;
    }

    public get newAlerts(): DisplayControlCenterAlertData[]
    {
        return this._newAlerts;
    }

    public get groupedAlerts(): DisplayControlCenterAlertData[]
    {
        return this._groupedAlerts;
    }

    public get isFilterAnomalies(): boolean
    {
        return this._isFilterAnomalies;
    }

    public set isFilterAnomalies(value: boolean)
    {
        this._isFilterAnomalies = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    public get isFilterPending(): boolean
    {
        return this._isFilterPending;
    }

    public set isFilterPending(value: boolean)
    {
        this._isFilterPending = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    public get pendingAlertsCount(): string
    {
        return this._pendingAlertsCount;
    }

    public get alertsStatus(): AlertStatusType
    {
        return this._alertsStatus;
    }

    public set alertsStatus(value: AlertStatusType)
    {
        this._alertsStatus = value;

        if (this._alertsStatus === AlertStatusType.Done)
        {
            this._isFilterPending = false;
        }
        else
        {
            this._isFilterAnomalies = false;
        }

        this.applyFilters();
        this.sortManagerItems();
    }

    public get AlertStatusType()
    {
        return AlertStatusType;
    }

    // #endregion

    // #region Constructors

    constructor(_httpClient: HttpClient)
    {
        super(_httpClient);

        this._managerItemKeyPropertyName = Utils.getPropertyNameof<DisplayControlCenterAlertData>(new DisplayControlCenterAlertData(),
            displayControlCenterAlertData => displayControlCenterAlertData.id);
        this._managerItemDatePropertyName = Utils.getPropertyNameof<DisplayControlCenterAlertData>(new DisplayControlCenterAlertData(),
            displayControlCenterAlertData => displayControlCenterAlertData.created);
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        const managerItems: DisplayControlCenterAlertData[] = this._managerItems;

        super.clear();
        this._managerItems = [...managerItems];

        this._groupedAlerts = [];
        this.selectedAlerts = [];

        this._isFilterAnomalies = false;
        this._isFilterPending = false;
        this._alertsStatus = AlertStatusType.Pending;

        this.applyFilters();
        this.sortManagerItems();
    }

    public updateGroupedAndSelectedAlerts(): void
    {
        for (const alert of this.selectedAlerts)
        {
            alert.isSelected = false;
        }

        if (this.selectedManagerItem === null)
        {
            this._groupedAlerts = [];
            this.selectedAlerts = [];
        }
        else
        {
            this._groupedAlerts = [];

            const groupedAlerts: DisplayControlCenterAlertData[] = [];
            for (const alert of this._filteredManagerItems)
            {
                if (alert.shipmentKey === this.selectedManagerItem.shipmentKey && alert.alertReasonId === this.selectedManagerItem.alertReasonId)
                {
                    groupedAlerts.push(alert);
                }
            }

            this._groupedAlerts = groupedAlerts;

            const selectedAlerts: DisplayControlCenterAlertData[] = [];
            for (const alert of this._groupedAlerts)
            {
                for (const selectedAlert of this.selectedAlerts)
                {
                    if (alert.id === selectedAlert.id)
                    {
                        alert.isSelected = true;
                        selectedAlerts.push(alert);
                        break;
                    }
                }
            }

            if (!selectedAlerts.includes(this.selectedManagerItem))
            {
                this.selectedManagerItem.isSelected = true;
                selectedAlerts.push(this.selectedManagerItem);
            }

            this.selectedAlerts = selectedAlerts;
        }
    }

    public changePendingAlertsStatus(): Observable<IApiResponseData<DisplayControlCenterAlertData>>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponseData<DisplayControlCenterAlertData>>) =>
        {
            const alertsToUpdate: DisplayControlCenterAlertData[] = [];
            for (const selectedAlert of this.selectedAlerts)
            {
                if (selectedAlert.statusId === AlertStatusType.Pending)
                {
                    const alertToUpdate: DisplayControlCenterAlertData = Utils.clearObjectEmptyStrings(cloneDeep(selectedAlert));
                    alertToUpdate.statusId = AlertStatusType.InProcess;
                    alertsToUpdate.push(alertToUpdate);
                }
            }

            if (alertsToUpdate.length === 0)
            {
                this._isBusy = false;

                observer.next({ isSuccess: true, isComplete: true });
                observer.complete();
                return;
            }

            this._httpClient.put<DisplayControlCenterAlertData[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/alerts`, JSON.stringify(alertsToUpdate),
                { headers: HttpHelper.GetHttpJsonHeaders() }).subscribe(
                    {
                        next: (updatedAlerts: DisplayControlCenterAlertData[]) =>
                        {
                            this._isBusy = false;

                            for (const updatedAlert of updatedAlerts)
                            {
                                for (const selectedAlert of this.selectedAlerts)
                                {
                                    if (updatedAlert.id === selectedAlert.id)
                                    {
                                        Utils.copyObjectBySourceProperties(updatedAlert, selectedAlert);
                                        break;
                                    }
                                }
                            }

                            if (this._isFilterPending)
                            {
                                this.applyFilters();
                                this.sortManagerItems();
                            }

                            this.updatePendingAlertsCount(this.getPendingAlerts(this._managerItems));

                            observer.next({ isSuccess: true, isComplete: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            let errorMessage: string = Constants.DATA_SERVICE_ERROR_STRING;
                            let alert: DisplayControlCenterAlertData | undefined = undefined;
                            if (error.status === HttpErrorCodes.CONFLICT)
                            {
                                errorMessage = ControlCenterAlertConstants.CONFLICT_WITH_CHANGES_MESSAGE;
                                alert = error.error;
                            }

                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: errorMessage, data: alert, status: error.status });
                            observer.complete();
                        }
                    });
        });
    }

    public getAlertsCountBetweenDates(fromDate: Date, toDate: Date): Observable<IApiResponseData<number>>
    {
        return new Observable((observer: Observer<IApiResponseData<number>>) =>
        {
            const controlCenterAlertsCountRequest: ControlCenterAlertsCountRequest = new ControlCenterAlertsCountRequest();
            controlCenterAlertsCountRequest.fromDate = fromDate;
            controlCenterAlertsCountRequest.toDate = toDate;

            this._httpClient.post<number>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/alerts-count`, JSON.stringify(controlCenterAlertsCountRequest),
                { headers: HttpHelper.GetHttpJsonHeaders() }).subscribe(
                    {
                        next: (alertsCount: number) =>
                        {
                            observer.next({ isSuccess: true, data: alertsCount });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            console.error(error);

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
        });
    }

    public override getManagerItems(): Observable<IApiResponse>
    {
        this._isBusy = true;
        this._isBackgroundFetchBusy = this._alertsStatus !== AlertStatusType.Done;

        this._alertsResponseSubject.next({ isSuccess: true, isComplete: false });

        let doneAlerts: DisplayControlCenterAlertData[] | null = null;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const lastManagerItems: DisplayControlCenterAlertData[] = this._managerItems;
            this._managerItems = [];

            this._httpClient.get<DisplayControlCenterAlertData[]>(
                `${this.ENTITY_ITEM_CONTROLLER_NAME}${this._isBackgroundFetchBusy ? '/pending-and-in-process-alerts' : '/alerts'}`,
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (alerts: DisplayControlCenterAlertData[]) =>
                        {
                            const pendingAlerts: DisplayControlCenterAlertData[] = this.getPendingAlerts(alerts);
                            this.updatePendingAlertsCount(pendingAlerts);

                            if (this._isAlertsInitialized)
                            {
                                const newAlerts: DisplayControlCenterAlertData[] = [];

                                if (alerts.length > 0)
                                {
                                    const currentPendingAlertsIds: number[] =
                                        this.getPendingAlerts(lastManagerItems).map((alert: DisplayControlCenterAlertData) => alert.id!);

                                    for (const pendingAlert of pendingAlerts)
                                    {
                                        if (pendingAlert.id !== null && !currentPendingAlertsIds.includes(pendingAlert.id))
                                        {
                                            newAlerts.push(pendingAlert);
                                        }
                                    }
                                }

                                this._newAlerts = newAlerts.length === 0 ? [] :
                                    orderBy(newAlerts, [Utils.getPropertyNameof<DisplayControlCenterAlertData>(newAlerts[0], alert => alert.eventTime)], ['desc']);
                            }

                            if (doneAlerts !== null)
                            {
                                alerts.push(...doneAlerts);
                            }

                            this._isBusy = false;

                            this.updateManagerItemsResponse(observer, alerts, false);
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            this._alertsResponseSubject.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING, status: error.status });

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING, status: error.status });
                            observer.complete();
                        }
                    });

            if (this._isBackgroundFetchBusy)
            {
                this._httpClient.get<DisplayControlCenterAlertData[]>(`${this.ENTITY_ITEM_CONTROLLER_NAME}/done-alerts`,
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                        {
                            next: (alerts: DisplayControlCenterAlertData[]) =>
                            {
                                this._isBackgroundFetchBusy = false;

                                if (this._isBusy)
                                {
                                    doneAlerts = alerts;
                                }
                                else
                                {
                                    this.updateManagerItemsResponse(observer, alerts, true);
                                }
                            },
                            error: (error: HttpErrorResponse) =>
                            {
                                this._isBackgroundFetchBusy = false;
                                console.error(error);

                                observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                                observer.complete();
                            }
                        });
            }
        });
    }

    // #endregion

    // #region Protected Methods

    protected override sortManagerItems(): void
    {
        this._filteredSortedManagerItems = [...this._filteredManagerItems];
    }

    protected override applyFilters(managerItemsRefreshed: boolean = false): void
    {
        this._filteredManagerItems = this._managerItems.filter((alert: DisplayControlCenterAlertData) =>
        {
            if (!this.isDateRangeFiltered(alert, 'minutes'))
            {
                return false;
            }

            if (this._isFilterPending && alert.statusId !== AlertStatusType.Pending ||
                alert.statusId !== AlertStatusType.Done && this._alertsStatus === AlertStatusType.Done ||
                alert.statusId === AlertStatusType.Done && this._alertsStatus !== AlertStatusType.Done)
            {
                return false;
            }

            if (this._isFilterAnomalies && !alert.isReportAnomaly)
            {
                return false;
            }

            if (!this.isDateRangeFiltered(alert))
            {
                return false;
            }

            return true;
        });

        if (!managerItemsRefreshed)
        {
            this.updateSelectedManagerItem();
            this.updateGroupedAndSelectedAlerts();
        }
    }

    // #endregion

    // #region Private Methods

    private updatePendingAlertsCount(pendingAlerts: DisplayControlCenterAlertData[]): void
    {
        this._pendingAlertsCount = pendingAlerts.length > 0 ? (`${pendingAlerts.length >= this.MIN_FORMAT_ALERTS_COUNT ?
            Utils.getFormattedNumber(pendingAlerts.length, true) : pendingAlerts.length}`) : '';
    }

    private getPendingAlerts(alerts: DisplayControlCenterAlertData[]): DisplayControlCenterAlertData[]
    {
        return alerts.filter((alert: DisplayControlCenterAlertData) => alert.statusId === AlertStatusType.Pending);
    }

    private updateManagerItemsResponse(observer: Observer<IApiResponse>, alerts: DisplayControlCenterAlertData[], isDoneItems: boolean): void
    {
        for (const alert of alerts)
        {
            if (alert.created !== null)
            {
                alert.createTimeFormatted = Utils.getFormattedDateTime(alert.created, DateTimeFormatType.DateTime);
            }

            if (alert.eventTime !== null)
            {
                alert.eventTimeFormatted = Utils.getFormattedDateTime(alert.eventTime, DateTimeFormatType.DateTime);
            }

            alert.escalationRequirementFormatted = Utils.getEnumParsedDescription(AlertEscalationRequestType, alert.escalationRequirement);

            this._managerItems.push(alert);
        }

        this.applyFilters(true);
        this.sortManagerItems();

        if (this._alertsStatus === AlertStatusType.Done && isDoneItems || this._alertsStatus !== AlertStatusType.Done && !isDoneItems)
        {
            this.updateGroupedAndSelectedAlerts();
        }

        if (!isDoneItems)
        {
            this._isAlertsInitialized = true;
        }

        this._alertsResponseSubject.next({ isSuccess: true, isComplete: !this._isBackgroundFetchBusy });

        observer.next({ isSuccess: true, isComplete: true });

        if (!this._isBackgroundFetchBusy && !this._isBusy)
        {
            observer.complete();
        }
    }

    // #endregion
}