import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { KeyValue } from '../../utils/globals';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { Utils } from '../../utils/utils';

@Component({
    selector: 'numeric-input',
    templateUrl: './numeric-input.component.html',
    styleUrls: ['./numeric-input.component.css'],
    animations: [fadeInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: NumericInputComponent, multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule]
})

export class NumericInputComponent implements ControlValueAccessor, OnInit
{
    // #region Private Members

    private _numericRegExp: RegExp = new RegExp('');
    private _numericPrefixRegExp: RegExp = new RegExp('');

    private _minValue: number | null = null;
    private _maxValue: number | null = null;

    private _innerValue: number | null = null;

    private _changed = new Array<(value: number | null) => void>();
    private _touched = new Array<() => void>();

    @ViewChild('numericInput') private _inputElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

    // #endregion

    // #region Properties

    public get value(): number | null
    {
        return this._innerValue;
    }

    public set value(value: number | null)
    {
        if (this._innerValue !== value)
        {
            if (value !== null)
            {
                if (value.toString().length === 0)
                {
                    value = null
                }
                else if (!isNaN(Number(value)))
                {
                    if (Number(value).toString() !== value.toString())
                    {
                        return;
                    }

                    value = Number(value);
                }
                else if (this._numericPrefixRegExp.test(value.toString()))
                {
                    return;
                }
                else
                {
                    value = null
                }
            }

            this._innerValue = value;

            this._changed.forEach(f => f(value));
            this._touched.forEach(f => f());
            this.change.emit(value);
        }
    }

    public get currencySymbol(): string | null
    {
        return Utils.getCurrencySymbol();
    }

    // #endregion

    // #region Inputs

    @Input() public disabled: boolean = false;
    @Input() public readonly: boolean = false;
    @Input() public clearable: boolean = true;
    @Input() public label: string = "";
    @Input() public maxLength: number = 10;
    @Input() public icon: string | null = null;
    @Input() public isRequired: boolean = false;
    @Input() public isDecimal: boolean = false;
    @Input() public isNegative: boolean = false;
    @Input() public decimalScale: number | null = 2;
    @Input() public isCurrency: boolean = false;
    @Input() public isPercentage: boolean = false;

    @Input()
    public get minValue(): number | null
    {
        return this._minValue;
    }

    public set minValue(minValue: number | null)
    {
        this._minValue = minValue;
        this.updateValueByRange();
    }

    @Input()
    public get maxValue(): number | null
    {
        return this._maxValue;
    }

    public set maxValue(maxValue: number | null)
    {
        this._maxValue = maxValue;
        this.updateValueByRange();
    }

    // #endregion

    // #region Events

    @Output() private focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
    @Output() private blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
    @Output() private change: EventEmitter<number | null> = new EventEmitter<number | null>();

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        this._numericRegExp = new RegExp(`^${this.isNegative ? '-?' : ''}${''
            }(?!-?0\\d)(\\d+${this.isDecimal ? `(\\.\\d${this.decimalScale !== null ? `{1,${this.decimalScale}}` : ''})` : ''}?)$`);

        this._numericPrefixRegExp = new RegExp(`^${this.isNegative ? '-?' : ''}(?!-?0\\d)(\\d+${this.isDecimal ? '(\\.)?' : ''})?$`);
    }

    public onFocus(event: FocusEvent): void
    {
        this.focus.emit(event);
    }

    public onBlur(event: FocusEvent): void
    {
        this.blur.emit(event);
    }

    public onFocusOut(): void
    {
        if (this._inputElementRef === undefined || this._inputElementRef.nativeElement.value === '')
        {
            return;
        }

        const value: number = Number(this._inputElementRef.nativeElement.value);

        if (this.value === null)
        {
            if (isNaN(Number(value)) || value.toString() !== this._inputElementRef.nativeElement.value)
            {
                this._inputElementRef.nativeElement.value = '';
            }
            else if (this.validateRange(value))
            {
                this.value = value;
            }
        }
        else if (this._inputElementRef.nativeElement.value !== this.value.toString())
        {
            if (!isNaN(value))
            {
                this.value = this.validateRange(value) ? value : null;
            }

            this._inputElementRef.nativeElement.value = this.value!.toString();
        }
    }

    public onPaste(event: ClipboardEvent): void
    {
        if (event.clipboardData === null)
        {
            return;
        }

        if (!this.validateText(event.clipboardData.getData('Text').trim()))
        {
            event.preventDefault();
        }
    }

    public onKeyDown(event: KeyboardEvent): void
    {
        if (event.key === KeyValue.Enter)
        {
            return;
        }

        if (event.key !== undefined && event.key.length === 1 && !this.validateText(event.key))
        {
            event.preventDefault();
        }
    }

    public onInput(): void
    {
        if (this._inputElementRef !== undefined && !this._numericPrefixRegExp.test(this._inputElementRef.nativeElement.value) &&
            !this._numericRegExp.test(this._inputElementRef.nativeElement.value))
        {
            this.value = null;
        }
    }

    // #endregion

    // #region Public Methods

    public touch(): void
    {
        this._touched.forEach(f => f());
    }

    public writeValue(value: number | null): void
    {
        if (value !== undefined)
        {
            this._innerValue = value;
        }
    }

    public registerOnChange(fn: (value: number | null) => void): void
    {
        this._changed.push(fn);
    }

    public registerOnTouched(fn: () => void): void
    {
        this._touched.push(fn);
    }

    // #endregion

    // #region Private Methods

    private updateValueByRange(): void
    {
        if (this._innerValue === null)
        {
            return;
        }

        if (this._minValue !== null && this._innerValue < this._minValue)
        {
            this.value = this._minValue;
        }
        else if (this._maxValue !== null && this._innerValue > this._maxValue)
        {
            this.value = this._maxValue;
        }
    }

    private validateText(newText: string): boolean
    {
        if (this._inputElementRef === undefined)
        {
            return false;
        }

        const prefixText: string = this._inputElementRef.nativeElement.value.substring(0, this._inputElementRef.nativeElement.selectionStart ?? 0);
        const suffixText: string = this._inputElementRef.nativeElement.value.substring(this._inputElementRef.nativeElement.selectionEnd ?? 0,
            this._inputElementRef.nativeElement.value.length);

        const targetText: string = `${prefixText}${newText}${suffixText}`;
        if (targetText.length > this.maxLength)
        {
            return false;
        }

        const isValidNumber: boolean = this._numericRegExp.test(targetText);

        if (this._numericPrefixRegExp.test(targetText) && !isValidNumber)
        {
            return true;
        }

        return isValidNumber ? this.validateRange(Number(targetText)) : false;
    }

    private validateRange(value: number | null): boolean
    {
        if (this.minValue !== null && value !== null && value < Number(this.minValue))
        {
            return false;
        }

        if (this.maxValue !== null && value !== null && value > Number(this.maxValue))
        {
            return false;
        }

        return true;
    }

    // #endregion
}