import { Constants } from "../../../utils/globals";
import { AnimationsConstants } from "../../../animations/constant";
import { CGIOverlayView } from "./cgi-overlay-view";
import { ICGIRenderer } from "./cgi-cluster-base-renderer";
import { CGICluster } from "./cgi-cluster";
import { GoogleMapUtils } from "./google-map-utils";
import Supercluster, { ClusterFeature } from "supercluster";
import { isEqual } from "lodash";
import { ICGIMarkerLocation } from "../../../base/classes/cgi-marker";

export type onClusterClickHandler = (event: google.maps.MapMouseEvent, cluster: CGICluster, map: google.maps.Map) => void;
export type createMarkerCallbackFunction = (location: ICGIMarkerLocation) => google.maps.marker.AdvancedMarkerElement;

export const defaultOnClusterClickHandler: onClusterClickHandler = (_: google.maps.MapMouseEvent, cluster: CGICluster, map: google.maps.Map): void =>
    map.fitBounds(cluster.bounds!);

export interface MarkerClustererOptions
{
    locations?: ICGIMarkerLocation[];
    map: google.maps.Map;
    renderer: ICGIRenderer;
    createMarkerCallback: createMarkerCallbackFunction;
    onClusterClick?: onClusterClickHandler;
}

export enum MarkerClustererEvents
{
    CLUSTERING_BEGIN = "clusteringbegin",
    CLUSTERING_END = "clusteringend"
}

export const SUPERCLUSTER_OPTIONS: Supercluster.Options<Supercluster.AnyProps, Supercluster.AnyProps> = { maxZoom: 21, radius: 150 };
export const MAP_MAX_BOUNDS: GeoJSON.BBox = [-180, -90, 180, 90];
export const MARKER_TOPMOST_ZINDEX: number = 10000000;

export class CGIMarkerClusterer extends CGIOverlayView
{
    // #region Constants

    private readonly MARKER_FADEOUT_ANIMATION_DURATION: number = AnimationsConstants.ANIMATION_DURATION;

    // #endregion

    // #region Private Members

    private _clusters: CGICluster[] = [];
    private _markers: (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[];
    private _renderer: ICGIRenderer;
    private _createMarkerCallback: createMarkerCallbackFunction;
    private _supercluster: Supercluster = new Supercluster(SUPERCLUSTER_OPTIONS);

    private _lastActiveMarker: google.maps.marker.AdvancedMarkerElement | null = null;
    private _lastActiveMarkerSavedZIndex: number | null | undefined = null;
    private _activeMarker: google.maps.marker.AdvancedMarkerElement | null = null;

    private _markerToClusterMap: Map<google.maps.marker.AdvancedMarkerElement, CGICluster> = new Map<google.maps.marker.AdvancedMarkerElement, CGICluster>();

    // #endregion

    // #region Properties

    public onClusterClick: onClusterClickHandler = defaultOnClusterClickHandler;
    public onClusterDblClick: onClusterClickHandler | null = null;

    public viewIsReady: boolean = false;

    public get activeMarker(): google.maps.marker.AdvancedMarkerElement | null
    {
        return this._activeMarker;
    }

    public set activeMarker(activeMarker: google.maps.marker.AdvancedMarkerElement | null)
    {
        this._activeMarker = activeMarker;

        if (this._activeMarker !== null)
        {
            this.updateActiveMarker(this._activeMarker);
        }

        for (const marker of this._markerToClusterMap.keys())
        {
            this.updateActiveMarker(marker);
        }
    }

    public get clusters(): CGICluster[]
    {
        return this._clusters;
    }

    public get markers(): (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[]
    {
        return this._markers;
    }

    // #endregion

    // #region Constructors

    constructor({ map, locations = [], renderer, createMarkerCallback }: MarkerClustererOptions)
    {
        super();

        this.setMap(map!);
        this._markers = locations;
        this._renderer = renderer;
        this._createMarkerCallback = createMarkerCallback;

        this.initializeRendering();

        google.maps.event.addListenerOnce(map, 'idle', () =>
        {
            this.loadMarkersPoints();
            this.render();
        });
    }

    // #endregion

    // #region Public Methods

    public waitForMapClusteringEnd(): Promise<void>
    {
        return new Promise<void>((resolve) =>
        {
            google.maps.event.addListenerOnce(this, MarkerClustererEvents.CLUSTERING_END, () => resolve());
        });
    }

    public findMarkerByData(data: any): google.maps.marker.AdvancedMarkerElement | null
    {
        for (let i: number = 0; i < this._markers.length; i++)
        {
            const marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation = this._markers[i];
            if (marker instanceof google.maps.marker.AdvancedMarkerElement)
            {
                if (isEqual((marker as any).data, data))
                {
                    return marker;
                }
            }
            else if (isEqual(marker, data))
            {
                const newMarker: google.maps.marker.AdvancedMarkerElement = this.createMarker(marker);
                this._markers[i] = newMarker;

                for (const cluster of this._clusters)
                {
                    const markerIndex: number = cluster.markers.indexOf(data);
                    if (markerIndex >= 0)
                    {
                        cluster.markers[markerIndex] = newMarker;
                        this._markerToClusterMap.set(newMarker, cluster);
                        break;
                    }
                }

                this.loadMarkersPoints();

                return newMarker;
            }
        }

        return null;
    }

    public updateActiveMarker(marker: google.maps.marker.AdvancedMarkerElement): void
    {
        if (marker === this._lastActiveMarker)
        {
            marker.zIndex = this._lastActiveMarkerSavedZIndex;
        }

        if (this._activeMarker !== null && this._activeMarker === marker)
        {
            this._lastActiveMarker = this._activeMarker;
            this._lastActiveMarkerSavedZIndex = this._activeMarker.zIndex;

            this._activeMarker.zIndex = MARKER_TOPMOST_ZINDEX;
        }

        this.updateMakerActiveVisibility(marker);
    }

    public findClusterByMarker(marker: google.maps.marker.AdvancedMarkerElement | null): CGICluster | undefined
    {
        return marker === null ? undefined : this._markerToClusterMap.get(marker);
    }

    public clear(): void
    {
        for (const cluster of this.clusters)
        {
            cluster.delete();
        }

        this._clusters = [];

        for (const marker of this._markers)
        {
            if (marker instanceof google.maps.marker.AdvancedMarkerElement)
            {
                marker.map = null;
            }
        }

        this._markers = [];

        this._markerToClusterMap.clear();

        this.loadMarkersPoints();

        this.render();
    }

    public replaceMarkers(locations: ICGIMarkerLocation[]): void
    {
        this._lastActiveMarker = null;

        const oldMarkers: (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[] = [...this._markers];
        oldMarkers.push(...this._markerToClusterMap.keys())

        this._markers = [...locations];
        this._clusters = [];

        this.loadMarkersPoints();

        this.render();

        setTimeout(() =>
        {
            const map: google.maps.Map = this.getMap() as google.maps.Map;
            const bounds: google.maps.LatLngBounds = map.getBounds()!;

            for (const marker of oldMarkers)
            {
                if (marker instanceof google.maps.marker.AdvancedMarkerElement)
                {
                    this.removeMarkerFromMap(marker, bounds);
                }
            }
        }, this.MARKER_FADEOUT_ANIMATION_DURATION);
    }

    public addMarker(marker: google.maps.marker.AdvancedMarkerElement): void
    {
        if (this._markers.includes(marker))
        {
            return;
        }

        this._markers.push(marker);

        this.loadMarkersPoints();

        this.render();

        const cluster: CGICluster | undefined = this.findClusterByMarker(marker);
        if (cluster !== undefined && cluster.markers.length > 1)
        {
            const map: google.maps.Map = this.getMap() as google.maps.Map;
            const bounds: google.maps.LatLngBounds = map.getBounds()!;

            this.removeMarkerFromMap(marker, bounds);
        }
    }

    public removeMarker(marker: google.maps.marker.AdvancedMarkerElement): void
    {
        const map: google.maps.Map = this.getMap() as google.maps.Map;

        const cluster: CGICluster | undefined = this.findClusterByMarker(marker);
        if (cluster === undefined)
        {
            return;
        }

        this._markers.splice(this._markers.indexOf(marker), 1);
        this._markerToClusterMap.delete(marker);

        this.loadMarkersPoints();

        if (cluster.markers.length === 1)
        {
            cluster.marker = undefined;
            cluster.delete();

            this._clusters.splice(this._clusters.indexOf(cluster), 1);
        }
        else if (cluster.marker !== undefined)
        {
            cluster.markers.splice(cluster.markers.indexOf(marker), 1);

            const updatedClusterMarker: google.maps.marker.AdvancedMarkerElement =
                this._renderer.render(cluster, map);

            const updatedClusterMarkerContentElement: HTMLElement = updatedClusterMarker.content as HTMLElement;

            const clusterMarkerContentElement: HTMLElement = (cluster.marker).content as HTMLElement;

            clusterMarkerContentElement.className = updatedClusterMarkerContentElement.className;
            clusterMarkerContentElement.style.background = updatedClusterMarkerContentElement.style.background;
            clusterMarkerContentElement.innerHTML = updatedClusterMarkerContentElement.innerHTML;
            clusterMarkerContentElement.classList.remove(Constants.ACTIVE_CLASSNAME);
        }
    }

    // #endregion

    // #region Private Methods

    private loadMarkersPoints()
    {
        this._supercluster.load(GoogleMapUtils.getMarkerSuperclusterPoints(this._markers));
    }

    private createCGICluster({ geometry: { coordinates: [lng, lat] }, properties }:
        ClusterFeature<{ marker: google.maps.marker.AdvancedMarkerElement }>): CGICluster
    {
        if (properties.cluster)
        {
            return new CGICluster(
                {
                    markers: this._supercluster.getLeaves(properties.cluster_id, Infinity).map((leaf: Supercluster.PointFeature<Supercluster.AnyProps>) =>
                        leaf.properties['marker']), position: { lat, lng }
                });
        }

        const marker = properties.marker;

        return new CGICluster(
            {
                markers: [marker],
                position: marker.position!,
            });
    }

    private render(): void
    {
        const map: google.maps.Map = this.getMap() as google.maps.Map;
        const bounds: google.maps.LatLngBounds = map.getBounds()!;

        google.maps.event.trigger(this, MarkerClustererEvents.CLUSTERING_BEGIN, this);

        const activeCluster: CGICluster | undefined = this.findClusterByMarker(this.activeMarker);

        const clusters: CGICluster[] = this._supercluster.getClusters(MAP_MAX_BOUNDS, Math.round(map.getZoom()!)).
            map((feature: ClusterFeature<any>) => this.createCGICluster(feature));

        const singleMarker = new Set<google.maps.marker.AdvancedMarkerElement>();
        for (const cluster of clusters)
        {
            if (cluster.markers.length === 1 && cluster.markers[0] instanceof google.maps.marker.AdvancedMarkerElement)
            {
                singleMarker.add(cluster.markers[0]);
            }
        }

        const groupMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

        for (const cluster of this._clusters)
        {
            if (cluster.marker == undefined)
            {
                continue;
            }

            const clusterMarker: google.maps.marker.AdvancedMarkerElement = cluster.marker

            if (cluster.markers.length === 1)
            {
                if (!singleMarker.has(clusterMarker))
                {
                    this.removeMarkerFromMap(clusterMarker, bounds);
                }
            }
            else
            {
                groupMarkers.push(clusterMarker);
            }
        }

        this._markerToClusterMap.clear();

        this._clusters = clusters;
        this.renderClusters();

        for (const marker of groupMarkers)
        {
            this.removeMarkerFromMap(marker, bounds);
        }

        if (activeCluster !== undefined)
        {
            for (const cluster of this._clusters)
            {
                if (cluster.markers.length === activeCluster.markers.length &&
                    cluster.position.lat() === activeCluster.position.lat() && cluster.position.lng() === activeCluster.position.lng())
                {
                    if (cluster.marker !== undefined)
                    {
                        this.activeMarker = cluster.marker;
                    }

                    break;
                }
            }
        }

        google.maps.event.trigger(this, MarkerClustererEvents.CLUSTERING_END, this);
    }

    private initializeRendering(): void
    {
        const map: google.maps.Map = this.getMap() as google.maps.Map;

        let lastZoom: number = map.getZoom()!;

        google.maps.event.addListener(map, 'bounds_changed', () =>
        {
            const mapZoom: number = map.getZoom()!;
            if (mapZoom === lastZoom)
            {
                this.renderClusters();
                return;
            }

            if (mapZoom < lastZoom)
            {
                this.render();
            }
            else
            {
                google.maps.event.addListenerOnce(map, 'idle', () => this.render());
            }

            lastZoom = mapZoom;
        });
    }

    private setMarkerMap(marker: google.maps.marker.AdvancedMarkerElement, map: google.maps.Map): void
    {
        marker.map = map;
        this.updateActiveMarker(marker);
        (marker.content as HTMLElement).classList.remove(Constants.HIDDEN_CLASSNAME);
    }

    private createMarker(markerLocation: ICGIMarkerLocation): google.maps.marker.AdvancedMarkerElement
    {
        const marker: google.maps.marker.AdvancedMarkerElement = this._createMarkerCallback(markerLocation);
        this.updateActiveMarker(marker);
        return marker;
    }

    private updateMakerActiveVisibility(marker: google.maps.marker.AdvancedMarkerElement): void
    {
        const markerElement: HTMLElement = marker.content as HTMLElement;

        if (this._activeMarker === null || this._activeMarker === marker)
        {
            markerElement.classList.add(Constants.ACTIVE_CLASSNAME);
        }
        else
        {
            markerElement.classList.remove(Constants.ACTIVE_CLASSNAME);
        }
    }

    private renderClusters(): void
    {
        const map: google.maps.Map = this.getMap() as google.maps.Map;
        const bounds: google.maps.LatLngBounds = map.getBounds()!;

        let isMarkerUpdated: boolean = false;

        for (const cluster of this._clusters)
        {
            const isClusterMarkerValid: boolean = cluster.marker instanceof google.maps.marker.AdvancedMarkerElement;

            if (!bounds.contains(cluster.position))
            {
                if (isClusterMarkerValid)
                {
                    this.removeMarkerFromMap(cluster.marker!);
                }

                continue;
            }
            else if (isClusterMarkerValid)
            {
                if (cluster.marker!.map === null)
                {
                    this.setMarkerMap(cluster.marker!, map);
                    this.updateMakerActiveVisibility(cluster.marker!);
                }

                continue;
            }

            if (cluster.markers.length === 1)
            {
                if (!(cluster.markers[0] instanceof google.maps.marker.AdvancedMarkerElement))
                {
                    const newMarker: google.maps.marker.AdvancedMarkerElement = this.createMarker(cluster.markers[0]);
                    this._markers[this._markers.indexOf(cluster.markers[0])] = newMarker;
                    cluster.markers[0] = newMarker;
                    isMarkerUpdated = true;
                }

                cluster.marker = cluster.markers[0];
            }
            else
            {
                cluster.marker = this._renderer.render(cluster, map);
            }

            this._markerToClusterMap.set(cluster.marker, cluster);
            if (cluster.markers.length > 1)
            {
                for (const marker of cluster.markers)
                {
                    if (marker instanceof google.maps.marker.AdvancedMarkerElement)
                    {
                        this._markerToClusterMap.set(marker, cluster);
                        this.removeMarkerFromMap(marker);
                    }
                }
            }

            this.setMarkerMap(cluster.marker, map);
            this.updateMakerActiveVisibility(cluster.marker);

            const markerElement: HTMLElement = cluster.marker.content as HTMLElement;

            if (cluster.markers.length > 1)
            {
                setTimeout(() =>
                {
                    let clickTimeoutHandleId: NodeJS.Timeout | null = null;

                    if (this.onClusterDblClick !== null)
                    {
                        markerElement.addEventListener('click', (event: MouseEvent) =>
                        {
                            if (clickTimeoutHandleId !== null)
                            {
                                clearTimeout(clickTimeoutHandleId);
                                clickTimeoutHandleId = null;

                                if (this.onClusterDblClick !== null)
                                {
                                    google.maps.event.trigger(this, 'dblclick', cluster);

                                    this.onClusterDblClick({ domEvent: event, latLng: cluster.position, stop: () => { } }, cluster, map);
                                }
                            }
                            else
                            {
                                clickTimeoutHandleId = setTimeout(() =>
                                {
                                    clickTimeoutHandleId = null;

                                    google.maps.event.trigger(this, 'click', cluster);

                                    if (this.onClusterClick !== null)
                                    {
                                        this.onClusterClick({ domEvent: event, latLng: cluster.position, stop: () => { } }, cluster, map);
                                    }
                                }, Constants.CLICK_TIMEOUT_DURATION);
                            }
                        });
                    }

                    if (this.onClusterClick !== null)
                    {
                        cluster.marker!.addListener('click', () => { });
                    }
                });
            }
        }

        if (isMarkerUpdated)
        {
            this.loadMarkersPoints();
        }
    }

    private removeMarkerFromMap(marker: google.maps.marker.AdvancedMarkerElement, bounds: google.maps.LatLngBounds | null = null): void
    {
        if (marker.map === null)
        {
            return;
        }

        if (!this.viewIsReady || bounds !== null && !bounds.contains(marker.position!))
        {
            marker.map = null;
            return;
        }

        const contentElement: HTMLElement = marker.content as HTMLElement;

        contentElement.addEventListener('transitionend', () =>
        {
            if (contentElement.classList.contains(Constants.HIDDEN_CLASSNAME))
            {
                marker.map = null;
                contentElement.classList.remove(Constants.HIDDEN_CLASSNAME);
            }
        }, { once: true });

        contentElement.classList.add(Constants.HIDDEN_CLASSNAME);
    }

    // #endregion
}