import { HttpClient } from "@angular/common/http";
import { HttpHelper } from "./http-helper";
import { firstValueFrom } from "rxjs";
import { DateTimeFormatType, Utils } from "./utils";
import { MonitoringSegmentType } from "../base/models/manager-base-model.class";
import { cloneDeep } from "lodash";
import { DisplayShipmentMonitoring, ShipmentMonitoringPerimeterType } from "../shipments-monitoring/shipment-monitoring/model/shipment-monitoring-model.class";
import { MonitoringDetailedEventType } from "../base/models/monitoring-items-base-model.class";

export class ManagerConstants
{
    public static readonly NO_DEVICE_VALUE: string = 'No Device';
    public static readonly NO_ROUTE_DESCRIPTION: string = 'No Route';
    public static readonly MISSING_ROUTE_DESCRIPTION: string = 'Unknown Route';
    public static readonly DEMO_ROUTE_ORIGIN: string = 'Warehouse Origin';
    public static readonly DEMO_ROUTE_DESTINATION: string = 'Warehouse Destination';
    public static readonly DEMO_ROUTE_DESCRIPTION: string = `${this.DEMO_ROUTE_ORIGIN} → ${this.DEMO_ROUTE_DESTINATION}`;
    public static readonly MISSING_VALUE: string = 'Unknown';
    public static readonly NO_VALUE: string = 'None';
}

export class CountryDetails
{
    public countryName: string | null = null;
    public countryCode: string | null = null;
}

export class DisplayProperties
{
    color: string = '';
    iconClassName: string = '';
    class: string = '';
    tooltip: string = '';
    units?: string = '';
}

export class MonitoringUtils
{
    // #region Private Constants

    private static readonly WAREHOUSE_ICON_CLASSNAME: string = 'icon-warehouse';
    private static readonly TRAIN_STATION_ICON_CLASSNAME: string = 'icon-train-station';
    private static readonly AIRPORT_ICON_CLASSNAME: string = 'icon-airport';
    private static readonly CONTAINER_ICON_CLASSNAME: string = 'icon-container';
    private static readonly SEAPORT_ICON_CLASSNAME: string = 'icon-destination-port';
    private static readonly WAYPOINT_ICON_CLASSNAME: string = 'icon-waypoint';

    private static readonly SHIPMENT_IN_TRANSIT: string = 'Shipment in transit';
    private static readonly PORT_OF_LOADING: string = 'POL';
    private static readonly PORT_OF_DESTINATION: string = 'POD';
    private static readonly TRANSSHIPMENT: string = 'Transhipment';

    private static readonly DEVICE_TYPE_WITH_G_FORCE: string = 'CMU AD';
    private static readonly DEVICE_TYPE_WITH_LOCK: string = 'CMU Lock';

    // #endregion

    // #region Public Constants

    public static readonly TEMPERATURE_EVENT_TITLE: string = 'Temperature';
    public static readonly HUMIDITY_EVENT_TITLE: string = 'Humidity';
    public static readonly SECURITY_EVENT_TITLE: string = 'Security';
    public static readonly LIGHT_ON_EVENT_TITLE: string = 'Light On';
    public static readonly DOOR_OPEN_EVENT_TITLE: string = 'Door Open';
    public static readonly GFORCE_EVENT_TITLE: string = 'G-Force';
    public static readonly LIGHT_EVENT_TITLE: string = 'Light';
    public static readonly STOP_EVENT_TITLE: string = 'Stop';
    public static readonly IMAPCT_EVENT_TITLE: string = 'Impact';
    public static readonly TIME_BASED_TEMPERATURE_EVENT_TITLE: string = 'Time-Based Temperature';
    public static readonly TIME_BASED_HUMIDITY_EVENT_TITLE: string = 'Time-Based Humidity';
    public static readonly TIME_EXCEEDED_AT_PORT_EVENT_TITLE: string = 'Time Exceeded at Port';

    public static readonly TEMPERATURE_UNITS: string = 'ºC';
    public static readonly HUMIDITY_UNITS: string = '%';
    public static readonly LIGHT_UNITS: string = 'Lux';
    public static readonly GFORCE_UNITS: string = 'g';

    public static readonly MISSING_SHIPMENT: string = '<b>This shipment can\'t be found.</b><br>Please go back and try a diffrent shipment.';

    public static readonly SHIPMENT_EVENT_TYPES_COUNT: number =
        (Object.values(MonitoringDetailedEventType).filter((value: any) => typeof (value) === 'number') as number[]).length;

    public static readonly SHIPMENT_EVENTS_DISPLAY_PROPERTIES: DisplayProperties[] =
        [
            { color: '', iconClassName: '', tooltip: 'None', class: 'none', units: '' },
            { color: '--color-event-impact', iconClassName: 'icon-explosion-solid', tooltip: this.IMAPCT_EVENT_TITLE, class: 'impact', units: this.GFORCE_UNITS },
            { color: '--color-event-impact', iconClassName: 'icon-explosion-solid', tooltip: this.IMAPCT_EVENT_TITLE, class: 'impact', units: this.GFORCE_UNITS },
            { color: '--color-event-temperature', iconClassName: 'icon-temperature-full', tooltip: this.TEMPERATURE_EVENT_TITLE, class: 'temperature', units: this.TEMPERATURE_UNITS },
            { color: '--color-event-temperature', iconClassName: 'icon-temperature-full', tooltip: this.TEMPERATURE_EVENT_TITLE, class: 'temperature', units: this.TEMPERATURE_UNITS },
            { color: '--color-event-humidity', iconClassName: 'icon-droplet', tooltip: this.HUMIDITY_EVENT_TITLE, class: 'humidity', units: this.HUMIDITY_UNITS },
            { color: '--color-event-humidity', iconClassName: 'icon-droplet', tooltip: this.HUMIDITY_EVENT_TITLE, class: 'humidity', units: this.HUMIDITY_UNITS },
            { color: '--color-event-security', iconClassName: 'icon-lightbulb-on', tooltip: this.LIGHT_ON_EVENT_TITLE, class: 'security' },
            { color: '--color-event-security', iconClassName: 'icon-door-open', tooltip: this.DOOR_OPEN_EVENT_TITLE, class: 'security' },
            { color: '--color-event-security', iconClassName: 'icon-lock-open', tooltip: this.SECURITY_EVENT_TITLE, class: 'security', units: this.LIGHT_UNITS },
            { color: '--color-event-stop', iconClassName: 'icon-hand', tooltip: this.STOP_EVENT_TITLE, class: 'stop' },
            { color: '--color-event-temperature', iconClassName: 'icon-time-based-temperature', tooltip: this.TIME_BASED_TEMPERATURE_EVENT_TITLE, class: 'temperature', units: this.TEMPERATURE_UNITS },
            { color: '--color-event-temperature', iconClassName: 'icon-time-based-temperature', tooltip: this.TIME_BASED_TEMPERATURE_EVENT_TITLE, class: 'temperature', units: this.TEMPERATURE_UNITS },
            { color: '--color-event-humidity', iconClassName: 'icon-time-based-humidity', tooltip: this.TIME_BASED_HUMIDITY_EVENT_TITLE, class: 'humidity', units: this.HUMIDITY_UNITS },
            { color: '--color-event-humidity', iconClassName: 'icon-time-based-humidity', tooltip: this.TIME_BASED_HUMIDITY_EVENT_TITLE, class: 'humidity', units: this.HUMIDITY_UNITS },
            { color: '--color-event-time-exceeded', iconClassName: 'icon-stopwatch', tooltip: this.TIME_EXCEEDED_AT_PORT_EVENT_TITLE, class: 'time-exceeded' }
        ];

    public static readonly SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES: DisplayProperties[] =
        [
            { color: '--color-arrival-unknown', iconClassName: 'icon-hourglass-empty', tooltip: 'Unknown', class: 'unknown' },
            { color: '--color-arrival-ontime', iconClassName: 'icon-hourglass-start', tooltip: 'Early / On Time', class: 'early' },
            { color: '--color-arrival-ontime', iconClassName: 'icon-hourglass-start', tooltip: 'On Time', class: 'early' },
            { color: '--color-arrival-late', iconClassName: 'icon-hourglass-end', tooltip: 'Late', class: 'late' }
        ];

    public static readonly SHIPMENT_DEMMURAGE_CONTRACT_DEVIATION_DISPLAY_PROPERTIES: DisplayProperties[] =
        [
            { color: '--color-arrival-late', iconClassName: '', tooltip: 'Late', class: 'late' },
            { color: '--color-arrival-ontime', iconClassName: 'icon-hourglass-start', tooltip: 'On Time', class: 'early' },
            { color: '--color-arrival-unknown', iconClassName: 'icon-hourglass-empty', tooltip: 'Unknown', class: 'unknown' }
        ];

    // #endregion

    // #region Public Methods

    public static getUpdatedEventDisplayPropertiesByDeviceType(eventType: MonitoringDetailedEventType, deviceType: string | null): DisplayProperties
    {
        const displayProperties: DisplayProperties = cloneDeep(MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventType]);

        if (eventType === MonitoringDetailedEventType.Security)
        {
            if (deviceType !== this.DEVICE_TYPE_WITH_LOCK)
            {
                displayProperties.tooltip = MonitoringUtils.LIGHT_EVENT_TITLE;
            }
            else
            {
                displayProperties.units = undefined;
            }
        }
        else if (eventType === MonitoringDetailedEventType.StrongImpact)
        {
            if (deviceType === MonitoringUtils.DEVICE_TYPE_WITH_G_FORCE)
            {
                displayProperties.tooltip = MonitoringUtils.GFORCE_EVENT_TITLE;
            }
            else
            {
                displayProperties.units = undefined;
            }
        }

        return displayProperties;
    }

    public static getShipmentIconClassName(shipmentMonitoringSegmentId: MonitoringSegmentType, shipmentMonitoringPerimeterType: string | null): string
    {
        switch (shipmentMonitoringSegmentId)
        {
            case MonitoringSegmentType.PortOfDestination:
            case MonitoringSegmentType.PortOfLoading:
            case MonitoringSegmentType.Transhipment:
                {
                    switch (shipmentMonitoringPerimeterType)
                    {
                        case ShipmentMonitoringPerimeterType.Airport:
                            {
                                return MonitoringUtils.AIRPORT_ICON_CLASSNAME;
                            }

                        case ShipmentMonitoringPerimeterType.RailwayStation:
                            {
                                return MonitoringUtils.TRAIN_STATION_ICON_CLASSNAME;
                            }

                        default:
                            {
                                return MonitoringUtils.SEAPORT_ICON_CLASSNAME;
                            }
                    }
                }

            case MonitoringSegmentType.Waypoint:
                {
                    return MonitoringUtils.WAYPOINT_ICON_CLASSNAME;
                }

            case MonitoringSegmentType.Destination:
            case MonitoringSegmentType.Origin:
                {
                    return MonitoringUtils.WAREHOUSE_ICON_CLASSNAME;
                }

            default:
                {
                    return MonitoringUtils.CONTAINER_ICON_CLASSNAME;
                }
        }
    }

    public static getShipmentPerimeterName(shipmentMonitoringSegmentId: MonitoringSegmentType, shipmentMonitoringPerimeterName: string | null): string
    {
        let updatedPerimeterName: string | null = shipmentMonitoringPerimeterName;

        if (shipmentMonitoringPerimeterName === null)// || shipmentMonitoringSegmentId === MonitoringSegmentType.Way)
        {
            updatedPerimeterName = this.SHIPMENT_IN_TRANSIT;
        }
        else if (shipmentMonitoringSegmentId === MonitoringSegmentType.PortOfLoading)
        {
            updatedPerimeterName = `${this.PORT_OF_LOADING}: ${shipmentMonitoringPerimeterName}`;
        }
        else if (shipmentMonitoringSegmentId === MonitoringSegmentType.PortOfDestination)
        {
            updatedPerimeterName = `${this.PORT_OF_DESTINATION}: ${shipmentMonitoringPerimeterName}`;
        }
        else if (shipmentMonitoringSegmentId === MonitoringSegmentType.Transhipment)
        {
            updatedPerimeterName = `${this.TRANSSHIPMENT}: ${shipmentMonitoringPerimeterName}`;
        }

        return updatedPerimeterName!;
    }

    public static async getCountryDetailsFromLocation(latitude: number | null, longitude: number | null,
        httpClient: HttpClient): Promise<CountryDetails>
    {
        const countryDetails: CountryDetails = new CountryDetails();

        if (latitude === null || longitude === null)
        {
            return countryDetails;
        }

        try
        {
            const url: string = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

            const response: any = await firstValueFrom(httpClient.get(url, { headers: HttpHelper.GetHttpJsonHeaders() }));
            if (!Utils.isNullOrUndefined(response.address))
            {
                countryDetails.countryName = response.address.country;
                countryDetails.countryCode = response.address.country_code.toLowerCase();
            }
        }
        catch (error: any)
        {
            console.error(error);
        }

        return countryDetails;
    }

    public static updateDisplayShipmentMonitoringProperties(displayShipmentMonitoring: DisplayShipmentMonitoring, isDemo: boolean): void
    {
        displayShipmentMonitoring.installDateFormatted = displayShipmentMonitoring.installDate !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.installDate), DateTimeFormatType.DateTime) :
            ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.removeDateFormatted = displayShipmentMonitoring.removeDate !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.removeDate), DateTimeFormatType.DateTime) :
            ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.etaFormatted = displayShipmentMonitoring.eta !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.eta), DateTimeFormatType.DateTime) : ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.ptaFormatted = displayShipmentMonitoring.pta !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.pta), DateTimeFormatType.DateTime) : ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.installDateShortFormatted = displayShipmentMonitoring.installDate !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.installDate), DateTimeFormatType.Date) : ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.removeDateShortFormatted = displayShipmentMonitoring.removeDate !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.removeDate), DateTimeFormatType.Date) : ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.etaShortFormatted = displayShipmentMonitoring.eta !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.eta), DateTimeFormatType.Date) : ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.ptaShortFormatted = displayShipmentMonitoring.pta !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.pta), DateTimeFormatType.Date) : ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.lastUpdateFormatted = displayShipmentMonitoring.lastUpdate !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.lastUpdate), DateTimeFormatType.DateTime) :
            ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.deviceDescription = displayShipmentMonitoring.deviceId === null ? ManagerConstants.NO_DEVICE_VALUE :
            displayShipmentMonitoring.deviceId;

        if (Utils.isNullOrUndefined(displayShipmentMonitoring.containerId))
        {
            displayShipmentMonitoring.containerId = ManagerConstants.MISSING_VALUE;
        }

        if (Utils.isNullOrUndefined(displayShipmentMonitoring.trailerNumber))
        {
            displayShipmentMonitoring.trailerNumber = ManagerConstants.MISSING_VALUE;
        }

        if (Utils.isNullOrUndefined(displayShipmentMonitoring.bookingReference))
        {
            displayShipmentMonitoring.bookingReference = ManagerConstants.NO_VALUE;
        }

        if (Utils.isNullOrUndefined(displayShipmentMonitoring.delayString))
        {
            displayShipmentMonitoring.delayString = ManagerConstants.MISSING_VALUE;
        }

        if (Utils.isNullOrUndefined(displayShipmentMonitoring.currentSegment))
        {
            displayShipmentMonitoring.currentSegment = ManagerConstants.MISSING_VALUE;
        }

        displayShipmentMonitoring.segmentDescription = Utils.getEnumParsedDescription(MonitoringSegmentType, displayShipmentMonitoring.segmentId);

        if (isDemo)
        {
            displayShipmentMonitoring.originName = displayShipmentMonitoring.originNameDemo;
            displayShipmentMonitoring.destinationName = displayShipmentMonitoring.destinationNameDemo;
            displayShipmentMonitoring.currentLocDes = displayShipmentMonitoring.segmentDescription;
        }

        if (Utils.isNullOrEmpty(displayShipmentMonitoring.originName) && Utils.isNullOrEmpty(displayShipmentMonitoring.destinationName))
        {
            displayShipmentMonitoring.routeDescription = ManagerConstants.MISSING_ROUTE_DESCRIPTION;
        }
        else
        {
            displayShipmentMonitoring.routeDescription = `${displayShipmentMonitoring.originName ?? ''} → ${displayShipmentMonitoring.destinationName ?? ''}`;
        }

        displayShipmentMonitoring.location = { lat: displayShipmentMonitoring.currentLat, lng: displayShipmentMonitoring.currentLon };
    }

    public static getShipmentsRoutsCount(shipments: DisplayShipmentMonitoring[]): number
    {
        const uniqueSet = new Set();
        return shipments.filter((shipment: DisplayShipmentMonitoring) =>
        {
            const route: string = `${shipment.originName}${shipment.destinationName}`;
            if (!uniqueSet.has(route))
            {
                uniqueSet.add(route);
                return true;
            }

            return false;
        }).length;
    }

    // #endregion
}