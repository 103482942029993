import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum SwipeDirection { Up, Down, Left, Right }

@Directive({
    selector: '[pointerEvents]',
    standalone: true
})
export class PointerEventsDirective implements OnInit
{
    // #region Private Constants

    private readonly SWIPE_TIMEOUT_DURATION: number = 500;
    private readonly SWIPE_MIN_DISTANCE: number = 20;
    private readonly SWIPE_OPPOSITE_MAX_DISTANCE: number = 60;
    private readonly MOUSE_WHEEL_CHECK_DURATION: number = 400;
    private readonly MOUSE_WHEEL_DISTANCE: number = 200;

    // #endregion

    // #region Private Members

    private _pointerStartX: number = 0;
    private _pointerStartY: number = 0;
    private _pointerStartTime: number = 0;
    private _pointerDownElement: HTMLElement | null = null;

    private _mouseWheelStartTime: number = 0;
    private _mouseWheelDeltaY: number = 0;

    // #endregion

    // #region Inputs

    @Input() public useMouseWheel: boolean = true;

    // #endregion

    // #region Events

    @Output() private swipe: EventEmitter<SwipeDirection> = new EventEmitter<SwipeDirection>();

    // #endregion

    // #region Constructor

    constructor(private _elementRef: ElementRef<HTMLElement>)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        this._elementRef.nativeElement.addEventListener('wheel', this.onWheel.bind(this), { passive: true });
        this._elementRef.nativeElement.addEventListener('touchstart', this.onTouchStart.bind(this), { passive: true });
        this._elementRef.nativeElement.addEventListener('mousedown', this.onMouseDown.bind(this), { passive: true });
        this._elementRef.nativeElement.addEventListener('touchend', this.onTouchEnd.bind(this), { passive: true });
        this._elementRef.nativeElement.addEventListener('mouseup', this.onMouseUp.bind(this), { passive: true });
    }

    private onWheel(event: WheelEvent): void
    {
        if (!this.useMouseWheel)
        {
            return;
        }

        event.preventDefault();

        if (this._mouseWheelStartTime === 0)
        {
            this._mouseWheelStartTime = performance.now() - this.MOUSE_WHEEL_CHECK_DURATION;
        }

        if (this._mouseWheelDeltaY !== 0 && Math.sign(this._mouseWheelDeltaY) !== Math.sign(event.deltaY))
        {
            this._mouseWheelDeltaY = Math.sign(event.deltaY) * this.MOUSE_WHEEL_DISTANCE;
            this._mouseWheelStartTime = 0;
        }
        else
        {
            this._mouseWheelDeltaY += event.deltaY;
        }

        if (performance.now() - this._mouseWheelStartTime > this.MOUSE_WHEEL_CHECK_DURATION)
        {
            this._mouseWheelStartTime = performance.now();

            if (Math.abs(this._mouseWheelDeltaY) >= this.MOUSE_WHEEL_DISTANCE)
            {
                if (this._mouseWheelDeltaY > 0)
                {
                    this.swipe.emit(SwipeDirection.Down);
                    this.swipe.emit(SwipeDirection.Right);
                }
                else
                {
                    this.swipe.emit(SwipeDirection.Up);
                    this.swipe.emit(SwipeDirection.Left);
                }
            }

            this._mouseWheelDeltaY = 0;
        }
    }

    private onTouchStart(event: TouchEvent): void
    {
        this._pointerStartTime = performance.now();
        this._pointerStartX = event.changedTouches[0].screenX;
        this._pointerStartY = event.changedTouches[0].screenY;
    }

    private onMouseDown(event: MouseEvent): void
    {
        this._pointerStartTime = performance.now();
        this._pointerStartX = event.clientX;
        this._pointerStartY = event.clientY;

        this._pointerDownElement = event.target as HTMLElement;
    }

    private onTouchEnd(event: TouchEvent): void
    {
        this._pointerDownElement = null;

        this.analyzeEventPointerSwipe(event.changedTouches[0].screenX, event.changedTouches[0].screenY);
    }

    private onMouseUp(event: MouseEvent): void
    {
        if (this._pointerDownElement === event.target)
        {
            this.analyzeEventPointerSwipe(event.clientX, event.clientY);
        }

        this._pointerDownElement = null;
    }

    // #endregion

    // #region Private Methods

    private analyzeEventPointerSwipe(x: number, y: number): void
    {
        const deltaTime: number = performance.now() - this._pointerStartTime;
        if (deltaTime < this.SWIPE_TIMEOUT_DURATION)
        {
            if (Math.abs(x - this._pointerStartX) > this.SWIPE_MIN_DISTANCE && Math.abs(y - this._pointerStartY) < this.SWIPE_OPPOSITE_MAX_DISTANCE)
            {
                if (x > this._pointerStartX)
                {
                    this.swipe.emit(SwipeDirection.Right);
                }
                else
                {
                    this.swipe.emit(SwipeDirection.Left);
                }
            }
            else if (Math.abs(y - this._pointerStartY) > this.SWIPE_MIN_DISTANCE && Math.abs(x - this._pointerStartX) < this.SWIPE_OPPOSITE_MAX_DISTANCE)
            {
                if (y > this._pointerStartY)
                {
                    this.swipe.emit(SwipeDirection.Down);
                }
                else
                {
                    this.swipe.emit(SwipeDirection.Up);
                }
            }
        }

        this._pointerStartTime = 0;
    }

    // #endregion
}
