<!DOCTYPE html>

<div class="page-container" [class.page-image]="('IS_MOBILE' | globals)" [class.administrator]="managerModel.isAdministrator">
    @if (('IS_MOBILE' | globals)) {
    <top-bar></top-bar>
    }
    @if (viewIsInitialized) {
    <div class="page-content" [class.grid-view]="isGridView" [class.mobile-standalone]="('IS_MOBILE' | globals)" @fadeInAnimation>
        <div class="page-inner-content">
            <div class="page-padding">
                <div class="form-group">
                    <clearable-input [(ngModel)]="managerModel.searchFilter" label="Filter Inventories" icon="icon-search"
                                     class="search-control" [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0 &&
                                     (managerModel.searchFilter === null ||
                                     managerModel.searchFilter.length === 0)">
                    </clearable-input>
                </div>
                <div class="manager-controls-container">
                    <div class="manager-buttons-container">
                        <button type="button" class="add-button ripple-effect ripple-fill" tooltipPopup="Add inventory"
                                (click)="onAddManagerItemButtonClick()">
                            <span>+</span>
                        </button>
                        @if (!('IS_MOBILE' | globals)) {
                        <div class="manager-items-view-type-container">
                            <label class="radio-container" tooltipPopup="Table view">
                                <input type="radio" name="manager-item-view-type" [(ngModel)]="isGridView" [value]="false" />
                                <span class="radio-checkmark ripple-effect ripple-center ripple-inverse-color icon-list-ul"></span>
                            </label>
                            <label class="radio-container" tooltipPopup="Tiles view">
                                <input type="radio" name="manager-item-view-type" [(ngModel)]="isGridView" [value]="true" />
                                <span class="radio-checkmark ripple-effect ripple-center ripple-inverse-color icon-grid"></span>
                            </label>
                        </div>
                        }
                    </div>
                    @if (!('IS_MOBILE' | globals)) {
                    <div class="dates-filter">
                        <datetime-picker label="From" name="filterFromDate" #filterFromDate="ngModel" [(ngModel)]="managerModel.filterFromDate"
                                         [maxDate]="managerModel.filterToDate" [autoSelect]="false">
                        </datetime-picker>
                        <datetime-picker label="To" name="filterToDate" #filterToDate="ngModel" [(ngModel)]="managerModel.filterToDate"
                                         [minDate]="managerModel.filterFromDate" [autoSelect]="false">
                        </datetime-picker>
                    </div>
                    }
                    <div class="sort-container">
                        @if (isGridView && !('IS_MOBILE' | globals)) {
                        <div class="animation-overflow sort-by" @foldFadeHorizontalAnimation>
                            <select-list placeholder="Sort by" [items]="managerTableSortColumns" [clearable]="false"
                                         [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0"
                                         [(ngModel)]="managerModel.sortColumn"
                                         bindLabel="text" bindValue="columnType">
                            </select-list>
                        </div>
                        }
                        @if (isGridView) {
                        <button type="button" class="image-button sort-button ripple-effect ripple-center ripple-inverse-color"
                                @foldFadeHorizontalAnimation [class.descending]="managerModel.isSortDescending"
                                [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0"
                                (click)="onSortDirectionButtonClick()" tooltipPopup="Sort">
                            <i class="icon-sort-direction"></i>
                        </button>
                        }
                    </div>
                    <div class="manager-behaviour-controls">
                        <label class="checkbox-container ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Show last inventories update">
                            <input name="filterLast" type="checkbox" [(ngModel)]="managerModel.isFilterLastInventory">
                            <span class="container-background"></span>
                            <i class="icon-warehouse-alt filter-last"></i>
                        </label>
                        @if (!('IS_MOBILE' | globals)) {
                        <button type="button" class="image-button excel-button ripple-effect ripple-center"
                                (click)="onExportToExcelButtonClick()" tooltipPopup="Export to Excel">
                            <i class="icon-excel"></i>
                        </button>
                        }
                        <button type="button" class="image-button refresh-button ripple-effect ripple-center" tooltipPopup="Refresh"
                                (click)="onRefreshButtonClick($event)">
                            <i class="icon-refresh"></i>
                        </button>
                    </div>
                </div>
                <div class="manager-elements-count">{{ (!managerVirtualList ? 0 : managerVirtualList.filteredSortedItems.length) }} Inventories</div>
            </div>
            <div class="virtual-list-container">
                <div class="manager-table-container" [class.page-padding]="!isGridView || ('IS_MOBILE' | globals)">
                    @if (isGridView) {
                    <ovarlay-scrollbar>
                        <virtual-list #managerItemsList [items]="managerModel.filteredSortedManagerItems"
                                      [filter]="managerModel.searchFilter" [isScrollSnap]="false"
                                      class="grid-view" [virtualListInfo]="managerItemsListInfo" [itemsPerRow]="gridViewItemsPerRow"
                                      [filterProperties]="managerSearchProperties"
                                      pointerEvents [useMouseWheel]="false" (swipe)="onManagerItemsListSwipe($event)"
                                      (itemsChange)="onManagerItemsListChange($event)" [class.active]="managerItemsList.viewPortItems.length > 0"
                                      [style.--grid-view-items-per-row]="gridViewItemsPerRow" (resized)="onManagerItemsListResized()">
                            @for (managerItem of managerItemsList.viewPortItems; track managerItem.inventoryId) {
                            <div #body
                                 class="manager-item select-none" [class.selected]="managerModel.selectedManagerItem === managerItem">
                                <div class="manager-item-content-container ripple-effect ripple-fill ripple-inverse-color"
                                     (click)="onManagerItemClick(managerItem)">
                                    <div class="manager-item-content">
                                        @for (column of managerListColumns; track column.columnType; let columnIndex = $index) {
                                        @switch (column.columnType) {
                                        @case (InventoryColumnType.Delete) {
                                        }
                                        @case (InventoryColumnType.Locks) {
                                        <div class="manager-element-details-item" [class]="column.classes ?? ''" [style.order]="column.order ?? columnIndex">
                                            <span class="manager-element-details-name">Amounts:</span>
                                            <span class="manager-element-details-value split-content">
                                                <span class="value"><b>Locks: </b>{{ managerItem.locks }}</span>
                                                <span class="value"><b>Shackles: </b>{{ managerItem.shackles }}</span>
                                                <span class="value"><b>Cables: </b>{{ managerItem.cables }}</span>
                                                <span class="value"><b>Chargers: </b>{{ managerItem.chargers }}</span>
                                            </span>
                                        </div>
                                        }
                                        @case (InventoryColumnType.Shackles) {
                                        }
                                        @case (InventoryColumnType.Cables) {
                                        }
                                        @case (InventoryColumnType.Chargers) {
                                        }
                                        @default {
                                        <div class="manager-element-details-item" [class]="column.classes ?? ''" [style.order]="column.order ?? columnIndex">
                                            <span class="manager-element-details-name"><span [innerHTML]="column.text"></span>:</span>
                                            <span class="manager-element-details-value"
                                                  [tooltipPopup]="column.propertyName ? managerItem[column.propertyName] : ''" [showTooltipOnVerflow]="true">
                                                <span>{{ column.propertyName ? managerItem[column.propertyName] : '' }}</span>
                                            </span>
                                        </div>
                                        }
                                        }
                                        }
                                        <div class="manager-item-details-open">
                                            @for (column of managerListColumns; track column.columnType) {
                                            @if (column.columnType === InventoryColumnType.Delete) {
                                            <button type="button" class="image-button ripple-effect ripple-inverse-color"
                                                    (click)="onInventoryDeleteButtonClick($event, managerItem)">
                                                <i class="icon-delete"></i>
                                            </button>
                                            }
                                            }
                                            @if (('IS_MOBILE' | globals)) {
                                            <i class="icon-chevron-right"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </virtual-list>
                    </ovarlay-scrollbar>
                    }
                    @if (!isGridView) {
                    <virtual-list #managerItemsList [items]="managerModel.filteredSortedManagerItems" id="inventory-manager-table"
                                  class="manager-table" [filter]="managerModel.searchFilter" tabindex="-1" [isSortable]="true"
                                  [class.vertical-scrollable]="managerItemsList.element.scrollHeight > managerItemsList.element.clientHeight"
                                  [filterProperties]="managerSearchProperties"
                                  [virtualListInfo]="managerTableListInfo" [isAutoColumnsWidths]="true"
                                  (itemsChange)="onManagerItemsListChange($event)" (sorted)="onManagerItemsListSorted()"
                                  [class.active]="managerItemsList.viewPortItems.length > 0">
                        <div #header class="list-header">
                            @for (column of managerTableColumns; track column.columnType) {
                            <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName"
                                 [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                                @switch (column.columnType) {
                                @case (InventoryColumnType.Delete) {
                                }
                                @default {
                                <span [innerHTML]="column.text"></span>
                                }
                                }
                            </div>
                            }
                        </div>
                        @for (managerItem of managerItemsList.viewPortItems; track managerItem.inventoryId) {
                        <div #body class="list-row ripple-effect ripple-fill ripple-inverse-color"
                             (click)="onManagerItemClick(managerItem)"
                             [ngClass]="{ 'odd-row': managerItemsList.filteredSortedItems.indexOf(managerItem) % 2,
                            'selected': managerItem === managerModel.selectedManagerItem }">
                            @for (column of managerTableColumns; track column.columnType) {
                            <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName">
                                @switch (column.columnType) {
                                @case (InventoryColumnType.Delete) {
                                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                        (click)="onInventoryDeleteButtonClick($event, managerItem)">
                                    <i class="icon-delete"></i>
                                </button>
                                }
                                @default {
                                <div [tooltipPopup]="column.propertyName ? managerItem[column.propertyName] : ''" [showTooltipOnVerflow]="true">
                                    <span>{{ column.propertyName ? managerItem[column.propertyName] : '' }}</span>
                                </div>
                                }
                                }
                            </div>
                            }
                        </div>
                        }
                    </virtual-list>
                    }
                </div>
                @if (!managerVirtualList || managerVirtualList.viewPortItems.length === 0) {
                <div class="list-empty-placeholder" @fadeInOutAnimation>
                    <i class="icon-inventory"></i>
                </div>
                }
            </div>
        </div>
        @if (!('IS_MOBILE' | globals)) {
        <div class="page-inner-content">
            <div class="manager-details-pane">
                @if (!managerModel.selectedManagerItem) {
                <div class="manager-details-placeholder">
                    <div class="title">No inventory selected</div>
                    <div>Please select an inventory from the list<br />to view or edit one</div>
                    <i class="icon-edit"></i>
                </div>
                }
                @if (managerModel.selectedManagerItem) {
                <div class="manager-element-container" @shortFadeInOutAnimation>
                    <div class="manager-element-title">
                        <span>Inventory {{ managerModel.selectedManagerItem.inventoryId }}</span>
                    </div>
                    <inventory class="manager-element" #managerItem (submitingManagerItem)="onSubmitingManagerItem($event)"></inventory>
                </div>
                }
            </div>
        </div>
        }
        @if (showAddNewManagerElement) {
        <div tabindex="-1" class="modal-backdrop new-element" (click)="onCloseAddNewManagerElementClick()" @fadeInOutAnimation></div>
        }
        @if (showAddNewManagerElement) {
        <div class="manager-new-element new-element" tabindex="-1" @fadeSlideDownInOutAnimation>
            <div class="modal-content-container">
                <div class="modal-content page-container page-image">
                    <div class="modal-header">
                        <div class="modal-title">New Inventory</div>
                        <button type="button" class="image-button close-button" (click)="onCloseAddNewManagerElementClick()"><i class="icon-cancel"></i></button>
                    </div>
                    <div class="modal-body page-scroll-padding">
                        <inventory #managerItem (submitingManagerItem)="onSubmitingManagerItem($event)"></inventory>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    }

    @if (!viewIsReady || managerModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation
            [loadingMessage]="statusMessage" [showSpinner]="showSpinner || managerModel.isBusy"
            [isBusyLoader]="viewIsReady && managerModel.isBusy" [isBusyLoaderOverlay]="true">
    </loader>
    }
</div>

